<div class="mmc-modal-wrapper add-extras-modal">
    <div class="mmc-modal-header mmc_flex--container">
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center">
            <i class="material-icons">star_border</i>
        </div>
        <div class="mmc_flex__item mmc_flex__item--vcenter">
            <span class="section-title txt-extra add-extra-txt">Add extras</span>
        </div>
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
            <button mat-button [mat-dialog-close]="true"><i class="material-icons">close</i></button>
        </div>
    </div>
    <div class="mmc-modal-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="main_search">
                    <div class="mmc_flex--container">
                        <div class="mmc_flex__item search-field pl-3 input_cage main-upsell-search">
                            <mat-form-field class="pw-txt-h4">
                                <input matInput placeholder="Search for extras" value="" [(ngModel)]="searchString" (keyup.enter)="onEnter()" name="facilityText">
                            </mat-form-field>
                        </div>
                        <div class="mmc_flex__item search-options">
                            <div class="mmc_flex--container">
                                <div class="mmc_flex__item search-date" *ngIf="false">
                                    <div class="mmc_flex--container">
                                        <div class="mmc_flex__item">
                                            <div class="font__small opt-txt__caption">
                                                Select date
                                            </div>
                                            <span class="selected-date">All</span>
                                        </div>
                                        <div class="mmc_flex__item mmc_just-content-end">
                                            <span class="down-arrow" [matMenuTriggerFor]="dateMenu"><i class="material-icons">keyboard_arrow_down</i></span>
                                            <mat-menu class="date-select-menu" #dateMenu="matMenu">
                                                <span class="font__semibold">Select dates</span>
                                                <div class="all">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox>All</mat-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="date-select-row">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="year">2018</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="month">Jan</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div><mat-checkbox class="day">10th Jan</mat-checkbox></div>
                                                            <div><mat-checkbox class="day">11th Jan</mat-checkbox></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="date-select-row">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="year">2019</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="month">Jan</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div><mat-checkbox class="day">10th Jan</mat-checkbox></div>
                                                            <div><mat-checkbox class="day">11th Jan</mat-checkbox></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <mat-checkbox class="month">Feb</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div><mat-checkbox class="day">10th Feb</mat-checkbox></div>
                                                            <div><mat-checkbox class="day">11th Feb</mat-checkbox></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </div>
                                <div class="mmc_flex__item search-category input_cage">
                                    <mat-form-field>
                                        <mat-label>Item category</mat-label>
                                        <mat-select [(ngModel)]="selectedItemType" placeholder="Item Type">
                                            <mat-option [value]="0">All</mat-option>
                                            <mat-option *ngFor="let itemCategory of facilitySummary?.availableUpsellItemCategories" [value]="itemCategory.id">{{itemCategory.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="mmc_flex__item search-btn btn--search" tabindex="0" (click)="searchClick()" (keydown.enter)="searchClick()">
                                    <i class="material-icons">
                                        search
                                    </i>
                                    <span class="btn-text">Search</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="items-available-label pw-txt-b3 pw-text-medium pw-text-color__tertiary" *ngIf="recordCount!=0">{{recordCount}} items available</div>
                <div class="items-available-label pw-txt-b3 pw-text-medium pw-text-color__tertiary" *ngIf="recordCount==0">There are no items matching your search criteria</div>
            </div>
        </div>
        <div class="row" *ngFor="let upsell of upsells;let i = index">
            <div class="col-sm-12">
                <div class="inner-container">
                    <div class="mmc_card search--result">
                        <div class="mmc_flex--container mobile-card">
                            <div class="mmc_flex__item image-cage">
                                <a>
                                    <img class="img" src="{{upsell.url}}" (error)="errorHandler($event)" />
                                    <div class="overlay"></div>
                                </a>
                                <a>
                                    <img class="img" />
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <div class="mmc_flex__item content">
                                <div class="mmc_flex--container header-content">
                                    <div class="mmc_flex__item">
                                        <div class='item_name'>
                                            <div class="pw-txt-h3_small pw-text-color__primary pw-text-semi-bold">{{upsell.name}}</div>
                                        </div>
                                        <div class='item_address opt-txt__caption'>
                                            <div class="mmc_flex--container ">
                                                <div class="mmc_flex__item--vcenter pw-txt-s2 pw-text-color__secondary">
                                                    {{upsell.description}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mmc_flex__item--inherit mmc_just-content-center mmc_fd--col quentity">
                                        <label class="qty font__semibold">QTY</label>
                                        <div class="quentity-increase">
                                            <button mat-raised-button tabindex="0" (click)="reduceQty(upsell)" (keydown.enter)="reduceQty(upsell)" [ngClass]="{'disable-div' : upsell.isAdded && !isEdit}">-</button>
                                            <mat-form-field class="qty-input">
                                                <input matInput type="number" [(ngModel)]="upsell.qty" (ngModelChange)="qtyChange(upsell)" min="1" onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" [disabled]="upsell.isAdded && !isEdit">
                                            </mat-form-field>
                                            <button mat-raised-button [disabled]="upsell.qty >= upsell.availabilityQty && upsell.availabilityUl == false" tabindex="0" (click)="addQty(upsell)" (keydown.enter)="addQty(upsell)" [ngClass]="{'disable-div' : upsell.isAdded && !isEdit}">+</button>
              
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mmc_flex__item butn__cage">
                                <div class="price">
                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.coverPrice && isUnitPriceRange(upsell.coverPrice)">{{upsell.coverPrice?.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsell.coverPrice?.maxPrice?.priceIncludingTax | CurrencyFormat}}/Covers</div>
                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.coverPrice && !isUnitPriceRange(upsell.coverPrice)  && (upsell.coverPrice?.minPrice || upsell.coverPrice?.maxPrice)">{{((upsell.coverPrice?.minPrice?.priceIncludingTax)?upsell.coverPrice?.minPrice?.priceIncludingTax : upsell.coverPrice?.maxPrice?.priceIncludingTax) | CurrencyFormat}}/Covers</div>

                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.hourlyPrice && isUnitPriceRange(upsell.hourlyPrice)">{{upsell.hourlyPrice?.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsell.hourlyPrice?.maxPrice?.priceIncludingTax | CurrencyFormat}}/Hourly</div>
                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.hourlyPrice && !isUnitPriceRange(upsell.hourlyPrice)  && (upsell.hourlyPrice?.minPrice || upsell.hourlyPrice?.maxPrice)">{{((upsell.hourlyPrice?.minPrice?.priceIncludingTax)?upsell.hourlyPrice?.minPrice?.priceIncludingTax : upsell.hourlyPrice?.maxPrice?.priceIncludingTax) | CurrencyFormat}}/Hourly</div>

                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.halfDayPrice && isUnitPriceRange(upsell.halfDayPrice)">{{upsell.halfDayPrice?.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsell.halfDayPrice?.maxPrice?.priceIncludingTax | CurrencyFormat}}/Half-Day</div>
                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.halfDayPrice && !isUnitPriceRange(upsell.halfDayPrice)  && (upsell.halfDayPrice?.minPrice || upsell.halfDayPrice?.maxPrice)">{{((upsell.halfDayPrice?.minPrice?.priceIncludingTax)?upsell.halfDayPrice?.minPrice?.priceIncludingTax : upsell.halfDayPrice?.maxPrice?.priceIncludingTax) | CurrencyFormat}}/Half-Day</div>

                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.dailyPrice && isUnitPriceRange(upsell.dailyPrice)">{{upsell.dailyPrice?.minPrice?.priceIncludingTax|CurrencyFormat}} - {{upsell.dailyPrice?.maxPrice?.priceIncludingTax | CurrencyFormat}}/Daily</div>
                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.dailyPrice && !isUnitPriceRange(upsell.dailyPrice) && (upsell.dailyPrice?.minPrice || upsell.dailyPrice?.maxPrice)">{{((upsell.dailyPrice?.minPrice?.priceIncludingTax)?upsell.dailyPrice?.minPrice?.priceIncludingTax : upsell.dailyPrice?.maxPrice?.priceIncludingTax) | CurrencyFormat}}/Daily</div>

                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.sessionPrice && isUnitPriceRange(upsell.sessionPrice)">{{upsell.sessionPrice?.minPrice?.priceIncludingTax|CurrencyFormat}} - {{upsell.sessionPrice?.maxPrice?.priceIncludingTax | CurrencyFormat}}/Session</div>
                                    <div class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1" *ngIf="upsell.sessionPrice && !isUnitPriceRange(upsell.sessionPrice) && (upsell.sessionPrice?.minPrice || upsell.sessionPrice?.maxPrice)">{{((upsell.sessionPrice?.minPrice?.priceIncludingTax)?upsell.sessionPrice?.minPrice?.priceIncludingTax : upsell.sessionPrice?.maxPrice?.priceIncludingTax) | CurrencyFormat}}/Sessions</div>

                                    <div class="total__amount pw-txt-h3_small pw-text-bold" *ngIf="isPriceRange(upsell) && upsell.loaded">{{upsell.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsell.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                    <div class="total__amount pw-txt-h3_small pw-text-bold" *ngIf="!isPriceRange(upsell) && upsell.loaded && (upsell.minPrice || upsell.maxPrice)">{{((upsell.minPrice?.priceIncludingTax)?upsell.minPrice?.priceIncludingTax:upsell.maxPrice?.priceIncludingTax) | CurrencyFormat}}</div>
                                   
                                    <div class="total__amount pw-txt-h3_small pw-text-bold" *ngIf="(!upsell.loaded)">Loading...</div>

    
                                </div>
                                <button mat-button class="mmc-btn-primary mmc_flex__item--vcenter" tabindex="0" (click)="add(upsell)" (keydown.enter)="add(upsell)" [disabled] ="(upsell.qty > upsell.availabilityQty && upsell.availabilityUl == false) || upsell.bookingItemPrices == null || upsell.bookingItemPrices.length == 0" *ngIf="!upsell.isAdded && (isAvailable(upsell))">Add</button>
                                <span class="error-message" *ngIf="upsell.isAdded!=true &&  isAvailable(upsell)!=true">This item is not available for the selected Event Type.</span>
                                <span class="error-message" *ngIf="upsell.qty > upsell.availabilityQty && upsell.availabilityUl == false">Maximum bookable quantity is {{upsell.availabilityQty}}</span>
                                <button mat-button class="mmc-btn-primary mmc_flex__item--vcenter disable-div" *ngIf="upsell.isAdded && !isEdit">Added</button>
                                <button mat-button class="mmc-btn-primary mmc_flex__item--vcenter" *ngIf="upsell.isAdded && isEdit" tabindex="0" (click)="update(upsell)" (keydown.enter)="add(upsell)" [disabled] ="upsell.qty > upsell.availabilityQty && upsell.availabilityUl == false">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="upsells?.length==0">
            <mat-paginator [length]="recordCount"
                           [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions"
                           [pageIndex]="pagenumber"
                           (page)="pageclickevent($event)">
            </mat-paginator>
        </div>
        <!--<div *ngIf="((loader$|async).isLoading_facilitySearch)">
            <opt-mmc-loader></opt-mmc-loader>
        </div>-->
    </div>

    <!--<ng-template #upsellSpinner>
        <div class="container" style="min-height:400px">
            <opt-mmc-loader></opt-mmc-loader>
        </div>
    </ng-template>-->
</div>
