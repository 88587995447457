import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ScrollTopService } from 'src/modules/services/scroll-top.service';

import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { Observable } from "rxjs";
import { Invoice } from 'src/modules/models/payment/Invoice';
import { PaymentConfiguration } from 'src/modules/models/settings/payment-portal/payment-configuration';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/modules/services/payment.service';
import { BookingService } from 'src/modules/services/booking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Deserializer } from 'json-api-format';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store, ICart, UpdateFacility, Redirect, DeleteFacility, CompleteBooking, ClearCart } from 'src/modules/store/index';
import { BookingSaveStarted, BookingSaveCompleted, LoadingCompleted, FacilitySearchLoaded } from 'src/modules/store/loading/actions';
import { PackageService } from 'src/modules/services/package.service';
import { ItemService } from 'src/modules/services/item.service';
import { BusinessArea } from 'src/modules/models/booking/package';
import { BookingInvoice } from 'src/modules/models/booking/booking';
import { AppSetting } from '../../models/settings/casual-portal/app-setting';
import { IndividualClient } from '../../models/client/Individual-client';
import { Contact } from 'src/modules/models/client/contact';
import { CommunicationMethodModel } from '../../models/enquiry/enquiryBooking';
import { CommunicationMethod } from '../../models/client/client';
import { CustomerService } from '../../services/customer.service';
import { SetGuestContact, SetIsGuestAgreedTerms, SetIsGuestisValidPackage, SetIsGuestMinimumAmount } from '../../store/actions';
import { tr } from 'date-fns/locale';
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'opt-booking-cart',
    templateUrl: './booking-cart.component.html'
})

export class BookingCartComponent extends BookingCommonComponent implements OnInit {
    selectedIndex: number;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    loader$: Observable<ILoadingStatus>;
    displayLogin: boolean = false;
    isLinear: boolean = false;
    cart$: Observable<ICart>;
    cart_subscriber: any;
    isCoparateTab = false;
    isIndividualTab = false;
    tabIndex = 0;
    selectedCusIndex = 1;
    showQuestionPop = false;
    displayPriceChange: boolean;
    isAllImmediateConfirmed: boolean = false;

    IsValidInvoiceRef: boolean;

    OutStandingAmount: number;
    ValidateInvoiceId: string;
    IsValidInvoice: boolean;
    invoice: Invoice;
    paymentConfiguration: PaymentConfiguration;
    currency: string

    IsDisable: boolean;
    continuePayment: boolean;
    hidePaymentGateway: boolean;
    paymentUrl: SafeResourceUrl;
    bookingId: string;
    invoiceReference: string;
    displayPaymentComplete: boolean;
    showCart: boolean;
    enquieryCompleted: boolean;
    billerCode: string;
    businessAreaId: number;
    bookingReference: string;
    isCompleted: boolean;
    @ViewChild('target') scrollTarget: ElementRef;
    goToTop: boolean = false;
    dispalyGuestForm: boolean = false;
    nameValidation: RegExp;
    emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    DefaultCountryId: number;
    phoneNumberPattern: RegExp;
    placholderSampleNumber: string;
    SelectedPhoneTypeId: string = '3';
    Contact: Contact;
    guestFirstName: string = "";
    guestLastName: string = "";

    constructor(private _formBuilder: FormBuilder,
        private router: Router,
        private scrollTopService: ScrollTopService,
        private store: Store<any>,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private _paymentService: PaymentService,
        private _bookingService: BookingService,
        private _customerService: CustomerService,
        private snackBar: MatSnackBar,
        private sanitizer: DomSanitizer,
        private _packageService: PackageService,
        _itemService: ItemService
    ) {
        super(store, _itemService, snackBar)
        this.loader$ = this.store.select('loader');
        this.scrollTopService.setScrollTop();
        this.selectedIndex = 1;
        this.cart$ = this.store.select('cart');

        this.invoiceReference = "";
        this.IsValidInvoiceRef = false;
        this.OutStandingAmount = 0;
        this.ValidateInvoiceId = "";
        this.IsValidInvoice = false;
        this.invoice = new Invoice();
        this.paymentConfiguration = new PaymentConfiguration();
        this.currency = environment.Currency;
        this.continuePayment = false;
        this.displayPriceChange = false;
        this.hidePaymentGateway = true;
        this.displayPaymentComplete = false;
        this.showCart = false;
        this.enquieryCompleted = false;
        this.businessAreaId = environment.DefaultBussinessAreaId;
        this.bookingReference = "";
        var appsettings = environment.AppSetting as AppSetting;
        this.nameValidation = new RegExp(appsettings.ClientRegistrationConfig.NameValidation);
        this.DefaultCountryId = Number(environment.DefaultCountryId);
    }

    ngOnInit() {
        super.ngOnInit();
        this.cart_subscriber = this.cart$.subscribe(state => {

            this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
            if (state.booking) {
                this.bookingId = state.booking.id;
                this.invoiceReference = (state.booking.bookingInvoices != undefined && state.booking.bookingInvoices.length > 0) ? state.booking.bookingInvoices[0].invoiceReference : "";
                this.bookingReference = state.booking.bookingReference;
                this.OutStandingAmount = state.booking.grossAmount - state.booking.paidAmount;
            }
            //this.showCart = ((state.addedFacilites && state.addedFacilites.length == 0) && !this.enquieryCompleted) ? true : false;
            this.showCart = ((state.addedFacilites && state.addedFacilites.length > 0) && !this.enquieryCompleted) ? true : false;

            this.businessAreaId = +(state.packageFilter && state.packageFilter.BussinessAreaId != null) ? state.packageFilter.BussinessAreaId : 0;
            if (this.bookingId != null && this.bookingId != "") {
                if (state?.guestContact?.client?.clientCategoryId == 6 && (state.contact?.firstName == "" || state.contact?.firstName == undefined)) {
                    if (this.guestLastName == "" && this.guestFirstName == "") {
                        this.guestFirstName = state?.guestContact?.firstName;
                        this.guestLastName = state?.guestContact?.lastName;
                    }
                }
            }
        });

        this.route.queryParamMap.subscribe(params => {
            let tabIndex = params.get("index");
            if (tabIndex != null) {
                this.selectedIndex = parseInt(tabIndex);
            }
            if (params.get("displayLogin") != null) {
                // this.store.dispatch(new BookingSaveCompleted());
                /*  if (params.get("displayLogin") == 'false') {
                     this.displayLogin = false;
                 } */
                this.displayLogin = params.get("displayLogin") != 'false';
            }
            if (params.get("dispalyGuestForm") != null) {
                if (params.get("dispalyGuestForm") == 'true') {
                    this.dispalyGuestForm = true;
                    this.displayLogin = false;
                }
            }
            if (params.get("showQuestion") == "1") {
                this.showQuestionPop = true;
            }

            if (params.get("priceChange") != null && params.get("priceChange") == "1") {
                this.displayPriceChange = true;
                this.displayLogin = false;
                this.calculateCartPrices();
            }
            if (params.get("payment") != null) {
                if (params.get("payment") == "pending") {
                    //  this.store.dispatch(new BookingSaveStarted());
                    this.displayLogin = false;
                    this.continuePayment = true;
                    this.getBillerCodeByBusinessArea();
                } else if (params.get("payment") == "complete") {
                    this.displayPaymentComplete = true;
                    // this.store.dispatch(new BookingSaveCompleted());
                    this.store.dispatch(new CompleteBooking());
                } else if (params.get("payment") == "failed") {
                    this.openSnackBarError(["Your payment hass been failed. Please try again"]);

                    //   this.store.dispatch(new CompleteBooking());
                    //   this.store.dispatch(new BookingSaveCompleted());
                    //   this.displayPaymentComplete = true;
                }
            }
            if (params.get("payment") == null && params.get("priceChange") == null && params.get("showQuestion") == null && params.get("displayLogin") == null && tabIndex == "3") {
                this.enquieryCompleted = true;
            }

        });

        if (this.selectedIndex == 3 || this.displayPaymentComplete) {
            var contact = new Contact()
            this.store.dispatch(new FacilitySearchLoaded());
            this.store.dispatch(new SetIsGuestAgreedTerms(false));
            this.store.dispatch(new SetIsGuestisValidPackage(false));
            this.store.dispatch(new SetIsGuestMinimumAmount(0));
            this.store.dispatch(new SetGuestContact(contact));
        }


        this.firstFormGroup = this._formBuilder.group({ firstCtrl: ['', Validators.required] });
        this.secondFormGroup = this._formBuilder.group({ secondCtrl: ['', Validators.required] });
        //this.calculateCartPrices();
    }



    venuSearch() {
        this.router.navigate(['/facility']);
    }

    setTabIndex(index: number) {
        this.selectedIndex = index;
    }

    ngAfterViewInit() {
        this.cd.detectChanges()
    }
    ngOnDestroy() {
        if (this.cart_subscriber) this.cart_subscriber.unsubscribe();
    }

    loadSignUp() {
        this.displayLogin = false;
    }

    openTab(val) {
        this.isCoparateTab = (val == 0);
        this.isIndividualTab = (val == 1);
        this.tabIndex = val;
        this.selectedCusIndex = val;
    }

    tabChange(e) {
        this.isCoparateTab = (e.index == 0);
        this.isIndividualTab = (e.index == 1);
        this.tabIndex = e.index;
    }


    answeredCurrentCount(question) {
        var count = 0;
        for (var q of question) {
            if ((q.type == "textQuestion" || q.type == "booleanQuestion" || q.type == "singleChoiceQuestion") && (q.answeredText != undefined && q.answeredText != null && q.answeredText != "")) {
                count++;
            } else if (q.type == "multipleChoiceQuestion") {
                let item = q.answerChoices.find(a => a.isSelectedAnswer);
                if (item != undefined) {
                    count++;
                }
            }
        }
        return (question.length) - count;
    }

    // --------------------------------- Payment Functions -----------------------------------------------



    getBillerCodeByBusinessArea() {
        if (this.isAllImmediateConfirmed && this.continuePayment) {
            let result = this._packageService.packageBusinessAreaSearch();
            result.subscribe((businessAreas) => {
                if (businessAreas != undefined && businessAreas instanceof Array && businessAreas.length > 0 && this.businessAreaId != 0) {
                    let businessArea = businessAreas.filter(businessArea => +businessArea.id == this.businessAreaId);
                    if (businessArea[0].billerCode != "") {
                        this.goToPayment(businessArea[0].billerCode);
                    }


                }
            });
        }
    }

    goToPayment(billerCode: string) {
        if (this.OutStandingAmount > 0) {
            if (environment.ExternalIntegrationIdForPaymentGateway == "21") {
                //    this.router.navigate(['/payment/secure-pay'], { queryParams: { amount: this.OutStandingAmount, bookingId: this.bookingId, billerCode: this.billerCode } });
                var url = environment.ApiUrl + ((environment.ApiUrl.endsWith('/')) ? "" : "/") + "Payment/Pay?BookingRef=" + this.bookingReference;
                if (this.OutStandingAmount != undefined && +this.OutStandingAmount > 0)
                    var url = url + "&amount=" + this.OutStandingAmount.toString();
                if (this.bookingId != undefined && this.bookingId != "") {
                    url = url + "&bookingId=" + this.bookingId;
                }
                if (billerCode != undefined && billerCode != "") {
                    url = url + "&billerCode=" + billerCode;
                }
                if (this.isAllImmediateConfirmed) {
                    url = url + "&immediate=1";
                }


                //     window.open(url);
                window.location.href = url;
            } else {
                var url = environment.ApiUrl + ((environment.ApiUrl.endsWith('/')) ? "" : "/") + "Payment/Pay?bookingId=" + this.bookingId + "&immediate=1&BookingRef=" + this.bookingReference + "&amount=" + this.OutStandingAmount.toString() + "&billerCode=" + billerCode;
                this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            }
            //   this.store.dispatch(new BookingSaveCompleted());
        } else {
            this.openSnackBarError(["This booking is already paid."]);
        }
    }

    proceedToPay() {
        if (this.IsValidInvoice) {
            //  this.router.navigate(['/payment/secure-pay'], { queryParams: { amount: this.OutStandingAmount } });
            this.hidePaymentGateway = false;
        }
    }

    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: 'top',
        });
    }

    Scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    // --------------------------------- Payment Functions -----------------------------------------------
}
