import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Notes } from 'src/modules/models/booking/booking-notes';
import { BookingDetailsComponent } from 'src/modules/booking-portal/booking-view/booking-details/booking-details.component';
import { EntityType, NoteType } from 'src/modules/models/bookingNotes-enum';
import { Booking } from 'src/modules/models/booking/booking';

@Component({
    selector: 'opt-booking-change-request',
    templateUrl: './booking-change-request.component.html',
    styleUrls: ['./booking-change-request.component.scss']
})

export class BookingChangeRequestComponent {

    @Input() contactName: string;
    @Output() notes = new EventEmitter();
    // @Input() facilityName: string;
    // @Input() bookingType: string;
    requestString: string;

    constructor() {
       
    }

ngOnInit(){

}
    submitChangeRequest() {

        var _notes: Notes[] = [];

        if (this.requestString.length > 0 && this.requestString != "" && this.requestString != undefined) {
            var bookingNotes = new Notes();
            bookingNotes.plainText = this.requestString + ' - Created by : ' + this.contactName;
            bookingNotes.entityTypeID = EntityType.Booking;
            bookingNotes.entityType = 'Booking';
            bookingNotes.noteTypeId = NoteType.ChangeRequest;
            bookingNotes.noteType = 'Booking change request';
     

            _notes.push(bookingNotes);

            this.notes.emit(_notes);

        }

    }
}

