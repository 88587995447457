<div class="mmc_cart">
  <div class="snipper-body">
    <div class="mmc_flex--container main">
      <div
        class="mmc_flex__item left-side"
        [ngClass]="{ 'when-notright': facilitySummaries.length == 0 }"
      >
        <div class="cartDetails-hide">
          <div>
            <div class="mmc_card">
              <div class="cart-outer-wrapper">
                <div class="mmc_flex--container">
                  <div class="mmc_flex__item drop">
                    <div
                      class="mmc_flex--container"
                      *ngIf="packageClassRestValue"
                    >
                      <mat-form-field>
                        <mat-select
                          disableOptionCentering
                          placeholder="Please select a category"
                          [(ngModel)]="selectedClass"
                          (ngModelChange)="packageClassChange()"
                          required
                          [disabled]="cartData && cartData?.booking?.id"
                        >
                          <mat-option
                            *ngFor="
                              let packageClass of packageFilter?.FilteredPackageClasses
                            "
                            [value]="packageClass.Id"
                            >{{ packageClass.Name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="mmc_flex--container">
                      <mat-form-field>
                        <mat-select
                          disableOptionCentering
                          placeholder="Please select an event"
                          [(ngModel)]="selectedPackage"
                          (ngModelChange)="packageChange()"
                          required
                          [disabled]="cartData && cartData?.booking?.id"
                        >
                          <mat-option value="">Select</mat-option>
                          <mat-option
                            *ngFor="let package of selectPackageList()"
                            [value]="package.id"
                            >{{ package.name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <form novalidate [formGroup]="aFormGroup">
                      <div class="mmc_flex--container error-con">
                        <mat-form-field class="example-full-width">
                          <input
                            matInput
                            placeholder="Your event name"
                            [(ngModel)]="defaultEventName"
                            formControlName="EventName"
                            (ngModelChange)="eventNameChange($event)"
                            autocomplete="off"
                            required
                          />
                        </mat-form-field>
                        <mat-error
                          *ngIf="
                            aFormGroup.controls.EventName.invalid &&
                            aFormGroup.controls.EventName.touched
                          "
                          >Event name is mandatory.</mat-error
                        >
                      </div>
                      <!--<div class="mmc_flex--container" *ngIf="2==1">
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Ledger number" [(ngModel)]="ledgerNumber" formControlName="ledgerNumber">
                                                </mat-form-field>
                                            </div>-->
                    </form>
                  </div>
                  <div class="mmc_flex__item button-wrapper">
                    <div class="mmc_flex--container mmc_fd--col">
                      <div
                        class="mmc_flex__item add"
                        [ngStyle]="
                          cartData && cartData?.booking?.id
                            ? { cursor: 'not-allowed' }
                            : {}
                        "
                      >
                        <div
                          mat-button
                          class="btn__validate font__large add-venue"
                          (click)="facilitySearch()"
                          [ngStyle]="
                            cartData && cartData?.booking?.id
                              ? { 'pointer-events': 'none' }
                              : {}
                          "
                        >
                          <div class="mmc_flex__item--inherit">
                            <span
                              _ngcontent-c3=""
                              class="mmc icon-calendar icn-aminity"
                            ></span>
                          </div>
                          <div class="mmc_flex__item--vcenter">
                            <!-- <div class="txt-add-more">ADD MORE<span class="font__semibold ml-2">VENUE(S)</span></div> -->
                            <div class="txt-add-more venueSec">
                              Add more<span class="font__semibold ml-2"
                                >venue(s)</span
                              >
                            </div>
                            <div
                              class="txt-add-more spaceSec"
                              style="display: none"
                            >
                              Add more<span class="font__semibold ml-2"
                                >space(s)</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="mmc_flex__item add mt-2"
                        *ngIf="
                          selectedPackageDetail &&
                          selectedPackageDetail.question &&
                          selectedPackageDetail.question.length > 0
                        "
                      >
                        <div
                          mat-button
                          class="btn__validate font__large add-venue"
                          (click)="popPackageQuestions()"
                          (keydown.enter)="popPackageQuestions()"
                          tabindex="0"
                          role="button"
                        >
                          <div class="mmc_flex__item--inherit">
                            <span
                              _ngcontent-c3=""
                              class="mmc material-icons icn-aminity"
                              >help_outline</span
                            >
                          </div>
                          <div class="mmc_flex__item--vcenter">
                            <div>
                              Event<span class="font__semibold ml-2"
                                >questionnaire</span
                              >
                              <div
                                class="count"
                                matTooltip="number of unanswered questions"
                              >
                                {{
                                  answeredCurrentCount(
                                    selectedPackageDetail.question
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- add documnt scroll to bottom -->
                      <div class="mmc_flex__item add mt-2">
                        <div
                          mat-button
                          class="btn__validate font__large add-venue"
                          tabindex="0"
                          (click)="goToAddDoc(document); fileInput.click()"
                          (keydown.enter)="
                            goToAddDoc(document); fileInput.click()
                          "
                          role="button"
                        >
                          <div class="mmc_flex__item--inherit">
                            <span
                              _ngcontent-c3=""
                              class="mmc icon-file icn-aminity"
                            ></span>
                          </div>
                          <div
                            class="mmc_flex__item--vcenter"
                            tabindex="0"
                            role="button"
                          >
                            <div class="txt-add-more-doc doc-add">
                              Add
                              <span class="font__semibold ml-2"
                                >document(s)</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mmc_flex--container" style="display: none">
                  <mat-radio-group
                    name="opList"
                    fxLayout="column"
                    [(ngModel)]="selectedClass"
                    (ngModelChange)="packageSelectorChange()"
                  >
                    <mat-radio-button
                      *ngFor="let op of packageClasses"
                      [value]="op.Id"
                      name="opList"
                      >{{ op.Value }}</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showEventIncludes" class="cart-panel">
            <mat-accordion>
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="cart-header-wrapper">
                      <div class="cart-panel-header-title">
                        This event includes
                      </div>
                      <div class="cart-header-attendees-wrapper">
                        <!-- <span [ngStyle]="{'color': attendees == 0 ? '#D32F2F' : '#616161'}"></span> -->
                        <div class="cart-header-attendees-textbox">
                          <mat-form-field
                            appearance="outline"
                            class="pw-txt-s3"
                          >
                            <mat-label class="d-flex pw-fd-col"
                              >Attendees</mat-label
                            >
                            <input
                              class=""
                              matInput
                              type="number"
                              style="text-align: left"
                              [(ngModel)]="maxattendees"
                              id="attendees"
                              (ngModelChange)="attendeesChanged($event)"
                              min="1"
                              onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))"
                              autocomplete="off"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="showInCorrespondance">
                  <div class="" *ngIf="showInInvoice">
                    <table
                      mat-table
                      [dataSource]="EventIncludes"
                      class="mat-elevation-z8 w-100 cart-panel-table"
                    >
                      <!--- Note that these columns can be defined in any order.
                                                  The actual rendered columns are set as a property on the row definition" -->
                      <!-- Name Column -->
                      <ng-container matColumnDef="item">
                        <th mat-header-cell *matHeaderCellDef>Item</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.item }}
                        </td>
                      </ng-container>

                      <!-- Weight Column -->
                      <ng-container matColumnDef="unit_price">
                        <th mat-header-cell *matHeaderCellDef>Unit Price</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.unit_price }}
                        </td>
                      </ng-container>

                      <!-- Symbol Column -->
                      <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>Quantity</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.quantity }}
                        </td>
                      </ng-container>

                      <!-- Total Column -->
                      <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef>Total</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.total }}
                        </td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                    </table>
                  </div>
                  <div class="" *ngIf="!showInInvoice">
                    <table
                      mat-table
                      [dataSource]="EventIncludes"
                      class="mat-elevation-z8 w-100 cart-panel-table"
                    >
                      <!-- Name Column -->
                      <ng-container matColumnDef="item">
                        <th mat-header-cell *matHeaderCellDef>Item</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.item }}
                        </td>
                      </ng-container>
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>Quantity</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.quantity }}
                        </td>
                      </ng-container>
                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumnsWithoutPrice"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumnsWithoutPrice
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
                <div class="show-additional-charge-wrapper">
                  <diV class="additional-charge-label">{{
                    bookingData.BookingConfig.PackageAdditionalChargesLabel
                  }}</diV>
                  <div class="additional-charge-total">
                    {{ showInInvoiceItemTotal | CurrencyFormat }}
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="mmc_card facility-card">
            <div *ngIf="facilitySummaries.length > 0" class="cart-list">
              <div *ngFor="let facilitySummary of facilitySummaries">
                <div class="cart_facility">
                  <div class="mmc_flex--container cart_facility--icon">
                    <div class="mmc_flex__item--inherit">
                      <div
                        class="mmc_flex--container card-icon border-right btn-click"
                        tabindex="0"
                        (click)="
                          popupQuestion(
                            facilitySummary,
                            facilitySummary.item,
                            false
                          )
                        "
                        (keydown.enter)="
                          popupQuestion(
                            facilitySummary,
                            facilitySummary.item,
                            false
                          )
                        "
                        *ngIf="facilitySummary.item?.question"
                        role="button"
                      >
                        <div class="mmc_flex__item">
                          <i class="material-icons pw-ico-color__tertiary">
                            help_outline
                          </i>
                          <div
                            class="count"
                            matTooltip="number of unanswered questions"
                          >
                            {{
                              answeredCurrentCount(
                                facilitySummary.item.question)
                              +
                              answeredCurrentCount(
                                facilitySummary.upsellSummaries[0]?.question != null ? facilitySummary.upsellSummaries[0].question : [])

                            }}
                          </div>
                        </div>
                        <div class="mmc_flex__item">
                          <div class="pw-txt-s1 pw-text-color__tertiary">
                            Questionnaire
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mmc_flex__item--inherit"
                      [ngStyle]="
                        cartData && cartData?.booking?.id
                          ? { 'pointer-events': 'none' }
                          : {}
                      "
                    >
                      <div
                        class="mmc_flex--container card-icon border-right btn-click"
                        (click)="popupExtra(facilitySummary)"
                        *ngIf="facilitySummary.isUpsellAvailable"
                        [ngStyle]="
                          cartData && cartData?.booking?.id
                            ? { cursor: 'not-allowed' }
                            : {}
                        "
                      >
                        <div class="mmc_flex__item">
                          <i class="pw-ico-color__tertiary material-icons">
                            star_border
                          </i>
                        </div>
                        <div class="mmc_flex__item">
                          <div
                            class="pw-txt-s1 pw-text-color__tertiary add-extra-txt"
                          >
                            Add extra
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="mmc_flex__item--inherit"
                      [ngStyle]="
                        cartData && cartData?.booking?.id
                          ? { cursor: 'not-allowed' }
                          : {}
                      "
                    >
                      <div
                        class="mmc_flex--container card-icon border-right btn-click"
                        (click)="openEditPopup(facilitySummary)"
                        [ngStyle]="
                          cartData && cartData?.booking?.id
                            ? { 'pointer-events': 'none' }
                            : {}
                        "
                      >
                        <div class="mmc_flex__item">
                          <i class="pw-ico-color__tertiary icon-edit"></i>
                        </div>
                        <div class="mmc_flex__item">
                          <div class="pw-txt-s1 pw-text-color__tertiary">
                            Edit
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mmc_flex__item--inherit"
                      [ngStyle]="
                        cartData && cartData?.booking?.id
                          ? { cursor: 'not-allowed' }
                          : {}
                      "
                    >
                      <div
                        class="mmc_flex--container card-icon"
                        (click)="deleteItem(facilitySummary)"
                        [ngStyle]="
                          cartData && cartData?.booking?.id
                            ? { 'pointer-events': 'none' }
                            : {}
                        "
                      >
                        <div class="mmc_flex__item btn-click">
                          <i class="pw-ico-color__tertiary icon-trash"></i>
                        </div>
                        <div class="mmc_flex__item">
                          <div class="pw-txt-s1 pw-text-color__tertiary">
                            Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mmc_flex--container cart_respo">
                    <!--<div class="mmc_flex__item--inherit image-wrapper" [style.background-image]="'url(' + facilitySummary.url + ')'">-->
                    <div>
                      <img
                        src="{{ facilitySummary.url }}"
                        alt="{{ getCartFacilityName(facilitySummary) }}"
                        class="mmc_flex__item--inherit image-wrapper"
                      />
                    </div>
                    <div class="mmc_flex__item cart_facility--detail">
                      <div
                        class="pw-txt-b3 pw-text-semi-bold pw-text-color__primary pw-txt-lh-2"
                      >
                        {{ getCartFacilityName(facilitySummary) }}
                      </div>
                      <div class="pw-txt-b2_small pw-txt-lh-1">
                        {{ facilitySummary.directions }}
                      </div>
                      <div class="table-details">
                        <div class="mmc_flex--container first-row">
                          <div class="detail-card mmc_flex__item w-50-mob">
                            <div class="mmc_flex--container">
                              <div class="mmc_flex__item--vcenter">
                                <span
                                  _ngcontent-c3=""
                                  class="mmc icon-calendar icn-aminity pw-ico-color__tertiary"
                                ></span>
                              </div>
                              <div class="mmc_flex__item">
                                <div
                                  class="pw-txt-s3 pw-txt-lh-1nh pw-ico-color__quaternary txt-768"
                                >
                                  Date
                                </div>
                                <div
                                  class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary"
                                >
                                  {{ facilitySummary.startTime | dateFormat }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="detail-card mmc_flex__item w-50-mob">
                            <div class="mmc_flex--container">
                              <div class="mmc_flex__item--vcenter">
                                <span
                                  _ngcontent-c3=""
                                  class="mmc icon-users icn-aminity pw-ico-color__tertiary"
                                ></span>
                              </div>
                              <div class="detail-card attendees">
                                <div
                                  class="pw-txt-s3 pw-txt-lh-1nh pw-ico-color__quaternary txt-768"
                                >
                                  Attendees
                                </div>
                                <div
                                  class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary"
                                >
                                  {{ facilitySummary.attendees }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="detail-card mmc_flex__item w-50-mob">
                            <div class="mmc_flex--container">
                              <div class="mmc_flex__item--vcenter">
                                <span
                                  _ngcontent-c3=""
                                  class="mmc icon-clock-circular-outline icn-aminity pw-ico-color__tertiary"
                                ></span>
                              </div>
                              <div class="mmc_flex__item">
                                <div
                                  class="pw-txt-s3 pw-txt-lh-1nh pw-ico-color__quaternary txt-768"
                                >
                                  Start time
                                </div>
                                <div
                                  class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary"
                                >
                                  {{
                                    facilitySummary.startTime
                                      | dateFormat : "time"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="detail-card mmc_flex__item w-50-mob">
                            <div class="mmc_flex--container">
                              <!-- <div class="mmc_flex__item--vcenter">
                                                                <span _ngcontent-c3="" class="mmc icon-clock-circular-outline icon-2 icn-aminity pw-ico-color__tertiary"></span>
                                                            </div> -->
                              <div class="detail-card endtime">
                                <div
                                  class="pw-txt-s3 pw-txt-lh-1nh pw-ico-color__quaternary txt-768"
                                >
                                  End time
                                </div>
                                <div
                                  class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary"
                                >
                                  {{
                                    facilitySummary.endTime
                                      | dateFormat : "time"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mmc_flex--container">
                          <div
                            class="detail-card mmc_flex__item"
                            *ngIf="isConfigurationEnabled == true"
                          >
                            <div class="mmc_flex--container">
                              <!-- <div class="mmc_flex__item--vcenter hide-in-cog">
                                                                <span _ngcontent-c3="" class="mmc material-icons  icn-aminity pw-ico-color__tertiary">build</span>
                                                            </div> -->
                              <div class="detail-card config">
                                <div
                                  class="pw-txt-s3 pw-txt-lh-1nh pw-ico-color__quaternary txt-768"
                                >
                                  Configuration
                                </div>
                                <div
                                  class="config-img hide-in-cog"
                                  *ngIf="
                                    configurationDisplayMode == 2 &&
                                    getSelectconfigurations(facilitySummary)
                                      .configurationThumbnail
                                  "
                                >
                                  <img
                                    src="{{
                                      getSelectconfigurations(facilitySummary)
                                        .configurationThumbnail
                                    }}"
                                    alt="{{
                                      getSelectconfigurations(facilitySummary)
                                        .name
                                    }}"
                                  />
                                </div>
                                <div
                                  class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary"
                                >
                                  {{
                                    getSelectconfigurations(facilitySummary)
                                      .name
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <!--<div class="detail-card mmc_flex__item">
                                                        <div class="mmc_flex--container">
                                                            <div class="mmc_flex__item--vcenter">
                                                                <span _ngcontent-c3="" class="mmc icon-clock-circular-outline icn-aminity pw-ico-color__tertiary"></span>
                                                            </div>
                                                            <div class="mmc_flex__item">
                                                                <div class="pw-txt-s3 pw-txt-lh-1nh pw-ico-color__quaternary">START TIME</div>
                                                                <div class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary">{{facilitySummary.startTime |dateFormat:'time'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="detail-card mmc_flex__item">
                                                        <div class="mmc_flex--container">
                                                            <div class="mmc_flex__item--vcenter">
                                                                <span _ngcontent-c3="" class="mmc icon-clock-circular-outline icon-2 icn-aminity pw-ico-color__tertiary"></span>
                                                            </div>
                                                            <div class="detail-card endtime">
                                                                <div class="pw-txt-s3 pw-txt-lh-1nh pw-ico-color__quaternary">END TIME</div>
                                                                <div class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary">{{facilitySummary.endTime |dateFormat:'time'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>-->
                          <!--<div class="detail-card mmc_flex__item">
                                                        <div class="mmc_flex--container">
                                                            <div class="mmc_flex__item--vcenter">
                                                                <span _ngcontent-c3="" class="mmc icon-clock-circular-outline icn-aminity pw-ico-color__tertiary"></span>
                                                            </div>
                                                            <div class="mmc_flex__item time-icon-cart">
                                                                <div class="pw-txt-b3 pw-txt-lh-1nh pw-ico-color__quaternary">START TIME</div>
                                                                <div class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary">
                                                                    {{facilitySummary.startTime |date:'h:mm a'| lowercase}}
                                                                </div>
                                                            </div>
                                                            <div class="detail-card mmc_flex__item">
                                                                <div class="mmc_flex--container">
                                                                    <div class="mmc_flex__item--vcenter">
                                                                        <span _ngcontent-c3="" class="mmc icon-clock-circular-outline icon-2 icn-aminity pw-ico-color__tertiary"></span>
                                                                    </div>
                                                                    <div class="mmc_flex__item">
                                                                        <div class="pw-txt-b3 pw-txt-lh-1nh pw-ico-color__quaternary">END TIME</div>
                                                                        <div class="pw-txt-b3 pw-txt-lh-1nh pw-text-color__tertiary">{{facilitySummary.endTime |date:'h:mm a'| lowercase}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>-->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="
                      facilitySummary.attendees == 0 ||
                      facilitySummary.attendees == ''
                    "
                    class="cart-zero-error"
                  >
                    Please click Edit to specify the number of attendees.
                  </div>

                  <div *ngIf="facilitySummary.upsellSummaries?.length > 0">
                    <!-- Extras for this facility -->
                    <div class="up-sell-items">
                      <div class="main--title">
                        <div class="mmc_flex--container">
                          <div
                            class="mmc_flex__item mmc_flex__item--vcenter icn--star"
                          >
                            <i class="material-icons">star_border</i>
                          </div>
                          <div
                            class="mmc_flex__item mmc_flex__item--vcenter pw-txt-h4 pw-text-color__primary"
                          >
                            Extras for this facility
                          </div>
                        </div>
                      </div>

                      <!-- facility cage -->

                      <div
                        class="cart_facility"
                        *ngFor="
                          let upsellSummary of facilitySummary.upsellSummaries
                        "
                      >
                        <div class="mmc_flex--container cart_facility--icon">
                          <div class="mmc_flex__item--inherit">
                            <div
                              class="mmc_flex--container card-icon border-right btn-click"
                              tabindex="0"
                              (click)="
                                popupQuestion(
                                  facilitySummary,
                                  upsellSummary,
                                  true
                                )
                              "
                              (keydown.enter)="
                                popupQuestion(
                                  facilitySummary,
                                  upsellSummary,
                                  true
                                )
                              "
                              *ngIf="upsellSummary.question"
                              role="button"
                            >
                              <div class="mmc_flex__item">
                                <i class="material-icons"> help_outline </i>
                                <div
                                  class="count"
                                  matTooltip="number of unanswered questions"
                                >
                                  {{
                                    answeredCurrentCount(upsellSummary.question)
                                  }}
                                </div>
                              </div>
                              <div class="mmc_flex__item">
                                <div class="pw-txt-s1 pw-text-color__tertiary">
                                  Questionnaire
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="mmc_flex__item--inherit"
                            [ngStyle]="
                              cartData && cartData?.booking?.id
                                ? { cursor: 'not-allowed' }
                                : {}
                            "
                          >
                            <div
                              class="mmc_flex--container card-icon border-right btn-click"
                              (click)="
                                editExtra(facilitySummary, upsellSummary)
                              "
                              [ngStyle]="
                                cartData && cartData?.booking?.id
                                  ? { 'pointer-events': 'none' }
                                  : {}
                              "
                            >
                              <div class="mmc_flex__item">
                                <i class="pw-ico-color__tertiary icon-edit"></i>
                              </div>
                              <div class="mmc_flex__item">
                                <div class="pw-txt-s1 pw-text-color__tertiary">
                                  Edit
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="mmc_flex__item--inherit"
                            [ngStyle]="
                              cartData && cartData?.booking?.id
                                ? { cursor: 'not-allowed' }
                                : {}
                            "
                          >
                            <div
                              class="mmc_flex--container card-icon"
                              (click)="
                                deleteUpsellItem(facilitySummary, upsellSummary)
                              "
                              [ngStyle]="
                                cartData && cartData?.booking?.id
                                  ? { 'pointer-events': 'none' }
                                  : {}
                              "
                            >
                              <div class="mmc_flex__item btn-click">
                                <i
                                  class="pw-ico-color__tertiary icon-trash"
                                ></i>
                              </div>
                              <div class="mmc_flex__item">
                                <div class="pw-txt-s1 pw-text-color__tertiary">
                                  Delete
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mmc_flex--container extra--cage">
                          <div class="mmc_flex__item extra--count">
                            <div class="mmc_flex--container">
                              <div class="mmc_flex__item count">
                                {{ upsellSummary.qty }}
                              </div>
                              <div class="mmc_flex__item txt-lbl-count">X</div>
                            </div>
                          </div>
                          <div class="mmc_flex__item extra--image">
                            <div
                              class="mmc_flex__item--inherit image-wrapper"
                              [style.background-image]="
                                'url(' + upsellSummary.url + ')'
                              "
                            ></div>
                          </div>
                          <div class="mmc_flex__item extra--titlle">
                            {{ upsellSummary.name }}
                          </div>
                        </div>
                      </div>
                      <!-- end of facility -->
                    </div>
                    <!-- end of Extras for this facility -->
                  </div>
                </div>
              </div>
            </div>

            <div
              class="cart_facility not"
              *ngIf="facilitySummaries.length == 0"
            >
              <div class="label-warn">
                <div class="icon text-align">
                  <i class="material-icons pw-ico-color__tertiary pw-txt-lh-1"
                    >shopping_cart</i
                  >
                </div>
                <div class="text-bold text-align pw-text-medium">
                  Cart is empty.
                </div>
                <div
                  class="pw-txt-h4 pw-txt-algn-c pw-text-color__tertiary pw-txt-lh-2"
                >
                  Looks like you have no items in your booking cart.
                </div>
                <div class="pw-txt-h4 pw-txt-algn-c pw-text-color__tertiary">
                  Click <a href="#" tabindex="0"> here</a> to continue booking.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div #document>
          <div class="document-upload">
            <div
              class="pw-txt-h2 pw-text-semi-bold pw-text-color__tertiary pw-txt-algn-c py-3"
            >
              <span class="pw-text-color__quinary">Upload</span> your documents
              (Maximum file size {{ documentUpldSize }})
            </div>
            <input
              id="fileupload"
              type="file"
              (change)="onFileChanged($event)"
              #fileInput
              style="display: none"
              #fileInputTag
            />
            <file-drop
              headertext=""
              (onFileDrop)="dropped($event)"
              showBrowseBtn="true"
              browseBtnLabel="Select a file from your computer"
            >
              <div
                class="mmc_flex__item--vcenter"
                onclick="$('#fileupload').trigger('click'); return false;"
              >
                <div class="mmc_flex--container">
                  <div class="mmc_flex__item--vcenter upload-icon">
                    <i class="material-icons">insert_drive_file</i>
                  </div>
                </div>
              </div>
              <div
                class="mmc_flex__item--vcenter doc-up-text--mobile pw-txt-algn-c"
              >
                <span
                  class="link-primary"
                  onclick="$('#fileupload').trigger('click'); return false;"
                  >Select a file from your device</span
                >
              </div>
              <div class="mmc_flex__item--vcenter doc-up-text--desktop">
                <span
                  class="font-family"
                  style="
                    padding-right: 5px;
                    color: #808080;
                    font-family: Tahoma;
                  "
                  >Drop file here or
                </span>
                <span
                  class="font-family link-primary"
                  onclick="$('#fileupload').trigger('click'); return false;"
                  >Select a file from your device</span
                >
              </div>
            </file-drop>
            <div class="mmc_flex--container" style="display: none">
              <div class="mmc_flex__item m-3">
                <span
                  class="pw-txt-b3 pw-txt-p pw-text-regular pw-text-color__tertiary"
                  [innerHtml]="CartPageDocumentDescription"
                ></span>
              </div>
            </div>
            <div class="mmc_flex--container doc-sec" #target>
              <div *ngIf="selectedFiles?.length > 0">
                <div
                  class="doc-container"
                  *ngFor="let item of selectedFiles; let i = index"
                >
                  <div class="mmc_flex--container doc-inner">
                    <div class="mmc_flex__item--inherit doc-icon">
                      <i class="material-icons"> file_copy </i>
                    </div>
                    <div class="mmc_flex__item middle-sec">
                      <div class="font__normal font-family">
                        {{ item?.name }}
                      </div>
                      <div class="font__small font__gray font-family">
                        {{ item?.fileSize }}
                      </div>
                    </div>
                    <div class="mmc_flex__item--inherit down-sec">
                      <a
                        [href]="get64StringData(item)"
                        download="{{ item?.name }}"
                        [target]="_blank"
                      >
                        <i class="material-icons down-icon link-icon"
                          >cloud_download</i
                        >
                      </a>
                      <a
                        [href]="get64StringData(item)"
                        download="{{ item?.name }}"
                        [target]="_blank"
                      >
                        <div
                          class="font__medium font__xsmall down-icon font-family"
                        >
                          Download
                        </div>
                      </a>
                    </div>
                    <div class="mmc_flex__item--inherit down-sec">
                      <a
                        ><i
                          class="material-icons down-icon link-icon"
                          (click)="removeFile(item)"
                          >close</i
                        ></a
                      >
                      <a
                        ><div
                          class="font__medium font__xsmall down-icon font-family"
                          (click)="removeFile(item)"
                        >
                          Remove
                        </div></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--[isAllImmediateConfirmed]="isAllImmediateConfirmed"-->
      <div
        class="right-side"
        [ngClass]="{ notright: facilitySummaries.length == 0 }"
      >
        <opt-cart-panel
          [facilitySummaries]="facilitySummaries"
          [selectedPackageDetail]="selectedPackageDetail"
          [defaultEventName]="defaultEventName"
          [isValidPackage]="validPackage"
          (validatePackage)="validatePackage($event)"
          (submitted)="clickSubmitEnquiry($event)"
          (makeEventNameValidation)="updateMyTextModel($event)"
          (ChangedStatus)="changeStatus($event)"
        >
        </opt-cart-panel>
      </div>
    </div>
    <!--<div #document>
                <div class="document-upload">
                    <h2 class="pw-txt-h2 pw-text-semi-bold pw-text-color__tertiary pw-txt-algn-c py-3"><span class="pw-text-color__quinary">Upload</span> your Documents (Maximum file size {{documentUpldSize}})</h2>
                    <input id="fileupload" type="file" (change)="onFileChanged($event)" #fileInput style="display:none" #fileInputTag>
                    <file-drop headertext="" (onFileDrop)="dropped($event)" showBrowseBtn="true" browseBtnLabel="Select a file from your computer">
                        <div class="mmc_flex__item--vcenter" tabindex="0" onclick="$('#fileupload').trigger('click'); return false;">
                            <div class="mmc_flex--container">
                                <div class="mmc_flex__item--vcenter upload-icon">
                                    <i class="material-icons">insert_drive_file</i>
                                </div>
                            </div>
                        </div>
                        <div class="mmc_flex__item--vcenter doc-up-text--mobile pw-txt-algn-c">
                            <span class="link-primary" tabindex="0" onclick="$('#fileupload').trigger('click'); return false;">Select a file from your device</span>
                        </div>
                        <div class="mmc_flex__item--vcenter doc-up-text--desktop">
                            <span class="font-family" style="padding-right:5PX; color:#2f2f2f; font-family: Tahoma;">Drop file here or </span>
                            <span class="font-family link-primary" tabindex="0" onclick="$('#fileupload').trigger('click'); return false;" role="link">Select a file from your device</span>
                        </div>
                    </file-drop>
                    <div class="mmc_flex--container">
                        <div class="mmc_flex__item m-3">
                            <span class="pw-txt-b3 pw-txt-p pw-text-regular pw-text-color__tertiary" [innerHtml]="CartPageDocumentDescription"></span>
                        </div>
                    </div>
                    <div class="mmc_flex--container doc-sec" #target>
                        <div *ngIf="selectedFiles?.length>0">
                            <div class="doc-container" *ngFor="let item of selectedFiles; let i=index">
                                <div class="mmc_flex--container doc-inner">
                                    <div class="mmc_flex__item--inherit doc-icon">
                                        <i class="material-icons">
                                            file_copy
                                        </i>
                                    </div>
                                    <div class="mmc_flex__item middle-sec">
                                        <div class="font__normal font-family">{{ item?.name }}</div>
                                        <div class="font__small font__gray font-family">{{item?.fileSize}}</div>
                                    </div>
                                    <div class="mmc_flex__item--inherit down-sec">
                                        <a [href]="get64StringData(item)" download="{{item?.name}}" [target]="_blank">
                                            <i class="material-icons down-icon link-icon">cloud_download</i>
                                        </a>
                                        <a [href]="get64StringData(item)" download="{{item?.name}}" [target]="_blank">
                                            <div class="font__medium font__xsmall down-icon font-family">Download</div>
                                        </a>
                                    </div>
                                    <div class="mmc_flex__item--inherit down-sec">
                                        <a><i class="material-icons down-icon link-icon"tabindex="0"  (click)="removeFile(item)" (keydown.enter)="removeFile(item)">close</i></a>
                                        <a><div class="font__medium font__xsmall down-icon font-family" tabindex="0" (click)="removeFile(item)" (keydown.enter)="removeFile(item)">Remove</div></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        -->
  </div>
</div>
