import { Component, OnInit } from '@angular/core';
import {CommunicationMethod} from '../../../models/client/client';
import {SetGuestContact} from '../../../store/actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import { Contact } from 'src/modules/models/client/contact';
import {Observable} from 'rxjs';
import {ICart, Store} from 'src/modules/store/index';
import {BookingService} from '../../../services/booking.service';
import {CustomerService} from '../../../services/customer.service';
import {AppSetting} from '../../../models/settings/casual-portal/app-setting';
import {BookingCommonComponent} from '../../booking-common.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ItemService } from 'src/modules/services/item.service';
import {FacilitySearchLoading} from '../../../store/loading';
import {RoutePath} from '../../route-path';
import {Router} from '@angular/router';


@Component({
  selector: 'opt-guest-setup-form',
  templateUrl: './guest-setup-form.component.html'
})
export class GuestSetupFormComponent extends BookingCommonComponent implements OnInit {

  guestClientSaveForm: FormGroup;
  cart$: Observable<ICart>;
  cart_subscriber: any;
  guestContact: Contact;
  nameValidation: RegExp;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  DefaultCountryId :number;
  phoneNumberPattern: RegExp;
  placholderSampleNumber: string;
  SelectedPhoneTypeId: string;
  bookingId: string;
  guestFirstName : string = "";
  guestLastName : string = "";
  hideSignInButtonIncart: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    private _bookingService: BookingService,
    private _customerService: CustomerService,
    private store: Store<any>,
    private snackBar: MatSnackBar,
    _itemService: ItemService,
    private route: Router,
  ) {
    super(store, _itemService, snackBar);
    this.cart$ = this.store.select('cart');
  }

  ngOnInit() {

    var appsettings = environment.AppSetting as AppSetting;
    var SelectedPhoneTypeId = appsettings?.ClientRegistrationConfig?.GuestClientConfig?.CommunicationTypeForPhone?.Id ?? '3';
    this.SelectedPhoneTypeId = SelectedPhoneTypeId.toString();
    this.hideSignInButtonIncart = appsettings.HideSignInButtonIncart ? true : false;
    this.DefaultCountryId = Number(environment.DefaultCountryId);
    this.cart_subscriber = this.cart$.subscribe(state => {
      this.guestContact = state?.guestContact ;
    });
    console.log(this.guestContact)
    this.guestClientSaveForm = this._formBuilder.group({
      FirstName: [ '', [Validators.required, Validators.maxLength(50), Validators.pattern(this.nameValidation)]],
      LastName: [ '', [Validators.required, Validators.maxLength(50), Validators.pattern(this.nameValidation)]],
      PersonalEmail: [ '', Validators.pattern(this.emailPattern)],
      PersonalPhoneNumber: [ ''],
    })

    if (this.guestContact?.firstName != undefined && this.guestContact?.firstName != "") {
      this.guestClientSaveForm.patchValue({
        FirstName: this.guestContact?.firstName,
        LastName: this.guestContact?.lastName,
        PersonalEmail: this.guestContact?.communicationMethods[1]?.value ,
        PersonalPhoneNumber: this.guestContact?.communicationMethods[0]?.value
      });
    }

    this.mobileNumberValidation();
  }

  ngOnDestroy() {
    if (this.cart_subscriber) this.cart_subscriber.unsubscribe();
  }

  mobileNumberValidation() {
    var sampleValueArray = [];
    this.guestClientSaveForm.get('PersonalPhoneNumber').clearValidators()
    var isPersonalPhoneNumberRequied = true;
    var reqValidator = null;
    if (isPersonalPhoneNumberRequied == true) {
      reqValidator = Validators.required;
    }
    var CommunicationTypeValidation = environment.CommunicationTypeValidation;
    if (CommunicationTypeValidation.length > 0) {
      CommunicationTypeValidation.forEach(ctv => {
        if (ctv.Rule != undefined && ctv.Rule.length > 0 && ctv.CommunicationTypeId != undefined && ctv.CommunicationTypeId != "") {
          var ids = ctv.CommunicationTypeId.split(",");
          if (ids != undefined && ids.length > 0 && ids.indexOf(this.SelectedPhoneTypeId) > -1) {
            ctv.Rule.forEach(rule => {
              if (this.DefaultCountryId.toString() == rule.countryId) {
                this.phoneNumberPattern = new RegExp(rule.Value);
                this.placholderSampleNumber = rule.SampleNumber;
                if (rule.SampleNumber != "") {
                  sampleValueArray.push(rule.SampleNumber);
                }
              }
            });
            if (this.phoneNumberPattern == undefined || this.phoneNumberPattern == null) {
              var selectedRule = ctv.Rule.find(a => a.countryId == "");
              this.phoneNumberPattern = new RegExp(selectedRule.Value);
            }
          }
        }
      });
      this.placholderSampleNumber = sampleValueArray.join(', ');
    }

    var patternValidator = null;
    if (this.phoneNumberPattern != undefined && this.phoneNumberPattern != null) {
      patternValidator = Validators.pattern(this.phoneNumberPattern);
    }
    const combinedValidators = Validators.compose([reqValidator, patternValidator]);
    this.guestClientSaveForm.get('PersonalPhoneNumber').setValidators(combinedValidators);
    this.guestClientSaveForm.get('PersonalPhoneNumber').updateValueAndValidity();
  }
  checkGuestDetailChange() : boolean {
    let ischanged = false;
    if(this.guestContact?.firstName != this.guestClientSaveForm.get('FirstName').value){
      ischanged = true;
    }else if (this.guestContact?.lastName != this.guestClientSaveForm.get('LastName').value){
      ischanged = true;
    }
    else if (this.guestContact?.communicationMethods[0].value != this.guestClientSaveForm.get('PersonalPhoneNumber').value){
      ischanged = true;
    }
    else if (this.guestContact?.communicationMethods[1].value  != this.guestClientSaveForm.get('PersonalEmail').value){
      ischanged = true;
    }
    return ischanged
  }

  OnSaveGuestClient(){
    if(this.guestClientSaveForm.valid){
      this.store.dispatch(new FacilitySearchLoading());
      if(this.checkGuestDetailChange()){
        this.guestContact = new Contact();
        this.guestContact.communicationMethods = [];

        this.guestContact.firstName = this.guestClientSaveForm.get('FirstName').value;
        this.guestContact.lastName = this.guestClientSaveForm.get('LastName').value;
        let email = this.guestClientSaveForm.get('PersonalEmail').value;
        let mobileNumber = this.guestClientSaveForm.get('PersonalPhoneNumber').value;

        let mobileCommunicationMethod = new CommunicationMethod();
        mobileCommunicationMethod.id = '-1';
        mobileCommunicationMethod.communicationTypeID = '3';
        mobileCommunicationMethod.value = mobileNumber;

        let emailCommunicationMethod = new CommunicationMethod();
        emailCommunicationMethod.id = '-2';
        emailCommunicationMethod.communicationTypeID = '6';
        emailCommunicationMethod.value = email;

        this.guestContact.communicationMethods.push(mobileCommunicationMethod);
        this.guestContact.communicationMethods.push(emailCommunicationMethod);
        console.log(this.guestContact);
        this._customerService.guestClientSave(this.guestContact).subscribe((data) => {
          if (data != null) {
            console.log(data);
            this.store.dispatch(new SetGuestContact(data));
            if(this.hideSignInButtonIncart){
              this.Redirect();
            }else {
              this._bookingService.GuestFlowEventtriggerProcess();
            }

          }
        })
      }else {
        if(this.hideSignInButtonIncart){
          this.Redirect();
        }else {
          this._bookingService.GuestFlowEventtriggerProcess();
        }
      }
    }else {
      return
    }
  }

  Redirect() {
    return this.route.navigate([RoutePath.BookingCart], { queryParams: { index: 1 } });
  }
}
