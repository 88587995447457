import { BaseResource } from '../base-resource';
import { Client, User, Venue, CommunicationMethod, Address } from './client';
import { Note } from 'src/modules/models/client/note';

export class Contact extends BaseResource {

    client: Client;
    title: string;
    ref: string;
    name: string;
    titleId: number;
    firstName: string;
    lastName: string;
    active: boolean;
    profilePicture: string;
    accountManager: User;
    address: Address;
    position: string;
    userName: string;
    decisionMaker: boolean;
    defaultVenue: Venue;
    communicationMethods: CommunicationMethod[];
    invoiceEmailAddress: string
    password: string;
    invoiceAddress: Address;
    deliveryAddress: Address;
    mailingAddress: Address;
    bookingTypeList: BookingType[];
    clientVenueIds: VenueType[];
    notes: Note[];
    isPrimaryContact: boolean;
    dateRegistered: Date;
    preferredLanguageID: string;
    tel: string;
    email: string;

    //these use for SSO integrations
    ssoContactRef?: string;
    phone?: string[];
    ssoAdditionalData?: any;
    lastFetchedTime?: Date;
    customerType: number;

    constructor() {
        super();
        this.type = "Contact";
    }
}

export class BookingType {
    id: string;
    name: string;
    isRestricted: boolean
}


export class VenueType {
    id: string;
    name: string
}
