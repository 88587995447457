
//import { SalesChannel } from '../question/sales-channel';
import { Answer, AnswerChoice } from '../question/answer';
import { BookingQuestion } from '../question/booking-question';
import { BaseResource } from 'src/modules/models/base-resource';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document';
import { jsonIgnore } from 'json-ignore';

export class Question extends BaseResource {
  constructor() {
    super();
    this.type = "Question";
}
  questionText: string;
  mandatory: boolean;
  allowUndecided: boolean;
  quantityRequired: boolean;
  //salesChannels: SalesChannel[];
  questionType: number;
  @jsonIgnore() questionNo: string;
  questionID: number;
  answerChoices: AnswerChoice[];
  defaultAnswer: string;
  condition: string;
  conditionOperator: ConditionOperator;
  answerUploadedFiles : AnswerUploadedFile[];

  // answer: Answer;
}
export class TextQuestion extends Question {
  constructor() {
    super();
    this.type = "TextQuestion";
}
  defaultAnswer: string;
}
export class DateAndTimeQuestion extends Question {
  constructor() {
    super();
    this.type = "DateAndTimeQuestion";
}
  answerDate: boolean;
  answerTime: boolean;
  answerDateAndTime: boolean;
}
export class BooleanQuestion extends Question {

}
export class SingleChoiceQuestion extends Question {
  constructor() {
    super();
    this.type = "SingleChoiceQuestion";
}
  answerChoices: AnswerChoice[];
}
export class MultipleChoiceQuestion extends Question {
  constructor() {
    super();
    this.type = "MultipleChoiceQuestion";
}
  answerChoices: AnswerChoice[];
}
export class FileUploadQuestionAnswer extends BookingQuestion {
  constructor() {
    super();
    this.type = "FileUploadQuestionAnswer";
}
  maxFileSize: number;
  //id: number;
  answerby: number;
  documentFile: string;
  fileType: number;
  supportedFileType: string;
  answeredText: string;
  // need to Change this Spelling mistake
  answerUploadedFiles: AnswerUploadedFile[];
}
export class FileUploadQuestion extends Question {
  constructor() {
    super();
    this.type = "FileUploadQuestion";
}
  maxSize: number;
  supportedFileType: string;
  maxCount: number;
  minCount: number;
}
  // need to Change this Spelling mistake
export class AnswerUploadedFile extends BaseResource {
  constructor() {
    super();
    this.type = "AnswerUploadedFile";
    this.isDelete = false;
  }
  name: string;
  fileSize: string;
  fileType: string;
  blobId: string;
  url: string;
  documentFileURL:string;
  documentFile: string;
  documentFileOriginal: string;
  isDelete: boolean;
  @jsonIgnore() file: File;
}


export enum ConditionOperator {
  EQUAL = 1,
  GREATER_THAN = 2,
  LESS_THAN = 3
}
