import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ViewportScroller, DecimalPipe, LocationStrategy} from '@angular/common';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatExpansionPanel} from '@angular/material/expansion';
import {PageEvent, MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from 'src/environments/environment';
import {Booking, BookingAdapter} from 'src/modules/models/booking/booking';
import {BookingStatus} from 'src/modules/models/regular-portal/booking/booking';
import {Booking as BookingRB} from 'src/modules/models/regular-portal/booking/booking';
import {BookingItems, BookingItemAssociation} from 'src/modules/models/booking/booking-items';
import {BookingPackages} from 'src/modules/models/booking/booking-packages';
import {FacilityAdaptor} from 'src/modules/models/asset/facility-adaptor';
import {QuestionSummary} from 'src/modules/models/booking/question-summary';
import {AdHocDocument} from 'src/modules/models/booking/adhoc-document';
import {AppSetting} from 'src/modules/models/settings/casual-portal/app-setting';

import {Utility} from 'src/modules/utility';
import {BookingService} from 'src/modules/services/booking.service';
import {PaymentService} from 'src/modules/services/payment.service';
import {RegualrBookingService} from 'src/modules/services/regular-booking.service';

import {BookingTask, User, Group} from 'src/modules/models/regular-portal/booking/save-booking/booking-task';
import {WpMessageComponent} from '../../modals/wp-message/wp-message.component';
import {WpChangeRequestComponent} from '../../modals/wp-change-request/wp-change-request.component';
import {WpRequestAccessComponent} from '../../modals/wp-request-access/wp-request-access.component';
import {WpAlreadyAccessComponent} from '../../modals/wp-already-access/wp-already-access.component';
import {WPBoookingRolloverComponent} from 'src/modules/regular-booking/wp-booking-rollover/wp-booking-rollover';

import {QuestionnaireComponent} from 'src/modules/booking-portal/modals/questionnaire/questionnaire.component';
import {UpsellComponent} from 'src/modules/booking-portal/booking-cart/upsell/upsell.component';
import {SuccessMessageComponent} from 'src/modules/shared/success-message/success-message.component';
import {CancelBookingComponent} from 'src/modules/booking-portal/modals/cancel-booking/cancel-booking.component';
//import { BookingChangeRequestComponent } from 'src/modules/booking-portal/modals/booking-change-request/booking-change-request.component';
import {AlertMessageComponent} from 'src/modules/shared/alert-message/alert-message.component';
import {
  WpRolloverBookingModalComponent
} from 'src/modules/regular-booking/popups/wp-rollover-booking-modal/wp-rollover-booking-modal.component';
import {DeleteConfirmationComponent} from 'src/modules/shared/delete-confirmation/delete-confirmation.component';
import {ExtraAllocationComponent} from 'src/modules/booking-portal/modals/extra-allocation/extra-allocation.component';
import {ExtraDeletionComponent} from 'src/modules/booking-portal/modals/extra-deletion/extra-deletion.component';

import {UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry} from 'ngx-file-drop';
import {Store, ICart, SetContact} from 'src/modules/store/index';
import {Observable, Subscription} from 'rxjs';
import {UpsellSummary, Item} from 'src/modules/models/item/item';
import {FacilitySummary} from 'src/modules/models/asset/facility-summary';
import {Configuration} from 'src/modules/models/asset/configuration';
import {AssetService} from 'src/modules/services/asset.service';

import {ReferenceData} from 'src/modules/models/reference-data';
import {SystemService} from 'src/modules/services/system.service';
import {RegularBookingSettings} from 'src/modules/models/settings/regular-portal/regular-booking-setting';

import {Notes} from 'src/modules/models/booking/booking-notes';
import {BaseComponent} from 'src/modules/shared/base.component';

//loader
import {ILoadingStatus} from 'src/modules/store/loading/status-store';
import {FacilitySearchLoading, FacilitySearchLoaded} from 'src/modules/store/loading/actions';
import * as _ from 'lodash';
import {forEach} from 'lodash';
import moment from 'moment';

import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {max, min} from 'date-fns';
import {BookingFilter} from 'src/modules/models/booking/booking-search/booking-filter';
import {SpinnerLoaded, SpinnerLoading} from 'src/modules/store/regular-booking';
import {Question} from 'src/modules/models/item/question';
import {CustomerService} from 'src/modules/services/customer.service';


@Component({
  selector: 'opt-past-booking-details',
  templateUrl: './booking-details.component.html'
})

export class BookingDetailsComponent extends BaseComponent implements OnInit {
  @ViewChild('recurrentFacilitySummaryPanel') recurrentFacilitySummaryPanel: MatExpansionPanel;
  @ViewChild('bookingRecurrentItemSummaryPanel') bookingRecurrentItemSummaryPanel: MatExpansionPanel;
  @ViewChild('recurrentUpsellPanel') recurrentUpsellPanel: MatExpansionPanel;
  @ViewChild('invoicePanel') invoicePanel: MatExpansionPanel;
  @ViewChild('recurrentPaginator') paginator: MatPaginator;
  @ViewChild('fileInputTag') fileInputTagVariable: ElementRef;
  status: boolean = false;

  public files: UploadFile[] = [];
  dialogRef: MatDialogRef<CancelBookingComponent>;
  //dialogRefChangeRequest: MatDialogRef<BookingChangeRequestComponent>;
  selectedFiles: AdHocDocument[] = [];
  webAPiBaseUrl: string;
  apiUrl: string;
  cart$: Observable<ICart>;
  cart_subscriber: any;
  bookingId: string;
  booking: any;
  invoices: any[] = [];
  isSessionCalled: boolean = false;
  isAllchecked: boolean = false;

  contactId: string;
  clientId: string;
  contactName: string;
  messages: any;
  selectedInvoiceId: string;
  totalQuestionCount: number = 0;
  TotalBondPrice: number = 0;
  // showLoader: boolean = true;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  //isCancelledBooking: boolean = false;
  isNotFullPaidedBooking: boolean = true;
  isHavePayments: boolean = false;
  isHaveBondItems: boolean = false;
  bondItems: any[] = [];
  isUpcommingBooking: boolean = false;
  isBookingLoaded: boolean = false;
  maximumDate: Date;
  bookingItems: BookingItems[] = [];
  isEnableUpdateButton: boolean = false;

  facilitySummaries: any;
  configrationLoaded: boolean = false;
  facilityName: string;
  bookingType: string;

  isLoggedIn = false;
  customerDefaultPriceConsessionId: number = 0;
  isUpsellAvailableForConcession: boolean = false;
  packageClasses = [];
  configurationDisplayMode: any;
  isConfigurationEnabled: boolean;
  isSystemAllowedImmediateConfirmation: boolean;

  //Document Categoty changes added
  selecteddocumentTemplateCategory: string;
  documentTemplateCategories: ReferenceData[] = [];
  requiredDocumentTemplateCategories: ReferenceData[] = [];

  isGroupByFacilityNameInBookingView: boolean;
  bookingData: AppSetting;
  documentUpldSize: any;

  availableBookingTypes: any[] = [];
  bookingTypeAvailable: boolean;
  enableBackButtonFunction: boolean = false;
  PaymentPortalUrl: string = environment.PaymentPortalUrl;
  isInternalUser: boolean = environment.IsInternalUser;

  bdSubscrption: Subscription;

  isBookingChangeRequest: boolean = false;
  changeRequestNotes: Notes[] = [];
  isCouncilCreatedBooking: boolean = false;
  isClientApprovalPending: boolean = false;
  bookingTermsAndConditions: string = '';
  isConditionsAccepted: boolean = false;
  clientEmail: string;
  // allMandatoryUpsellQuestionsAnswerd: boolean = true;
  loggedInContactId: string;
  newUpsellIds: string[] = [];
  //startTimer
  maxStartDate = moment();
  minStartDate = moment();

  //endtimer
  maxEndDate = moment();
  minEndDate = moment();

  dateStart;
  dateEnd;
  venues: any[] = [];
  _filter = new BookingFilter();
  selectedVenuesInBooking: any[] = [];
  @ViewChild('menufilter') filter: MatMenu;
  sessionSubs: Subscription;
  mandotoryFieldNotFilled: boolean = true;
  isSecureInvoiceEnabled: boolean = environment.SystemOption.isSecureInvoiceEnabled;

  // selectedBooking : FacilitySummary[] = [];

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private bookingService: BookingService, private snackBar: MatSnackBar,
              private store: Store<ICart>, private router: Router, private sanitizer: DomSanitizer, private vps: ViewportScroller, private _paymentService: PaymentService,
              private _assetService: AssetService, private _systemService: SystemService, private location: LocationStrategy,
              private _customerService: CustomerService,
  ) {
    super();
    this.webAPiBaseUrl = environment.WebApiUrl;
    this.apiUrl = environment.ApiUrl;
    this.cart$ = this.store.select('cart');
    this.packageClasses = environment.PackageClasses.filter(cls => cls.Id != '0');
    this.isGroupByFacilityNameInBookingView = environment.IsGroupByFacilityNameInBookingView;

    this.cart_subscriber = this.cart$.subscribe(cart => {

      console.log('booking view cart subscription');
      if ((cart != undefined && cart.contact != undefined && cart.contact.id != undefined && cart.contact.id != null && cart.contact.id != '')) {
        this.contactId = cart.contact.id;
        this.clientId = cart.contact.client.id;
        this.contactName = cart.contact.firstName;
        //this.selectedFiles = [];
        this.isLoggedIn = (cart.contact != null && cart.contact.firstName != null && cart.contact.firstName != '') ? true : false;
        this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? (cart.contact.client.priceConcessionId != '') ? cart.contact.client.priceConcessionId : 0 : 0;
        this.availableBookingTypes = cart.contact.bookingTypeList ? cart.contact.bookingTypeList : cart.contact.client.bookingTypes;
        if (cart.contact.invoiceEmailAddress) {
          this.clientEmail = cart.contact.invoiceEmailAddress;

        }

        // only if oged in booking can load else no need of other APi calls or workingd

      }
      // else {
      //     this.router.navigate(['/client/login']);
      // }
    });

    if (!this.sessionSubs && !this.isSessionCalled) {
      this.isSessionCalled = true;
      // get session from mvc
      this.sessionSubs = this._customerService.GetSession().safeSubscribe(this, (res) => {
        if (res != null) {
          this.store.dispatch(new SetContact(res, null, null, null, null, null));
          this.loadBookingData();
        } else if (!this.isInternalUser) {
          // show session expired error message
          this.snackBar.openFromComponent(AlertMessageComponent, {
            data: ['Session Timeout'],
            duration: 3000,
            verticalPosition: 'top'
          });

          this.store.dispatch(new FacilitySearchLoaded());
          //redirect to login
          this.router.navigate(['/client/login']);
        }
      });
    }


    this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
    //this.isCancelledBooking = false;
    this.messages = environment.Messages;
    this.bookingId = this.route.snapshot.paramMap.get('id');

    var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
    this.maximumDate = new Date();
    this.maximumDate.setDate(this.maximumDate.getDate() + leadDays);
    // this.loadBookingData();


    const regularBookingData = (environment.RegularBookingSetting as RegularBookingSettings);

    this.bookingTermsAndConditions = regularBookingData.booking?.bookingTermsAndConditions;

    this.bookingData = (environment.AppSetting as AppSetting);
    if (this.bookingData.BookingConfig.MaximumDocUploadSize) {
      this.documentUpldSize = this.bookingData.BookingConfig.MaximumDocUploadSize + 'MB';
    } else {
      this.documentUpldSize = '2GB';
    }

    // this.enableBackButtonFunction = true;
    // history.pushState(null, null, window.location.href);
    // console.log(window.location);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      if (!window.location.href.includes('/facility') && !this.enableBackButtonFunction) {
        this.enableBackButtonFunction = true;
        this.backButtonFunnction();
      }
    });


  }

  ngOnDestroy(): void {
    if (this.bdSubscrption) {
      this.bdSubscrption.unsubscribe();
    }

    if (this.cart_subscriber) {
      this.cart_subscriber.unsubscribe();
    }
  }

  ngOnInit() {
    this.preventWindowDragEvent();
    this.configurationDisplayMode = environment.ConfigurationSelection.DisplayMode;
    console.log(this.configurationDisplayMode);
    this.isConfigurationEnabled = environment.ConfigurationSelection.Enabled;

    //default select all option filtervenue dropdown
    this.selectedVenuesInBooking.push('0');
  }

  focusTo(id) {
    this.vps.scrollToAnchor(id);
  }

  isAvailableUpsell(itemDate) {
    return (itemDate != undefined && (new Date(itemDate) > this.maximumDate));
  }

  backButton() {
    this.enableBackButtonFunction = true;
    this.backButtonFunnction();
  }

  backButtonFunnction() {
    if (this.enableBackButtonFunction) {
      if (this.isEnableUpdateButton) {
        // Yes No Confirmation popup
        let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
          data: {
            message: 'Changes have been made to this booking. Would you like to save your changes?',
            yesText: 'YES',
            noText: 'NO'
          },
          panelClass: ['custom-dialog-container', 'w30modal'],
          height: 'auto',
          width: '30%'
        });
        const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
          if (data == true) {
            this.updateBooking();
          } else {
            window.open(window.location.origin + window.location.pathname + '#/bookings', '_self');
            //this.enableBackButtonFunction = false;
          }
        });
      } else if (this.isEnableUpdateButton != undefined) {
        window.open(window.location.origin + window.location.pathname + '#/bookings', '_self');
        //this.enableBackButtonFunction = false;
      }
    }
  }

  loadBookingData(isFilter: Boolean = true) {
    console.log(this.clientId);
    if (this.bookingId != undefined) {
      this.store.dispatch(new FacilitySearchLoading());
      this.bdSubscrption = this.bookingService.BookingSelect(this.bookingId, this._filter, this.selectedVenuesInBooking).subscribe(result => {
        if (result.isError) {
          console.log('Error - Booking Select :', result.Errors);

          if (result.errors.length > 0) {
            var arr = [];
            result.errors.forEach(e => {
              arr.push(e.detail);
            });
            this.openSnackBarError(arr);
          } else {
            this.openSnackBarError(['Booking details failed, unknown error has occurred. Please refresh the page and try again.']);
          }

          this.store.dispatch(new FacilitySearchLoaded());
          this.router.navigate(['/bookings']);
          return;
        }

        if ((!result.data) || (!environment.IsBookingSearchByClient && result.data.contactId != this.contactId) || (environment.IsBookingSearchByClient && result.data.clientId != this.clientId)) {
          this.store.dispatch(new FacilitySearchLoaded());
          this.router.navigate(['/bookings']);
          return;
        }
        console.log(' result.data- -- -- ', result.data);
        this.booking = result.data;
        this.facilitySummaries = _.clone(this.booking.facilitySummaries);
        const excludedBookingTypes = [0, 1, 6, 7, 8];
        if (!excludedBookingTypes.includes(this.booking.bookingTypeId)) {
          this.bookingType = this.booking.type;
        }else{
          this.facilityName = this.facilitySummaries[0].name;
        }



        this.bookingData.BookingConfig.ClientBookingApprovalPendingStatus == Number(this.booking.bookingStatusId) ? this.isClientApprovalPending = true : this.isClientApprovalPending = false;
        this.isCouncilCreatedBooking = (Number(this.booking.bookingStatusId) == this.bookingData.BookingConfig.ClientBookingApprovedStatus) || (Number(this.booking.bookingStatusId) == this.bookingData.BookingConfig.ClientBookingApprovalPendingStatus);

        if (isFilter) {
          //filter Date min max date
          var _startEndTime = this.booking.facilitySummaries.map(fs => ({start: fs.startTime, end: fs.endTime}));
          this.minEndDate = this.minStartDate = moment(min(_startEndTime.map(se => Utility.convertISOToDate(new Date(moment(se.start.toString(), 'YYYY-MM-DD').toDate())))));
          this.maxEndDate = this.maxStartDate = moment(max(_startEndTime.map(se => Utility.convertISOToDate(new Date(moment(se.end.toString(), 'YYYY-MM-DD').toDate())))));

          //venues in booking
          var _anyVenue = {'venueId': '0', 'name': 'All'};
          this.venues.push(_anyVenue);
          var _venues = this.booking.facilitySummaries.map(fs => ({venueId: fs.facilityId, name: fs.name}));
          this.venues = [...this.venues, ..._venues];
          this.venues = this.venues.filter((obj, index, self) => self.findIndex(t => JSON.stringify(t) === JSON.stringify(obj)) === index);
          this.selectedVenuesInBooking = this.venues.map(x => x.venueId);


        }

        if (this.isGroupByFacilityNameInBookingView) {
          this.groupByFacilityName();
        }
        this.getImagesforConfigurations();
        // Initially, all panel data should be expanded
        this.expandAllPannelChange(this.booking);
        this.isBookingLoaded = true;
        this.isUpcommingBooking = (new Date(this.booking.eventEndDate) > new Date());
        //this.isCancelledBooking = !(this.booking.isHideRequestBookingCancelBtn);


        this.bondItems = result.data.bondItems;
        if (this.bondItems) {
          this.isHaveBondItems = (result.data.bondItems.length > 0);
        }


        let sumOfOutstanding = this.booking.grossAmountExcludingBond - this.booking.paidAmount;
        this.isNotFullPaidedBooking = (sumOfOutstanding != 0);

        if (result.data.bookingQuestionnaires && result.data.bookingQuestionnaires.length > 0) {
          var bokingData = BookingAdapter.populateQuestions(this.booking, result.data.bookingQuestionnaires);
          this.booking = bokingData.booking;
          this.totalQuestionCount = bokingData.totalQuestionCount;
        }

        if (this.booking.bookingTypeId > 1) {
          this.loadDocumentTemplateCategories();
        }

        this.store.dispatch(new FacilitySearchLoaded());
        this.getImagesforConfigurations();
        this.CheckSeasonAvailability();

        //get only the facility summeries
        // if(this.booking){
        //   this.selectedBooking =  this.booking.facilitySummaries
        // }

      }, err => {
        this.store.dispatch(new FacilitySearchLoaded());
      }, () => {

      });

      //invoice of booking
      this.bookingService.BookingInvoiceSearch(this.bookingId).subscribe(result => {
        this.invoices = result.Data;

        if (this.invoices && this.invoices.length > 0) {
          this.invoices.forEach((inv, i) => {
            if (inv.Cancelled) {
              inv.Status = 'Cancelled';
            }

            this._paymentService.GetPaymentAllocation(inv.InvoiceId).subscribe(result => {
              inv.payments = result.data;
              this.isHavePayments = (result.data.length != 0);
              inv.payments.forEach((p, i) => {
                if (p.source) {
                  p.source.type = this.getPaymentMethod(p.source.type);
                }
              });
            }, err => {
              this.openSnackBarError(['Unable to load payment information. Please refresh the page and try again.']);
              this.store.dispatch(new FacilitySearchLoaded());
            });
          });
        }
      }, err => {
        this.openSnackBarError(['Unable to load invoice information. Please refresh the page and try again.']);
        this.store.dispatch(new FacilitySearchLoaded());
      });
    }
  }

  countFaciltyUpsellTotalQuestions(bookingItemSummary): number {
    var totalUpselQuestion = 0;
    if (bookingItemSummary.upsellSummaries) {
      bookingItemSummary.upsellSummaries.forEach(upsl => {
        if (upsl.question) {
          totalUpselQuestion += upsl.question.length;
        }
      });
    }
    return totalUpselQuestion;
  }

  groupByFacilityName() {
    var thisComponent = this;
    var seasonalBasePackageFacilities = [];
    var nonseasonalBasePackageFacilities = [];
    var facilitySummaries = _.clone(this.booking.facilitySummaries.filter(fs => fs.isRecurrent == false));
    this.booking.facilitySummaries = _.clone(this.booking.facilitySummaries.filter(fs => fs.isRecurrent == true));

    // Grouping Non Recurrent items
    if (facilitySummaries && facilitySummaries.length > 0) {
      var result = facilitySummaries.reduce(function (r, a) {
        r[a.name] = r[a.name] || [];
        r[a.name].push(a);
        return r;
      }, Object.create(null));
      var facilityGroupsArray = Object.keys(result)
        .map(function (key) {
          return result[key];
        });
      if (facilityGroupsArray && facilityGroupsArray.length > 0) {
        facilityGroupsArray.forEach(function (facilityGroups) {
          facilityGroups.sort(function (a, b) {
            return a.startTime - b.startTime;
          });
          if (facilityGroups && facilityGroups.length > 0) {
            var facilitySummary = facilityGroups[0];
            for (var i = 1; i < facilityGroups.length; i++) {
              Array.prototype.push.apply(facilitySummary.bookingItemSummaries, facilityGroups[i].bookingItemSummaries);
              if (facilityGroups[i].startTime < facilitySummary.startTime) {
                facilitySummary.startTime = facilityGroups[i].startTime;
              }
              if (facilitySummary.endTime < facilityGroups[i].endTime) {
                facilitySummary.endTime = facilityGroups[i].endTime;
              }
              if (facilitySummary.attendees < facilityGroups[i].attendees) {
                facilitySummary.attendees = facilityGroups[i].attendees;
              }
              facilitySummary.total += facilityGroups[i].total;
              if (facilityGroups[i].isHaveUpsell) {
                facilitySummary.isHaveUpsell = facilityGroups[i].isHaveUpsell;
              }
            }
            if (facilitySummary.isSeasonalBasePackageFacilitySummary) {
              seasonalBasePackageFacilities.push(facilitySummary);
            } else {
              nonseasonalBasePackageFacilities.push(facilitySummary);
            }
          }
        });
        thisComponent.booking.facilitySummaries = seasonalBasePackageFacilities.concat(nonseasonalBasePackageFacilities);
      }
    }
    console.log('groupByFacilityName', thisComponent.booking);
  }

  expandAllUpsellChange(facilitySummary) {
    facilitySummary.isExpandAll = !facilitySummary.isExpandAll;
    if (facilitySummary.bookingItemSummaries != undefined && facilitySummary.bookingItemSummaries.length > 0) {
      facilitySummary.bookingItemSummaries.forEach(i => {
        i.isExpandedUpsell = facilitySummary.isExpandAll;
      });
    }
  }

  getPaymentMethod(value: string) {
    let returnData = 'Unknown';
    if (value && value != null && value != '') {
      let paymentMethods = {
        'cashreceipt': 'Cash',
        'creditcardreceipt': 'Credit Card',
        'chequereceipt': 'Cheque',
        'contrareceipt': 'Contra',
        'bankdraftsreceipt': 'Bank Drafts',
        'transfersreceipt': 'Transfers',
        'voucherreceipt': 'Voucher',
        'clientaccountreceipt': 'Client Account'
      };
      returnData = paymentMethods[value.toLowerCase()];
      if (returnData === undefined) {
        returnData = 'Unknown';
      }
    }
    return returnData;
  }

  onInvoiceView(inv) {
    this.store.dispatch(new FacilitySearchLoading());
    this.bookingService.InvoiceView(inv.InvoiceId).subscribe((res) => {
        if (res.data) {
          //     window.open((environment.WebApiUrl + res.data), '_blank');
          window.open((res.data), '_blank');
        }
      },
      err => {
        this.openSnackBarError(['Unable to load invoice information. Please try again.']);
      },
      () => {
        this.store.dispatch(new FacilitySearchLoaded());
      }
    );
  }

  filterPagination(itemsList: any[], pageSize: number, pageNumber: number) {
    let startNumber = pageNumber * pageSize;
    let endNumber = startNumber + pageSize;
    return itemsList.slice(startNumber, endNumber);
  }

  checkDiscountItem(item) {


    if (item) {

      if (item.type.toLowerCase() == 'discountitem') {
        return true;
      } else {
        return false;
      }
    }


  }


  openQuestions() {
    let dialogRef = this.dialog.open(QuestionnaireComponent, {
      data: {
        booking: this.booking,
        isEditMode: true,
        isFullBookingView: true,
        isReadOnly: (this.booking.isDisableBookingEdit || !this.isUpcommingBooking)
      },
      panelClass: ['custom-dialog-container', 'w80modal', 'modal-width', 'extrapop'],
      height: 'auto',
      width: '80%'
    });

    dialogRef.componentInstance.onSubmit.subscribe((data: any) => {
      if (data) {
        this.booking = data.booking;
        this.isEnableUpdateButton = data.isAnswerChanged;

        //  //get only the facility summeries
        //  if(this.booking){
        //   this.selectedBooking =  this.booking.facilitySummaries
        // }
      }
    });
  }

  cancelBooking(booking) {
    if (booking && booking.bookingId != undefined) {
      let dialogRef = this.dialog.open(CancelBookingComponent, {
        data: {
          booking: booking,
          facilityName: this.facilityName,
          bookingType: this.bookingType
        },
        panelClass: ['w80modal', 'dialog-container-common'],
        width: '250px'
      });

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          // this.bookingCancel(booking);
          this.loadBookingData();
          this.CreateTask(1);
          //this.isEnableUpdateButton = true;
        }
        dialogRef = null;
      });
    } else {
      this.openSnackBarError(['Failed to cancel booking. Please try again.']);
    }
  }

  CreateTask(configurationType: number) {

    // configurationType
    // 1 => BookingCancellation
    // 2 => Change Request
    // 3 => Client Approval
    var _config;
    var _appSettingXmlData = (environment.AppSetting as AppSetting);
    if (configurationType == 1) {
      _config = _appSettingXmlData.BookingConfig.BookingCancellation;
    } else if (configurationType == 2) {
      _config = _appSettingXmlData.BookingConfig.ChangeBooking;
    } else if (configurationType == 3) {
      _config = _appSettingXmlData.BookingConfig.ClientApprovedBooking;
    }

    if (_config != null && _config.Task != null) {
      if (_config.Task.Subject != undefined) {

        var subject = _config.Task.Subject.replace('#BookingRef#', this.booking.bookingRef);

        let bookingTask = new BookingTask();
        bookingTask.id = '-1';
        bookingTask.name = subject;
        bookingTask.taskTypeID = '1';
        bookingTask.taskType = 'Booking';
        bookingTask.date = new Date();
        //bookingTask.note = "";
        bookingTask.reference = this.booking.bookingRef;
        bookingTask.dueDate = new Date();
        bookingTask.sentDate = new Date();
        //bookingTask.completed = false;
        //bookingTask.readStatus = false;
        //bookingTask.cancelled = false;
        //bookingTask.notification = false;

        bookingTask.booking = new BookingRB();
        bookingTask.booking.id = this.booking.bookingId;

        bookingTask.fromUser = new User();
        bookingTask.fromUser.id = _config.Task.FromUser;

        bookingTask.assignedUsers = [] as User[];

        var assignedUsersArray = _config.Task.AssignedUsers.split(',');
        assignedUsersArray.forEach((usr, i) => {
          var assignedUser = new User();
          assignedUser.id = usr;
          bookingTask.assignedUsers.push(assignedUser);
        });

        bookingTask.assignedUserGroups = [] as Group[];

        var assignedUserGroupArray = _config.Task.AssignedUserGroups.split(',');
        assignedUserGroupArray.forEach((usr, i) => {
          var assignedUserGrp = new Group();
          assignedUserGrp.id = usr;
          bookingTask.assignedUserGroups.push(assignedUserGrp);
        });

        this.bookingService.PostTask(bookingTask).safeSubscribe(this, result => {
          if (result != undefined && result.length > 0) {
            console.log('result after task post=>> ', result);
          }

        });
      }

    }


  }

  changeRequest() {
    this.isBookingChangeRequest = true;

  }

  bookingCancel(data) {
    this.store.dispatch(new FacilitySearchLoading());
    this.bookingService.BookingCancelPatch(data.bookingId, data).subscribe(result => {
        if (result) {
          if (result.body.data != null && result.body.data.cancelled) {
            // this.store.dispatch(new FacilitySearchLoaded());
            this.openSnackBarSuccess(['Your request has been forwarded and we will respond shortly.']);
          } else if (result.body.isError) {
            // this.store.dispatch(new FacilitySearchLoaded());
            this.openSnackBarError(['Booking cancel request has been failed, ' + result.body.errors[0].detail]);
          }

        } else {
          // this.store.dispatch(new FacilitySearchLoaded());
          this.openSnackBarError(['Booking cancel request has been failed. Please try again.']);

        }
      }, error => {
        // this.store.dispatch(new FacilitySearchLoaded());
        this.openSnackBarError(['Booking cancel request has been failed. Please try again.']);
      },
      () => {
        this.store.dispatch(new FacilitySearchLoaded());
      }
    );

  }

  //isPriceRange(minPrice, maxPrice) {
  //    if (minPrice === undefined || maxPrice === undefined) return false;
  //    let min = (minPrice.priceIncludingTax !== undefined) ? minPrice.priceIncludingTax : 0;
  //    let max = (maxPrice.priceIncludingTax !== undefined) ? maxPrice.priceIncludingTax : 0;

  //    var dp: DecimalPipe = new DecimalPipe('en');
  //    return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
  //}

  isPriceRange(upsell: UpsellSummary) {

    if (upsell == undefined || (upsell.minPrice == undefined && upsell.maxPrice == undefined)) {
      return;
    }
    if (this.booking.priceConcessionId != 0) {
      return false;
    } else {
      var min = 0;
      var max = 0;
      var dp: DecimalPipe = new DecimalPipe('en');

      min = (upsell.minPrice) ? upsell.minPrice!.priceIncludingTax : 0;
      max = (upsell.maxPrice) ? upsell.maxPrice!.priceIncludingTax : 0;

      return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
    }
  }

  popupExtra(facilitySummary, bookingItemSummary, priceConcessionId) {
    console.log('this.booking ', this.booking);
    console.log('facilitySummary ', facilitySummary);
    var thisPage = this;
    let dialogRef = this.dialog.open(UpsellComponent, {
      data: {fc: facilitySummary, bookingItemSummary: bookingItemSummary, isBookingEdit: true, priceConcessionId: priceConcessionId},
      panelClass: ['w80modal', 'dialog-container-common'],
      width: '250px'
    });

    const sub = dialogRef.componentInstance.onSubmit.subscribe((upsellSummary: any) => {
      console.log('facilitySummary ', facilitySummary);

      if (upsellSummary) {
        facilitySummary.isHaveUpsell = true;
        this.newUpsellIds.push(upsellSummary.id);
        //if (!this.isGroupByFacilityNameInBookingView) {
        let upselAdded = [];
        if (bookingItemSummary.upsellSummaries == undefined) {
          bookingItemSummary.upsellSummaries = [];
        }
        if (bookingItemSummary.upsellSummaries != undefined && bookingItemSummary.upsellSummaries.length > 0) {
          upselAdded = bookingItemSummary.upsellSummaries.filter(upsell => upsell.id == upsellSummary.id);
        }
        console.log('upselAdded ', upselAdded);
        if (upselAdded == undefined || upselAdded.length == 0) {
          bookingItemSummary.upsellSummaries.push(upsellSummary);

          // populate BookingItems
          var bookingUpsellItem = new BookingItems();
          bookingUpsellItem.type = FacilityAdaptor.getItemPrefix(upsellSummary.type.toLowerCase()) + upsellSummary.type;
          bookingUpsellItem.quantity = upsellSummary.qty;
          bookingUpsellItem.item.id = upsellSummary.id;
          bookingUpsellItem.item.type = upsellSummary.type;
          bookingUpsellItem.startTime = bookingItemSummary.startTime;
          bookingUpsellItem.endTime = bookingItemSummary.endTime;
          bookingUpsellItem.upsell = true;
          bookingUpsellItem.name = upsellSummary.name;
          bookingUpsellItem.question = upsellSummary.question;

          // populate suitable package
          upsellSummary.itemPrice = (upsellSummary.minPrice == undefined || upsellSummary.minPrice == null) ? upsellSummary.maxPrice : upsellSummary.minPrice;

          if (upsellSummary.itemPrice !== undefined && !(isNaN(upsellSummary.itemPrice.concessionId))) {
            bookingUpsellItem.priceTypeId = upsellSummary.itemPrice.priceTypeId;
          }


          bookingUpsellItem.id = ((thisPage.bookingItems.length + 1) * (-1)).toString();
          bookingUpsellItem.bookingItemAssociations = [];

          let bookingItemAssociation = new BookingItemAssociation();
          bookingItemAssociation.type = undefined;
          bookingItemAssociation.id = ((thisPage.bookingItems.length + 1) * (-1)).toString();
          bookingItemAssociation.allocatedQuantity = upsellSummary.qty;
          bookingItemAssociation.startTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.startTime));
          bookingItemAssociation.endTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.endTime));
          bookingItemAssociation.asset.id = facilitySummary.facilityId;
          bookingItemAssociation.asset.type = undefined;
          bookingItemAssociation.primaryBookingSpaceItem = {id: bookingItemSummary.bookingItemId};
          bookingUpsellItem.bookingItemAssociations.push(bookingItemAssociation);
          thisPage.bookingItems.push(bookingUpsellItem);
          if (upsellSummary.question) {
            this.totalQuestionCount += upsellSummary.question.length;
            facilitySummary.upsellSummaries = [];
            facilitySummary.upsellSummaries.push(upsellSummary);
            this.popupUpsellQuestion(facilitySummary, bookingUpsellItem);

          }
          this.isEnableUpdateButton = true;
        }
      }
    });
  }

  popupUpsellQuestion(fc, item) {
    this.dialog.open(QuestionnaireComponent, {
      data: {fc: fc, item: item, isUpsellItem: true},
      panelClass: ['custom-dialog-container', 'w80modal', 'modal-width', 'extrapop'],
      height: 'auto',
      width: '80%'
    });
  }


  popQuestion(fc) {
    let dialogRef = this.dialog.open(QuestionnaireComponent, {
      data: {
        fc: fc,
        item: fc.bookingItemSummaries[0],
        isEditMode: true,
        isReadOnly: (this.booking.isDisableBookingEdit || !this.isUpcommingBooking)
      },
      panelClass: ['custom-dialog-container', 'w80modal', 'modal-width', 'extrapop'],
      height: 'auto',
      width: '80%'
    });

    dialogRef.componentInstance.onSubmit.subscribe((data: any) => {
      if (data) {
        this.booking.facilitySummaries.forEach(fs => {
          fs.bookingItemSummaries.forEach(bis => {
            if (bis.bookingItemId == data.bookingItemId) {
              bis.question = data.question;
              bis.totalAnsweredQuestions = data.totalAnsweredQuestions;
            }

            if (bis.upsellSummaries && bis.upsellSummaries.length > 0) {
              bis.upsellSummaries.forEach(us => {
                if (us.bookingItemId == data.bookingItemId) {
                  us.question = data.question;
                  us.originalAnswer = data.originalAnswer;
                  us.isSaved = data.isSaved;
                }
              });
            }
          });
        });
        this.isEnableUpdateButton = true;
      }
    });
  }

  getFacilitySummaryChanges(index, facilitySummary) {
    if (this.booking != undefined) {
      if (facilitySummary.bookingItemSummaries[index].totalAnsweredQuestions != undefined) {
        this.booking.facilitySummaries = facilitySummary;
      }
    }
    return index;
  }

  pageclickevent(fc, pageEvent: PageEvent) {
    fc.pageSize = pageEvent.pageSize;
    fc.pageNumber = pageEvent.pageIndex;
  }

  downloadDocument(url,name ) {
    this.bookingService.downloadFile(url, name);
  }

  expandAllPannelChange(booking) {
    booking.isExpanded = !booking.isExpanded;

    if (booking && booking.facilitySummaries && booking.facilitySummaries.length > 0) {
      booking.facilitySummaries.forEach(fc => {
        fc.isExpandedSummary = fc.isExpandAll = booking.isExpanded ? true : false;
        if (fc.isRecurrent || this.isGroupByFacilityNameInBookingView) {
          fc.isExpandedItems = booking.isExpanded ? true : false;
        }

        // expand collapse upsell items
        if (fc.bookingItemSummaries && fc.bookingItemSummaries.length > 0) {
          fc.bookingItemSummaries.forEach(i => {
            i.isExpandedUpsell = booking.isExpanded ? true : false;
          });
        }
      });

    }
  }

  expandSummaryPannelChange(facilitySummary) {
    facilitySummary!.isExpandedSummary = !facilitySummary!.isExpandedSummary;
  }

  expandRecurrentItemSummaryPanelChange(facilitySummary) {
    facilitySummary!.isExpandedItems = !facilitySummary!.isExpandedItems;
  }

  expandrecurrentItemUpsellSummaryPanelChange(bookingItemSummary, facilitySummary) {
    bookingItemSummary!.isExpandedUpsell = !bookingItemSummary!.isExpandedUpsell;
    facilitySummary.isExpandAll = !facilitySummary.isExpandAll;
  }

  expandInvoicePannel(invoice) {
    this.invoicePanel.expanded = !this.invoicePanel.expanded;
    if (this.selectedInvoiceId === invoice.InvoiceId) {
      this.selectedInvoiceId = undefined;
    } else {
      this.selectedInvoiceId = invoice.InvoiceId;
    }
  }

  loadPaymentAllocation(invoice) {
    this._paymentService.GetPaymentAllocation(invoice.InvoiceId).subscribe(result => {
      invoice.payments = result.data;
    }, err => {
      this.openSnackBarError(['Unable to load payment information. Please try again.']);
    });
  }

  updateBooking() {
    if (this.isEnableUpdateButton) {
      let isMandatoryQuestionAnswerd: boolean = true;
      this.newUpsellIds = [];
      var booking = new Booking();
      booking.id = this.booking.bookingId;
      booking.bookingReference = this.booking.bookingRef;
      booking.questionSummaries = [];

      const excludedBookingTypes = [0, 1, 6, 7, 8];
      if (!excludedBookingTypes.includes(this.booking.bookingTypeId)) {
        var _regularXmlData = (environment.RegularBookingSetting as RegularBookingSettings);
        var _filteredBookingType = _regularXmlData.booking.bookingTypes.find(x => x.id == this.booking.bookingTypeId);
        if(_filteredBookingType.name != null){
          booking.BookingType= _filteredBookingType.name;
        }

      }else{
        booking.facilityName = this.facilitySummaries[0].name;
      }

      if (this.bookingItems.length > 0) {
        booking.bookingPackages = [];
        var bookingPackage = new BookingPackages();
        bookingPackage.type = undefined;
        bookingPackage.id = this.booking.bookingPackageId;
        bookingPackage.quantity = '1';
        bookingPackage.bookingItems = this.bookingItems;
        booking.bookingPackages.push(bookingPackage);
      }


      if (this.booking.packageQuestion && this.booking.packageQuestion.length > 0) {
        var addToQueue = false;
        let questionPackageSummary = new QuestionSummary();
        questionPackageSummary.questions = [];
        this.booking.packageQuestion.forEach(q => {
          if (this.validateMandatoryQuestion(q)) {
            if (q.isSaved == undefined) {
              switch (q.type) {
                case 'textQuestion':
                case 'booleanQuestion': {
                  var answerText = (q.answeredText == undefined) ? '' : q.answeredText.trim();
                  if (answerText != '') {
                    addToQueue = true;
                  }
                }
                  break;
                case 'singleChoiceQuestion': {
                  if (q.bookingAnswerChoice) {
                    addToQueue = true;
                  }
                }
                  break;
                case 'multipleChoiceQuestion': {
                  if (q.answerChoices && q.answerChoices.length > 0) {
                    let selectedAnswers = [];
                    q.answerChoices.forEach(qac => {
                      if (q.quantityRequired) {
                        if (qac.Quantity) {
                          selectedAnswers.push(qac.id);
                        }
                      } else {
                        if (qac.isSelectedAnswer) {
                          selectedAnswers.push(qac.id);
                          qac.Quantity = 1;
                        } else {
                          qac.Quantity = 0;
                        }
                      }
                      selectedAnswers.push(qac.id);
                    });
                    addToQueue = true;
                  }
                }
                  break;
                case 'fileUploadQuestion' : {
                  if(q.answerUploadedFiles){
                    addToQueue = true;
                  }
                }break
              }
              q.bookingQuestionId = q.id;
            } else {
              switch (q.type) {
                case 'textQuestion':
                case 'booleanQuestion': {
                  if (q.originalAnswer != q.answeredText) {
                    addToQueue = true;
                  }
                }
                  break;
                case 'singleChoiceQuestion': {
                  if (q.bookingAnswerChoice && q.originalAnswer != q.answeredText) {
                    addToQueue = true;
                  }
                }
                  break;
                case 'multipleChoiceQuestion': {
                  if (q.answerChoices && q.answerChoices.length > 0) {
                    let selectedAnswers = [];
                    q.answerChoices.forEach(qac => {
                      if (q.question.quantityRequired) {
                        if (qac.Quantity) {
                          selectedAnswers.push(qac.id);
                        }
                      } else {
                        if (qac.isSelectedAnswer) {
                          selectedAnswers.push(qac.id);
                          qac.Quantity = 1;
                        } else {
                          qac.Quantity = 0;
                        }
                      }

                    });
                    addToQueue = true;
                  }
                }
                  break;
                case 'fileUploadQuestion' : {
                  if(q.answerUploadedFiles){
                    addToQueue = true;
                  }
                }break
              }
            }
            if (addToQueue) {
              questionPackageSummary.questions.push(q);
            }
          } else {
            isMandatoryQuestionAnswerd = false;
          }
        });
        if (questionPackageSummary.questions.length > 0) {
          booking.questionSummaries.push(questionPackageSummary);
        }


      }


      if (!(this.booking.bookingTypeId > 1)) {
        this.booking.facilitySummaries.forEach(fs => {
          let addToQueue = false;

          fs.bookingItemSummaries.forEach(bis => {
            addToQueue = false;
            let questionItemSummary = new QuestionSummary();

            if (bis.question && bis.question.length > 0) {
              questionItemSummary.questions = [];
              bis.question.forEach(q => {
                if (this.validateMandatoryQuestion(q)) {
                  if (q.isSaved == undefined) {
                    switch (q.type) {
                      case 'textQuestion':
                      case 'booleanQuestion': {
                        var answerText = (q.answeredText == undefined) ? '' : q.answeredText.trim();
                        if (answerText != '') {
                          addToQueue = true;
                        }
                      }
                        break;
                      case 'singleChoiceQuestion': {
                        if (q.answeredText) {
                          addToQueue = true;
                        }
                      }
                        break;
                      case 'multipleChoiceQuestion': {
                        if (q.bookingQuestionChoices && q.bookingQuestionChoices.length > 0) {
                          let selectedAnswers = [];
                          q.bookingQuestionChoices.forEach(qac => {
                            if (q.question.quantityRequired) {
                              if (qac.Quantity) {
                                selectedAnswers.push(qac.id);
                              }
                            } else {
                              if (qac.isSelectedAnswer) {
                                selectedAnswers.push(qac.id);
                                qac.Quantity = 1;
                              } else {
                                qac.Quantity = 0;
                              }
                            }
                            selectedAnswers.push(qac.id);
                          });
                          //set this becase mvc api use answerchoices front end use bookingQuestionChoices
                          q.answerChoices = q.bookingQuestionChoices;
                          addToQueue = true;
                        }
                      }
                        break;
                      case 'fileUploadQuestion' : {
                        if(q.answerUploadedFiles){
                          addToQueue = true;
                        }
                      }break
                    }
                    q.bookingQuestionId = q.id;
                  } else {
                    switch (q.type) {
                      case 'textQuestion':
                      case 'booleanQuestion': {
                        if (q.originalAnswer != q.answeredText) {
                          addToQueue = true;
                        }
                      }
                        break;
                      case 'singleChoiceQuestion': {
                        if (q.bookingAnswerChoice && q.answeredText != q.bookingAnswerChoice.bookingQuestionChoice.id) {
                          addToQueue = true;
                        }
                      }
                        break;
                      case 'multipleChoiceQuestion': {
                        if (q.bookingQuestionChoices && q.bookingQuestionChoices.length > 0) {
                          let selectedAnswers = [];
                          q.bookingQuestionChoices.forEach(qac => {
                            if (q.question.quantityRequired) {
                              if (qac.Quantity) {
                                selectedAnswers.push(qac.id);
                              }
                            } else {
                              if (qac.isSelectedAnswer) {
                                selectedAnswers.push(qac.id);
                                qac.Quantity = 1;
                              } else {
                                qac.Quantity = 0;
                              }
                            }
                          });
                          q.answerChoices = q.bookingQuestionChoices;
                          addToQueue = true;
                        }
                      }
                        break;
                      case 'fileUploadQuestion' : {
                        if(q.answerUploadedFiles){
                          addToQueue = true;
                        }
                      }break
                    }
                  }
                  if (addToQueue) {
                    questionItemSummary.questions.push(q);
                  }
                } else {
                  isMandatoryQuestionAnswerd = false;
                }
              });
              if (questionItemSummary.questions.length > 0) {
                booking.questionSummaries.push(questionItemSummary);
              }


            }

            //upsell Questions
            if (bis.upsellSummaries && bis.upsellSummaries.length > 0) {
              let UpsellQuestionItemSummary = new QuestionSummary();
              UpsellQuestionItemSummary.questions = [];

              bis.upsellSummaries.forEach(upsl => {
                if (upsl.question) {
                  upsl.question.forEach(q => {
                    if (this.validateMandatoryQuestion(q)) {
                      if (q.isSaved == undefined) {
                        switch (q.type) {
                          case 'textQuestion':
                          case 'booleanQuestion': {
                            var answerText = q.answeredText == undefined ? '' : q.answeredText.trim();
                            if (answerText != '') {
                              addToQueue = true;
                            }
                          }
                            break;
                          case 'singleChoiceQuestion': {
                            if (q.answeredText) {
                              addToQueue = true;
                            }
                          }
                            break;
                          case 'multipleChoiceQuestion': {
                            if (q.bookingQuestionChoices && q.bookingQuestionChoices.length > 0) {
                              let selectedAnswers = [];
                              q.bookingQuestionChoices.forEach(qac => {
                                if (q.question.quantityRequired) {
                                  if (qac.Quantity) {
                                    selectedAnswers.push(qac.id);
                                  }
                                } else {
                                  if (qac.isSelectedAnswer) {
                                    qac.Quantity = 1;
                                  } else {
                                    qac.Quantity = 0;
                                  }
                                  selectedAnswers.push(qac.id);
                                }
                              });
                              q.answerChoices = q.bookingQuestionChoices;
                              addToQueue = true;
                            }
                          }
                            break;
                          case 'fileUploadQuestion' : {
                            if(q.answerUploadedFiles){
                              addToQueue = true;
                            }
                          }break
                        }
                        q.bookingQuestionId = q.id;
                      } else {
                        switch (q.type) {
                          case 'textQuestion':
                          case 'booleanQuestion': {
                            if (q.originalAnswer != q.answeredText) {
                              addToQueue = true;
                            }
                          }
                            break;
                          case 'singleChoiceQuestion': {
                            if (q.bookingAnswerChoice && q.answeredText != q.bookingAnswerChoice.bookingQuestionChoice.id) {
                              addToQueue = true;
                            }
                          }
                            break;
                          case 'multipleChoiceQuestion': {
                            if (q.bookingQuestionChoices && q.bookingQuestionChoices.length > 0) {
                              let selectedAnswers = [];
                              q.bookingQuestionChoices.forEach(qac => {
                                if (q.question.quantityRequired) {
                                  if (qac.Quantity) {
                                    selectedAnswers.push(qac.id);
                                  }
                                } else {
                                  if (qac.isSelectedAnswer) {
                                    qac.Quantity = 1;
                                  } else {
                                    qac.Quantity = 0;
                                  }
                                  selectedAnswers.push(qac.id);
                                }
                              });
                              q.answerChoices = q.bookingQuestionChoices;
                              addToQueue = true;
                            }
                          }
                            break;
                          case 'fileUploadQuestion' : {
                            if(q.answerUploadedFiles){
                              addToQueue = true;
                            }
                          }break
                        }
                      }

                      if (addToQueue) {
                        UpsellQuestionItemSummary.questions.push(q);
                      }
                    } else {
                      isMandatoryQuestionAnswerd = false;
                    }
                  });

                }
              });
              if (UpsellQuestionItemSummary.questions.length > 0) {
                booking.questionSummaries.push(UpsellQuestionItemSummary);
              }
            }
          });
        });
      }

      if (isMandatoryQuestionAnswerd) {

        if (this.selectedFiles && this.selectedFiles.length > 0) {
          booking.documents = this.selectedFiles;
        }

        if (this.changeRequestNotes && this.changeRequestNotes.length > 0) {
          booking.notes = this.changeRequestNotes;
        }
        this.store.dispatch(new FacilitySearchLoading());


        this.bookingService.BookingPatch(this.booking.bookingId, booking).subscribe(result => {
          if (!result.body.isError) {
            this.bookingItems = [];
            this.selectedFiles = [];
            this.changeRequestNotes = [];
            this.loadBookingData();
            this.isEnableUpdateButton = false;
            this.openSnackBarSuccess(['Booking updated successfully.']);
            if (this.enableBackButtonFunction) {
              window.open(window.location.origin + window.location.pathname + '#/bookings', '_self');
              this.enableBackButtonFunction = false;
            }
            this.CreateTask(2);
          } else {
            if (result.body.errors.length > 0) {
              var arr = [];
              result.body.errors.forEach(e => {
                arr.push(e.detail);
              });
              this.openSnackBarError(arr);
            } else {
              this.openSnackBarError(['Booking details failed, unknown error has occurred. Please refresh the page and try again.']);
            }
            this.enableBackButtonFunction = false;

          }

          this.store.dispatch(new FacilitySearchLoaded());
        }, err => {
          this.store.dispatch(new FacilitySearchLoaded());
          this.openSnackBarError(['Failed to update booking. Please try again.']);
        });
      } else {
        this.openSnackBarError(['Please fill all the mandatory questions.']);
      }
    }
  }

  //#region File upload

  dropped(event: UploadEvent) {
    this.files = event.files;
    var thispage = this;

    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.checkFileExtesion(file.name)) {
            //if (file.size < 3000000) {
            if (file.size < this.AcceptedFileSize()) {
              var myReader: FileReader = new FileReader();
              myReader.onloadend = (e) => {
                let aHD = new AdHocDocument();
                aHD.name = file.name;
                aHD.fileSize = Utility.calculateFileSize(file.size);
                let stringdata = myReader.result.toString().split(',');
                aHD.documentFile = stringdata[stringdata.length - 1];
                aHD.file = file;
                if (this.booking.bookingTypeId > 1) {
                  aHD.documentCategoryId = this.selecteddocumentTemplateCategory;
                }
                aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], '');
                this.selectedFiles.push(aHD);
                thispage.isEnableUpdateButton = true;
              };
              myReader.readAsDataURL(file);
            } else {
              thispage.openSnackBarError(['Your request could not be processed. You have attempted to upload a document larger than' + this.documentUpldSize + 'in size.']);
            }
          } else {
            thispage.openSnackBarError(['Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png']);
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  onFileChanged(event) {
    var thispage = this;
    if (this.checkFileExtesion(event.target.files[0].name)) {
      //if (event.target.files[0].size < 3000000) {
      if (event.target.files[0].size < this.AcceptedFileSize()) {
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          let aHD = new AdHocDocument();
          aHD.file = event.target.files[0];
          aHD.name = event.target.files[0].name;
          aHD.fileSize = Utility.calculateFileSize(event.target.files[0].size);
          let stringdata = myReader.result.toString().split(',');
          aHD.documentFile = stringdata[stringdata.length - 1];
          if (this.booking.bookingTypeId > 1) {
            aHD.documentCategoryId = this.selecteddocumentTemplateCategory;
          }
          aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], '');
          this.selectedFiles.push(aHD);
          thispage.isEnableUpdateButton = true;
          this.fileInputTagVariable.nativeElement.value = '';
        };
        myReader.readAsDataURL(event.target.files[0]);
      } else {
        thispage.openSnackBarError(['Your request could not be processed. You have attempted to upload a document larger than' + this.documentUpldSize + 'in size.']);
      }
    } else {
      thispage.openSnackBarError(['Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png']);
    }
  }

  AcceptedFileSize() {

    if (this.bookingData.BookingConfig.MaximumDocUploadSize) {

      var _docSizeInBytes = this.bookingData.BookingConfig.MaximumDocUploadSize * 1000000;
      return _docSizeInBytes;
    } else {
      return 2000000000;
    }
  }


  removeFile(index) {
    this.selectedFiles.splice(index, 1);
  }

  get64Stringdata(file: AdHocDocument) {
    if (file && file.file && file.file.size) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.file));
    } else {
      return null;
    }
  }

  checkFileExtesion(name: string) {
    let supportTypes = ['doc', 'docx', 'pdf', 'txt', 'xls', 'xlsx', 'jpeg', 'jpg', 'png'];
    let fileExtensionArray = name.split('.');
    let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
    return (supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
  }

  //#endregion

  loadDocumentTemplateCategories() {
    var thisComponent = this;
    const regularSetting = environment.RegularBookingSetting as RegularBookingSettings;
    if (thisComponent.booking && regularSetting) {
      let _bookingType = regularSetting.booking.bookingTypes.find(bk => bk.id == String(this.booking.bookingTypeId));
      if (_bookingType) {
        var availableDocumentCategories = (_bookingType.availableDocumentCategories) ? _bookingType.availableDocumentCategories.split(',') : [];
        var requiredDocumentCategories = (_bookingType.requiredDocumentCategories) ? _bookingType.requiredDocumentCategories.split(',') : [];
        if (availableDocumentCategories && availableDocumentCategories.length > 0) {
          thisComponent.store.dispatch(new FacilitySearchLoading());
          this._systemService.getDocumentTemplateCategories().subscribe(
            response => {
              thisComponent.documentTemplateCategories = [];
              if (response && response.length > 0) {
                response.forEach(function (rd) {
                  if (availableDocumentCategories.includes(rd.Id)) {
                    thisComponent.documentTemplateCategories.push(rd);
                  }
                  if (requiredDocumentCategories.includes(rd.Id)) {
                    thisComponent.requiredDocumentTemplateCategories.push(rd);
                  }
                });
                if (thisComponent.requiredDocumentTemplateCategories && thisComponent.requiredDocumentTemplateCategories.length > 0) {
                  this.selecteddocumentTemplateCategory = thisComponent.requiredDocumentTemplateCategories[0].Id;
                } else if (thisComponent.documentTemplateCategories && thisComponent.documentTemplateCategories.length > 0) {
                  this.selecteddocumentTemplateCategory = thisComponent.documentTemplateCategories[0].Id;
                }
              }
            },
            error => {
              console.log(error);
              this.store.dispatch(new FacilitySearchLoaded());
            },
            () => {
              this.store.dispatch(new FacilitySearchLoaded());
              console.log('documentTemplateCategories loaded');
            }
          );
        }
      }
    }
  }

  openSnackBarSuccess(message) {
    this.snackBar.openFromComponent(SuccessMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: 'top'
    });
  }

  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: 'top'
    });
  }

  preventWindowDragEvent() {
    window.addEventListener('dragover', e => {
      e && e.preventDefault();
    }, false);
    window.addEventListener('drop', e => {
      e && e.preventDefault();
    }, false);
  }

  editExtra(facilitySummary, bookingItemSummary, upsellSummary, priceConcessionId) {
    var thisPage = this;
    let dialogRef = this.dialog.open(UpsellComponent, {
      data: {
        bookingItemSummary: bookingItemSummary,
        fc: facilitySummary,
        us: upsellSummary,
        priceConcessionId: priceConcessionId,
        isEdit: true,
        isBookingEdit: true,
        isBookingDetailsEdit: true
      },
      panelClass: ['w80modal', 'dialog-container-common'],
      width: '250px'
    });

    const sub = dialogRef.componentInstance.onSubmit.subscribe((upsellSummary: any) => {
      if (upsellSummary) {

        const upsellSummarysIndex = bookingItemSummary.upsellSummaries.findIndex(fs => fs.id == upsellSummary.id);
        bookingItemSummary.upsellSummaries[upsellSummarysIndex] = upsellSummary;
        const editingUpselItemId = thisPage.bookingItems.findIndex(bookingItem => bookingItem.item.id == upsellSummary.id);

        thisPage.bookingItems[editingUpselItemId].quantity = upsellSummary.qty;

        upsellSummary.itemPrice = upsellSummary.minPrice == undefined || upsellSummary.minPrice == null ? upsellSummary.maxPrice : upsellSummary.minPrice;
        if (upsellSummary.itemPrice != undefined && !(isNaN(upsellSummary.itemPrice.concessionId))) {
          thisPage.bookingItems[editingUpselItemId].priceTypeId = upsellSummary.itemPrice.priceTypeId;

          if (bookingItemSummary.upsellSummaries[upsellSummarysIndex].bookingItemPrices === undefined) {
            bookingItemSummary.upsellSummaries[upsellSummarysIndex].bookingItemPrices = [];
            let basePriceGroup = bookingItemSummary.upsellSummaries[upsellSummarysIndex].itemPriceGroups.filter(itemPriceGroup => {
              return itemPriceGroup.priceTypeId == upsellSummary.itemPrice.priceTypeId && itemPriceGroup.concessionId == upsellSummary.itemPrice.concessionId;
            });
            let bookingItemPrice = {
              rate: basePriceGroup[0].priceIncludingTax,
              unit: upsellSummary.itemPrice.units,
              priceType: upsellSummary.itemPrice.priceType
            };
            bookingItemSummary.upsellSummaries[upsellSummarysIndex].bookingItemPrices.push(bookingItemPrice);

          }

        }
        thisPage.bookingItems[editingUpselItemId].bookingItemAssociations[0].allocatedQuantity = upsellSummary.qty;
        this.isEnableUpdateButton = true;
      }
    });
  }

  deleteUpsellItem(bookingItemSummary, upsellSummary) {
    let indexnum = bookingItemSummary.upsellSummaries.findIndex(x => x.id == upsellSummary.id);
    if (indexnum != undefined) {
      bookingItemSummary.upsellSummaries.splice(indexnum, 1);
      const editingUpselItemId = this.bookingItems.findIndex(bookingItem => bookingItem.item.id == upsellSummary.id);

      this.bookingItems.splice(editingUpselItemId, 1);
      this.totalQuestionCount -= upsellSummary.question.length;
      // Check if the upsell id exists in the newUpsellIds array
      const hasUpsellId = this.newUpsellIds.includes(upsellSummary.id);
      // Update isEnableUpdateButton based on the presence of the upsell id
      this.isEnableUpdateButton = !hasUpsellId;


      //  //   this.store.dispatch(new UpdateFacility(facilitySummary));
    }
  }


  getImagesforConfigurations() {

    let configurationWithNoImage = [];
    if (this.facilitySummaries != undefined) {
      this.facilitySummaries.forEach((c, i) => {
        if (c.configuration != undefined) {
          let configuration = c.configuration as Configuration;
          if (configuration.configurationThumbnail == undefined) {//&& configuration.type != undefined
            configurationWithNoImage.push({
              id: configuration.id,
              type: configuration.type,
              configurationCategoryId: configuration.configurationCategoryId
            });
          }
        }
      });

      if (configurationWithNoImage.length > 0) {
        let result = this._assetService.getConfigurationImages(configurationWithNoImage);
        result.subscribe(data => {
          data.forEach((c, i) => {
            let _filterF = this.facilitySummaries.filter(x => x.configuration && x.configuration.id == c.id);
            if (_filterF && _filterF.length > 0) {
              _filterF.forEach((f, j) => {
                f.configuration.configurationThumbnail = (c.isLocalUrl) ? environment.ApiUrl + c.url : c.url;
              });
            }
            let _filterBF = this.booking.facilitySummaries.filter(x => x.configuration && x.configuration.id == c.id);
            if (_filterBF && _filterBF.length > 0) {
              _filterBF.forEach((l, k) => {
                l.configuration.configurationThumbnail = (c.isLocalUrl) ? environment.ApiUrl + c.url : c.url;
              });
            }

          });
          this.configrationLoaded = true;
        });

      } else {
        this.configrationLoaded = true;
      }

    }
  }

  getConfiguration(bookingItemId) {
    var fac = this.facilitySummaries.find(summary => summary.bookingItemSummaries.some(itemSummary =>
      itemSummary.bookingItemId === bookingItemId
    ));
    if (fac != undefined) {
      if (fac.configuration.internetName != undefined && fac.configuration.internetName != '') {
        return fac.configuration.internetName;
      } else {
        return fac.configuration.name;
      }
    }
  }

  getConfigurationImg(bookingItemId) {
    var fac = this.facilitySummaries.find(summary => summary.bookingItemSummaries.some(itemSummary =>
      itemSummary.bookingItemId === bookingItemId
    ));
    if (fac != undefined) {
      if (fac.configuration.configurationThumbnail != undefined && fac.configuration.configurationThumbnail != '') {
        return fac.configuration.configurationThumbnail;
      }
    }
  }

  isUpsellEnable(facilitySummary) {

    if (facilitySummary) {
      return facilitySummary.isUpsellAvailable && !this.booking.isDisableBookingEdit && this.isUpcommingBooking && this.isAvailableUpsell(facilitySummary.endTime);
    }
  }

  //wp-modals

  openWpMessage(): void {
    const dialogRef = this.dialog.open(WpMessageComponent, {
      panelClass: ['w60modal', 'dialog-container-common'],
      width: '150px'
    });
  }


  openWpChangeRequest(): void {
    const dialogRef = this.dialog.open(WpChangeRequestComponent, {
      panelClass: ['w60modal', 'dialog-container-common'],
      width: '150px'
    });
  }

  openWpRequestAccess(): void {
    const dialogRef = this.dialog.open(WpRequestAccessComponent, {
      panelClass: ['w60modal', 'dialog-container-common'],
      width: '150px'
    });
  }

  openWpAlreadyAccess(): void {
    const dialogRef = this.dialog.open(WpAlreadyAccessComponent, {
      panelClass: ['w60modal', 'dialog-container-common'],
      width: '150px'
    });
  }


  openRollOverBooking(booking) {
    if (booking) {
      const dialogRef = this.dialog.open(WpRolloverBookingModalComponent, {
        data: booking,
        panelClass: ['w90modal', 'dialog-container-common', 'opt-regular-booking'],
        width: '100px'
      });
    }
  }

  CheckSeasonAvailability() {
    if (this.availableBookingTypes) {
      if (this.availableBookingTypes.length > 0) {
        var isBookingTypeAvailable = this.availableBookingTypes.find(btype => Number(btype.id) == this.booking.bookingTypeId);
        if (isBookingTypeAvailable) {
          this.bookingTypeAvailable = true;
        } else {
          this.bookingTypeAvailable = false;
        }
      }
    }
  }

  trackByFn(index, item) {
    return index;
  }

  getChangeRequestNotes(notes) {

    this.changeRequestNotes = notes;

    if (this.changeRequestNotes.length > 0) {
      this.isBookingChangeRequest = false;
      this.isEnableUpdateButton = true;
    }
  }

  openExtraAllocation(facilitySummary, upsellSummary, priceConcessionId) {
    let thisPage = this;
    let dialogRef = this.dialog.open(ExtraAllocationComponent, {
      data: {fc: facilitySummary, upsell: upsellSummary, priceConcessionId: priceConcessionId},
      panelClass: ['custom-dialog-container', 'w80modal', 'modal-width', 'extrapop'],
      height: 'auto',
      width: '80%'
    });

    dialogRef.componentInstance.onSubmit.subscribe((addedUpsells: any) => {
      if (addedUpsells) {
        addedUpsells.forEach(function (addedUpsell) {
          let bookingItemSummary = facilitySummary.bookingItemSummaries[addedUpsell.index];
          bookingItemSummary.isExpandedUpsell = true;
          let upselAdded = [];
          if (bookingItemSummary.upsellSummaries == undefined) {
            bookingItemSummary.upsellSummaries = [];
          }
          if (bookingItemSummary.upsellSummaries != undefined && bookingItemSummary.upsellSummaries.length > 0) {
            upselAdded = bookingItemSummary.upsellSummaries.filter(upsell => upsell.id == addedUpsell.id);
          }
          console.log('upselAdded ', upselAdded);
          if (upselAdded == undefined || upselAdded.length == 0) {
            bookingItemSummary.upsellSummaries.push(addedUpsell);

            // populate BookingItems
            var bookingUpsellItem = new BookingItems();
            bookingUpsellItem.type = FacilityAdaptor.getItemPrefix(addedUpsell.type.toLowerCase()) + addedUpsell.type;
            bookingUpsellItem.quantity = addedUpsell.qty;
            bookingUpsellItem.item.id = addedUpsell.id;
            bookingUpsellItem.item.type = addedUpsell.type;
            bookingUpsellItem.startTime = bookingItemSummary.startTime;
            bookingUpsellItem.endTime = bookingItemSummary.endTime;
            bookingUpsellItem.upsell = true;
            bookingUpsellItem.name = addedUpsell.name;

            // populate suitable package
            addedUpsell.itemPrice = (addedUpsell.minPrice == undefined || addedUpsell.minPrice == null) ? addedUpsell.maxPrice : addedUpsell.minPrice;

            if (addedUpsell.itemPrice !== undefined && !(isNaN(addedUpsell.itemPrice.concessionId))) {
              bookingUpsellItem.priceTypeId = addedUpsell.itemPrice.priceTypeId;
            }

            bookingUpsellItem.id = (((+(bookingItemSummary.bookingItemId.replace(/\D/g, ''))) * (+(addedUpsell.id))) * (-1)).toString();
            bookingUpsellItem.bookingItemAssociations = [];

            let bookingItemAssociation = new BookingItemAssociation();
            bookingItemAssociation.type = undefined;
            bookingItemAssociation.id = (((+(bookingItemSummary.bookingItemId.replace(/\D/g, ''))) * (+(addedUpsell.id))) * (-1)).toString();
            bookingItemAssociation.allocatedQuantity = addedUpsell.qty;
            bookingItemAssociation.startTime = Utility.convertToISO(Utility.convertISOToDate(addedUpsell.startTime));
            bookingItemAssociation.endTime = Utility.convertToISO(Utility.convertISOToDate(addedUpsell.endTime));
            bookingItemAssociation.asset.id = facilitySummary.facilityId;
            bookingItemAssociation.asset.type = undefined;
            bookingItemAssociation.primaryBookingSpaceItem = {id: bookingItemSummary.bookingItemId};
            bookingUpsellItem.bookingItemAssociations.push(bookingItemAssociation);
            thisPage.bookingItems.push(bookingUpsellItem);
          }
        });
        thisPage.isEnableUpdateButton = true;
      }
    });

  }

  validateMandatoryQuestion(questions): boolean {
    let allMandatoryAnswered = true;
    if (questions.mandatory && questions.isHidden != true && (!FacilityAdaptor.isQuestionAnswerd(questions))) {
      allMandatoryAnswered = false;
    }
    return allMandatoryAnswered;
  }


  deletionExtraAllocation(facilitySummary, bookingItemSummary, upsellSummary) {
    let thisPage = this;
    let dialogRef = this.dialog.open(ExtraDeletionComponent, {
      data: {fc: facilitySummary, upsell: upsellSummary},
      panelClass: ['custom-dialog-container', 'w80modal', 'modal-width', 'extrapop'],
      height: 'auto',
      width: '80%'
    });

    dialogRef.componentInstance.onSubmit.subscribe((deleteAll: any) => {
      if (deleteAll) {
        facilitySummary.bookingItemSummaries.forEach(function (bookingItem) {
          if (bookingItem.upsellSummaries && bookingItem.upsellSummaries.length > 0) {
            let UpsellIndex = bookingItem.upsellSummaries.findIndex(u => u.id == upsellSummary.id && u.bookingItemId == undefined);
            if (UpsellIndex >= 0) {
              bookingItem.upsellSummaries.splice(UpsellIndex, 1);
            }

            let OriginaUpsellIndex = thisPage.bookingItems.findIndex(u => u.id == (((+(bookingItemSummary.bookingItemId)) * (+(upsellSummary.id))) * (-1)).toString());
            if (OriginaUpsellIndex >= 0) {
              thisPage.bookingItems.splice(OriginaUpsellIndex, 1);
            }
          }
        });
      } else {
        let seletedUpsellIndex = bookingItemSummary.upsellSummaries.findIndex(u => u.id == upsellSummary.id);
        if (seletedUpsellIndex >= 0) {
          bookingItemSummary.upsellSummaries.splice(seletedUpsellIndex, 1);
        }

        let seletedOriginaUpsellIndex = thisPage.bookingItems.findIndex(u => u.id == (((+(bookingItemSummary.bookingItemId)) * (+(upsellSummary.id))) * (-1)).toString());
        if (seletedOriginaUpsellIndex >= 0) {
          thisPage.bookingItems.splice(seletedOriginaUpsellIndex, 1);
        }
      }
    });
  }

  AcceptCondition(event) {

    if (event.checked) {

      this.isConditionsAccepted = true;
    } else {
      this.isConditionsAccepted = false;
    }
  }

  ApproveBooking() {
    var _updateBooking = new Booking();
    _updateBooking.bookingStatus = new BookingStatus();
    _updateBooking.id = this.booking.bookingId;
    _updateBooking.bookingReference = this.booking.bookingRef;
    _updateBooking.bookingStatus.id = String(this.bookingData.BookingConfig.ClientBookingApprovedStatus);


    this.bookingService.BookingPatch(this.booking.bookingId, _updateBooking, true, this.clientEmail).subscribe(result => {
      if (!result.body.isError) {
        this.bookingItems = [];
        this.selectedFiles = [];
        this.changeRequestNotes = [];
        this.loadBookingData();
        this.isEnableUpdateButton = false;
        this.openSnackBarSuccess(['Booking approved successfully.']);
        this.CreateTask(3);
      } else {
        if (result.body.errors.length > 0) {
          var arr = [];
          result.body.errors.forEach(e => {
            arr.push(e.detail);
          });
          this.openSnackBarError(arr);
        } else {
          this.openSnackBarError(['Booking details failed, unknown error has occurred. Please refresh the page and try again.']);
        }
      }
    }, err => {
      this.openSnackBarError(['Failed to approve booking. Please try again.']);
    });
  }

  RedirectToPaymentPortal(invoice) {

    let invoiceRef = '';
    var url = this.PaymentPortalUrl;

    if (this.isSecureInvoiceEnabled) {
      if (environment.SystemOption.invoiceRefCutOffDate < new Date()) {
        if (invoice.InvoiceRefSecure != '' && invoice.InvoiceRefSecure != null) {
          invoiceRef = invoice.InvoiceRefSecure;
        } else {
          invoiceRef = invoice.InvoiceRef
        }
      } else {
          invoiceRef = invoice.InvoiceRef
      }
    } else {
      invoiceRef = invoice.InvoiceRef;
    }
    var url = this.PaymentPortalUrl;
    window.open(url + 'payment?InvoiceRef=' + invoiceRef + '&fromMyBooking=\'1\'', '_self');

  }

  GetPaymentAllocation(InvoiceId) {

    if (InvoiceId) {
      if (this.invoices) {
        this.invoices.forEach((inv, i) => {
          if (inv.InvoiceId == InvoiceId && inv.paymentLoadStatus == 1) {
            inv.paymentLoadStatus = 2;
            this._paymentService.GetPaymentAllocation(InvoiceId).subscribe(result => {
              inv.payment = result.data;
              this.isHavePayments = (result.data.length != 0);
              inv.payment.forEach((p, i) => {
                if (p.source) {
                  p.source.type = this.getPaymentMethod(p.source.type);
                }
              });
              inv.paymentLoadStatus = 3;


            }, err => {
              this.openSnackBarError(['Unable to load payment information. Please refresh the page and try again.']);
              this.store.dispatch(new FacilitySearchLoaded());
              inv.paymentLoadStatus = 1;

            });

          }
        });
      }


    }

  }


  dateChangedEnd() {
    if (this.dateEnd != null || this.dateEnd != undefined) {
      var dtEnd = this.dateEnd.toDate();
      this._filter.eventDateTo = dtEnd.toDateString();
      this.maxStartDate = moment(dtEnd);
    }
    // this.selectedBooking = this.booking.facilitySummaries.filter(sb => Utility.convertISOToDate(new Date(moment(sb.endTime.toString(), "YYYY-MM-DD").toDate())) <= dtEnd)
  }

  dateChangedSatrt() {
    if (this.dateStart != null || this.dateStart != undefined) {
      var dtStart = this.dateStart.toDate();
      this._filter.eventDateFrom = dtStart.toDateString();
      this.minEndDate = moment(dtStart);
    }
    // this.selectedBooking = this.booking.facilitySummaries.filter(sb => Utility.convertISOToDate(new Date(moment(sb.startTime.toString(), "YYYY-MM-DD").toDate())) >= dtStart)

  }

  ExportBooking() {
    if (this.booking.bookingId) {
      this.store.dispatch(new SpinnerLoading());

      this.bookingService.BooingExport(this.bookingId, this._filter, this.selectedVenuesInBooking).safeSubscribe(this, result => {

        if (result != undefined && result != '') {
          //  console.log("result after booking EXPORT=>> ", result);
          var showSuccessMessage = false;

          var message = '';
          this.store.dispatch(new SpinnerLoaded());
          result = this.apiUrl + encodeURIComponent(result.trim());
          window.open(result);
          showSuccessMessage = true;
          message = 'Booking has been exported';
          // this.scrollPageTop();
          setTimeout(() => {
            showSuccessMessage = false;
            message = '';
          }, 5000);

        } else {

          this.store.dispatch(new SpinnerLoaded());

          var showErrorMessage = true;
          // this.showErrorMessage = true;
          message = 'Something went wrong, please try again';
          // this.scrollPageTop();
          setTimeout(() => {
            showErrorMessage = false;
            message = '';
          }, 3000);

        }

      });


    }
  }


  ApplyFilter() {
    // this.selectedBooking = this.booking.facilitySummaries.filter(sb => (Utility.convertISOToDate(new Date(moment(sb.endTime.toString(), "YYYY-MM-DD").toDate())) <= this.dateEnd.toDate() ) && (Utility.convertISOToDate(new Date(moment(sb.startTime.toString(), "YYYY-MM-DD").toDate())) >= this.dateStart.toDate()))

    // if(this.selectedVenuesInBooking && this.selectedBooking)
    // {
    //   this.selectedBooking = this.selectedBooking.filter(sb => this.selectedVenuesInBooking.includes(sb.id));
    // }
    // else{
    //   this.selectedBooking = this.booking.facilitySummaries.filter(sb => this.selectedVenuesInBooking.includes(sb.id));
    // }
    this.filter.closed.emit();

    this.loadBookingData(false);
  }

  clear() {
    this.selectedVenuesInBooking = [];
    this.selectedVenuesInBooking.push('0'); // set default as All

    this.dateStart = null;
    this.dateEnd = null;

    this._filter = new BookingFilter(); // clear filters

    //set the dates
    this.minEndDate = this.minStartDate; // startDate picker has original min date value
    this.maxStartDate = this.maxEndDate; // End Picker has the original max date value
  }

  //dropdown selected
  optionClicked(id?: string) {
    if (this.selectedVenuesInBooking.length > 0) {
      if (id != '0') {
        this.selectedVenuesInBooking = this.selectedVenuesInBooking.filter(v => v != 0);
      }
      // id = 0 means all checkbox
      else {
        if (this.isAllchecked) { // if all box checed unselect all
          this.isAllchecked = false;
          this.selectedVenuesInBooking = [];
        } else { //else select all
          this.isAllchecked = true;
          this.selectedVenuesInBooking = [];
          this.selectedVenuesInBooking = this.venues.map(x => x.venueId);
        }
      }

    }
  }

}
