import { jsonIgnore } from 'json-ignore';
import { BaseResource } from 'src/modules/models/base-resource'
import { ConditionalQuestionAnswer } from '../regular-portal/booking/question/booking-question';

export class Question extends BaseResource {
    allowUndecided?: boolean;
    mandatory: boolean;
    quantityRequired: boolean;
    questionText: string;
    questionType: number;
    answerChoices: AnswerChoices[];
    //answer properties..
    answered?: boolean; //answered or not
    answeredText?: string; //answer
    answerTxtBox?: boolean; //istext answer
    answerBool?: boolean; //isBoolen answer
    answerCheck?: boolean; //ischeck box answer
    bookingQuestionId: string;
    originalAnswer: string;
    isSaved: boolean;
    bookingStatusId: string;
    bookingStatus: string;
    isBookingQuestion: boolean;
    bookingQuestionChoices: any[];
    bookingAnswerChoice: any[];


    primaryQuestionId: string;
    condition:string;
    conditionOperator: string;
    isHidden: boolean = false;
    isAnswered: boolean = false;
    //file upload question
    maxFileSize : number;
    documentFile :string;
    fileType : number;
    supportedFileType : string;
    answerUploadedFiles : AnswerUploadedFiles[];

}

export class AnswerChoices extends BaseResource {
    id: string;
    name: string;
    isSelectedAnswer: boolean;
    Quantity: string;
}
export class AnswerUploadedFiles extends BaseResource{
  id : string;
  answerId : number;
  name : string;
  fileSize : string;
  fileType : string;
  url : string;
  isDelete : boolean;
  documentFileURL : string;
  documentFile : string;
  @jsonIgnore() file: File;
}
