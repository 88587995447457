import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Deserializer, Serializer } from 'json-api-format';

import { BaseService } from '../services/base/base.service';

import { map, catchError, switchMap } from "rxjs/operators";

import { HttpResponse, HttpResponseBase, HttpEvent } from '@angular/common/http';

//Models
import { Invoice } from '../models/payment/Invoice';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

    constructor(private _baseService: BaseService) {

    }
  GetInvoiceDetails(Getdata: string): any {
    return this._baseService.InternalWebAPIGET("api/booking/invokeGet?id=117&" + "Getdata=" + Getdata).pipe(
     map((response: HttpResponse<any>) => {
       return response;

     })
    );
    
    }

    GetPaymentAllocation(invoiceId: string): any {
        return this._baseService.InternalWebAPIGET("api/Payment/allocations?invoiceid=" + invoiceId).pipe(
            map((response) => {
                return response;
            })
        );
    }
}