import {ConditionOperator} from 'src/modules/models/enum';
import {Question} from 'src/modules/models/item/question';
import {
  BookingQuestion,
  BooleanQuestionAnswer,
  FileUploadQuestionAnswer,
  MultipleChoiceQuestionAnswer, SingleChoiceQuestionAnswer, TextQuestionAnswer
} from '../regular-portal/booking/question/booking-question';
export class QuestionUtility {


  static checkForConditionalQuestion(questions: Question[], question: Question, selectedChoice: any)
  {
    //let _condQArray = questions.filter(q => q.primaryQuestionId === questionId);
      let _primQ = questions.find(q => q.id === question.id);

    if (!_primQ) {
      return;
    }

    let isAnyCondQMatched = false;

    //if (Array.isArray(_condQArray) && _condQArray.length > 0)
    {
          questions.forEach(_condQ => {

              if ((_condQ?.primaryQuestionId ?? "" ) == question.id) {
                  _condQ.isHidden = false;
                  if ( !(!_condQ || !_condQ.conditionOperator)) {
                    let conditionArray = _condQ.condition.split(',');
                    conditionArray = conditionArray.map(element => element.toLowerCase());
                      let selectedValues = Array.isArray(selectedChoice) ? selectedChoice : [selectedChoice];

                      switch (_condQ.conditionOperator.toString()) {
                          case ConditionOperator.ANY_OPERATOR:
                              if (conditionArray.some(cond => selectedValues.includes(cond))

                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          case ConditionOperator.EQUAL_OPERATOR:
                              if (
                                  conditionArray.every(cond => selectedValues.includes(cond))
                                  && conditionArray.length == selectedValues.length
                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          case ConditionOperator.GREATERTHAN_OPERATOR:
                              if (
                                  conditionArray.some(cond => selectedValues.includes(cond))

                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          case ConditionOperator.LESSTHAN_OPERATOR:
                              if (
                                  conditionArray.some(cond => selectedValues.includes(cond))

                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          case ConditionOperator.ALL_OPERATOR:
                              if (
                                  conditionArray.every(cond => selectedValues.includes(cond))

                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          default:
                              break;
                      }
                  }
              }
      });
    }

    // If none of the _condQ objects match, call setHiddenFalseForChildQuestions
    if (!isAnyCondQMatched) {
        QuestionUtility.setHiddenFalseForChildQuestions(questions, question.id);
    }
  }


  static setHiddenFalseForChildQuestions(questions: Question[], parentQuestionId: string, _freshLoad: boolean = false,visitedQuestionIds: Set<string> = new Set()) {
    if (!questions || !parentQuestionId) {
      return;
    }
   // const childQuestions = questions.filter(questions => questions.primaryQuestionId === parentQuestionId);
    //console.log(childQuestions);
    if (visitedQuestionIds.has(parentQuestionId)) {
      return;
    }
    visitedQuestionIds.add(parentQuestionId);
    if (questions.length > 0) {
        questions.forEach(child => {
            if ((child?.primaryQuestionId ?? "") === parentQuestionId){
                child.isHidden = true;
                if (!_freshLoad) {
                    child.answeredText = undefined;
                    if(child.answerUploadedFiles){
                      child.answerUploadedFiles.forEach(file => {
                        file.isDelete = true;
                      });
                    }
                }
                // this.updateVisibleQuestionsCount(questions);
                QuestionUtility.setHiddenFalseForChildQuestions(questions, child.id, _freshLoad,visitedQuestionIds);
            }
            else {
              return;
            }


      });
    }
  }





  static isAnswerd(question: BookingQuestion[], q ) {
    var isQuestionAnswerd = false;
    if(q.type == 'singleChoiceQuestionAnswer'){
      if(q.bookingAnswerChoice != undefined){
        QuestionUtility.checkForConditionalQuestionRegularPortal(question, event, q.id, q.bookingAnswerChoice.bookingQuestionChoice.id);
      }
    }
    if (q.type == 'booleanQuestionAnswer') {
      if (q.answer != undefined) {
        if(q.answer){
          QuestionUtility.checkForConditionalQuestionRegularPortal(question, event, q.id, 'true');
        }
        if(!q.answer){
          QuestionUtility.checkForConditionalQuestionRegularPortal(question, event, q.id, 'false');
        }
      }
    }
    if (q.type == 'textQuestionAnswer') {
      if(q.answer != undefined && q.answer != null){} {}
      QuestionUtility.checkForConditionalQuestionRegularPortal(question, event, q.id, q.answer);
    }
    if (q.type == 'multipleChoiceQuestionAnswer') {
      var selectedAnswerIds = [];
      if (q.bookingAnswerChoice) {
        q.bookingAnswerChoice.forEach((answ, indx) => {
          if (q.question.quantityRequired && q.question.quantity) {
            selectedAnswerIds.push(answ.bookingQuestionChoice.id);
          }
          else {
            selectedAnswerIds.push(answ.bookingQuestionChoice.id);
          }
        });
      }

      if (selectedAnswerIds) {
        isQuestionAnswerd = true;
        QuestionUtility.checkForConditionalQuestionRegularPortal(question, event, q.id, selectedAnswerIds);
      }
    }if (q.type == 'fileUploadQuestionAnswer') {
      if (q.answerUploadedFiles) {
        let minCount: number = q.question?.minCount
        let maxCount: number = q.question?.maxCount
        // Determine minCount and maxCount from question or fallback to q object


        // Filter out files marked for deletion
        const validFiles = q.answerUploadedFiles.filter(file => file.isDelete === undefined || file.isDelete !== true);

        if (validFiles.length > 0) {
          const count = validFiles.length;

          if ((minCount && count < minCount) || (maxCount && count > maxCount)){
            isQuestionAnswerd = false;
          }else {
            isQuestionAnswerd = true;
          }

        } else {
          // Handle case where no valid files are uploaded
          /*this.snackBar.openFromComponent(AlertMessageComponent, {
            duration: 3000,
            verticalPosition: 'top',
            data: ['You need to upload at least one valid document.'],
          });*/
          isQuestionAnswerd = false;
        }
      }
    }
    return isQuestionAnswerd;

  }


  static checkForConditionalQuestionRegularPortal(questions: BookingQuestion[], event: any, questionId: string, selectedChoice: any) {
    let _condQArray = questions.filter(q => q.primaryQuestionId && q.primaryQuestionId === questionId);
    let _primQ = questions.find(q => q.id === questionId);

    if (!_primQ) {
      return;
    }

    let isAnyCondQMatched = false;

    if (Array.isArray(_condQArray) && _condQArray.length > 0) {
      _condQArray.forEach(_condQ => {
        if (!_condQ || !_condQ.conditionOperator) {
          return;
        }

        let conditionArray = _condQ.condition.split(',');
        let selectedValues = Array.isArray(selectedChoice) ? selectedChoice : [selectedChoice];

        switch (_condQ.conditionOperator.toString()) {
          case ConditionOperator.ANY_OPERATOR:
            if (conditionArray.some(cond => selectedValues.includes(cond))

            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          case ConditionOperator.EQUAL_OPERATOR:
            if (
              conditionArray.every(cond => selectedValues.includes(cond))
              && conditionArray.length == selectedValues.length
            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          case ConditionOperator.GREATERTHAN_OPERATOR:
            if (
              conditionArray.some(cond => selectedValues.includes(cond))

            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          case ConditionOperator.LESSTHAN_OPERATOR:
            if (
              conditionArray.some(cond => selectedValues.includes(cond))

            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          case ConditionOperator.ALL_OPERATOR:
            if (
              conditionArray.every(cond => selectedValues.includes(cond))

            ) {
              _condQ.isHidden = false;
              isAnyCondQMatched = true;
            } else {
              _condQ.isHidden = true;
            }
            break;
          default:
            break;
        }
      });
    }

    // If none of the _condQ objects match, call setHiddenFalseForChildQuestions
    if (!isAnyCondQMatched) {
      QuestionUtility.setHiddenFalseForChildQuestionsRegularPortal(questions, questionId);
    }
  }


  static setHiddenFalseForChildQuestionsRegularPortal(questions: BookingQuestion[], parentQuestionId: string, _freshLoad: boolean = false) {
    if (!questions || !parentQuestionId) {
      return;
    }
    const childQuestions = questions.filter(questions => questions.primaryQuestionId === parentQuestionId);
    if (childQuestions.length > 0) {
      childQuestions.forEach(child => {
        child.isHidden = true;
        if (!_freshLoad) {
          if (child.type == "fileUploadQuestionAnswer") {
            const fileUploadAnswer = child as FileUploadQuestionAnswer;
            fileUploadAnswer.isHidden = true;
          }
          if (child.type == "multipleChoiceQuestionAnswer") {
            const multipleChoiceAnswer = child as MultipleChoiceQuestionAnswer;
            multipleChoiceAnswer.bookingAnswerChoice = undefined;
          }
          if (child.type == "booleanQuestionAnswer") {
            const booleanAnswer = child as BooleanQuestionAnswer;
            booleanAnswer.answer = undefined;
          }
          if (child.type == "TextQuestionAnswer") {
            const textAnswer = child as TextQuestionAnswer;
            textAnswer.answer = undefined;
          }
          if (child.type == "singleChoiceQuestionAnswer") {
            const singleChoiceAnswer = child as SingleChoiceQuestionAnswer;
            singleChoiceAnswer.bookingAnswerChoice = undefined;
          }
        }
        QuestionUtility.setHiddenFalseForChildQuestionsRegularPortal(questions, child.id, _freshLoad);
      });
    }
  }


}
