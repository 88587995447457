<div class="container d-flex align-items-center justify-content-center">
    <div class="opt-callout-box" [class]="status">
        <div class="opt-callout-box-header">
            <span class="opt-info-icon" *ngIf="status === 'opt-callout-warning'">
                <i class="fa fa-info" aria-hidden="true"></i>
            </span>
            <i class="icon-verified" *ngIf="status === 'opt-callout-success'"></i>
            <h1>{{mainHeader}}</h1>
        </div>
        <div class="opt-callout-box-bottom">
            <h1 *ngIf="secondaryHeader?.length > 0">{{secondaryHeader}}</h1>
            <p>{{bodyText}}</p>
        </div>
    </div>
</div>
