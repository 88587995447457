<main role="main">
  <div class="past-booking-details">
    <opt-sub-header></opt-sub-header>

    <div class="past-booking-details-container container">
      <div class="mmc_flex--container primary-header-row secondary-header-row button-wrapper">
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter">
          <div class="pw-txt-h4 pw-text-semi-bold title title-primary pw-text-color__quinary pr-2">
            <span class="pr-2 txt-color-same" *ngIf="isBookingLoaded && isUpcommingBooking">Upcoming</span>
            <span class="pr-2" *ngIf="isBookingLoaded && !isUpcommingBooking">Past</span>
            <span class="pw-text-semi-bold title-secondary pw-text-color__primary pr-2">bookings ></span>
            <span class="pw-text-semi-bold title-secondary pw-text-color__primary pw-text-color__tertiary"
              *ngIf="booking && booking?.bookingRef">{{booking?.bookingRef}}</span>
          </div>
        </div>
      </div>
      <div class="mmc_flex--container secondary-header-row button-wrapper">
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter">
          <button mat-button class="btn__card secondary icon-button mmc-btn-ternary--small" tabindex="0"
            (click)="backButton()" (keydown.enter)="backButton()" role="button"> <span
              class="material-icons btn-icon">arrow_back</span><span class="back">Back</span></button>
        </div>


        <div class="mmc_flex__item mmc_just-content-end">
          <button mat-button class="mmc-btn-xl--request_changes " tabindex="0" (click)="changeRequest()"
            (keydown.enter)="changeRequest()" role="button">
            <div class="mmc_flex--container">
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                <span class="icon-p2-card icon f-size-md"></span>
              </div>
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                Request changes
              </div>
            </div>
          </button>
          <div
            *ngIf="booking?.bookingTypeId != undefined && booking?.bookingTypeId != 1  && booking?.bookingTypeId != 0 && bookingTypeAvailable && booking?.isDisableRolloverBooking == false">
            <button mat-button class="mmc-btn-xl--rollover_booking" tabindex="0"
              (click)="openRollOverBooking(booking)" (keydown.enter)="openRollOverBooking(booking)">
              <div class="mmc_flex--container">
                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                  <span class="icon-p2-card icon f-size-md"></span>
                </div>
                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                  Rollover booking
                </div>
              </div>
            </button>

          </div>


          <button mat-button class="mmc-btn-xl--primary mmc-btn-xl--secondary "
            *ngIf="booking?.paidAmount<booking?.invoicedAmount && !booking?.cancelled && PaymentPortalUrl"
            (click)="focusTo('targetBtn')" (keydown.enter)="focusTo('targetBtn')">
            <div class="mmc_flex--container">
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                <span class="icon-p2-card icon f-size-md"></span>
              </div>
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                Make payment
              </div>
            </div>
          </button>

          <button mat-button class="mmc-btn-xl--secondary " tabindex="0"
            (click)="focusTo('targetFileBtn')" (keydown.enter)="focusTo('targetFileBtn')"
            *ngIf="!booking?.isDisableBookingEdit && isUpcommingBooking">
            <div class="mmc_flex--container">
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                <span class="icon-p2-file-1 icon f-size-md"></span>
              </div>
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                Upload file
              </div>
            </div>
          </button>

          <button mat-button class="mmc-btn-xl--ternary " tabindex="0" (click)="cancelBooking(booking)"
            (keydown.enter)="cancelBooking(booking)" *ngIf="!booking?.isHideRequestBookingCancelBtn">
            <div class="mmc_flex--container">
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                <span class="icon-p2-delete icon f-size-md"></span>
              </div>
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                Request cancellation
              </div>
            </div>
          </button>
          <!--<button mat-button class="mmc-btn-xl--ternary item-w25-mobile" (click)="openRollOverBooking(booking)" *ngIf="isCancelledBooking && isUpcommingBooking ">
                    <div class="mmc_flex--container">
                        <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                            <span class="icon-p2-delete icon f-size-md"></span>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                           Rollover Booking
                        </div>
                    </div>
                </button>-->

          <button mat-button class="mmc-btn-xl--ternary "
            [ngClass]="{'disable-div' : !isEnableUpdateButton}" tabindex="0" (click)="updateBooking()"
            (keydown.enter)="updateBooking()"
            *ngIf="((!booking?.isDisableBookingEdit) ||!booking?.isDisableBookingDocumentUpload) && isUpcommingBooking">
            <div class="mmc_flex--container">
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                <span class="icon-p2-update icon f-size-md"></span>
              </div>
              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                Update booking
              </div>
            </div>
          </button>
        </div>
      </div>

        <div class="col-md-12 col-md-offset-3" *ngIf="changeRequestNotes.length > 0 || selectedFiles.length > 0 || ((!booking?.isDisableBookingEdit) && isUpcommingBooking && isEnableUpdateButton)">
            <div class="col-md-12 alert success--btn pw-text-medium" ><!--*ngIf="isUpcommingBooking"> -->
                Please click<span class="pw-text-semi-bold px-1">&#34;Update booking&#34;</span>to save your booking changes.
            </div>
        </div>



      <div class="inner-container" *ngIf="booking">
        <div class="wp-modal-wrapper wp-message-modal" *ngIf="isBookingChangeRequest">
          <opt-booking-change-request [contactName]="contactName" (notes)="getChangeRequestNotes($event)">
          </opt-booking-change-request>
        </div>

        <div class="row changes-summery-wrapper my-3 booking-details-review-section" hidden>
          <div class="col-sm-12">
            <div class="mmc_flex--container">
              <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter">
                <div class="pw-txt-h5 pw-text-color__primary mr-3">9 Booking changed to be reviewed</div>
                <div class="btn-wrapper"><button class="mmc-btn-tertiary" mat-button>View All</button></div>
              </div>
            </div>
          </div>
        </div>

        <div class="past-booking-details--card " *ngIf="isClientApprovalPending">
          <div class="mmc_flex--container b-terms-n-conditions">
            <div class="booking-details-wrapper booking-detail-check pb-4">
              <section class="example-section">
                <span class="wp-txt-body1" [innerHtml]="bookingTermsAndConditions"></span><span
                  class="wp-txt-body1 wp-txt--danger">*</span>
                <!--<mat-checkbox class="example-margin" (change)="AcceptCondition($event)"><span class="wp-txt-body1" [innerHtml]="bookingTermsAndConditions"></span><span class="wp-txt-body1 wp-txt--danger">*</span></mat-checkbox>-->
              </section>

            </div>



            <div class="mmc_flex--container mmc_flex__item mmc_just-content-end mb-3 booking-detail-btn-wrapper">
              <div class="mmc_flex__item--vcenter mmc_flex__item--hcenter">
                <button class="btn__card secondary icon-button mmc-btn-ternary--small booking-details-button"
                  mat-icon-button color="primary" tabindex="0" (click)="ApproveBooking()"
                  (keydown.enter)="ApproveBooking()">
                  <!--<button class="btn__card secondary icon-button mmc-btn-ternary--small booking-details-button" mat-icon-button color="primary" (click)="ApproveBooking()" [disabled]="!isConditionsAccepted">-->
                  ACCEPT
                </button>
              </div>
            </div>

          </div>
        </div>

        <div class="past-booking-details--card ">
          <div class="mmc_flex--container card-wrapper">
            <div class="mmc_flex__item mmc_flex__item--vcenter">
              <div class="mmc_flex--container mmc_fd--col">
                <h5 class="mmc_flex__item mmc_flex__item--vcenter pw-txt-h5 pw-text-color__primary">
                  {{booking?.bookingRef}}</h5>
                <h5 class="mmc_flex__item mmc_flex__item--vcenter pw-txt-h5 pw-text-color__primary">
                  {{booking?.eventName}}</h5>
                <div class="mmc_flex__item mmc_flex__item--vcenter pw-text-color__tertiary"><span
                    class="pw-txt-s1">{{booking?.package?.packageClass}}</span><span class="seperator">|</span><span
                    class="pw-txt-s1">{{booking.eventStartDate | dateFormat}}</span></div>
              </div>
            </div>

            <div class="mmc_flex__item mmc_flex__item--vcenter">
              <div class="mmc_flex--container mmc_fd--col ">
                <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end status-wrapper">
                  <div class="expansion-header-icons" *ngIf="totalQuestionCount!=0"
                    matTooltip="number of total questions" tabindex="0" (click)="openQuestions()"
                    (keydown.enter)="openQuestions()">
                    <span class="icon-p2-questions"></span>
                    <span class="count">{{totalQuestionCount}}</span>
                  </div>
                  <span class="card-status"
                    [ngClass]="true ? booking.bookingStateCss: ''">{{booking.bookingState}}</span>
                </div>
                <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end total-field"><span
                    class="icon-p2-checked pr5"></span><span
                    class="pw-txt-s1 pw-text-trans__upper pr5">Total</span><span class="pw-txt-h5">{{booking.grossAmount
                    | CurrencyFormat}}</span></div>
              </div>
            </div>
          </div>
        </div>

        <div class="mmc_flex--container" *ngIf="isUpcommingBooking">
          <div class="mmc_flex__item--vcenter mmc_flex__item--hcenter">
            <span class="note-message">
              {{messages.BookingDetailsPage}}
            </span>
          </div>
        </div>

        <div class="mmc_flex--container mmc_flex__item mmc_just-content-end mb-3">
          <!-- Export Booking details -->
          <div class="d-flex align-items-center">
            <i class="material-icons wp-mr-10 mat-btn-icon--secondary export-icon" (click)="ExportBooking()"
              (keydown.enter)="ExportBooking()" matTooltip="Export details">unarchive</i>
          </div>

          <!-- filter -->

          <div class="mmc_flex--container search-filter-btn" mat-button [matMenuTriggerFor]="menufilter" tabindex="0">
            <button class="mmc_flex__item" matTooltip="Filter">
              <i class="mr-0 mmc icon-filter-filled-tool-symbol icn-aminity pw-ico-color__quinary"></i>
            </button>
            <!-- <div class="mmc_flex__item pw-text-trans__upper pw-text-color__quinary pw-txt-b2_small">FILTER</div> -->
          </div>

          <mat-menu #menufilter="matMenu" class="filter--menu" tabindex="0">
            <div class="mmc_flex--container" (click)="$event.stopPropagation();"
              (keydown.enter)="$event.stopPropagation();" tabindex="0">
              <!-- Filter by Start ENd TIME -->
              <div class="venue-event-wrapper">
                <div class="wrap mmc_flex__item pr-5">
                  <!-- Filter By VENUES -->
                  <div class="mmc_flex--container pw-txt-b2 pb-2 stat">Venues</div>
                  <mat-form-field class="venue-select-dropdown">
                    <mat-select panelClass="venue-select-panel" multiple [(ngModel)]="selectedVenuesInBooking"
                      (ngModelChange)="selectedVenuesInBooking" placeholder="All">
                      <!-- <mat-option (onSelectionChange)="checkforselect($event,'advanceSelectedFacility')" value="{{advanceSelectedFacilityValue}}">Any</mat-option> -->


                      <mat-option style="color: black" (click)="optionClicked(v?.venueId)" *ngFor="let v of venues"
                        [value]="v?.venueId" matTooltip="{{v?.name}}">
                        {{v?.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>


                <div class="booking-detail-clear mmc_flex__item">
                  <div class="mmc_flex--container date-clear-wrapper">
                    <div class="mmc_flex__item pw-txt-b2 pb-2 event-date">Event date</div>
                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter clear-all-btn">
                      <button mat-icon-button (click)="clear()">
                        Clear all<mat-icon>clear_all</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="start-end-date-wrapper">
                    <div class="mmc_flex__item--inherit start-date date remove-table-border">
                      <mat-form-field class="remove_underline">
                        <!-- <mat-placeholder class="placeholder pw-txt-b2">START DATE</mat-placeholder> -->
                        <input class="pw-txt-b1 pw-text-semi-bold date-small-font" matInput
                          [matDatepicker]="pickerStart" (focus)="pickerStart.open()" [min]="minStartDate"
                          [max]="maxStartDate" placeholder="From" [(ngModel)]="dateStart"
                          (ngModelChange)="dateChangedSatrt()" readonly>
                        <mat-datepicker-toggle class="pw-ico-color__tertiary" matSuffix
                          [for]="pickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="mmc_flex__item--inherit date remove-table-border">
                      <mat-form-field class="remove_underline">
                        <!-- <mat-placeholder class="placeholder pw-txt-b2">END DATE</mat-placeholder> -->
                        <input class="pw-txt-b1 pw-text-semi-bold date-small-font" matInput [matDatepicker]="pickerEND"
                          (focus)="pickerEND.open()" [min]="minEndDate" [max]="maxEndDate" placeholder="To"
                          [(ngModel)]="dateEnd" (ngModelChange)="dateChangedEnd()" readonly>
                        <mat-datepicker-toggle class="pw-ico-color__tertiary" matSuffix
                          [for]="pickerEND"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEND></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>

                </div>

              </div>
              <!-- Apply Filter -->
              <div class="mmc_flex--container btn-content filter-btn-apply">
                <div class="btn-date-picker btn__card main mmc_flex__item--vcenter" (click)="ApplyFilter()"
                  (keydown.enter)="ApplyFilter()" tabindex="0">
                  Apply
                </div>
              </div>

            </div>
          </mat-menu>
          <!-- filter  End -->
          <div class="mmc_flex__item--vcenter mmc_flex__item--hcenter">
            <button class="btn__card secondary icon-button mmc-btn-ternary--small" mat-icon-button color="primary"
              tabindex="0" (click)="expandAllPannelChange(booking)" (keydown.enter)="expandAllPannelChange(booking)"
              [disableRipple]="true">
              {{(booking?.isExpanded) ?'Collapse all' : 'Expand all'}}
            </button>
          </div>
        </div>


        <!--Changes summery expansion-->

        <div class="row changes-summery-wrapper" hidden>
          <div class="col-sm-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="row w-100">
                    <div class="col-sm-12 col-md-12 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit wp_flex__item--vcenter">
                          <div class="wp-txt-body2 booking-details-header-title" matTooltip="">
                            Summery of Changes
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="changes-summery-expansion-body">
                  <div class="changes-summery-row row">
                    <div class="col-sm-2 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Team</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Cricket U15 Boys</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Date</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Tue 01:00 PM - 04:00 PM</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Requested</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Beacon Hill Reserve Field 1</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">New</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Beacon Hill Reserve Field 1</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-1 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item">
                          <button class="mat-btn-tertiary mat-button" mat-button="">
                            <span class="mat-button-wrapper">Accept</span>
                            <!--<div class="mat-button-ripple mat-ripple" matripple=""></div>
                                          <div class="mat-button-focus-overlay"></div>-->
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="changes-summery-row row">
                    <div class="col-sm-2 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Team</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Cricket U15 Boys</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Date</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Tue 01:00 PM - 04:00 PM</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Requested</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Beacon Hill Reserve Field 1</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">New</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Beacon Hill Reserve Field 1</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-1 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item">
                          <button class="mat-btn-tertiary mat-button" mat-button="">
                            <span class="mat-button-wrapper">Accept</span>
                            <!--<div class="mat-button-ripple mat-ripple" matripple=""></div>
                                          <div class="mat-button-focus-overlay"></div>-->
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="changes-summery-row row">
                    <div class="col-sm-2 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Team</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Cricket U15 Boys</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Date</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Tue 01:00 PM - 04:00 PM</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">Requested</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Beacon Hill Reserve Field 1</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body2 booking-details-header-title">New</div>
                        </div>
                        <div class="wp_flex__item--inherit">
                          <div class="wp-txt-body1 wp-txt--secondary" style="">Beacon Hill Reserve Field 1</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-1 align-items-center d-flex">
                      <div class="wp_flex--container flex-column booking-details-header-title-container">
                        <div class="wp_flex__item">
                          <button class="mat-btn-tertiary mat-button" mat-button="">
                            <span class="mat-button-wrapper">Accept</span>
                            <!--<div class="mat-button-ripple mat-ripple" matripple=""></div>
                                          <div class="mat-button-focus-overlay"></div>-->
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="changes-summery-button-row mb-3" style="">
                  <div class="wp_flex--container justify-content-end">

                    <div class="wp_flex__item--inherit wp-mr-10">
                      <button class="mat-btn-tertiary mat-button" mat-button="">
                        <span class="mat-button-wrapper">Respond with message</span>
                        <div class="mat-button-ripple mat-ripple" matripple=""></div>
                        <div class="mat-button-focus-overlay"></div>
                      </button>
                    </div>
                    <div class="wp_flex__item--inherit">
                      <button class="mat-btn-primary mat-button" mat-button="" type="button">
                        <span class="mat-button-wrapper">Send Response</span>
                        <div class="mat-button-ripple mat-ripple" matripple=""></div>
                        <div class="mat-button-focus-overlay"></div>
                      </button>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <!--Booking item population-->
        <div *ngIf="booking?.facilitySummaries && booking?.facilitySummaries?.length>0">


          <div
            *ngFor="let facilitySummary of booking.facilitySummaries; index as i; trackBy: getFacilitySummaryChanges">
            <div class="past-booking-details--card-secondary"
              *ngIf="!facilitySummary?.isRecurrent && !isGroupByFacilityNameInBookingView">

              <!--<div class="mmc_flex--container mmc_flex__item mmc_just-content-end mb-3">
                          <div class="mmc_flex__item--vcenter mmc_flex__item--hcenter">
                              <button class="btn__card secondary icon-button mmc-btn-ternary--small" mat-icon-button color="primary" (click)="expandAllPannelChange(booking)"
                                      [disableRipple]="true">
                                  {{(booking?.isExpanded) ?'Collapse all' : 'Expand all'}}
                              </button>
                          </div>
                      </div>-->


              <div class="past-booking-facility--card" *ngIf="!checkDiscountItem(facilitySummary?.item)">

                <mat-accordion>
                  <mat-expansion-panel hideToggle="true" [disabled]="true"
                    [expanded]="facilitySummary?.isExpandedSummary" (opened)="booking.isExpanded=true"
                    (closed)="booking.isExpanded=false">
                    <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px">
                      <div class="mmc_flex--container facility-expansion-header--container">

                        <div class="mmc_flex__item--vcenter">
                          <img class="facility-img" src="{{apiUrl}}{{facilitySummary?.imageUrl}}"
                            alt="{{facilitySummary?.name}}" />
                        </div>

                        <div class="mmc_flex__item mmc_flex__item--vcenter">
                          <div class="mmc_flex__item pw-txt-s2 pw-text-color__primary">{{facilitySummary?.name}}</div>
                        </div>
                          <div class="row col-lsg-3 col-md-4 col-sm-6 ml-sm-0 ml-5 col-12 facilityExpansionHeaderDropdownIcon">
                              <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-center facilityExpansionHeaderIcons"
                                   matTooltip="number of unanswered questions" tabindex="0"
                                   (click)="popQuestion(booking.facilitySummaries[i])"
                                   (keydown.enter)="popQuestion(booking.facilitySummaries[i])"
                                   *ngIf="!facilitySummary?.isRecurrent && facilitySummary?.bookingItemSummaries && facilitySummary?.bookingItemSummaries?.length > 0 && facilitySummary?.bookingItemSummaries[0].question && facilitySummary?.bookingItemSummaries[0].question?.length>0 ">
                                  <div class="expansion-header-icons">
                                      <span class="icon-p2-questions icon f-size-md"></span>
                                      <span class="count">
                                          {{
(booking.facilitySummaries[i]?.bookingItemSummaries[0]?.question?.length +
                              countFaciltyUpsellTotalQuestions(booking.facilitySummaries[i].bookingItemSummaries[0])) -
                              (booking.facilitySummaries[i].bookingItemSummaries[0]?.totalAnsweredQuestions==undefined?'0':
                              booking.facilitySummaries[i].bookingItemSummaries[0].totalAnsweredQuestions)
                                          }}
                                      </span>
                                  </div>
                              </div>

                        <div class="extra-allocation" style="display: none !important" *ngIf="!isCouncilCreatedBooking">
                          <button class="mmc-btn-xl--primary item-w25-mobile mat-button ng-star-inserted" tabindex="0"
                            (click)="openExtraAllocation()" (keydown.enter)="openExtraAllocation()" mat-button=""><span
                              class="mat-button-wrapper">
                              <div class="mmc_flex--container">
                                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                                  <span class="icon-p2-invoice icon f-size-md"></span></div>
                                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label add-extra-txt">
                                  Add extras </div>
                              </div>
                            </span>
                            <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false"
                              ng-reflect-disabled="false" ng-reflect-trigger="[object HTMLButtonElement]"></div>
                            <div class="mat-button-focus-overlay"></div>
                          </button>
                        </div>

                        <div class="deletion-allocation" style="display: none !important"
                          *ngIf="!isCouncilCreatedBooking">
                          <button class="mmc-btn-xl--ternary item-w25-mobile mat-button ng-star-inserted" tabindex="0"
                            (click)="openDeletionAllocation()" (keydown.enter)="openDeletionAllocation()"
                            mat-button=""><span class="mat-button-wrapper">
                              <div class="mmc_flex--container">
                                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                                  <span class="icon-p2-delete icon f-size-md"></span></div>
                                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                                  Delete extras</div>
                              </div>
                            </span>
                            <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false"
                              ng-reflect-disabled="false" ng-reflect-trigger="[object HTMLButtonElement]"></div>
                            <div class="mat-button-focus-overlay"></div>
                          </button>
                        </div>

                        <div class="extra-allocation" style="display: none !important">
                          <button class="mmc-btn-xl--primary item-w25-mobile mat-button ng-star-inserted" tabindex="0"
                            (click)="openExtraAllocation()" (keydown.enter)="openExtraAllocation()" mat-button=""><span
                              class="mat-button-wrapper">
                              <div class="mmc_flex--container">
                                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                                  <span class="icon-p2-invoice icon f-size-md"></span></div>
                                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label add-extra-txt">
                                  Add extras </div>
                              </div>
                            </span>
                            <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false"
                              ng-reflect-disabled="false" ng-reflect-trigger="[object HTMLButtonElement]"></div>
                            <div class="mat-button-focus-overlay"></div>
                          </button>
                        </div>

                        <div class="deletion-allocation" style="display: none !important">
                          <button class="mmc-btn-xl--ternary item-w25-mobile mat-button ng-star-inserted" tabindex="0"
                            (click)="openDeletionAllocation()" (keydown.enter)="openDeletionAllocation()"
                            mat-button=""><span class="mat-button-wrapper">
                              <div class="mmc_flex--container">
                                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                                  <span class="icon-p2-delete icon f-size-md"></span></div>
                                <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label">
                                  Delete extras </div>
                              </div>
                            </span>
                            <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false"
                              ng-reflect-disabled="false" ng-reflect-trigger="[object HTMLButtonElement]"></div>
                            <div class="mat-button-focus-overlay"></div>
                          </button>
                        </div>

                        <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit facilityExpansionHeaderIcons"
                          *ngIf="isUpsellEnable(facilitySummary) && !isCouncilCreatedBooking" tabindex="0"
                          (click)="popupExtra(facilitySummary,facilitySummary?.bookingItemSummaries[0],booking.priceConcessionId)"
                          (keydown.enter)="popupExtra(facilitySummary,facilitySummary?.bookingItemSummaries[0],booking.priceConcessionId)">
                          <button mat-button class="mmc-btn-xl--fourth">
                            <div class="mmc_flex--container">
                              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon">
                                <span class="icon-p2-star icon f-size-md"></span>
                              </div>
                              <div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label txt-extra">
                                Extras
                              </div>
                            </div>
                          </button>
                        </div>

                        <div
                          class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end">
                          <button class="down-arrow" mat-icon-button color="primary" tabindex="0"
                            (click)="expandSummaryPannelChange(facilitySummary)"
                            (keydown.enter)="expandSummaryPannelChange(facilitySummary)" aria-label="expand arrow"
                            [disableRipple]="true">
                            <mat-icon class="material-icons">
                              {{(facilitySummary?.isExpandedSummary) ?'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                            </mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    </mat-expansion-panel-header>
                    <div>
                      <div class="mmc_expansion__body--row-header">
                        <div class="mmc_flex--container past-booking-facility--container mobileGap">
                          <div class="mmc_flex__item mmc_flex__item--vcenter col-12 col-md-3 col-lg-3 col-xl-2">
                            <img class="expanded-row-img" src="{{apiUrl}}{{facilitySummary?.imageUrl}}"
                              alt="{{facilitySummary?.name}}" />
                          </div>

                          <div class="col-12 col-md-9 col-lg-9 col-xl-10 row d-flex">
                            <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w50-mobile mobileItemsCenter">
                              <div class="pw-txt-s1 ml-2 pw-text-color__tertiary">Date</div>
                              <div class="pw-txt-s3 ml-2 col-value">{{facilitySummary?.startTime | dateFormat:'fulldate'}}
                              </div>
                            </div>
                            <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w50-mobile mobileItemsCenter">
                              <div class="pw-txt-s1 ml-2 pw-text-color__tertiary">Start time</div>
                              <div class="pw-txt-s3 ml-2 col-value">{{facilitySummary?.startTime | dateFormat:'time'}}</div>
                            </div>
                            <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w50-mobile mobileItemsCenter">
                              <div class="pw-txt-s1 ml-2 pw-text-color__tertiary">End time</div>
                              <div class="pw-txt-s3 ml-2 col-value">{{facilitySummary?.endTime | dateFormat:'time'}} </div>
                            </div>
                            <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w50-mobile mobileItemsCenter"
                              *ngIf="!booking?.cancelled">
                              <div class="pw-txt-s1 ml-2 pw-text-color__tertiary">Attendees</div>
                              <div class="pw-txt-s3 ml-2 col-value">{{(facilitySummary?.attendees != 0)?
                                facilitySummary?.attendees:''}}</div>
                            </div>
                            <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w50-mobile mobileItemsCenter">
                              <div class="pw-txt-s1 ml-2 pw-text-color__tertiary">Rate</div>
                              <div class="pw-txt-s3 ml-2 col-value"
                                *ngFor="let bookingItemPrice of facilitySummary?.bookingItemSummaries[0].bookingItemPrices">
                                {{bookingItemPrice.rate | CurrencyFormat}} X {{bookingItemPrice.unit}}
                                {{bookingItemPrice.priceType}}</div>
                            </div>
                            <div
                              *ngIf="isConfigurationEnabled && facilitySummary.configuration != undefined && configrationLoaded"
                              class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w50-mobile mobileItemsCenter">
                              <div class="pw-txt-s1 ml-2 pw-text-color__tertiary">
                                {{getConfiguration(facilitySummary.bookingItemId)}}</div>
                              <div class="config-img cog-d-none" *ngIf="configurationDisplayMode=='2'">
                                <!--<img src="{{imagePath}}/Optimo.PrivateWeb/dist/assets/images/u-shape.png" alt="u-shape" />-->
                                <!-- <img src="{{facilitySummary.configuration.configurationThumbnail}}" />-->
                                <img src="{{getConfigurationImg(facilitySummary.bookingItemId)}}" alt="Meeting room" />
                              </div>
                            </div>
                            <div
                              class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter mmc_txt-align--right item-w50-mobile mobileItemsCenter">
                              <div class="pw-txt-s1 ml-2 pw-text-color__tertiary">Total</div>
                              <div class="pw-txt-s3 ml-2 pw-text-bold col-value">{{facilitySummary?.total | CurrencyFormat}}
                              </div>
                            </div>
                          </div>

                          <!-- -------------------------------------- Bottom Icons for Mobile View -----------------------------------------------  -->
                          <div class="iconSetBottom">
                            <div class="row justify-content-end">
                              <div
                                class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-center mmc_fd--col col-3"
                                matTooltip="number of unanswered questions"
                                tabindex="0"
                                (click)="
                                  popQuestion(booking.facilitySummaries[i])
                                "
                                (keydown.enter)="
                                  popQuestion(booking.facilitySummaries[i])
                                "
                                *ngIf="
                                  !facilitySummary?.isRecurrent &&
                                  facilitySummary?.bookingItemSummaries &&
                                  facilitySummary?.bookingItemSummaries
                                    ?.length > 0 &&
                                  facilitySummary?.bookingItemSummaries[0]
                                    .question &&
                                  facilitySummary?.bookingItemSummaries[0]
                                    .question?.length > 0
                                "
                              >
                                <div class="expansion-header-icons">
                                  <span
                                    class="icon-p2-questions icon f-size-md"
                                  ></span>
                                  <span class="count">
                                    {{
                                      booking.facilitySummaries[i]
                                        ?.bookingItemSummaries[0]?.question
                                        ?.length +
                                        countFaciltyUpsellTotalQuestions(
                                          booking.facilitySummaries[i]
                                            .bookingItemSummaries[0]
                                        ) -
                                        (booking.facilitySummaries[i]
                                          .bookingItemSummaries[0]
                                          ?.totalAnsweredQuestions == undefined
                                          ? "0"
                                          : booking.facilitySummaries[i]
                                              .bookingItemSummaries[0]
                                              .totalAnsweredQuestions)
                                    }}
                                  </span>
                                </div>
                                <div
                                  class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label"
                                >
                                  Questionnaire
                                </div>
                              </div>

                              <div
                                class="extra-allocation"
                                style="display: none !important"
                                *ngIf="!isCouncilCreatedBooking"
                              >
                                <button
                                  class="mmc-btn-xl--primary item-w25-mobile mat-button ng-star-inserted"
                                  tabindex="0"
                                  (click)="openExtraAllocation()"
                                  (keydown.enter)="openExtraAllocation()"
                                  mat-button=""
                                >
                                  <span class="mat-button-wrapper">
                                    <div
                                      class="mmc_flex--container mmc_flex__item--vcenter mmc_fd--col"
                                    >
                                      <div
                                        class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon"
                                      >
                                        <span
                                          class="icon-p2-invoice icon f-size-md"
                                        ></span>
                                      </div>
                                      <div
                                        class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label"
                                      >
                                        Add extras
                                      </div>
                                    </div>
                                  </span>
                                  <div
                                    class="mat-button-ripple mat-ripple"
                                    matripple=""
                                    ng-reflect-centered="false"
                                    ng-reflect-disabled="false"
                                    ng-reflect-trigger="[object HTMLButtonElement]"
                                  ></div>
                                  <div class="mat-button-focus-overlay"></div>
                                </button>
                              </div>

                              <div
                                class="deletion-allocation"
                                style="display: none !important"
                                *ngIf="!isCouncilCreatedBooking"
                              >
                                <button
                                  class="mmc-btn-xl--ternary item-w25-mobile mat-button ng-star-inserted"
                                  tabindex="0"
                                  (click)="openDeletionAllocation()"
                                  (keydown.enter)="openDeletionAllocation()"
                                  mat-button=""
                                >
                                  <span class="mat-button-wrapper">
                                    <div class="mmc_flex--container">
                                      <div
                                        class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon"
                                      >
                                        <span
                                          class="icon-p2-delete icon f-size-md"
                                        ></span>
                                      </div>
                                      <div
                                        class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label"
                                      >
                                        Delete extras
                                      </div>
                                    </div>
                                  </span>
                                  <div
                                    class="mat-button-ripple mat-ripple"
                                    matripple=""
                                    ng-reflect-centered="false"
                                    ng-reflect-disabled="false"
                                    ng-reflect-trigger="[object HTMLButtonElement]"
                                  ></div>
                                  <div class="mat-button-focus-overlay"></div>
                                </button>
                              </div>

                              <div
                                class="extra-allocation"
                                style="display: none !important"
                              >
                                <button
                                  class="mmc-btn-xl--primary item-w25-mobile mat-button ng-star-inserted"
                                  tabindex="0"
                                  (click)="openExtraAllocation()"
                                  (keydown.enter)="openExtraAllocation()"
                                  mat-button=""
                                >
                                  <span class="mat-button-wrapper">
                                    <div class="mmc_flex--container">
                                      <div
                                        class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon"
                                      >
                                        <span
                                          class="icon-p2-invoice icon f-size-md"
                                        ></span>
                                      </div>
                                      <div
                                        class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label add-extra-txt"
                                      >
                                        Add extras
                                      </div>
                                    </div>
                                  </span>
                                  <div
                                    class="mat-button-ripple mat-ripple"
                                    matripple=""
                                    ng-reflect-centered="false"
                                    ng-reflect-disabled="false"
                                    ng-reflect-trigger="[object HTMLButtonElement]"
                                  ></div>
                                  <div class="mat-button-focus-overlay"></div>
                                </button>
                              </div>

                              <div
                                class="deletion-allocation"
                                style="display: none !important"
                              >
                                <button
                                  class="mmc-btn-xl--ternary item-w25-mobile mat-button ng-star-inserted"
                                  tabindex="0"
                                  (click)="openDeletionAllocation()"
                                  (keydown.enter)="openDeletionAllocation()"
                                  mat-button=""
                                >
                                  <span class="mat-button-wrapper">
                                    <div class="mmc_flex--container">
                                      <div
                                        class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon"
                                      >
                                        <span
                                          class="icon-p2-delete icon f-size-md"
                                        ></span>
                                      </div>
                                      <div
                                        class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label"
                                      >
                                        Delete extras
                                      </div>
                                    </div>
                                  </span>
                                  <div
                                    class="mat-button-ripple mat-ripple"
                                    matripple=""
                                    ng-reflect-centered="false"
                                    ng-reflect-disabled="false"
                                    ng-reflect-trigger="[object HTMLButtonElement]"
                                  ></div>
                                  <div class="mat-button-focus-overlay"></div>
                                </button>
                              </div>

                              <div
                                class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit col-3"
                                *ngIf="
                                  isUpsellEnable(facilitySummary) &&
                                  !isCouncilCreatedBooking
                                "
                                tabindex="0"
                                (click)="
                                  popupExtra(
                                    facilitySummary,
                                    facilitySummary?.bookingItemSummaries[0],
                                    booking.priceConcessionId
                                  )
                                "
                                (keydown.enter)="
                                  popupExtra(
                                    facilitySummary,
                                    facilitySummary?.bookingItemSummaries[0],
                                    booking.priceConcessionId
                                  )
                                "
                              >
                                <div
                                  class="mmc_flex--container mmc_flex__item--vcenter mmc_fd--col"
                                >
                                  <div
                                    class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter"
                                  >
                                    <span
                                      class="icon-p2-star icon f-size-md"
                                    ></span>
                                  </div>
                                  <div
                                    class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label"
                                  >
                                    Extras
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div *ngIf="facilitySummary?.bookingItemSummaries">
                        <div *ngFor="let bookingItemSummary of facilitySummary?.bookingItemSummaries">
                          <div class="mmc_expansion__body--row"
                            *ngFor="let upsellSummary of bookingItemSummary.upsellSummaries">
                            <div class="mmc_flex--container">
                              <div
                                class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter p10 aminity-count">
                                <div class="pw-txt-s2 pw-text-trans__upper">
                                  <span>{{upsellSummary.qty}}</span><span>X</span></div>
                              </div>
                              <div class="mmc_flex__item mmc_flex__item--vcenter p25 aminity-img">
                                <img class="expanded-row-img--addextra" src="{{apiUrl}}{{upsellSummary.url}}"
                                  alt="{{facilitySummary?.name}}" />
                              </div>
                              <div class="mmc_flex__item mmc_flex__item--vcenter p40">
                                <div class="pw-txt-s2">{{upsellSummary.name}}</div>
                              </div>
                              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 cog-d-none">
                                <div class="pw-txt-s1 pw-text-color__tertiary" *ngIf="upsellSummary.bookingItemPrices">
                                  Rate</div>
                                <div class="pw-txt-s3 col-value"
                                  *ngFor="let bookingItemPrice of upsellSummary.bookingItemPrices">
                                  {{bookingItemPrice.rate|CurrencyFormat}} X {{bookingItemPrice.unit}}
                                  {{bookingItemPrice.priceType}}</div>
                              </div>
                              <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end p10"
                                *ngIf="upsellSummary.bookingItemId != undefined">
                                <div class="pw-txt-s2">{{upsellSummary.totalAmount|CurrencyFormat}}</div>
                              </div>

                              <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end p10"
                                *ngIf="upsellSummary.bookingItemId == undefined">
                                <!--<div class="pw-txt-s2" *ngIf="isPriceRange(upsellSummary.minPrice,upsellSummary.maxPrice)  && upsellSummary.minPrice?.priceIncludingTax !=0  && upsellSummary.minPrice">{{upsellSummary.minPrice?.priceIncludingTax  |CurrencyFormat}} - {{upsellSummary.maxPrice?.priceIncludingTax  |CurrencyFormat}}</div>
                                                          <div class="pw-txt-s2" *ngIf="!(isPriceRange(upsellSummary.minPrice,upsellSummary.maxPrice) && upsellSummary.minPrice?.priceIncludingTax !=0 && upsellSummary.minPrice)">{{upsellSummary.minPrice?.priceIncludingTax |CurrencyFormat}}</div>-->
                                <!--<div class="pw-txt-s2" *ngIf="facilitySummary?.allowImmediateConfirmedBookings == 1 && upsellSummary.minPrice?.priceIncludingTax != 0">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                                          <div class="pw-txt-s2" *ngIf="facilitySummary?.allowImmediateConfirmedBookings == 1 && upsellSummary.minPrice?.priceIncludingTax == 0">{{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                                          <div class="pw-txt-s2" *ngIf="facilitySummary?.allowImmediateConfirmedBookings == 0 && upsellSummary.minPrice?.priceIncludingTax != 0 && upsellSummary.maxPrice?.priceIncludingTax != 0">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}}  - {{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                                          <div class="pw-txt-s2" *ngIf="facilitySummary?.allowImmediateConfirmedBookings == 0 && upsellSummary.minPrice?.priceIncludingTax == 0 && upsellSummary.maxPrice?.priceIncludingTax != 0">{{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                                          <div class="pw-txt-s2" *ngIf="facilitySummary?.allowImmediateConfirmedBookings == 0 && upsellSummary.minPrice?.priceIncludingTax == 0 && upsellSummary.maxPrice?.priceIncludingTax == 0">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}}  - {{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>-->


                                <div class="pw-txt-s2" *ngIf="isPriceRange(upsellSummary)">
                                  {{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}} -
                                  {{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                <div class="pw-txt-s2" *ngIf="!isPriceRange(upsellSummary)">
                                  {{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}}</div>


                              </div>

                              <div class="mmc_flex__item mmc_flex__item--vcenter w90">
                                <div class="mmc_flex--container">
                                  <div class="mmc_flex__item mmc_flex__item--vcenter w50"
                                    *ngIf="upsellSummary.isAdded==true" tabindex="0"
                                    (click)="editExtra(facilitySummary,bookingItemSummary,upsellSummary,booking.priceConcessionId)"
                                    (keydown.enter)="editExtra(facilitySummary,bookingItemSummary,upsellSummary,booking.priceConcessionId)">
                                    <div class="expansion-header-icons">
                                      <i class="material-icons icon f-size-md curser">
                                        edit
                                      </i>

                                      <!--<span class="font__mcc icon-p2-star-circle icon f-size-md "></span>-->
                                    </div>
                                  </div>

                                  <div class="mmc_flex__item mmc_flex__item--vcenter"
                                    *ngIf="upsellSummary.isAdded==true" tabindex="0"
                                    (click)="deleteUpsellItem(bookingItemSummary,upsellSummary)"
                                    (keydown.enter)="deleteUpsellItem(bookingItemSummary,upsellSummary)">
                                    <div class="expansion-header-icons">
                                      <i class="material-icons icon f-size-md curser">
                                        delete
                                      </i>
                                      <!--<span class="icon-p2-questions icon f-size-md"></span>-->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>

              <!-- <div *ngFor="let discountItem of booking.discountItems; index as i; trackBy: getFacilitySummaryChanges">
                <div class="past-booking-facility--card" *ngIf="checkDiscountItem(discountItem?.item)">
                  <div class="cancellation-fee-card">
                    <div class="mmc_flex--container facility-expansion-header--container">
                      <div class="mmc_flex__item mmc_flex__item--vcenter">
                        <div class="mmc_flex__item pw-txt-s2">{{discountItem?.name}}</div>
                      </div>

                      <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter mmc_txt-align--right">
                        <div class="pw-txt-s1 pw-text-color__tertiary">Total</div>
                        <div class="pw-txt-s3 pw-text-bold">{{discountItem?.total | CurrencyFormat}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

            </div>
            <!--this is used for casual booking recurrent and group view-->

            <div class="past-booking-details--card-secondary"
              *ngIf="facilitySummary?.isRecurrent || isGroupByFacilityNameInBookingView">
              <div class="past-booking-facility--card" *ngIf="!checkDiscountItem(facilitySummary?.item)">
                <mat-accordion class="mcc-expansion-panel-outter">
                  <mat-expansion-panel #recurrentFacilitySummaryPanel [disabled]="true"
                    [expanded]="facilitySummary?.isExpandedSummary" (opened)="booking.isExpanded=true"
                    (closed)="booking.isExpanded=false">
                    <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px">
                      <div class="mmc_flex--container  facility-expansion-header--container">
                        <div class="mmc_flex__item mmc_flex__item--vcenter p25 aminity-img">
                          <img class="facility-img" src="{{apiUrl}}{{facilitySummary?.imageUrl}}"
                            alt="{{facilitySummary?.name}}" />
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter facilityExpansionHeaderWidth">
                          <div class="mmc_flex__item pw-txt-s2 pw-text-color__primary">{{facilitySummary?.name}}<span
                              class="pw-text-bold"
                              *ngIf="isGroupByFacilityNameInBookingView">{{(facilitySummary?.isRecurrent) ? ' -
                              Recurrent Date(s)': (facilitySummary?.item?.itemTypeID =='1')?' - Adhoc Date(s)'
                              :([7,3,6,5].includes(facilitySummary?.item?.itemTypeID))?' - Additional Charges'
                              :''}}</span></div>
                        </div>
                        <!--<div class="extra-allocation" *ngIf="isUpsellEnable(facilitySummary) && !isCouncilCreatedBooking" (click)="popupExtra(facilitySummary,facilitySummary.bookingItemSummaries[0],booking.priceConcessionId)">
                                          <button class="mmc-btn-xl--ternary item-w25-mobile mat-button ng-star-inserted" mat-button=""><span class="mat-button-wrapper"><div class="mmc_flex--container"><div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-icon"><span class="icon-p2-delete icon f-size-md"></span></div><div class="mmc_flex__item mmc_flex__item--inherit mmc_flex__item--vcenter btn-label"> Add-ons </div></div></span><div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false" ng-reflect-disabled="false" ng-reflect-trigger="[object HTMLButtonElement]"></div><div class="mat-button-focus-overlay"></div></button>
                                        </div>-->
                                        <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-center facilityExpansionHeaderIcons"
                                          matTooltip="number of unanswered questions" tabindex="0" (click)="popQuestion(booking.facilitySummaries[i])"
                                          (keydown.enter)="popQuestion(booking.facilitySummaries[i])"
                                          *ngIf="!facilitySummary?.isRecurrent && facilitySummary?.bookingItemSummaries && facilitySummary?.bookingItemSummaries?.length > 0 && facilitySummary?.bookingItemSummaries[0].question && facilitySummary?.bookingItemSummaries[0].question?.length>0 ">
                                          <div class="expansion-header-icons">
                                            <span class="icon-p2-questions icon f-size-md"></span>
                                            <span class="count">{{(booking.facilitySummaries[i]?.bookingItemSummaries[0]?.question?.length +
                                              countFaciltyUpsellTotalQuestions(booking.facilitySummaries[i].bookingItemSummaries[0])) -
                                              (booking.facilitySummaries[i].bookingItemSummaries[0]?.totalAnsweredQuestions==undefined?'0':
                                              booking.facilitySummaries[i].bookingItemSummaries[0].totalAnsweredQuestions)}}</span>
                                          </div>
                                        </div>

                                        <div
                          class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end">
                          <button class="down-arrow" mat-icon-button color="primary" tabindex="0"
                            (click)="expandSummaryPannelChange(facilitySummary)"
                            (keydown.enter)="expandSummaryPannelChange(facilitySummary)" aria-label="expand arrow"
                            [disableRipple]="true">
                            <mat-icon>{{(facilitySummary?.isExpandedSummary) ?'keyboard_arrow_up' :
                              'keyboard_arrow_down'}}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-expansion-panel-header>

                    <mat-accordion class="mcc-expansion-panel-inner" *ngIf="(facilitySummary?.isExpandedSummary)">
                      <mat-expansion-panel #bookingRecurrentItemSummaryPanel [disabled]="true"
                        [expanded]="facilitySummary?.isExpandedItems">
                        <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px" aria-hidden="true">
                          <div class="mmc_flex--container  facility-expansion-header--container">
                            <div class="mmc_flex__item">
                              <div class="mmc_flex--container mcc-wrap-items ptMobile">
                                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w33-mobile justifyContetntStart">
                                  <div class="pw-txt-s1 pw-text-color__tertiary">Start date</div>
                                  <div class="pw-txt-s3">{{facilitySummary?.startTime | dateFormat}}</div>
                                </div>
                                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w33-mobile justifyContetntStart">
                                  <div class="pw-txt-s1 pw-text-color__tertiary">End date</div>
                                  <div class="pw-txt-s3">{{facilitySummary?.endTime | dateFormat}}</div>
                                </div>
                                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w33-mobile justifyContetntStart"
                                  *ngIf="!booking?.cancelled">
                                  <div class="pw-txt-s1 pw-text-color__tertiary">Attendees</div>
                                  <div class="pw-txt-s3">{{facilitySummary?.attendees}}</div>
                                </div>
                                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter item-w33-mobile justifyContetntStart">
                                  <div class="pw-txt-s1 pw-text-color__tertiary">Total</div>
                                  <div class="pw-txt-s3 pw-text-bold">{{facilitySummary?.total|CurrencyFormat}}</div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end icon__expand">
                              <button class="down-arrow" mat-icon-button color="primary" tabindex="0"
                                (click)="expandRecurrentItemSummaryPanelChange(facilitySummary)"
                                (keydown.enter)="expandRecurrentItemSummaryPanelChange(facilitySummary)"
                                aria-label="expand arrow" [disableRipple]="true">
                                <mat-icon>{{(facilitySummary?.isExpandedItems) ?'keyboard_arrow_up' :
                                  'keyboard_arrow_down'}}</mat-icon>
                              </button>
                            </div>
                          </div>
                        </mat-expansion-panel-header>

                        <div class="mmc_expansion__body--row-header h-mobile">
                          <div class="mmc_flex--container past-booking-facility--container">
                            <div class="mmc_flex__item mmc_flex__item--vcenter p20">
                              <div class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Date</div>
                            </div>

                            <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                              <div class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Start time</div>
                            </div>

                            <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                              <div class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">End time</div>
                            </div>

                            <div class="mmc_flex__item mmc_flex__item--vcenter p10" *ngIf="!booking?.cancelled">
                              <div class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Attendees</div>
                            </div>

                            <div class="mmc_flex__item mmc_flex__item--vcenter p20">
                              <div class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Rate</div>
                            </div>

                            <div class="mmc_flex__item mmc_flex__item--vcenter p20"
                              *ngIf="isConfigurationEnabled && facilitySummary.configuration != undefined && configrationLoaded">
                              <div class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Configuration</div>
                            </div>

                            <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                              <div class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Total</div>
                            </div>
                            <!-- <div class="mmc_flex__item mmc_flex__item--vcenter p15 item-w33-mobile" *ngIf="!facilitySummary?.isHaveUpsell"></div> -->
                            <!-- <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end icon-replace"></div>-->

                            <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                              <button class="btn__card secondary icon-button mmc-btn-ternary--small"
                                *ngIf="facilitySummary?.isHaveUpsell"
                                [ngClass]="facilitySummary?.isHaveUpsell ? '' : 'opacity-0'" mat-icon-button
                                color="primary" tabindex="0" (click)="expandAllUpsellChange(facilitySummary)"
                                (keydown.enter)="expandAllUpsellChange(facilitySummary)" [disableRipple]="true">
                                {{(facilitySummary?.isExpandAll) ?'Collapse' : 'Expand'}}
                                <span class="txt-extra">extras</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div
                          *ngIf="facilitySummary?.bookingItemSummaries?.length>0 && facilitySummary?.isExpandedItems">
                          <div
                            *ngFor="let bookingItemSummary of filterPagination(facilitySummary?.bookingItemSummaries,facilitySummary?.pageSize ,facilitySummary?.pageNumber) ">
                            <mat-accordion class="mcc-expansion-panel-inner mcc-expansion-panel-inner-inner">
                              <mat-expansion-panel #recurrentUpsellPanel  [hideToggle]="true"
                                [expanded]="facilitySummary.isExpandAll"
                                [disabled]="true">
                                <!-- only click on arrorheader this should change -->
                                <!-- (opened)="facilitySummary.isExpandAll=true"
                                (closed)="facilitySummary.isExpandAll=false" -->
                                  <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px"
                                  aria-hidden="true">

                                  <div class="facility-expansion-header--container w-100">
                                    <div class="mmc_flex__item">
                                      <div class="mmc_flex--container mcc-wrap-items ptMobile">
                                          <div
                                          class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p20 item-w33-mobile justifyContetntStart">
                                          <div class="pw-txt-s1 d-mobile">Date</div>
                                          <div class="pw-txt-s3">{{bookingItemSummary.startTime |
                                            dateFormat:'fulldate'}}
                                          </div>
                                        </div>

                                        <div
                                          class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p10 item-w33-mobile justifyContetntStart">
                                          <div class="pw-txt-s1 d-mobile">Start time</div>
                                          <div class="pw-txt-s3">{{bookingItemSummary.startTime | dateFormat:'time'}}
                                          </div>
                                        </div>

                                        <div
                                          class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p10 item-w33-mobile justifyContetntStart">
                                          <div class="pw-txt-s1 d-mobile">End time</div>
                                          <div class="pw-txt-s3">{{bookingItemSummary.endTime | dateFormat:'time'}}
                                          </div>
                                        </div>

                                        <div
                                          class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p10 item-w33-mobile justifyContetntStart"
                                          *ngIf="!booking?.cancelled">
                                          <div class="pw-txt-s1 d-mobile">Attendees</div>
                                          <div class="spw-txt-s3">{{bookingItemSummary.attendees}}</div>
                                        </div>

                                        <div
                                          class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p20 item-w33-mobile justifyContetntStart">
                                          <div class="pw-txt-s1 d-mobile">Rate</div>
                                          <div class="pw-txt-s3"
                                            *ngFor="let bookingItemPrice of bookingItemSummary.bookingItemPrices">

                                            {{bookingItemPrice.rate | CurrencyFormat}}
                                            <span
                                              class="multiplication" *ngIf="!(bookingItemPrice?.priceType == 'seasonal per cover' || bookingItemPrice?.priceType == 'seasonal per booking')">
                                              X
                                              {{bookingItemPrice.unit}}
                                            </span>
                                            {{bookingItemPrice.priceType}}
                                          </div>
                                        </div>

                                        <div
                                          *ngIf="isConfigurationEnabled && facilitySummary.configuration != undefined && configrationLoaded"
                                          class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p20 item-w33-mobile justifyContetntStart">
                                          <div class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">
                                            {{getConfiguration(facilitySummary.bookingItemId)}}</div>
                                          <div class="config-img cog-d-none"
                                            *ngIf="configurationDisplayMode=='2' && getConfiguration(facilitySummary.bookingItemId)">
                                            <img src="{{getConfigurationImg(facilitySummary.bookingItemId)}}"
                                              alt="{{getConfiguration(facilitySummary.bookingItemId)}}" />
                                          </div>
                                        </div>

                                        <div
                                          class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p5 item-w33-mobile justifyContetntStart">
                                          <div class="pw-txt-s1 d-mobile">Total</div>
                                          <div class="pw-txt-s3 pw-text-bold">{{bookingItemSummary?.total |
                                            CurrencyFormat}}</div>
                                        </div>

                                        <div class="mmc_flex__item mmc_flex__item--vcenter p15 item-w33-mobile">
                                          <div class="mmc_flex--container">
                                            <!-- -------------------------------------------------- Mobile View2 --------------------------------------------------------  -->

                                            <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-center iconSetBottom pb-0"
                                            matTooltip="number of unanswered questions" tabindex="0" (click)="popQuestion(booking.facilitySummaries[i])"
                                            (keydown.enter)="popQuestion(booking.facilitySummaries[i])"
                                            *ngIf="!facilitySummary?.isRecurrent && facilitySummary?.bookingItemSummaries && facilitySummary?.bookingItemSummaries?.length > 0 && facilitySummary?.bookingItemSummaries[0].question && facilitySummary?.bookingItemSummaries[0].question?.length>0 ">
                                              <div class="expansion-header-icons">
                                                <span class="icon-p2-questions icon f-size-md"></span>
                                                <span class="count">{{(booking.facilitySummaries[i]?.bookingItemSummaries[0]?.question?.length +
                                                  countFaciltyUpsellTotalQuestions(booking.facilitySummaries[i].bookingItemSummaries[0])) -
                                                  (booking.facilitySummaries[i].bookingItemSummaries[0]?.totalAnsweredQuestions==undefined?'0':
                                                  booking.facilitySummaries[i].bookingItemSummaries[0].totalAnsweredQuestions)}}</span>
                                              </div>
                                            </div>
                                            <div class="mmc_flex__item mmc_flex__item--vcenter"
                                              *ngIf="isUpsellEnable(facilitySummary) && !isCouncilCreatedBooking"
                                              tabindex="0">
                                              <div class="expansion-header-icons">
                                                <span class="font__mcc icon-p2-star-circle icon f-size-md "   (click)="popupExtra(facilitySummary,bookingItemSummary,booking.priceConcessionId)"
                                                (keydown.enter)="popupExtra(facilitySummary,bookingItemSummary,booking.priceConcessionId)"></span>
                                              </div>
                                            </div>
                                            <div class="mmc_flex__item mmc_flex__item--vcenter" tabindex="0"
                                              (click)="popQuestion(facilitySummary)"
                                              (keydown.enter)="popQuestion(facilitySummary)"
                                              *ngIf="facilitySummary.totalQuestions && facilitySummary.totalQuestions>0 && facilitySummary.isRecurrent">
                                              <div class="expansion-header-icons">
                                                <span class="icon-p2-questions icon f-size-md"></span>
                                              </div>
                                            </div>

                                          </div>
                                        </div>

                                        <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p5 item-w33-mobile">
                                          <button class="down-arrow" mat-icon-button color="primary" tabindex="0"
                                            (click)="expandrecurrentItemUpsellSummaryPanelChange(bookingItemSummary,facilitySummary)"
                                            (keydown.enter)="expandrecurrentItemUpsellSummaryPanelChange(bookingItemSummary,facilitySummary)" aria-label="expand arrow"
                                            *ngIf="bookingItemSummary.upsellSummaries && bookingItemSummary.upsellSummaries?.length>0" [disableRipple]="true">
                                            <mat-icon>{{(facilitySummary?.isExpandAll) ?'keyboard_arrow_up' :
                                              'keyboard_arrow_down'}}</mat-icon>
                                          </button>
                                      </div>
                                      </div>
                                    </div>

                                    <!-- <div
                                      class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end icon__expand wfixed">
                                      <button class="down-arrow" mat-icon-button color="primary" tabindex="0"
                                        (click)="expandrecurrentItemUpsellSummaryPanelChange(bookingItemSummary)"
                                        (keydown.enter)="expandrecurrentItemUpsellSummaryPanelChange(bookingItemSummary)"
                                        aria-label="expand arrow"
                                        *ngIf="bookingItemSummary.upsellSummaries && bookingItemSummary.upsellSummaries?.length>0"
                                        [disableRipple]="true">
                                        <mat-icon>{{(bookingItemSummary?.isExpandedUpsell) ?'keyboard_arrow_up' :
                                          'keyboard_arrow_down'}}</mat-icon>
                                      </button>
                                    </div> -->
                                  </div>
                                </mat-expansion-panel-header>
                                <div
                                  *ngIf="bookingItemSummary.upsellSummaries?.length>0 && (bookingItemSummary?.isExpandedUpsell)">
                                  <div class="mmc_expansion__body--row body-row-height extrasDesktop"
                                    *ngFor="let upsellSummary of bookingItemSummary.upsellSummaries">
                                    <div class="mmc_flex--container">
                                      <div class="mmc_flex__item mmc_flex__item--vcenter p40">
                                        <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter p10">
                                          <div class="pw-txt-s2 pw-text-trans__upper">
                                            <span>{{upsellSummary.qty}}</span><span>X</span>
                                          </div>
                                        </div>
                                        <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                                          <img class="expanded-row-img--addextra" src="{{apiUrl}}{{upsellSummary.url}}"
                                            alt="{{facilitySummary?.name}}" />
                                        </div>

                                        <div class="mmc_flex__item mmc_flex__item--vcenter p20">
                                          <div class="pw-txt-s2">{{upsellSummary.name}}</div>
                                        </div>
                                      </div>
                                      <!-- <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter p10">
                                                                            <div class="pw-txt-s2 pw-text-trans__upper"><span>{{upsellSummary.qty}}</span><span>X</span></div>
                                                                        </div>
                                                                        <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                                                                            <img class="expanded-row-img--addextra" src="{{apiUrl}}{{upsellSummary.url}}" />
                                                                        </div>

                                                                      <div class="mmc_flex__item mmc_flex__item--vcenter p20">
                                                                          <div class="pw-txt-s2">{{upsellSummary.name}}</div>
                                                                      </div> -->

                                      <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p20 cog-d-none"
                                        style="padding-left: 25px;">
                                        <div class="pw-txt-s1 pw-text-color__tertiary"
                                          *ngIf="upsellSummary.bookingItemPrices">Rate</div>
                                        <div class="pw-txt-s3 col-value"
                                          *ngFor="let bookingItemPrice of upsellSummary.bookingItemPrices">
                                          {{bookingItemPrice.rate|CurrencyFormat}} X {{bookingItemPrice.unit}}
                                          {{bookingItemPrice.priceType}}</div>
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                                        <div class="pw-txt-s2"></div>
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-start p20"
                                        *ngIf="upsellSummary.bookingItemId != undefined">
                                        <div class="pw-txt-s3">{{upsellSummary.totalAmount | CurrencyFormat}}</div>
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-start p20" *ngIf="upsellSummary.bookingItemId == undefined">
                                          <div class="pw-txt-s2" *ngIf="isPriceRange(upsellSummary.minPrice,upsellSummary.maxPrice) && upsellSummary.minPrice?.priceIncludingTax !=0 && upsellSummary.minPrice">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                          <div class="pw-txt-s2" *ngIf="!(isPriceRange(upsellSummary.minPrice,upsellSummary.maxPrice) && upsellSummary.minPrice?.priceIncludingTax !=0 && upsellSummary.minPrice)">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter w90">
                                          <div class="mmc_flex--container">
                                              <div class="mmc_flex__item mmc_flex__item--vcenter w50" *ngIf="upsellSummary.isAdded==true" tabindex="0" (click)="editExtra(facilitySummary,bookingItemSummary,upsellSummary,booking.priceConcessionId)" (keydown.enter)="editExtra(facilitySummary,bookingItemSummary,upsellSummary,booking.priceConcessionId)">
                                                  <div class="expansion-header-icons">
                                                      <i class="material-icons icon f-size-md curser">
                                                          edit
                                                      </i>
                                                  </div>
                                              </div>

                                              <div class="mmc_flex__item mmc_flex__item--vcenter" *ngIf="upsellSummary.isAdded==true" tabindex="0" (click)="deleteUpsellItem(bookingItemSummary,upsellSummary)" (keydown.enter)="deleteUpsellItem(bookingItemSummary,upsellSummary)">
                                                  <div class="expansion-header-icons">
                                                      <i class="material-icons icon f-size-md curser">
                                                          delete
                                                      </i>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <!--<div class="extra-allocation" *ngIf="(!upsellSummary.bookingItemId) && isGroupByFacilityNameInBookingView" (click)="deletionExtraAllocation(facilitySummary,bookingItemSummary,upsellSummary)">
                                          <button class="mmc-btn-xl--ternary item-w25-mobile mat-button ng-star-inserted" mat-button=""><span class="icon-p2-delete pr5"></span><span class="section-subheading bold">Delete</span></button>
                                      </div>-->

                                    </div>
                                  </div>
                                </div>
                                  <!-- -------------------------------------------------- Mobile View --------------------------------------------------------  -->
                                <div
                                *ngIf="bookingItemSummary.upsellSummaries?.length>0 && (bookingItemSummary?.isExpandedUpsell)">
                                <div class="mmc_expansion__body--row body-row-height extrasMobile"
                                  *ngFor="let upsellSummary of bookingItemSummary.upsellSummaries">
                                  <div class="mmc_flex--container">
                                    <div class="mmc_flex__item mmc_flex__item--vcenter p40">
                                      <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter p10">
                                        <div class="pw-txt-s2 pw-text-trans__upper">
                                          <span>{{upsellSummary.qty}}</span><span>X</span>
                                        </div>
                                      </div>
                                      <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                                        <img class="expanded-row-img--addextra" src="{{apiUrl}}{{upsellSummary.url}}"
                                          alt="{{facilitySummary?.name}}" />
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter p20">
                                        <div class="pw-txt-s2">{{upsellSummary.name}}</div>
                                      </div>
                                    </div>
                                    <!-- <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter p10">
                                                                          <div class="pw-txt-s2 pw-text-trans__upper"><span>{{upsellSummary.qty}}</span><span>X</span></div>
                                                                      </div>
                                                                      <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                                                                          <img class="expanded-row-img--addextra" src="{{apiUrl}}{{upsellSummary.url}}" />
                                                                      </div>

                                                                    <div class="mmc_flex__item mmc_flex__item--vcenter p20">
                                                                        <div class="pw-txt-s2">{{upsellSummary.name}}</div>
                                                                    </div> -->
                                    <div class="extrasBottomContent">
                                      <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p20 cog-d-none">
                                        <div class="pw-txt-s1 pw-text-color__tertiary"
                                          *ngIf="upsellSummary.bookingItemPrices">Rate
                                        </div>
                                        <div class="pw-txt-s3 col-value"
                                          *ngFor="let bookingItemPrice of upsellSummary.bookingItemPrices">
                                          {{bookingItemPrice.rate|CurrencyFormat}} X {{bookingItemPrice.unit}}
                                          {{bookingItemPrice.priceType}}
                                        </div>
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                                        <div class="pw-txt-s2"></div>
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-start p20"
                                        *ngIf="upsellSummary.bookingItemId != undefined">
                                        <div class="pw-txt-s3">{{upsellSummary.totalAmount | CurrencyFormat}}</div>
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-start p20" *ngIf="upsellSummary.bookingItemId == undefined">
                                          <div class="pw-txt-s2" *ngIf="isPriceRange(upsellSummary.minPrice,upsellSummary.maxPrice) && upsellSummary.minPrice?.priceIncludingTax !=0 && upsellSummary.minPrice">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}} - {{upsellSummary.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                          <div class="pw-txt-s2" *ngIf="!(isPriceRange(upsellSummary.minPrice,upsellSummary.maxPrice) && upsellSummary.minPrice?.priceIncludingTax !=0 && upsellSummary.minPrice)">{{upsellSummary.minPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                      </div>

                                      <div class="mmc_flex__item mmc_flex__item--vcenter w90 optionButtonsMobile">
                                          <div class="mmc_flex--container">
                                              <div class="mmc_flex__item mmc_flex__item--vcenter w50" *ngIf="upsellSummary.isAdded==true" tabindex="0" (click)="editExtra(facilitySummary,bookingItemSummary,upsellSummary,booking.priceConcessionId)" (keydown.enter)="editExtra(facilitySummary,bookingItemSummary,upsellSummary,booking.priceConcessionId)">
                                                  <div class="expansion-header-icons">
                                                      <i class="material-icons icon f-size-md curser">
                                                          edit
                                                      </i>
                                                  </div>
                                              </div>

                                              <div class="mmc_flex__item mmc_flex__item--vcenter" *ngIf="upsellSummary.isAdded==true" tabindex="0" (click)="deleteUpsellItem(bookingItemSummary,upsellSummary)" (keydown.enter)="deleteUpsellItem(bookingItemSummary,upsellSummary)">
                                                  <div class="expansion-header-icons">
                                                      <i class="material-icons icon f-size-md curser">
                                                          delete
                                                      </i>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </div>


                                    <!--<div class="extra-allocation" *ngIf="(!upsellSummary.bookingItemId) && isGroupByFacilityNameInBookingView" (click)="deletionExtraAllocation(facilitySummary,bookingItemSummary,upsellSummary)">
                                        <button class="mmc-btn-xl--ternary item-w25-mobile mat-button ng-star-inserted" mat-button=""><span class="icon-p2-delete pr5"></span><span class="section-subheading bold">Delete</span></button>
                                    </div>-->

                                  </div>
                                </div>
                              </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>


                          <mat-paginator #recurrentPaginator [length]="facilitySummary.bookingItemSummaries?.length"
                            [pageSize]="facilitySummary.pageSize" [pageSizeOptions]="pageSizeOptions"
                            [pageIndex]="facilitySummary.pageNumber" (page)="pageclickevent(facilitySummary,$event)">
                          </mat-paginator>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>

          <div class="past-booking-details--card-secondary bond-item-card" *ngIf="booking?.discountItems?.length>0">
            <div class="mmc_flex--container mb-2 align-items-center">
                <h5 class="mmc_flex__item--inherit section-subheading pw-txt-h5">Discount Items</h5>
                <div class="mmc_flex__item"><hr /></div>
            </div>

            <div *ngFor="let discountItem of booking.discountItems; index as i; trackBy: getFacilitySummaryChanges">
              <div class="past-booking-facility--card" *ngIf="checkDiscountItem(discountItem?.item)">
                <div class="cancellation-fee-card">
                  <div class="mmc_flex--container facility-expansion-header--container">
                    <div class="mmc_flex__item mmc_flex__item--vcenter">
                      <div class="mmc_flex__item pw-txt-s2">{{discountItem?.name}}</div>
                    </div>

                    <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter mmc_txt-align--right">
                      <div class="pw-txt-s1 pw-text-color__tertiary">Total</div>
                      <div class="pw-txt-s3 pw-text-bold">{{discountItem?.total | CurrencyFormat}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="past-booking-details--card-secondary " *ngIf="booking?.isHaveCancellationFee">
            <div class="past-booking-facility--card cancellation-fee-card ">
              <div class="mmc_flex--container facility-expansion-header--container">
                <div class="mmc_flex__item mmc_flex__item--vcenter">
                  <div class="mmc_flex__item pw-txt-s2">{{booking?.cancellationFeeName}}</div>
                </div>

                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter mmc_txt-align--right">
                  <div class="pw-txt-s1 pw-text-color__tertiary">Total</div>
                  <div class="pw-txt-s3 pw-text-bold">{{booking?.cancellationFee | CurrencyFormat}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="past-booking-details--card-secondary bond-item-card" *ngIf="isHaveBondItems">
          <div class="mmc_flex--container mb-2 align-items-center">
            <h5 class="mmc_flex__item--inherit section-subheading pw-txt-h5">Bond items</h5>
            <div class="mmc_flex__item">
              <hr />
            </div>
          </div>
          <div class="past-booking-facility--card cancellation-fee-card bond-item-card-panel"
            *ngFor="let bondItem of bondItems">
            <div class="mmc_flex--container facility-expansion-header--container">
              <div class="mmc_flex__item mmc_flex__item--vcenter">
                <div class="mmc_flex__item pw-txt-s2">{{bondItem.name}}</div>
              </div>

              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter mmc_txt-align--right">
                <div class="pw-txt-s1 pw-text-color__tertiary">Total</div>
                <div class="pw-txt-s3 pw-text-bold">{{bondItem.total | CurrencyFormat}}</div>
              </div>
            </div>
          </div>
        </div>

        <h1 class="pw-txt-h3 pw-text-semi-bold section-heading">Payment information</h1>
        <div class="past-booking-details--card-ternary">
          <div class="mmc_flex--container mb-2 align-items-center">
            <h5 class="mmc_flex__item--inherit section-subheading pw-txt-h5">Payment summary</h5>
            <div class="mmc_flex__item">
              <hr />
            </div>
          </div>
          <div class="payment-summery--card">
            <div class="mmc_flex--container payment-summery-wrapper">
              <div class="mmc_flex__item--inherit mmc_fd--col mmc_flex__item--hend mobileItemsAlignCenter">
                <div class="mmc_txt-align--right"><span class="icon-p2-balance pw-text-color__tertiary pr5"></span><span
                    class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Booking balance</span></div>
                <div class="pw-txt-b2 pw-text-semi-bold mmc_txt-align--right">{{(booking.grossAmount -
                  booking.paidAmount) | CurrencyFormat}}</div>
              </div>
              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hend mobileItemsAlignCenter">
                <div class="mmc_txt-align--right"><span class="icon-p2-invoice pw-text-color__tertiary pr5"></span><span
                    class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Total invoiced</span></div>
                <div class="pw-txt-b2 pw-text-semi-bold mmc_txt-align--right">{{booking.invoicedAmount |
                  CurrencyFormat}}</div>
              </div>
              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hend mobileItemsAlignCenter">
                <div class="mmc_txt-align--right"><span class="icon-p2-checked pw-text-color__tertiary pr5"></span><span
                    class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Total paid</span></div>
                <div class="pw-txt-b2 pw-text-semi-bold mmc_txt-align--right">{{booking?.paidAmount | CurrencyFormat}}
                </div>
              </div>
              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hend mobileItemsAlignCenter">
                <div class="mmc_txt-align--right"><span class="icon-p2-bill pw-text-color__tertiary pr5"></span><span
                    class="pw-txt-s1 pw-text-trans__upper pw-text-color__tertiary">Invoice balance</span></div>
                <div class="pw-txt-b2 pw-text-semi-bold mmc_txt-align--right"
                  *ngIf="booking.invoicedAmount>booking.paidAmount">{{(booking.invoicedAmount - booking?.paidAmount) |
                  CurrencyFormat}}</div>
                <div class="pw-txt-b2 pw-text-semi-bold mmc_txt-align--right"
                  *ngIf="!(booking.invoicedAmount>booking.paidAmount)">{{(booking.invoicedAmount -
                  booking?.invoicedAmount) | CurrencyFormat}}</div>
              </div>
            </div>
          </div>

          <!--detailed info-->
          <div class="mmc_flex--container mb-2 align-items-center">
            <h5 class="mmc_flex__item--inherit section-subheading pw-txt-h5">Detailed information</h5>
            <div class="mmc_flex__item">
              <hr />
            </div>
          </div>

          <div class="mmc_expansion__header--row detailed-information-wrapper  mmc_flex--container h-mobile">
            <div class="mmc_flex__item">
              <div class="mmc_flex--container">
                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Invoice</div>
                </div>

                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Due date</div>
                </div>

                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Invoice amount</div>
                </div>

                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Outstanding</div>
                </div>

                <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Status</div>
                </div>

                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Date paid</div>
                </div>

                <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Action</div>
                </div>
              </div>
            </div>
          </div>

          <!--Invoice-->
          <div id="targetBtn" *ngIf="invoices && invoices?.length>0">
            <div *ngFor="let invoice of invoices" class="pb-2">
              <mat-accordion>
                <mat-expansion-panel #invoicePanel [disabled]="true"
                  [expanded]="(invoice?.InvoiceId == selectedInvoiceId)">
                  <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px" aria-hidden="true">
                    <div class="mmc_flex--container">
                      <div class="mmc_flex__item ">
                        <div class="mmc_flex--container mcc-wrap-items ">

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w100-mobile flexMobile">
                            <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Invoice</div>
                            <div class="pw-txt-s3 link-primary" role="link" tabindex="0"
                              (click)="onInvoiceView(invoice)" (keydown.enter)="onInvoiceView(invoice)">
                              {{invoice.InvoiceRef}}</div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile flexMobile">
                            <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Due date</div>
                            <div class="pw-txt-s3">{{invoice.DueDate | dateFormat}}</div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile flexMobile">
                            <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Invoice amount
                            </div>
                            <div class="pw-txt-s3" style="padding: 0 8px;">{{invoice.InvoiceAmount | CurrencyFormat}}
                            </div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile flexMobile">
                            <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Outstanding
                            </div>
                            <div class="pw-txt-s3" style="padding: 0 8px;">{{invoice.Outstanding | CurrencyFormat}}
                            </div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile flexMobile">
                            <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Status</div>
                            <div
                              class="mmc_flex__item--vcenter mmc_flex__item--hcenter secondary_section-title--medium status paid item-w50-mobile"
                              [ngClass]="{'unpaid' : invoice.Status=='Unpaid'}">{{invoice.Status}}</div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile flexMobile">
                            <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Date paid</div>
                            <div class="pw-txt-s3">{{invoice.DatePaid | dateFormat}}</div>
                          </div>

                          <div
                            class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-center item-btn-wrapper p15"
                            *ngIf="PaymentPortalUrl">
                            <div class="btn-wrapper"
                              *ngIf="invoice.Outstanding!=0 && invoice.InvoiceAmount>0 && invoice.Cancelled!=true && !(booking?.cancelled)">
                              <a tabindex="0" (click)="RedirectToPaymentPortal(invoice)"
                                (keydown.enter)="RedirectToPaymentPortal(invoice)">
                                <div class="mmc-btn-secondary--small main mmc_flex__item--vcenter booking-paynow-btn" mat-button
                                  role="button">Pay now</div>
                              </a>
                            </div>
                            <div class="btn-wrapper"
                              *ngIf="invoice.Outstanding<=0 || !invoice.InvoiceAmount>0 || invoice.Cancelled || booking.cancelled">
                              <div class="mmc-btn-secondary--small main mmc_flex__item--vcenter dissabled booking-paynow-btn" role="button"
                                mat-button>Pay now</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter mmc_just-content-end icon__expand p5">
                        <button class="down-arrow" mat-icon-button color="primary" tabindex="0"
                          (click)="expandInvoicePannel(invoice);GetPaymentAllocation(invoice?.InvoiceId);"
                          (keydown.enter)="expandInvoicePannel(invoice);GetPaymentAllocation(invoice?.InvoiceId);"
                          [disableRipple]="true" aria-label="expand arrow">
                          <mat-icon class="material-icons" *ngIf="invoice?.InvoiceAmount!=invoice?.Outstanding">
                            {{(invoice?.InvoiceId == selectedInvoiceId) ?'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                          </mat-icon>
                        </button>
                      </div>
                    </div>

                  </mat-expansion-panel-header>



                  <div
                    *ngIf="invoice?.InvoiceAmount!=invoice?.Outstanding  && (invoice?.InvoiceId == selectedInvoiceId)">
                    <div class="mmc_expansion__body--row-header h-mobile">
                      <div *ngIf="invoice?.payments?.length>0" class="mmc_flex--container">


                        <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                          <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Payment method</div>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                          <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Amount</div>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                          <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Payment reference</div>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                          <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Authorization code</div>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                          <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Date paid</div>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter p25">
                          <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Payment notes</div>
                        </div>

                      </div>


                      <div *ngIf="!invoice?.payments || invoice?.payments?.length==0">
                        <h3 class="text-center">No payments to be displayed</h3>
                      </div>
                    </div>
                    <div *ngIf="invoice?.paymentLoadStatus==2" class="loader2"></div>

                    <div class="mmc_expansion__body--row detailed-information-expansion-body"
                      *ngFor="let payment of invoice.payments">

                      <div class="mmc_flex--container mcc-wrap-items bookingWeb">
                        <div class="mmc_flex__item mmc_flex__item--vcenter p15 bookingMobile">
                          <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Payment method</div>
                          <div class="pw-txt-s3">{{payment.source?.type}}</div>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter p15 bookingMobile">
                          <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Amount</div>
                          <div class="pw-txt-s3">{{payment.source?.paidAmount | CurrencyFormat}}</div>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter p15 bookingMobile">
                          <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Payment reference</div>
                          <div class="pw-txt-s3">{{payment.source?.paymentRef}}</div>
                        </div>

                        <div class="mmc_flex__item mmc_flex__item--vcenter p15 bookingMobile">
                          <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Authorization Code</div>
                          <div class="pw-txt-s3" *ngIf="payment.source && payment.source.authorisationCode">
                            {{payment.source?.authorisationCode}}</div>
                        </div>

                        <div class="mmc_flex__item mmc_flex__item--vcenter p15 bookingMobile">
                          <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Date paid</div>
                          <div class="pw-txt-s3">{{payment.source?.paymentDate | dateFormat}}</div>
                        </div>

                        <div class="mmc_flex__item mmc_flex__item--vcenter p25 bookingMobile">
                          <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Payment notes</div>
                          <div class="pw-txt-s3">{{payment.source?.paymentNote}}</div>
                        </div>
                      </div>
                    </div>
                  </div>


                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>







          <div class="mmc_expansion__header--row detailed-information-wrapper mmc_flex--container h-mobile"
            *ngIf="booking?.adminfeeItems && booking?.adminfeeItems?.length>0">
            <div class="mmc_flex__item">
              <div class="mmc_flex--container">
                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p80">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Name</div>
                </div>
                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 pl-30">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Total amount</div>
                </div>
              </div>
            </div>
          </div>

          <!--Invoice-->
          <div id="targetBtn" *ngIf="booking?.adminfeeItems && booking?.adminfeeItems?.length>0">
            <div *ngFor="let adminItem of booking?.adminfeeItems">
              <mat-accordion>
                <mat-expansion-panel #invoicePanel [disabled]="true" [expanded]="true">
                  <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px" aria-hidden="true">
                    <div class="mmc_flex--container">
                      <div class="mmc_flex__item mt-2">
                        <div class="mmc_flex--container mcc-wrap-items">

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p80 item-w100-mobile flexMobile">
                            <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Name</div>
                            <div class="pw-txt-s3">{{adminItem.name}}</div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile pl-60 flexMobile">
                            <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Amount</div>
                            <div class="pw-txt-s3">{{adminItem.total| CurrencyFormat}}</div>
                          </div>
                        </div>
                      </div>

                      <!--<div class="mmc_flex__item--inherit mmc_flex__item--vcenter mmc_just-content-end icon__expand p5">
                          <button class="down-arrow" mat-icon-button color="primary" [disableRipple]="true">
                              <mat-icon class="material-icons" *ngIf="invoice.payments && invoice.payments.length>0">-->
                      <!--{{(invoice?.InvoiceId == selectedInvoiceId) ?'keyboard_arrow_up' : 'keyboard_arrow_down'}}-->
                      <!--</mat-icon>
                              </button>
                          </div>-->
                    </div>
                  </mat-expansion-panel-header>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>

        </div>

        <h1 class="pw-txt-h3 pw-text-semi-bold section-heading">Request and messages</h1>
        <div class="past-booking-details--card-ternary">

          <div *ngIf="booking.notes.length === 0">There aren't any messages to be displayed</div>
          <!--<div class="mmc_flex--container mb-2 align-items-center">
          <div class="mmc_flex__item--inherit section-subheading pw-txt-h5">Payment Summary</div>
          <div class="mmc_flex__item"><hr /></div>
        </div>-->
          <div class="payment-summery--card"
            *ngFor="let note of booking.notes;first as isFirst index as i;trackBy: trackByFn">
            <div class="mmc_flex--container payment-summery-wrapper">
              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter booking-payment-card-one">
                <div class="mmc_txt-align pw-txt-s1 pw-text-trans__upper pw-text-color__primary">{{note.noteType}}</div>
              </div>
              <div class="mmc_flex__item mmc_fd--col msg-status booking-payment-card">
                <div class="mmc_txt-align pw-txt-b2 pw-text-semi-bold pw-text-trans__upper pw-text-color__primary">Sent
                </div>
                <div class="pw-txt-s1 pw-text-color__tertiary" *ngIf="note.auditInfo">{{note.auditInfo.insertedTime |
                  dateFormat}}</div>
              </div>
              <div class="mmc_flex__item mmc_fd--col msg-content booking-payment-card">
                <div class="mmc_txt-align mt-3 mt-md-0 pw-txt-b2 pw-text-semi-bold pw-text-trans__upper pw-text-color__primary">
                  Message</div>
                <div class="pw-txt-s1 pw-text-color__tertiary">{{note.plainText}}</div>
              </div>
            </div>
          </div>
        </div>


        <!--<div class="past-booking-details--card-fourth">
              <div class="mmc_flex--container mb-2 align-items-center">
                  <div class="mmc_flex__item--inherit section-subheading pw-txt-h5">Detailed Information</div>
                  <div class="mmc_flex__item"><hr /></div>
              </div>

              <div class="mmc_expansion__header--row mmc_flex--container h-mobile">
                  <div class="mmc_flex__item">
                      <div class="mmc_flex--container">
                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Invoice</div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Due Date</div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p10">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Invoice Amount</div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Outstanding</div>
                          </div>

                          <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Status</div>
                          </div>

                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Date paid</div>
                          </div>

                          <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter p15">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Action</div>
                          </div>
                      </div>
                  </div>
              </div>


                <div id="targetBtn" *ngIf="invoices && invoices.length>0">
                    <div *ngFor="let invoice of invoices">
                        <mat-accordion>
                            <mat-expansion-panel #invoicePanel [disabled]="true" [expanded]="(invoice?.InvoiceId == selectedInvoiceId)">
                                <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px">
                                    <div class="mmc_flex--container">
                                        <div class="mmc_flex__item ">
                                            <div class="mmc_flex--container mcc-wrap-items">

                                              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w100-mobile">
                                                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Invoice</div>
                                                  <div class="pw-txt-s3 link-primary" (click)="onInvoiceView(invoice)">{{invoice.InvoiceRef}}</div>
                                              </div>

                                              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile">
                                                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Due Date</div>
                                                  <div class="pw-txt-s3">{{invoice.DueDate | dateFormat}}</div>
                                              </div>

                                              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p10 item-w50-mobile">
                                                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Invoice Amount</div>
                                                  <div class="pw-txt-s3">{{invoice.InvoiceAmount | CurrencyFormat}}</div>
                                              </div>

                                              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile">
                                                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Outstanding</div>
                                                  <div class="pw-txt-s3">{{invoice.Outstanding | CurrencyFormat}}</div>
                                              </div>

                                              <div class="mmc_flex__item mmc_flex__item--vcenter p10 item-w50-mobile">
                                                  <div class="mmc_flex__item--vcenter mmc_flex__item--hcenter secondary_section-title--medium status paid item-w50-mobile" [ngClass]="{'unpaid' : invoice.Status=='Unpaid'}">{{invoice.Status}}</div>
                                              </div>

                                              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile">
                                                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Date paid</div>
                                                  <div class="pw-txt-s3">{{invoice.DatePaid | dateFormat}}</div>
                                              </div>

                                              <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-center item-btn-wrapper p15">
                                                  <div class="btn-wrapper" *ngIf="invoice.Outstanding!=0 && invoice.InvoiceAmount>0 && invoice.Cancelled!=true">
                                                      <a href="payment?InvoiceRef={{invoice.InvoiceRef}}"> <div class="mmc-btn-secondary--small main mmc_flex__item--vcenter" mat-button>Pay now</div></a>
                                                  </div>
                                                  <div class="btn-wrapper" *ngIf="!(invoice.Outstanding!=0 && invoice.InvoiceAmount>0 && invoice.Cancelled!=true)">
                                                      <div class="mmc-btn-secondary--small main mmc_flex__item--vcenter dissabled" mat-button>Pay now</div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter mmc_just-content-end icon__expand p5">
                                          <button class="down-arrow" mat-icon-button color="primary" (click)="expandInvoicePannel(invoice)" [disableRipple]="true">
                                              <mat-icon class="material-icons" *ngIf="invoice.payments && invoice.payments.length>0">
                                                  {{(invoice?.InvoiceId == selectedInvoiceId) ?'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                                              </mat-icon>
                                          </button>
                                      </div>
                                  </div>
                              </mat-expansion-panel-header>
                              <div *ngIf="invoice.payments && invoice.payments.length>0 && (invoice?.InvoiceId == selectedInvoiceId)">
                                  <div class="mmc_expansion__body--row-header h-mobile">
                                      <div class="mmc_flex--container">
                                          <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Payment Method</div>
                                          </div>
                                          <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Amount</div>
                                          </div>
                                          <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Payment Reference</div>
                                          </div>
                                          <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Authorization Code</div>
                                          </div>
                                          <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Date Paid</div>
                                          </div>
                                          <div class="mmc_flex__item mmc_flex__item--vcenter p25">
                                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Payment Notes</div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="mmc_expansion__body--row detailed-information-expansion-body" *ngFor="let payment of invoice.payments">

                                      <div class="mmc_flex--container mcc-wrap-items">
                                          <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Payment Method</div>
                                              <div class="pw-txt-s3">{{payment.source?.type}}</div>
                                          </div>
                                          <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Amount</div>
                                              <div class="pw-txt-s3">{{payment.source?.paidAmount | CurrencyFormat}}</div>
                                          </div>
                                          <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Payment Reference</div>
                                              <div class="pw-txt-s3">{{payment.source?.paymentRef}}</div>
                                          </div>

                                          <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Authorization Code</div>
                                              <div class="pw-txt-s3" *ngIf="payment.source && payment.source.authorisationCode">{{payment.source?.authorisationCode}}</div>
                                          </div>

                                          <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p15">
                                              <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Date Paid</div>
                                              <div class="pw-txt-s3">{{payment.source?.paymentDate | dateFormat}}</div>
                                          </div>

                                          <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p25">
                                              <div class="pw-txt-s1 pw-text-color__tertiary d-mobile">Payment Notes</div>
                                              <div class="pw-txt-s3">{{payment.source?.paymentNote}}</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>


                          </mat-expansion-panel>
                      </mat-accordion>
                  </div>
              </div>







              <div class="mmc_expansion__header--row mmc_flex--container h-mobile" *ngIf="booking?.adminfeeItems && booking?.adminfeeItems.length>0">

                  <div class="mmc_flex__item">
                      <div class="mmc_flex--container">
                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p80">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Name</div>
                          </div>
                          <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 pl-30">
                              <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">Total Amount</div>
                          </div>
                      </div>
                  </div>
              </div>


                <div id="targetBtn" *ngIf="booking?.adminfeeItems && booking?.adminfeeItems.length>0">
                    <div *ngFor="let adminItem of booking?.adminfeeItems">
                        <mat-accordion>
                            <mat-expansion-panel #invoicePanel [disabled]="true" [expanded]="true">
                                <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px">
                                    <div class="mmc_flex--container">
                                        <div class="mmc_flex__item ">
                                            <div class="mmc_flex--container mcc-wrap-items">

                                              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p80 item-w100-mobile">
                                                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Name</div>
                                                  <div class="pw-txt-s3">{{adminItem.name}}</div>
                                              </div>

                                              <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter p15 item-w50-mobile pl-60">
                                                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">Amount</div>
                                                  <div class="pw-txt-s3">{{adminItem.total| CurrencyFormat}}</div>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                              </mat-expansion-panel-header>
                          </mat-expansion-panel>
                      </mat-accordion>
                  </div>
              </div>

          </div>-->







      </div>
      <div class="past-booking-details booking-document-upload gray-bg" id="targetFileBtn"
        *ngIf="!(booking?.isDisableBookingDocumentUpload  && (booking.documents== undefined ||booking.documents?.length==0))">
        <div class="container">
          <h1 class="pw-txt-h3  upload-doc-heading mmc_txt-align--center"
            *ngIf="!booking?.isDisableBookingDocumentUpload"><span class="pw-text-color__quinary">Upload </span>your
            documents (maximum file size {{documentUpldSize}})</h1>

          <div class="wp_flex--container wp-mb-10 document-select-dropdown" *ngIf="booking?.bookingTypeId>1">
            <div class="wp_flex__item--inherit wp-pr-10">
              <div class="wp-txt-body1">
                Select document type:
              </div>
            </div>
            <div class="wp_flex__item--inherit wp_flex__item--vcenter">
              <mat-form-field>
                <mat-select placeholder="Document Type" [(ngModel)]="selecteddocumentTemplateCategory"
                  name="selecteddocumentTemplateCategory">
                  <mat-option *ngFor="let documentTemplateCategory of documentTemplateCategories"
                    [value]="documentTemplateCategory.Id">
                    {{documentTemplateCategory?.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="file-upload--card" *ngIf="!booking?.isDisableBookingDocumentUpload">
            <input id="fileupload" type="file" (change)="onFileChanged($event)" #fileInput style="display:none"
              #fileInputTag aria-hidden="true">
            <file-drop class="file-drop" headertext="" (onFileDrop)="dropped($event)" showBrowseBtn="true"
              browseBtnLabel="Select a file from your computer">
              <div class="mmc_flex__item--vcenter" tabindex="0"
                onclick="$('#fileupload').trigger('click'); return false;">
                <div class="mmc_flex--container">
                  <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                    <i class="material-icons pw-ico-color">insert_drive_file</i>
                  </div>
                </div>
              </div>
              <div class="mmc_flex__item--vcenter doc-up-text--mobile d-mobile">
                <span class="link-primary" role="link" tabindex="0"
                  onclick="$('#fileupload').trigger('click'); return false;">Select a file from your device</span>
              </div>
              <div class="mmc_flex__item--vcenter doc-up-text--desktop h-mobile">
                <span class="pw-txt-b1 pr-2 pw-text-color__tertiary">Drag your files here or</span>
                <span class="link-primary" role="link" tabindex="0"
                  onclick="$('#fileupload').trigger('click'); return false;">select a file from your device</span>
              </div>
            </file-drop>
          </div>
          <div class="uploaded-file-list--card"
            *ngIf="booking && ((booking.documents && booking.documents?.length>0) || selectedFiles?.length>0) ">
            <div *ngIf="booking.documents">
              <div class="file-list-row" *ngFor="let document of booking.documents">
                <div class="mmc_flex--container">
                  <div class="mmc_flex__item--inherit icon-col">
                    <span class="material-icons">file_copy</span>
                  </div>
                  <div class="mmc_flex__item label-col">
                    <div class="mmc_flex--container mmc_fd--col d-flex justify-content-center">
                      <span class="font__normal">{{document?.name}}</span>
                    </div>
                  </div>
                  <div class="mmc_flex__item--inherit download-col">
                    <a class="text-center" (click)="downloadDocument(document?.documentFileURL,document?.name)"
                       *ngIf="document?.documentFileURL" >
                      <span class="material-icons font__gray">cloud_download</span>Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedFiles?.length>0">
              <div class="file-list-row" *ngFor="let item of selectedFiles; let i=index">
                <div class="mmc_flex--container">
                  <div class="mmc_flex__item--inherit icon-col">
                    <span class="material-icons">file_copy</span>
                  </div>
                  <div class="mmc_flex__item label-col">
                    <div class="mmc_flex--container mmc_fd--col">
                      <span class="font__normal">{{ item?.name }}</span>
                      <span class="font__small font__gray">{{item?.fileSize}}</span>
                    </div>
                  </div>
                  <div class="mmc_flex__item--inherit download-col">
                    <a class="mat-download " [href]="get64Stringdata(item)" download="{{item?.name}}">
                      <span class="material-icons font__gray">cloud_download</span> Download
                    </a>
                  </div>
                  <div class="mmc_flex__item--inherit download-col">
                    <a><i class="material-icons font__gray" tabindex="0" (click)="removeFile(i)"
                        (keydown.enter)="removeFile(i)">close</i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-12 col-md-offset-3" *ngIf="changeRequestNotes.length > 0 || selectedFiles.length > 0 || ((!booking?.isDisableBookingEdit) && isUpcommingBooking && isEnableUpdateButton)">
            <div class="col-md-12 alert success--btn pw-text-medium" ><!--*ngIf="isUpcommingBooking"> -->
                Please click<span class="pw-text-semi-bold px-1">&#34;Update Booking&#34;</span>to save your booking changes.
            </div>
        </div>




          <button *ngIf="!booking?.isHideRequestBookingCancelBtn" tabindex="0" (click)="cancelBooking(booking)"
            (keydown.enter)="cancelBooking(booking)" class="btn-cancel-booking " mat-button><span
              class="icon-p2-delete pr5"></span><span class="section-subheading bold">Request
              cancellation</span></button>
        </div>
        <!--<div *ngIf="(showLoader)">
                <opt-mmc-loader></opt-mmc-loader>
            </div>-->
      </div>

    </div>

    <!--<ng-template #bookingDetailSpinner>
        <div class="container" style="min-height:400px">
            <opt-mmc-loader></opt-mmc-loader>
        </div>
    </ng-template>-->
  </div>
</main>
