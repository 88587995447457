import { BaseResource } from '../base-resource';

export class Notes extends BaseResource {
    constructor() {
        super();
        this.type = "note";
    }

    plainText: string;
    entityTypeID: number;
    entityType: string;
    noteTypeId: number;
    noteType: string;
    facilityName: string;
    bookingType: string;
}