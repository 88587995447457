import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { OptFormModules } from "src/modules/shared/form.modules";
import { OptCommonModules } from "src/modules/shared/common.modules";


import { OptHttpProvider } from "../../services/base/api-request-handler";
import { CustomerService } from "../../services/customer.service";
import { QASIntegrationService } from "../../services/qas-integration.service";

import {
  StoreModule,
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
} from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { statusReducer } from "src/modules/store/loading/reducer";
import { LoadingActions } from "src/modules/store/loading/actions";
import { cartReducer } from "../../store/cart-reducer";
import { AppState } from "../../store/cart";
import { EffectsModule } from "@ngrx/effects";
import { LoginEffects } from "../../store/effects/login-effects";

// File Drop
import { FileDropModule } from "ngx-file-drop";

import { PBReducer } from "../../store/public-web/public-web-reducers";

import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";

import { B2CLoginComponent } from '../b2c-login/b2c-login.component';

const reducers: ActionReducerMap<AppState> = {
  cart: cartReducer,
  loader: statusReducer,
  PBReducer: PBReducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["cart", "PBReducer"],
    rehydrate: true,
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
//translation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "../dist/assets/i18n/", ".json");
}
let _window: any = window;


@NgModule({
  imports: [
    OptCommonModules,
    OptFormModules,

    StoreModule.forFeature('b2c-login', reducers, { metaReducers }),
    EffectsModule.forFeature([LoginEffects]),

  ],
  declarations: [
    B2CLoginComponent,
  ],
  providers: [OptHttpProvider],
  exports: [
    B2CLoginComponent
  ]
})
export class B2CSubModule { }
