import {
    Component, OnInit, OnDestroy
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Facility } from 'src/modules/models/asset/facility';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { ItemSearchParams } from 'src/modules/models/item/item-search-params';
import { environment } from 'src/environments/environment';

import { AssetService } from 'src/modules/services/asset.service';
import { ItemService } from 'src/modules/services/item.service';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';

import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { Utility } from 'src/modules/utility';

import { Observable } from "rxjs";
import { Store, ICart, AddFacility, PackageSearch } from 'src/modules/store/index';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { FacilitySearchLoading, FacilitySearchLoaded, BookingSaveStarted } from 'src/modules/store/loading/actions';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { CalenderTimeslot, Timeslot } from 'src/modules/models/asset/timeslots';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { Booking } from 'src/modules/models/client/client';
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: { dateInput: 'LL' },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-facility-detail',
    templateUrl: './facility-detail.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})

export class FacilityDetailComponent implements OnInit, OnDestroy {
    cart$: Observable<ICart>;
    cart_subscriber: any;
    loader$: Observable<ILoadingStatus>;
    facilityDetail: Facility;
    venueFacilities: Facility[];
    webAPiBaseUrl: string;
    facilityId: string;
    startDate: string;
    endDate: string;
    isAvailable: boolean = false;
    isShowVirtualTour: boolean = false;
    searchFacilities: FacilitySummary[];
    isSummaryPopulated: boolean = false;
    attendees: number;
    time: string;
    isTimeAny: boolean;
    isTimeAnyAndChanged: boolean;
    dateInUrl;
    lat: number;
    lng: number;
    zoom: number = 17;
    youtubeUrl: SafeResourceUrl;
    isFacilityDetailLoaded: boolean = false;
    salesChannelId: number = environment.SalesChannel;
    selectedClass: number;
    packageFilter: any;
    addedFacilites: any;
    viewOnly;
    bookingTypeId: any;
    isAllImmediateConfirmed: boolean;
    isLoggedIn: boolean;
    isSystemAllowedImmediateConfirmation: boolean;
    systemDefaultPriceConsessionId: number;
    customerDefaultPriceConsessionId: number;
    isEnableTimeLineAdvancedMode: boolean;
    bookingDetails: Booking;
    constructor(private _assetService: AssetService, private _itemService: ItemService, private route: ActivatedRoute, private scrollTopService: ScrollTopService,
        private store: Store<any>, private router: Router, private sanitizer: DomSanitizer) {
        this.webAPiBaseUrl = environment.WebApiUrl;
        this.facilityDetail = new Facility();
        this.venueFacilities = [];
        this.cart$ = this.store.select('cart');
        this.isSummaryPopulated = false;
        this.loader$ = this.store.select('loader');
        this.scrollTopService.setScrollTop();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;
        this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
        this.isEnableTimeLineAdvancedMode = environment.CheckAvailability.EnableTimeLineAdvancedMode;
        this.cart_subscriber = this.cart$.subscribe(state => {
            this.packageFilter = state.packageFilter;
            this.addedFacilites = state.addedFacilites;
            this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
            console.log("MMC CART this.isLoggedIn", this.isLoggedIn)
            this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? (state.contact.client.priceConcessionId != "") ? state.contact.client.priceConcessionId : 0 : 0;
            this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
            this.bookingDetails = state.booking;
        });
    }

    ngOnInit() {
        this.isTimeAny = false;
        this.isTimeAnyAndChanged = true;


        this.route.queryParamMap.subscribe((invariantParams: Params) => {
            //const params = this.toLowerParams(invariantParams.params);

            //this.facilityId = params.get("assetid");
            //this.startDate = params.get("startdate");
            //this.endDate = params.get("enddate");
            //this.attendees = +params.get("attendees");
            //this.time = params.get("time");
            //this.dateInUrl = params.get("date");

            //this.dateInUrl = new Date(this.dateInUrl);
            //this.dateInUrl.setHours(0, 0, 0);

            this.facilityId = invariantParams.get("AssetId");
            if (this.facilityId == null) {
                this.facilityId = invariantParams.get("assetid");
            }
            this.startDate = invariantParams.get("StartDate");
            if (this.startDate == null) {
                this.startDate = invariantParams.get("startdate");
            }
            this.endDate = invariantParams.get("EndDate");
            if (this.endDate == null) {
                this.endDate = invariantParams.get("enddate");
            }
            this.attendees = +invariantParams.get("Attendees");
            if (this.attendees == null) {
                this.attendees = +invariantParams.get("attendees");
            }
            this.time = invariantParams.get("Time");
            if (this.time == null) {
                this.time = invariantParams.get("time");
            }
            this.dateInUrl = invariantParams.get("Date");
            if (this.dateInUrl == null) {
                this.dateInUrl = invariantParams.get("date");
              if (this.dateInUrl == null) {
                var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
                this.dateInUrl = moment().add(leadDays, 'days').toDate();
              }
            }
            this.dateInUrl = new Date(this.dateInUrl);
            this.dateInUrl.setHours(0, 0, 0);

            this.viewOnly = invariantParams.get("ViewOnly");
            if (this.viewOnly == null) {
                this.viewOnly = invariantParams.get("viewOnly");
            }
            this.bookingTypeId = invariantParams.get("bookingTypeId");
            if (this.bookingTypeId == null) {
                this.bookingTypeId = invariantParams.get("bookingTypeId");
            }
        });

        if (!(this.startDate && this.endDate)) {
            var CurrentDate = new Date();
            var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
            CurrentDate.setDate(CurrentDate.getDate() + leadDays)

            let startTime = Utility.roundToMinDuration(CurrentDate, environment.MinimumBookingDuration).newDate;
            if (this.dateInUrl && this.dateInUrl > startTime) {
                startTime.setDate(this.dateInUrl.getDate());
                startTime.setMonth(this.dateInUrl.getMonth());
                startTime.setFullYear(this.dateInUrl.getFullYear());
            }

            this.startDate = Utility.convertToISO(startTime);
            let EndTime = moment(startTime).add(environment.MinimumBookingDuration, 'm').toDate();
            this.endDate = Utility.convertToISO(EndTime);
        }
        if (this.time == 'Any') {
            this.isTimeAny = true;
            this.isTimeAnyAndChanged = false;
        }
        //   this.store.dispatch(new FacilitySearchLoading());
        this.store.dispatch(new PackageSearch());

        this._assetService.facilityDetail(this.facilityId, this.salesChannelId).subscribe(data => {
            this.facilityDetail = data;
            this.isFacilityDetailLoaded = true;

            if ((this.facilityDetail.latitude != undefined || this.facilityDetail.latitude != "")
                && (this.facilityDetail.longitude != undefined || this.facilityDetail.longitude != "")) {

                this.lng = +this.facilityDetail.longitude;
                this.lat = +this.facilityDetail.latitude;
            }
            else {
                this.lng = +this.facilityDetail.venue.longitude;
                this.lat = +this.facilityDetail.venue.latitude;
            }
            if (this.facilityDetail.url)
                this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.facilityDetail.url.replace('watch?v=', 'embed/'));

            this.populateFacilitySummary();

            this._assetService.facilitySearchByVenue(this.facilityDetail.venue.id, this.salesChannelId, 1, 1, "Facility.Amenities,Facility.configurations" ).subscribe(faclitydata => {
                    let index = faclitydata.findIndex(x => x.id == this.facilityDetail.id);
                    if (index != undefined) {
                        faclitydata.splice(index, 1);
                    }
                    this.venueFacilities = faclitydata;

                    var res = this._assetService.getVenueRoomsImages(faclitydata);
                    if (res != null) {
                        var resPromise = res.toPromise();
                        resPromise.then(data => {
                            this.venueFacilities = data;
                        });
                    }
                });

        },
            err => { },
            () => {
                this.scrollTopService.scrollPageTop();
                //    this.store.dispatch(new FacilitySearchLoaded());
            },
        );
    }

    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
    }

    toLowerParams(params: Params): Params {
        const lowerParams: Params = {};
        for (const key in params) {
            lowerParams[key.toLowerCase()] = params[key];
        }
        return lowerParams;
    }

    populateFacilitySummary() {
        var priceConsessions = [];
        if (this.addedFacilites != null && this.addedFacilites != undefined && this.addedFacilites.length > 0) {
            if (this.isLoggedIn && this.customerDefaultPriceConsessionId != 0) {
                priceConsessions.push(this.customerDefaultPriceConsessionId)
            } else if (this.packageFilter != undefined && this.packageFilter.PackageClass != null && this.packageFilter.PackageClass != undefined && this.packageFilter.PackageClass != "0") {
                let isPriceConcessionAvailable = FacilityAdaptor.isPriceConcessionAvailable(this.addedFacilites, this.packageFilter.PackageClass);
                if (isPriceConcessionAvailable.isHasValidMinPriceConcession) priceConsessions.push(isPriceConcessionAvailable.PriceMinConsessionId);
                if (isPriceConcessionAvailable.isHasValidMaxPriceConcession) priceConsessions.push(isPriceConcessionAvailable.PriceMaxConsessionId);
            }
        }

        let facilitySummary = new FacilitySummary();
        facilitySummary.id = this.facilityDetail.id;
        facilitySummary.name = this.facilityDetail.name;
        facilitySummary.type = this.facilityDetail.type;
        facilitySummary.tid = this.facilityDetail.tid;
        facilitySummary.description = this.facilityDetail.description;
        facilitySummary.directions = this.facilityDetail.directions;
        facilitySummary.venue = this.facilityDetail.venue;
        facilitySummary.capacity = this.facilityDetail.capacity;
        facilitySummary.attendees = this.attendees ? this.attendees : 0;
        facilitySummary.configurations = [];
        facilitySummary.configurations = this.facilityDetail.configurations;
        facilitySummary.amenities = this.facilityDetail.amenities;
        facilitySummary.items = this.facilityDetail.items;
        facilitySummary.venue = this.facilityDetail.venue;
        facilitySummary.capacity = this.facilityDetail.capacity;
        facilitySummary.openingTime = this.facilityDetail.openingTime;
        facilitySummary.closingTime = this.facilityDetail.closingTime;
        facilitySummary.facilityTypeId = this.facilityDetail.facilityTypeId;

        this.searchFacilities = [];
        this.searchFacilities.push(facilitySummary);

        if (this.facilityDetail.items.length > 0) {
            let itemsearch = new ItemSearchParams();
            itemsearch.ItemTypeid = 1;
            itemsearch.itemidlist = this.facilityDetail.items.map(i => +i.id);
            itemsearch.FromDate = Utility.convertToISO(Utility.convertISOToDate(this.startDate));
            itemsearch.Todate = Utility.convertToISO(Utility.convertISOToDate(this.endDate));
            itemsearch.salesChannelId = environment.SalesChannel;

            this._itemService.itemSearch(itemsearch).subscribe((data) => {

                this.searchFacilities[0].items = data;
                this.cart_subscriber = this.cart$.subscribe(cart => {
                    this.selectedClass = (cart.packageFilter != undefined && cart.packageFilter != null && !isNaN(cart.packageFilter.PackageClass)) ? cart.packageFilter.PackageClass : environment.DefaultPackageClassId;
                    if (!this.isSummaryPopulated) {
                      FacilityAdaptor.PopulateFacilitySummary(this.searchFacilities, data, this.selectedClass, FacilityAdaptor.getBookingPriceConcession(this.customerDefaultPriceConsessionId, false), (this.attendees ? this.attendees:0));

                        this.isSummaryPopulated = true;
                        this.validateAvailability(this.startDate, this.endDate);
                    }
                });
            });
        }
    }

    getSerchbaleDatefomTime(SeletedDate: Date, selectedTime: Date) {
        let searchabledate = new Date(SeletedDate.getFullYear(), SeletedDate.getMonth(), SeletedDate.getDate(), selectedTime.getHours(), selectedTime.getMinutes(), selectedTime.getSeconds(), 0);
        return Utility.convertToISO(searchabledate);
    }

    bookingEnquiry(val) {
        //  this.store.dispatch(new BookingSaveStarted());

        if (this.searchFacilities.length > 0) {
            this.searchFacilities[0].calenderTimeslots = val.timeslots;
            this.searchFacilities[0].timeslotLoaded = true;

            if (this.searchFacilities[0].startTime == undefined)
                this.searchFacilities[0].startTime = this.startDate;

            if (this.searchFacilities[0].endTime == undefined)
                this.searchFacilities[0].endTime = this.endDate;

            if (environment.PriceDisplayOption == 3) {
                //  FacilityAdaptor.populateItemPrice(this.searchFacilities[0], this.selectedClass);
                this.getItemPrices(this.searchFacilities);
            }
            this.store.dispatch(new AddFacility(this.searchFacilities[0]));
        }
    }

    checkAvailablity(val) {
        let sDate = new Date(val.date);
        let sTime = new Date(val.startTime);
        let eTime = new Date(val.endTime);

        this.isTimeAnyAndChanged = true;
        this.validateAvailability(this.getSerchbaleDatefomTime(val.date, sTime), this.getSerchbaleDatefomTime(val.date, eTime));
    }

    validateAvailability(startTime, endTime) {
        if (this.facilityDetail.items.length > 0 && this.searchFacilities[0].item) {
            this.startDate = startTime;
            this.endDate = endTime;
            if (this.searchFacilities.length > 0) {
                this.searchFacilities[0].startTime = this.startDate;
                this.searchFacilities[0].endTime = this.endDate;
            }

            var TimeWithBumpIn;
            var TimeWithBumpOut
            var startTimeN = startTime;
            var endTimeN = endTime;

            if (this.searchFacilities != undefined) {
                if (this.searchFacilities.length > 0 && this.searchFacilities[0] != undefined) {
                    if (this.searchFacilities[0].configuration != undefined) {
                        if (this.searchFacilities[0].configuration.setupTime != undefined) {
                            startTimeN = Utility.convertISOToDate(startTime);
                            startTimeN.setMinutes(startTimeN.getMinutes() - this.searchFacilities[0].configuration.setupTime);
                            startTimeN = Utility.convertToISO(startTimeN);
                        }

                        if (this.searchFacilities[0].configuration.teardownTime != undefined) {
                            endTimeN = Utility.convertISOToDate(endTime);
                            endTimeN.setMinutes(endTimeN.getMinutes() + this.searchFacilities[0].configuration.teardownTime);
                            endTimeN = Utility.convertToISO(endTimeN);
                        }
                    }
                }
            }

            if (environment.PriceDisplayOption == 3) {
                //   FacilityAdaptor.populateItemPrice(this.searchFacilities[0].item, this.searchFacilities[0], this.selectedClass);
                this.getItemPrices(this.searchFacilities);
            }

            if (this.isTimeAnyAndChanged) {
                this._assetService.facilityAvailablity(this.facilityId, startTimeN, endTimeN).subscribe((data: Timeslot[]) => {
                    if (!environment.CheckAvailability.DisplayPreliminarySlotAsUnavailble) {
                        data = data.filter(s => !s.preliminary);
                    }
                    this.isAvailable = (data.length < 1);
                });
            }
        } else {
            this.isAvailable = false;
        }
    }

    downloadDocument(name, path) {
        if (path != undefined && path != null) {
            let arr = path.split('/');
            let fileFullName = arr[arr.length - 1];
            let fileExtension = fileFullName.split('.');
            return name + "." + fileExtension[fileExtension.length - 1];
        }
    }

    changeAvailbleStatus(val) {
        this.isAvailable = val;
    }

    getItemPrices(facilitySummaries: FacilitySummary[]) {
        // //   this.store.dispatch(new BookingSaveStarted());
        let priceConsession = { minConsessionId: this.systemDefaultPriceConsessionId, maxConsessionId: 0 };

        if (environment.PriceDisplayOption == 3 && (!this.isLoggedIn || (this.isLoggedIn && (this.customerDefaultPriceConsessionId == 0 || this.customerDefaultPriceConsessionId == null)))) {
            let selectedPackageClass = environment.PackageClasses.find(a => a.Id == this.selectedClass);
            if (selectedPackageClass.PriceMinConsessionId != "" && selectedPackageClass.PriceMaxConsessionId != "") {
                priceConsession.minConsessionId = selectedPackageClass.PriceMinConsessionId;
                priceConsession.maxConsessionId = selectedPackageClass.PriceMaxConsessionId;
            }
        }

        let request = [];
        let _priceConcession = (this.isLoggedIn && (this.customerDefaultPriceConsessionId != 0 && this.customerDefaultPriceConsessionId != null)) ? this.customerDefaultPriceConsessionId : (this.systemDefaultPriceConsessionId > 0) ? this.systemDefaultPriceConsessionId : 0;
        facilitySummaries.forEach((facilitySummary, index) => {
            if (facilitySummary.item) {
                let item = {
                    index: index,
                    itemId: facilitySummary.item.id,
                    //minPriceConcessionId: (this.isLoggedIn && (this.customerDefaultPriceConsessionId != 0 && this.customerDefaultPriceConsessionId != null)) ? +this.customerDefaultPriceConsessionId : (facilitySummary.item.allowImmediateConfirmedBookings == 1 && this.isSystemAllowedImmediateConfirmation) ? +this.systemDefaultPriceConsessionId : +priceConsession.minConsessionId,
                    //maxPriceConcessionId: (facilitySummary.item.allowImmediateConfirmedBookings == 1 && this.isSystemAllowedImmediateConfirmation) ? 0 : +priceConsession.maxConsessionId,
                    minPriceConcessionId: _priceConcession > 0 ? +_priceConcession : +priceConsession.minConsessionId,
                    maxPriceConcessionId: _priceConcession > 0 ? +_priceConcession : +priceConsession.maxConsessionId,
                    quantity: 1,
                    startTime: this.startDate,
                    endTime: this.endDate
                };

                request.push(item);
            }
        })
        if (request != undefined && request.length > 0) {
            let itemPrice = this._itemService.GetItemPrices(request);
            itemPrice.subscribe(result => {
                if (result != undefined)
                    result.forEach(data => {
                        facilitySummaries[data.index].minPrice = new ItemPriceGroup;
                        facilitySummaries[data.index].maxPrice = new ItemPriceGroup;
                        facilitySummaries[data.index].minPrice.priceIncludingTax = data.minimumPrice;
                        facilitySummaries[data.index].maxPrice.priceIncludingTax = data.maximumPrice;

                    });
                //   this.store.dispatch(new FacilitySearchLoaded());
            }, err => {
                //   this.store.dispatch(new FacilitySearchLoaded());
            });
        }
    }
}
