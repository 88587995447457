import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OptFormModules } from 'src/modules/shared/form.modules';
import { HttpLoaderFactory, OptCommonModules } from 'src/modules/shared/common.modules';
import { OptMaterialModule } from 'src/modules/shared/opt-material/opt-material.module';

import { CorporateComponent } from './setup/corporate/corporate.component';
import { IndividualComponent } from './setup/individual/individual.component';
import { LoginComponent } from './login/login.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// import { ChangePasswordComponent } from 'src/modules/booking-portal/account/change-password/change-password.component';
// import { CustomerProfileComponent } from 'src/modules/booking-portal/account/customer-profile/customer-profile.component';

import { NgxCaptchaModule } from 'ngx-captcha';


// File Drop
import { FileDropModule } from 'ngx-file-drop';
import { HttpClient } from "@angular/common/http";
import { B2CLoginComponent } from 'src/modules/b2c/b2c-login/b2c-login.component';
//import {CitizenIdModule} from '../../b2c/citizenId/citizenid.module';
import { B2CSubModule } from '../../b2c/b2c-login/b2c.sub.module'

@NgModule({
  imports: [
    CommonModule,
    OptFormModules,
    OptCommonModules,
    NgxCaptchaModule,
    OptMaterialModule,
    FileDropModule,
    B2CSubModule
    // B2CModule
  ],
  exports: [
    CommonModule,
    CorporateComponent,
    IndividualComponent,
    LoginComponent,
    B2CSubModule,
    // ChangePasswordComponent,
    OptMaterialModule
  ],
  declarations: [
    CorporateComponent,
    IndividualComponent,
    LoginComponent


    // ChangePasswordComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class CusModules { }
