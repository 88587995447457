import { Injectable } from "@angular/core";
import { Serializer } from "json-api-format";
import { jsonIgnoreReplacer } from "json-ignore";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Booking, DeliveryMethod } from "src/modules/models/booking/booking";
import { BookingQuestion } from "src/modules/models/regular-portal/booking/question/booking-question";
import { BaseService } from "../base/base.service";
import { Attendee, Host } from 'src/modules/models/booking/Attendee'
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { SetSelectedLanguage } from '../../store/public-web/select-language/language-action';
import { BaseParam, SearchBaseFilter } from "src/modules/models/public-web/filter";
import { Package } from 'src/modules/models/public-web/Package';
import { ParameterMapper } from "../base/parameter-mapper";
import { BookingPackageSearchResult } from 'src/modules/models/booking/booking-search/booking-package-search-result';
import { HttpResponse } from "@angular/common/http";
import { PaymentInfo } from "src/modules/store/public-web/public-web-reducers";
import { SetPaymentUrl } from "src/modules/store/public-web/public-web-actions";

@Injectable({
  providedIn: "root",
})
export class PublicBookingService {

  private bookingUrl = "api/public/booking";
  private publicBookingUrl = "api/public/public-booking"
  private bookingCacheURL = "api/public/booking-cache"

  paymentInfo: PaymentInfo = {
    amount: 0,
    billerCode: '',
    paymentUrl: '' 
  };

  private selectedEventCategoryId = new BehaviorSubject<any>(null);
  selectedEventCategoryId$ = this.selectedEventCategoryId.asObservable();

  constructor(private _baseService: BaseService, private store: Store<any>) {
  }

  setPaymentUrl(url) {
    this.store.dispatch(new SetPaymentUrl(url));
  }

  setEventCategoryId(id) {
    this.selectedEventCategoryId.next(id)
  }

  removeEditBooking(id: string) {
    return this._baseService.Internal_WebAPI_POST("edit-booking/" + id, null)
  }

  postBooking(postData: Booking): Observable<any> {
    const json = new Serializer().serialize(JSON.parse(JSON.stringify(postData, jsonIgnoreReplacer)));
    return this._baseService.Internal_WebAPI_POST(this.bookingUrl, json)
      .pipe(
        map((response: any) => {
          return response.body;
        }),
        catchError(error => {
          return error
        })
      );
  }

  selectBooking(id, isBookingEditSelect = false, isFromWebPortal = false, isAllIncludes = false): Observable<any> {
    var url = this.publicBookingUrl + "/" + id + "?isBookingEdit=";
    url += isBookingEditSelect ? 1 : 0;
    if (isAllIncludes) {
      url += "&isAllIncludes=" + 1;
    } else {
      url += "&isFromWebPortal=";
      url += isFromWebPortal ? 1 : 0;
    }
    url += "&isRemovedUnwantedDetails=" + 1;
    return this._baseService
      .Internal_WebAPI_GET(url)
      .pipe(
        map((response: any) => {
          // var booking = new Deserializer().deserialize(response);
          return response.body.data;
        })
      );
  }


  selectInternalBooking(id, isBookingEditSelect = false, isAllIncludes = false): Observable<any> {
    var url = this.bookingUrl + "/" + id + "?isBookingEdit=";
    url += isBookingEditSelect ? 1 : 0;
    url += "&isAllIncludes=";
    url += isAllIncludes ? 1 : 0;
    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((response: any) => {
        return response.body.data;
      })
    );
  }

  BookingHostPatch(id: string, hostId, postdata: Host, patchPreview = ''): Observable<any> {
    return this._baseService.Internal_WebAPI_PATCH("api/public/booking/" + id + "/host/" + hostId + "?patchPreviewId=" + patchPreview, postdata).pipe(map(response => {
      return response;
    }));
  }

  BookingHostPost(id: string, postdata: Host, patchPreview = ''): Observable<any> {
    return this._baseService.Internal_WebAPI_POST("api/public/booking/" + id + "/host" + "?patchPreviewId=" + patchPreview, postdata).pipe(map(response => {
      return response;
    }));
  }
  deleteBookingPackage(bookingId, packageId, disableSpinner = false): Observable<any> {
    let url = `api/public/bookings/${bookingId}/booking-packages/${packageId}?`;
    if (disableSpinner) {
      url += '&disableSpinner'
    }
    return this._baseService.Internal_WebAPI_POST(url, null)
  }

  deleteBookingItem(bookingId, itemId, disableSpinner = false): Observable<any> {
    let url = `api/public/bookings/${bookingId}/booking-items/${itemId}?`;
    if (disableSpinner) {
      url += '&disableSpinner'
    }
    return this._baseService.Internal_WebAPI_POST(url, null)
  }

  ChangeGuestToHost(bookingId, guestId): Observable<Host> {
    return this._baseService.Internal_WebAPI_GET(this.bookingUrl + "/" + bookingId + "/guest-to-host/" + guestId).pipe(map((response: any) => {
      return response.body.data;
    }));
  }

  SwitchGuestToHost(bookingId, oldGuestId, guestId): Observable<any> {
    return this._baseService.Internal_WebAPI_GET("api/public/booking/" + bookingId + "/Attendee/" + oldGuestId + "/switch-guest/" + guestId).pipe(map((response: any) => {
      return response.body.data;
    }));
  }

  BookingAttendeePatch(id: string, attendeeID, postdata: Attendee, patchPreview = ''): Observable<any> {
    return this._baseService.Internal_WebAPI_PATCH("api/public/booking/" + id + "/attendee/" + attendeeID + "?patchPreviewId=" + patchPreview, postdata).pipe(map(response => {
      return response;
    }));
  }

  selectBookingHost(bookingID, bookingPackageIDs: string[], patchPreview = ''): Observable<Array<Host>> {
    let url = "api/public/booking/" + bookingID + "/Hosts?patchPreview=" + patchPreview;
    url +=
      bookingPackageIDs.length > 0
        ? `&BookingPackageIDs=` +
        bookingPackageIDs.join(`&BookingPackageIDs=`)
        : '';
    return this._baseService
      .Internal_WebAPI_GET(url)
      .pipe(
        map((response: any) => {
          // var booking = new Deserializer().deserialize(response);
          // if (!!response.body.data && response.body.data.length > 0) {
          //   const d = response.body.data.sort((x, y) => parseInt(x.packageDate.id) > parseInt(y.packageDate.id));
          //   var results = _(d)
          //     .groupBy(x => x.packageDate && x.packageDate.id)
          //     .map((value, key) => ({ dateId: key, hosts: value }))
          //     .value();
          //   return results && results.length ? results[0].hosts : d;
          // }
          if (!!response.body.data && response.body.data.length > 0) {
            const d = response.body.data.sort((x, y) => parseInt(x.bookingPackage.id) > parseInt(y.bookingPackage.id));
            var results = _(d)
              .groupBy(x => x.bookingPackage && x.bookingPackage.id)
              .map((value, key) => ({ bookingPackageId: key, hosts: value }))
              .value();
            return d;
          }
          return []
        })
      );
  }

  deleteAttendee(bookingID, hostID: string[]): Observable<any> {
    let url = "api/public/booking/" + bookingID + "/attendees?";
    url +=
      hostID.length > 0
        ? `GuestIDs=` +
        hostID.join(`&GuestIDs=`)
        : '';
    return this._baseService
      .Internal_WebAPI_POST(url, null)
      .pipe(
        map((response: any) => {
          return response.body.data;
        })
      );
  }

  BookingPatchPreview(patchData: Booking, patchPreviewId, orderItemName: string[] = [], disableSpinner = false): Observable<any> {
    let changingOrder = [];
    changingOrder.push("PrivateEventFacilityTimeslot");
    changingOrder.push("Allocation");
    if (orderItemName.length > 0) {
      orderItemName.forEach(a => changingOrder.push(a));
    }
    changingOrder.push("BookingPackage");
    var json = new Serializer().serialize(JSON.parse(JSON.stringify(patchData, jsonIgnoreReplacer)));
    if (json.included) {
      //no need to sorting for PublicBookingSeatingItem type of including
      if (json.included.findIndex(a => a.type == "PublicBookingSeatingItem") == -1) {
        json.included.sort((a, b) => changingOrder.indexOf(a.type) - changingOrder.indexOf(b.type));
        json.included.reverse();
      }
    }
    let url = this.bookingUrl + '/' + patchData.id + "/patch-preview?patchPreviewId=" + patchPreviewId;
    url += "&fromPublicWeb=" + 1;
    if (disableSpinner) {
      url += '&disableSpinner'
    }
    return this._baseService
      .Internal_WebAPI_POST(
        url,
        json
      )
      .pipe(
        map((response: any) => {
          return response.body;
        }),
        catchError(error => {
          return error
        })
      );
  }

  BookingPatch(postBookingData: Booking, orderItemName: string[] = [], amount = undefined,
               paymentId = undefined, disableSpinner = false): Observable<any> {

    let changingOrder = [];
    changingOrder.push("PrivateEventFacilityTimeslot");
    changingOrder.push("Allocation");
    if (orderItemName.length > 0) {
      orderItemName.forEach(a => changingOrder.push(a));
    }
    changingOrder.push("BookingPackage");

    var json = new Serializer().serialize(JSON.parse(JSON.stringify(postBookingData, jsonIgnoreReplacer)));
    if (json.included) {
      //no need to sorting for PublicBookingSeatingItem type of including
      if (json.included.findIndex(a => a.type == "PublicBookingSeatingItem") == -1) {
        json.included.sort((a, b) => changingOrder.indexOf(a.type) - changingOrder.indexOf(b.type));
        json.included.reverse();
      }
    }
    let url = this.bookingUrl + '/' + postBookingData.id + '?';
    if (amount != undefined) {
      url += `amount=${amount}`
    }
    if (paymentId != undefined) {
      url += `paymentId=${paymentId}`
    }
    url += "&fromPublicWeb=" + 1;
    if (disableSpinner) {
      url += '&disableSpinner'
    }
    return this._baseService
      .Internal_WebAPI_PATCH(
        url,
        json
      )
      .pipe(
        map((response: any) => {
          return response.body;
        }),
        catchError(error => {
          return error
        })
      );
  }

  cacheBookingPatch(postdata: Booking, orderItemName: string[] = []): Observable<any> {
    let changingOrder = [];
    changingOrder.push("PrivateEventFacilityTimeslot");
    changingOrder.push("Allocation");
    if (orderItemName.length > 0) {
      orderItemName.forEach(a => changingOrder.push(a));
    }
    changingOrder.push("BookingPackage");

    var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));
    if (json.included) {
      json.included.sort((a, b) => changingOrder.indexOf(a.type) - changingOrder.indexOf(b.type));
      json.included.reverse();
    }
    let url = this.bookingCacheURL;
    return this._baseService
      .Internal_WebAPI_POST(
        url,
        json
      )
      .pipe(
        map((response: any) => {
          return response.body.data;
        }),
        catchError(error => {
          return error
        })
      );
  }

  cancelBooking(bk): Observable<any> {
    const booking = new Booking();
    booking.id = bk.id;
    booking.cancelled = true;
    booking.cancellationReasonId = bk.cancellationReasonId ? bk.cancellationReasonId : null;
    booking.cancellationNote = bk.cancellationNote ? bk.cancellationNote : null;
    var json = new Serializer().serialize(JSON.parse(JSON.stringify(booking, jsonIgnoreReplacer)));
    let url = `api/public/booking-cancel/${bk.id}`;
    return this._baseService.Internal_WebAPI_POST(url, json);
  }

  QuestionSavePatch(id: string, answers: BookingQuestion[], patchPreview = ""): Observable<any> {
    // var data =  JSON.stringify(postdata);
    var json = new Serializer().serialize(answers);
    return this._baseService
      .Internal_WebAPI_PATCH("api/public/booking/" + id + "/answers?patchPreviewId=" + patchPreview, json)
      .pipe(
        map((response: any) => {
          return response && response.body && response.body.data ? response.body.data : undefined;
        })
      );
  }

  QuestionSavePatchV2(id: string, answers: BookingQuestion[]): Observable<any> {
    var json = new Serializer().serialize(answers);
    return this._baseService
      .Internal_WebAPI_PATCH("api/public/" + id + "/attendee/answers?", json)
      .pipe(
        map((response: any) => {
          return response && response.body && response.body.data ? response.body.data : undefined;
        })
      );
  }

  //get BookingDelivery Methods
  getBookingDeliveryMethods(bookingId: string): Observable<any> {
    return this._baseService.Internal_WebAPI_GET("api/booking/" + bookingId + "/eligible-delivery-methods").pipe(map((response: any) => {
      return response.body.data && response.body.data?.data.length > 0 ? response.body.data.data.map(x => new DeliveryMethod().initialize(x)) : [];
    }));
  }

  getCancellationFee(bookingId: string) {
    let url = `api/public/cancellation-policy-schedule/${bookingId}`;
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data.data
    }));
  }

  getCancellationReasons() {
    let url = `api/public/cancellation-reasons/`;
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data.data
    }));
  }

  getDispatchStatus(bookingId, bookingPackageIds) {
    let baseUrl = `api/public/booking/`;
    if (bookingId) {
      baseUrl += `${bookingId}/dispatch-status?`;
    }
    if (bookingPackageIds && bookingPackageIds.length > 0) {
      bookingPackageIds.forEach(id => {
        baseUrl += `bookingPackageIds=${id}&`;
      })
    }
    return this._baseService.Internal_WebAPI_GET(baseUrl).pipe(map((b: any) => {
      return b.body.data
    }));
  }

  saveSelectedLanguage(selectedLanguage: string): Observable<any> {
    this.store.dispatch(new SetSelectedLanguage(selectedLanguage));
    let url = `api/public/selectedLanguage/${selectedLanguage}`;
    return this._baseService.Internal_WebAPI_POST(url, null).pipe(map((b: any) => {
      return true;
    }));
  }

  GetBookingItemForBookingPackage(bookingId, bookingPackageId, disableSpinner:boolean=true) {
    let baseUrl = `api/public/booking/`;
    if (bookingId) {
      baseUrl += `${bookingId}/booking-packages/`
    }
    if (bookingPackageId) {
      baseUrl += `${bookingPackageId}/booking-items?`
    }
    if(disableSpinner) {
      baseUrl += '&disableSpinner'
    }
    return this._baseService.Internal_WebAPI_GET(baseUrl).pipe(map((b: any) => {
      return b.body.data;
    }));
  }

  GetBookingPackageForBooking(bookingId, bookingPackageId) {
    let baseUrl = `api/public/booking/`;
    if (bookingId) {
      baseUrl += `${bookingId}/booking-packages/`
    }
    if (bookingPackageId) {
      baseUrl += `${bookingPackageId}?`
    }
    baseUrl += '&disableSpinner'
    return this._baseService.Internal_WebAPI_GET(baseUrl).pipe(map((b: any) => {
      return b.body.data;
    }));
  }

  GetBookingPackageList(bookingId, baseParam): Observable<BookingPackageSearchResult> {
    var filter = "";
    if (baseParam) {
      filter = ParameterMapper.mapFilters(baseParam.filter);
      filter = ParameterMapper.mapFilters(baseParam.paging, filter);
      if (baseParam.sort) {
        if (filter.length > 0) filter += "&";
        filter += "sort=" + baseParam.sort;
      }
    }
    let url = this.bookingUrl + `/${bookingId}/booking-packages?`;
    return this._baseService.Internal_WebAPI_GET(url + filter).pipe(
      map((response: HttpResponse<any>) => {
        var res = new BookingPackageSearchResult();
        if (response.ok) {
          res.data = response.body.data;
          res.total = response.body.total;
          res.isError = response.body.isError;
          if (response.body.error) {
            res.errorMessage = response.body.error.errorMessage;
            res.errorCode = response.body.error.errorCode;
          } else if (response.body.Errors) {
            res.errorMessage = response.body.errors[0].errorMessage;
            res.errorCode = response.body.errors[0].errorCode;
          }
        }
        return res;
      })
    );
  }

  searchBookingPackages(searchFiler: SearchBaseFilter, includeTemporary: boolean = false) {
    let url = this.bookingUrl + `/bookingPackages?`;
    if (searchFiler) {
      url = searchFiler.addFilter(url, 'filters')
    }
    if (includeTemporary) {
      url += "&includeTemporary=" + 1;
    }
    url += '&disableSpinner';
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data.data;
    }));
  }

  // getPackageList(bookingPackages : BookingPackage[]){
  //   const param = new SearchBaseFilter();
  //   let packageIds = [];
  //   bookingPackages.forEach(bpack => {
  //     if(packageIds.length == 0){
  //       packageIds.push(bpack.package.id)
  //     } else {
  //       const isExist = packageIds.findIndex(p => p.id === bpack.package.id);
  //       if(isExist == -1) {
  //         packageIds.push(bpack.package.id)
  //       }
  //     }
  //   })
  //   if(packageIds.length > 0) {
  //     param.packageIDs = packageIds
  //     this._packageService.getPublicPackage(new BaseParam(), param).pipe(
  //       map((packages) => {
  //         if (packages) {
  //           for(let i=0;i<bookingPackages.length;i++){
  //             const findPackage = packages.find(p => p.id == bookingPackages[i].package.id);
  //             if(findPackage){
  //               bookingPackages[i].package = findPackage;
  //             }
  //           }
  //           console.log('include packages' + bookingPackages);
  //           return bookingPackages;
  //         } else {
  //           return bookingPackages;
  //         }
  //       }
  //     ))
  //   }
  // }

  getPublicPackage(filter: BaseParam, searchFiler: SearchBaseFilter) {
    let url = 'api/public/public-package?';
    if (searchFiler) {
      url = searchFiler.addFilter(url, 'filters')
    }
    url += filter.eventID ? `&filters.EventID=${filter.eventID}` : '';
    url += filter.packageID ? `&filters.PackageID=${filter.packageID}` : '';
    url += `&filters.ActiveStatus=3`;

    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((d: any) => {
        if (d.isError) {
          return [];
        } else if (d.body) {
          return d.body.data.data.map(d => {
            return new Package().initialize(d)
          })
        }
      }))
  }
}
