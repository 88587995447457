import { Injectable, Injector, OnDestroy } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { AppSetting } from '../../../../src/modules/models/settings/casual-portal/app-setting';
import { AuthService } from '../../b2c/auth-service';
import { Store } from '@ngrx/store';
import { Contact } from '../../../../src/modules/models/client/contact';
import { SetB2CContact } from '../../../../src/modules/store/actions';

@Injectable({
  providedIn: 'root',
})
export class CognitoAuthService implements AuthService, OnDestroy {

  appSetting = environment.AppSetting as AppSetting;
  loggedInContact: Contact;
  b2cContact: Contact;
  private store: Store<any>
  private intervalId: any;
  constructor() { }

  async init(injector: Injector, st: Store<any>): Promise<void> {
    this.store = st;

  }
  async silentLogin(fromLogin?: boolean): Promise<void> {
    this.checkUserLoginStatus(fromLogin);
  }

  async loginRedirect(): Promise<void> {
    const redirectUrl = environment.ApiUrl + 'Customer/SignIn';
    console.log(redirectUrl);
    const url = `${this.appSetting?.B2CIntegration?.CognitoB2CIntegration.CognitoAPIURL}/login?client_id=${this.appSetting?.B2CIntegration?.CognitoB2CIntegration?.ClientId}&response_type=code&redirect_uri=${redirectUrl}`;
    window.location.href = url;
  }

  async logout(callback?: () => void): Promise<void> {
    this.handleLogout(callback)
  }

  async profileRedirect(): Promise<void> {
    const profileUrl = environment.ApiUrl + "#/my-profile";
    if (!window.location.href.includes('my-profile')) {
      window.location.href = profileUrl;
    }
  }

  async changePasswordRedirect(): Promise<void> {
    const redirectUrl = environment.ApiUrl + 'Customer/SignIn';
    const url = `${this.appSetting?.B2CIntegration?.CognitoB2CIntegration.CognitoForgotPasswordURL}?client_id=${this.appSetting?.B2CIntegration?.CognitoB2CIntegration?.ClientId}&response_type=code&redirect_uri=${redirectUrl}`;
    window.location.href = url;
  }

  private handleLogout(callback?: () => void) {
    const redirectUrl = environment.ApiUrl + "#/client/login";
    //const redirectUrl = environment.ApiUrl + 'Customer/SignIn';
    const url = `${this.appSetting?.B2CIntegration?.CognitoB2CIntegration.CognitoAPIURL}/logout?client_id=${this.appSetting?.B2CIntegration?.CognitoB2CIntegration?.ClientId}&response_type=code&logout_uri=${redirectUrl}`;
    this.store.dispatch(new SetB2CContact(undefined));
    environment.LoggedInUser = '';
    window.location.href = url;

  }

  checkUserLoginStatus(fromLogin?: boolean) {
    this.verifySession(fromLogin);
  }

  verifySession(fromLogin?: boolean) {

  }

  ngOnDestroy() {
    this.stopSessionVerification();
  }

  private stopSessionVerification() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
}

  getSSOContactRef(c: Contact): string {
    return (c?.ssoContactRef ?? '') + ((c?.client?.SSOContactRef ?? '') != '' ? ('_' + (c?.client?.SSOContactRef ?? '')) : '')
  }

  getSetupCustomerRedirectUrl(c: Contact): Promise<string> {
    return new Promise((resolve, reject) => {
      let _tab = (c.client?.SSOContactRef ?? '') != '' ? 0 : 1;
      resolve(environment.ApiUrl + '/Booking/Casual#/client/setup?tab=' + _tab)

    });
  }

}
