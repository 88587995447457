
import { Injectable, Injector, OnDestroy } from '@angular/core';


import { environment } from 'src/environments/environment';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';

import { AuthService } from '../../b2c/auth-service';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ICart, PackageSearch } from 'src/modules/store';
import { Contact } from 'src/modules/models/client/contact';
import { SetB2CContact } from 'src/modules/store/actions';
import { ContactFilters } from 'src/modules/models/filters/contact-filters';
import { CustomerService } from 'src/modules/services/customer.service';
import { Client } from 'src/modules/models/client/client';
import { SetCIAMDataLoaded } from 'src/modules/store/loading/actions';

@Injectable({
    providedIn: 'root',
})
export class CDCAuthService implements AuthService, OnDestroy {
    appsetting = environment.AppSetting as AppSetting;
    loggedInDBContact: Contact;
    b2cContact: Contact;

    private store: Store<any>
    private customerService: CustomerService;
    private injector: Injector;

    private intervalId: any;
    private checkInterval = 1000 * 60 * 5; // Example interval time

    initialized = false;

    constructor() { }
    /*    private gigyaConfig = {
           sessionExpiration: 60 * 60 * 24 * 3,
           onGigyaServiceReady: () => {
               gigya.accounts.addEventHandlers({
                   onLogin: (response) => {
                       console.log(response)
                       this.handleLogin(true)
                   },
                   onLogout: () => this.logout(),
               });
           },
       };
    */

    async init(injector: Injector, st: Store<any>, customerService: CustomerService): Promise<void> {
        this.store = st;
        this.customerService = customerService;
        this.injector = injector;
        if (!this.initialized) {
            this.initialized = true;

            this.store.select('cart').pipe().subscribe((st: ICart) => {
                this.b2cContact = st.b2cContact;
                this.loggedInDBContact = st.contact;
            })
        }

        console.log(`Init CDC`);

    }
    async silentLogin(fromLogin?: boolean): Promise<void> {
        console.log(`silentLogin CDC`);
        this.initializeGigya();
        this.checkUserLoginStatus(fromLogin);
    }

    async loginRedirect(): Promise<void> {
        console.log(`Login to CDC with`);
        // Implement login logic
        gigya.sso.login({
            authFlow: "redirect",
            redirectURL: environment.ApiUrl + '/Customer/SignIn',// window.location.href.split('#')[0] + '#/b2c/home',
            context: {
                myCustomLang: "en",
                myCustomSessionExpiration: 60 * 60 * 24 * 1,
                useChildContext: true,
                redirect: environment.ApiUrl,// after sign up org client redirect to the portal home
                source: "bookings"
            },
        });
    }
    //test
    async logout(callback?: () => void): Promise<void> {
        console.log('Logout from CDC');
        // Implement logout logic
        this.handleLogout(callback)
    }

    async profileRedirect(): Promise<void> {
        console.log(`CDC Profile Redirect`);
        var _redirectUrl = encodeURIComponent(environment.ApiUrl + '/Customer/Profile');
        window.location.href = (this.appsetting?.B2CIntegration?.SAPCitizenIDIntegration?.ProfileUpdateUrl ?? '').replace('#REDIRECT_URL#', _redirectUrl);
    }
    async changePasswordRedirect(): Promise<void> {
        console.log(`CDC Change Password Redirect`);
        var _redirectUrl = encodeURIComponent(environment.ApiUrl + '/Customer/Profile');
        window.location.href = (this.appsetting?.B2CIntegration?.SAPCitizenIDIntegration?.ChangePasswordUrl ?? '').replace('#REDIRECT_URL#', _redirectUrl);
    }
    getSetupCustomerRedirectUrl(c: Contact): Promise<string> {
        return new Promise((resolve, reject) => {
            let _tab = (c.client?.SSOContactRef ?? '') != '' ? 0 : 1;
            resolve(environment.ApiUrl + '/Booking/Casual#/client/setup?tab=' + _tab)

        });
    }
    getSSOContactRef(c: Contact): string {
        return (c?.ssoContactRef ?? '') + ((c?.client?.SSOContactRef ?? '') != '' ? ('_' + (c?.client?.SSOContactRef ?? '')) : '')
    }

    /************************************************************************************************************************ */
    private initializeGigya() {
        // Ensure that the Gigya library is ready
        /*   gigya.init({
              apiKey: this.appsetting?.B2CIntegration?.SAPCitizenIDIntegration?.APIKey ?? '',
              sessionExpiration: this.gigyaConfig.sessionExpiration,
              onLoad: this.gigyaConfig.onGigyaServiceReady,
          }); */
        gigya.accounts.addEventHandlers({
            onLogin: (response) => {
                console.log(response)
                //this.handleLogin(true
                // this.loginUI(true)
            },
            onLogout: () => () => {
                console.log('onLogout')
                //this.logout()
            },
        });
    }

    private handleLogin(response: any) {
        // Handle successful login here
        console.log('Login successful:', response);
        // Call your UI update function

    }

    private handleLogout(callback?: () => void) {
        // Handle logout here
        console.log('User logged out');
        // Call your UI update function
        if (!this.b2cContact) {
            gigya.accounts.logout({
                callback: (r) => {
                    console.log("User logged out", r);
                    this.store.dispatch(new SetB2CContact(undefined));
                    if (callback) {
                        callback()
                    }

                    // Get the username (replace with your actual method to retrieve the username)
                    //const username = r.profile.firstName+" "+r.profile.lastName; // Replace with the actual username

                    // Update the welcome message with the username
                    //document.getElementById("username").textContent = username;

                },
            });
        }

    }


    checkUserLoginStatus(fromLogin?: boolean) {
        this.verifySession(fromLogin);
        this.intervalId = setInterval(() => {
            this.verifySession(fromLogin);
        }, this.checkInterval);
    }

    verifySession(fromLogin?: boolean) {
        gigya.accounts.session.verify({
            callback: (response) => {
                if (response.errorCode === 0) {
                    this.loginUI(fromLogin);
                } else if (this.b2cContact) {
                    this.handleLogout();
                }
            }
        });
    }
    ngOnDestroy() {
        this.stopSessionVerification();
    }

    private stopSessionVerification() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
    loginUI(fromOnLogin: boolean = false) {

        //Getting the customer object onLogin to get the Groups/Organizations array for the logged in customer to determine if we need to navigate the user to the Organization Selection Hosted Page
        gigya.accounts.getAccountInfo({
            include: "profile,data,groups",
            extraProfileFields: "*",
            includeCommunications: "all",
            callback: (r) => {
                // User is logged in and returned Customer object with groups/organizations populated
                console.log("onLogin fired from HTML JS", r);

                // Checking only on first login if the user is a member of multiple organizations to navigate them to the organizations selection screen

                // Get the current URL
                const url = new URL(window.location.href.replace('#', ''));
                // Use URLSearchParams to parse the query string
                const params = new URLSearchParams(url.search);
                // Get specific parameters
                const gig_context = params.get('gig_context') ?? ''; // this will be set when select org

                if (fromOnLogin) {


                    if (gig_context == '' && r.groups && r.groups.organizations && r.groups.organizations.length > 0) {
                        // window.location.href = "https://logindev.ccc.govt.nz/pages/org-change?gig_redirect=" + encodeURIComponent(window.location.href);
                        var _redirectUrl = encodeURIComponent(window.location.href);
                        window.location.href = (this.appsetting?.B2CIntegration?.SAPCitizenIDIntegration?.OrgChangeUrl ?? '').replace('#REDIRECT_URL#', _redirectUrl);

                    }
                    // Checking the DOB field (Can be expanded to other fields) to make sure that already existing registered users have it set OR it navigates them to the update profile hosted page
                    else if (!r.data.birthDate) {
                        //window.location.href = "https://logindev.ccc.govt.nz/pages/ccc-cust-update-profile?gig_redirect=" + encodeURIComponent(window.location.href) + "&gig_source=bookings";
                        //window.location.href="https://logindev.ccc.govt.nz/pages/ccc-cust-reg-comp?gig_redirect="+encodeURIComponent(window.location.href)+"&gig_source=bookings";
                        var _redirectUrl = encodeURIComponent(window.location.href);
                        window.location.href = (this.appsetting?.B2CIntegration?.SAPCitizenIDIntegration?.ProfileUpdateUrl ?? '').replace('#REDIRECT_URL#', _redirectUrl);

                    } else {
                        console.log("from Login ", r);
                        if (r.groups && r.groups.organizations && r.groups.organizations.length > 0) {
                            gigya.accounts.b2b.getOrganizationContext({
                                callback: (o) => {
                                    console.log(o);
                                    gigya.accounts.b2b.getOrganizationInfo({
                                        bpid: o.bpid,
                                        callback: (org) => {
                                            console.log(org);
                                            this.loadContactdataFromSAP(r, org, fromOnLogin)
                                        }
                                    })


                                }
                            })
                        } else {
                            //individual client
                            this.loadContactdataFromSAP(r, undefined, fromOnLogin)
                        }

                    }
                } else {
                    console.log("Silent Login ", r);
                    if (gig_context == 'IND') {
                        this.loadContactdataFromSAP(r, undefined, fromOnLogin)
                    }
                    else if (r.groups && r.groups.organizations && r.groups.organizations.length > 0) {
                        gigya.accounts.b2b.getOrganizationContext({
                            callback: (o) => {
                                console.log(o);
                                if ((o.bpid ?? '') != '') {
                                    gigya.accounts.b2b.getOrganizationInfo({
                                        bpid: o.bpid,
                                        callback: (org) => {
                                            console.log(org);
                                            this.loadContactdataFromSAP(r, org, fromOnLogin)
                                        }
                                    })
                                } else {
                                    var _redirectUrl = encodeURIComponent(window.location.href);
                                    window.location.href = (this.appsetting?.B2CIntegration?.SAPCitizenIDIntegration?.OrgChangeUrl ?? '').replace('#REDIRECT_URL#', _redirectUrl);

                                }

                            }
                        })
                    }
                    else {
                        //individual client
                        this.loadContactdataFromSAP(r, undefined, fromOnLogin)
                    }

                }


                /*   // Can be ignored, just for POC purposes
                  document.getElementById("RAASLogin").style.display = "none";
                  document.getElementById("welcomeMessageAnon").style.display =
                      "none";
                  document.getElementById("profileUpdateLink").href = document.getElementById("profileUpdateLink").href + "?gig_redirect=" + encodeURIComponent(window.location.href) + "&gig_source=bookings";
                  document.getElementById("welcomeMessage").style.display = "block";
                  document.getElementById("RAASLogout").style.display = "block";

                  // Get the username (replace with your actual method to retrieve the username)
                  const username = r.profile.firstName + " " + r.profile.lastName; // Replace with the actual username

                  // Update the welcome message with the username
                  document.getElementById("username").textContent = username; */

                // Receiving the parameter back from Organization selection, to check if the logged in user selected individual customer which will go to the ELSE condition below, otherwise it will use the getOrganizationContext to retrieve the currently chosen ORG ID and BPID
                /* const urlParams = new URLSearchParams(window.location.search);
                let context = urlParams.get("gig_context");
                if (context && context !== 'IND') {
                    gigya.accounts.b2b.getOrganizationContext({
                        callback: function (o) {
                            console.log(o);
                            this.loadContactdataFromSAP(r)
                            /*  if (o.bpid) {
                                 document.getElementById("orgMember").style.display = "block";
                                 document.getElementById("orgId").innerHTML = " with CDC Org ID:<b>" + o.orgId + "</b> and Org BP ID:<b>" + o.bpid + "</b>";
                             } else {
                                  document.getElementById("orgMember").style.display = "none";
                             } * /
                            //orgMember
                            //orgId
                        }
                    })
                } else {
                    console.log('individual User')
                    this.loadContactdataFromSAP(r)
                    //document.getElementById("orgMember").style.display = "none";
                } */
            },
        })


    }

    loadContactdataFromSAP(res: any, org: any, fromOnLogin: boolean = false, isIndividual = false) {
        var _res = res;

        if (_res.profile) {
            let alternativeReference = _res.UID;
            if (alternativeReference != null && alternativeReference != '') {
                // this.loadContactInfo(alternativeReference);  TODO

                let _payload = {
                    ssoContactRef: _res.UID,
                    email: _res.profile?.email,
                    firstName: _res.profile?.firstName,
                    lastName: _res.profile?.lastName,
                    address: {
                        address1: _res.data.structuredAddresses?.addressType == "Street" ?
                          (((_res.data.structuredAddresses?.house_nr ?? '') && (_res.data.structuredAddresses?.unit ?? ''))
                          ? `${_res.data.structuredAddresses.house_nr}/${_res.data.structuredAddresses.unit}`
                          : `${_res.data.structuredAddresses?.house_nr ?? ''}${_res.data.structuredAddresses?.unit ?? ''}`) :
                          _res.data.structuredAddresses?.poNumber,
                        address2: _res.data.structuredAddresses?.addressType == "Street" ? _res.data.structuredAddresses?.street_name ?? ''
                                  : _res.data.structuredAddresses?.poBoxLobby ?? '' ,
                        address3 : _res.data.structuredAddresses?.suburb ?? '',
                        city: _res.data.structuredAddresses?.town_city ?? '',
                        postCode: _res.data.structuredAddresses?.postcode ?? '',
                    },
                    phone: (_res.profile?.phones ?? []).map((p) => { p.number }),
                    ssoAdditionalData: {
                        groups: _res.groups
                    }

                } as Contact;
                if (org) {
                    _payload.client = new Client();
                    _payload.client.SSOContactRef = org.orgId;
                    _payload.client.companyName = org.orgName;
                    _payload.client.companyRegistrationNo = (org.info?.NZBN ?? []).length > 0 ? org.info?.NZBN[0] : '';
                    _payload.client.email = (org.info?.orgEmail ?? []).length > 0 ? org.info?.orgEmail[0] : '';
                    _payload.client.phone = (org.info?.orgPhone ?? []);
                }
                this.store.dispatch(new SetB2CContact(_payload));
                if (fromOnLogin) {
                    //  this.orgId = o.orgId;
                    //  this.orgBpid = o.bpid;
                    // let _tab = org.bpid ? 0 : 1;
                    //window.location.href = environment.ApiUrl + '/Booking/Casual#/client/setup?tab=' + _tab
                    this.store.dispatch(new SetCIAMDataLoaded(true));

                }



            }
        }

    }



    getOrganizationContext() {
        gigya.accounts.b2b.getOrganizationContext({
            callback: (o) => {
                console.log("getOrganizationContext: " + o);
                if (o.bpid) {
                    //  this.orgId = o.orgId;
                    //  this.orgBpid = o.bpid;
                }
            }
        });
    }




}
