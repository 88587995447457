import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, filter, scan } from "rxjs/operators";
import { RoutePath } from 'src/modules/booking-portal/route-path';
import { Redirect, SetGuestContact } from 'src/modules/store/actions';
import { ClearStore } from 'src/modules/store/regular-booking/actions';
import { environment } from '../../../environments/environment';
import { ICart } from 'src/modules/store/index'
import { Contact } from '../../models/client/contact';
import { SetCIAMReturnUrl } from '../loading/actions';


@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<ICart>

  ) { }

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType("SET_CONTACT"),
    map((data: any) => {
      //console.log('SET_CONTACT');
      //console.log(user);
      //this.router.navigate(['/facility'])
      let _isInternalUser: boolean = environment.IsInternalUser;
      let newContact = new Contact();
      this.store.dispatch(new SetGuestContact(newContact));
      if (data.redirectFullUrl) {
        window.open(data.redirectFullUrl, "_self");
      } else if (data.redirectActionPayload) {
        this.store.dispatch(new Redirect(data.redirectActionPayload));
      } else if (data.navigateUrl) {
        if (data.queryParams) {
          this.router.navigate([data.navigateUrl], {
            queryParams: data.queryParams,
          });
        } else {
          this.router.navigate([data.navigateUrl]);
        }
      } else if (data.action) {
        this.store.dispatch(data.action);
      }
      //else {
      //  this.store.dispatch(new Redirect("FACILITY"));
      //}
    })
  );

  @Effect({ dispatch: false })
  redirectUser$ = this.actions$.pipe(
    ofType("REDIRECT"),
    map((url: Redirect) => {
      //console.log('SET_CONTACT');
      //console.log(user);
      switch (url.payload) {
        case "LOGIN":
          this.router.navigate([RoutePath.Login]);
          break;
        case "SIGNUP":
          this.router.navigate([RoutePath.SignUp]);
          break;

        case "FACILITY":
          this.router.navigate([RoutePath.Facility]);
          break;

        case "CART":
          this.router.navigate([RoutePath.BookingCart]);
          break;
        case "CART-LOGIN"://fire from booking flow
          let _retUrl = window.location.href;
          if (_retUrl.indexOf('?') >= 0) {
            _retUrl = _retUrl + '&fromBookingFlow=1'
          } else {
            _retUrl = _retUrl + '?fromBookingFlow=1'
          }
          this.store.dispatch(new SetCIAMReturnUrl(_retUrl));
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { index: "2", displayLogin: true },
          });
          break;
        case "CART-SIGNUP":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { index: "2", displayLogin: false },
          });
          break;
        case "CART-COMPLETE":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { index: "3" },
          });
          break;
        case "CART-PRICECHANGE":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { priceChange: "1", index: "1" },
          });
          break;
        case "CART-PAYMENT":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { payment: "pending", index: "3" },
          });
          break;
        case "CART-PAYMENT-COMPLETE":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { payment: "complete", index: "3" },
          });
          break;
        case "SIGNUP-FROM-BOOKING-DETAIL":
          this.store.dispatch(new SetCIAMReturnUrl(window.location.href));
          this.router.navigate([RoutePath.SignUpFromBookingDetail]);
          break;
        case "DirectLoginFromBooking":
          this.store.dispatch(new SetCIAMReturnUrl(window.location.href));
          this.router.navigate([RoutePath.Login], {
            queryParams: { DirectLoginFromBooking: "1" },
          });
          break;

        case "CartFromFC":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { showQuestion: "1" },
          });
          break;

        case "MyBooking":
          this.router.navigate([RoutePath.MyBooking]);
          break;

        case "MyProfile":
          this.router.navigate([RoutePath.MyProfile]);
          break;
        case "PublicWebHome":
          window.open(environment.PublicWebPortalUrl, "_self");
          break;
        case "WasteManagementHome":
          window.open(environment.WasteBookingPortalUrl, "_self");
          break;
        case "WasteManagementBookingCart":
          window.open(
            environment.WasteBookingPortalUrl + "#/wm-booking-cart?loggedIn=1",
            "_self"
          );
          break;
        case "WasteManagementBookingState":
          window.open(
            environment.WasteBookingPortalUrl + "#?loggedIn=1",
            "_self"
          );
          break;
        case "SignUpFromWasteManagement":
          this.router.navigate([RoutePath.SignUpFromWasteManagement], {
            queryParams: { fromWasteBooking: "1" },
          });
          break;
        case "SignUpFromWasteManagementCart":
          this.router.navigate([RoutePath.SignUpFromWasteManagement], {
            queryParams: { fromWasteBooking: "1", fromCart: "1" },
          });
          break;
        case "SIGNUPFROMPUBLICWEB":
          this.router.navigate([RoutePath.SignUpFromPublicWeb]);
          break;
        /*     case "DirectLoginFromBooking":
              this.router.navigate([RoutePath.Login], {
                queryParams: { DirectLoginFromBooking: "1" },
              });
              break; */
        case "CartFromFC":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { showQuestion: "1" },
          });
          break;
        case "CONTINUE-AS-GUEST-FROM-CART":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { index: "2", displayLogin: false, dispalyGuestForm: true },
          });
          break;
        case "CONTINUE-AS-GUEST-FROM-TO-CART":
          this.router.navigate([RoutePath.BookingCart], {
            queryParams: { index: "1" },
          });
          break;
        default:
          window.location.href = url.payload;
        //regular booking
      }
    })
  );

  @Effect({ dispatch: false })
  clearStore$ = this.actions$.pipe(
    ofType("STORE_CLEARSTOREOPTION"),
    map((url: Redirect) => {
      if (url.payload) {
        window.open(url.payload, "_self");
      }
    })
  );
}
