import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OptFormModules } from 'src/modules/shared/form.modules';

import { SubHeaderComponent } from './sub-header/sub-header.component';
import { MmcLoaderComponent } from './mmc-loader/mmc-loader.component';
import { OptLoaderComponent } from './opt-loader/opt-loader.component'
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { ToastMessageComponent } from './toast-message/toast-message.component';
import { SuccessMessageComponent } from './success-message/success-message.component';
import { WarningMessageComponent } from './warning-message/warning-message.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { CalloutBoxComponent } from './callout-box/callout-box.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { QuestionnaireModalComponent } from './questionnaire-modal/questionnaire-modal.component';
import { SecondarySearchComponent } from '../shared/secondary-search/secondary-search.component';
import { LanguageSelectorComponent } from '../shared/opt-translate/language-selector/language-selector.component'
//Store
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { statusReducer } from 'src/modules/store/loading/reducer';
import { LoadingActions } from 'src/modules/store/loading/actions';

import { cartReducer } from '../store/cart-reducer';
import { CartActionTypes } from '../store/actions';
import { ICart, AppState } from '../store/cart';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from '../store/effects/login-effects';

//Pipe
import { CurrencyPipe } from '@angular/common';
import { CurrencyFormat } from 'src/modules/currency-format';



import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

//import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import { environment } from '../../environments/environment';

import {WarningAlertMessageComponent } from './warning-alert-message/warning-alert-message.component';

export const reducers: ActionReducerMap<AppState> = { cart: cartReducer, loader: statusReducer };

function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: ['cart'], rehydrate: true })(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

import { DateFormat } from 'src/modules/date-format';


//Date format
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { ClientSearchModule } from '../client-search/client-search.module';
import { ClientSearchModule } from '../client-search/client-search.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { LanguageReducer } from "../store/public-web/select-language/language-reducer";
import { OptimoTranslate } from './opt-translate/optimo-translate.pipe';
import { SafeHtmlPipe } from './opt-safeHtml/optimo-safehtml.pipe';
import { OptimoFilterPipe } from './opt-filter/optimo-filter.pipe';
import { PbCustomItemQuantityPipe } from '../public-web/pipe/pb-custom-item-quantity.pipe';
import { PBCurrencyPipe } from '../public-web/pipe/pb-custom-currency.pipe';


const moment = _rollupMoment || _moment;

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "../dist/assets/i18n/", ".json");
}


//Routing
//const routes: Routes = [
//    {
//        path: '',
//        pathMatch: 'full',
//        redirectTo: 'facility'
//    },
//    //{
//    //    path: 'booking-cart',
//    //    loadChildren: 'src/modules/booking-portal/booking.module#BookingModule'
//    //},

//    {
//        path: 'client',
//        loadChildren: () => import('src/modules/booking-portal/client/client.module').then(m => m.ClientModule)

//    },

//];
const DT_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        TranslateModule.forRoot({
            defaultLanguage: '',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        //BrowserModule,
        //CommonModule,
        //HttpClientModule,
        //CommonModule,
        RouterModule,
        OptFormModules,
        FormsModule,
        //RouterModule.forChild(routes),
        // StoreModule.forFeature('cart-header', reducers, { metaReducers }),
        RecaptchaModule,
        ///  RecaptchaFormsModule
    ],
    exports: [
        SubHeaderComponent,
        MmcLoaderComponent,
        OptLoaderComponent,
        AlertMessageComponent,
        ToastMessageComponent,
        SuccessMessageComponent,
        WarningMessageComponent,
        DeleteConfirmationComponent,
        ReactiveFormsModule,
        NotFoundComponent,
        ComingSoonComponent,
        QuestionnaireModalComponent,
        SecondarySearchComponent,
        CurrencyFormat,
        DateFormat,
        OptimoTranslate,
        SafeHtmlPipe,
        OptimoFilterPipe,
        LanguageSelectorComponent,
        //RecaptchaFormsModule,
        RecaptchaModule,
        OptFormModules,
        QuestionnaireModalComponent,
        SecondarySearchComponent,
        TranslateModule,
        WarningAlertMessageComponent,
        PbCustomItemQuantityPipe,
        PBCurrencyPipe,
        CalloutBoxComponent,
    ],
    declarations: [
        SubHeaderComponent,
        MmcLoaderComponent,
        OptLoaderComponent,
        AlertMessageComponent,
        ToastMessageComponent,
        SuccessMessageComponent,
        WarningMessageComponent,
        DeleteConfirmationComponent,
        NotFoundComponent,
        ComingSoonComponent,
        QuestionnaireModalComponent,
        SecondarySearchComponent,
        LanguageSelectorComponent,
        CurrencyFormat,
        DateFormat,
        OptimoTranslate,
        PbCustomItemQuantityPipe,
        PBCurrencyPipe,
        SafeHtmlPipe,
        OptimoFilterPipe,
        WarningAlertMessageComponent,
        CalloutBoxComponent
    ],
    entryComponents: [
        AlertMessageComponent,
        SuccessMessageComponent,
        WarningMessageComponent,
        DeleteConfirmationComponent,
        QuestionnaireModalComponent,
        WarningAlertMessageComponent,

    ],
    providers: [CurrencyPipe,
        CurrencyFormat,
        PbCustomItemQuantityPipe,
        PBCurrencyPipe,
        OptimoTranslate,
        SafeHtmlPipe,
        OptimoFilterPipe,
        DateFormat,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6LdfjpAUAAAAAIeNoRQ_cqpibuV1PTNIwCyyszrY',
            } as RecaptchaSettings,
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DT_FORMATS },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: environment.messageDurationInSeconds } }
    ]
})
export class OptCommonModules { }
