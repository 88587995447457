import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppSetting, MenuSettings, Condition } from 'src/modules/models/settings/casual-portal/app-setting';
import { Login } from './../../../models/login/login';
import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';

import { CustomerService } from "../../../services/customer.service";
import { ItemService } from "src/modules/services/item.service";

import { Utility } from 'src/modules/utility';
import { Store, ICart, SetContact, Redirect, PatchBookingContact, SaveBooking } from '../../../store/index';
import { FacilitySearchLoaded, SetCIAMReturnUrl } from 'src/modules/store/loading/actions';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
import { ResetInternalUserDataAction } from '../../../store/waste-management/actions';

import { RoutePath } from 'src/modules/booking-portal/route-path';
import { PublicBookingService } from 'src/modules/services/public-web/public-booking.service';
import { MatDialog } from '@angular/material/dialog';
import { GuestClientComponent } from '../modals/guest/guest-checkout.component';
import { ExistingGuestMailComponent } from '../modals/existing-guest-mail/existing-guest-mail.component';

import { WMComponentsTitleText } from '../../../models/waste-management/enum';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import * as LanguageSelector from '../../../store/public-web/select-language/language-selector';
import { TranslateService } from '@ngx-translate/core';
import { SignalRService } from 'src/modules/public-web/booking-application/services/signal-r.service';
import { SetContactID } from '../../../store/public-web/public-web-actions';
import { PublicBookingSettings } from 'src/modules/models/settings/public-booking/public-booking-setting';
import { OptimoTranslate } from 'src/modules/shared/opt-translate/optimo-translate.pipe';
import { Title } from '@angular/platform-browser';

declare let gtag: Function;
/** Raise error when invalid control is dirty, touched, or submitted. */
export class StateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["../../../../sass/pages/_login.scss"],
})
export class LoginComponent extends BookingCommonComponent implements OnInit {
  @Input() isFromBooking: string
  ApiUrl: string;
  RegularBookingPortalUrl: string;
  matcher = new StateMatcher();
  frmLogin: FormGroup;
  login: Login;
  errorMessage: string;
  directLoginFromBooking: boolean = false;
  directLoginFromPublicWeb: boolean = false;
  cart$: Observable<ICart>;
  // cart_subscriber: any;
  isSystemAllowedImmediateConfirmation: boolean;
  systemDefaultPriceConsessionId: number;
  // customerDefaultPriceConsessionId: number;
  // isAllImmediateConfirmed: boolean;
  loader$: Observable<ILoadingStatus>;
  store_BookingType: any;
  bookingTypeArray: any;
  regularBooking: boolean = false;
  wasteBooking = false;
  wasteBookingFromCart = false;
  // wasteBookingFromBookingState = false;
  displayForgotUserNameLink: boolean;
  ForgotPasswordLink: string;
  WasteBookingPortalUrl: string;
  PublicBookingPortalUrl: string;
  PublicBookingReturnUrl: string;
  enableGuestCheckout = false;
  AddedFacilites: FacilitySummary[];
  isAllImmediateConfirmed: boolean;
  bookingRef: string
  appsetting = environment.AppSetting as AppSetting;
  signUpButtonSetting: MenuSettings;
  homeSetting: MenuSettings;
  languages = [];
  selectedLanguage: string;
  isSocialMediaLogin: boolean;
  isPublicWebPortal = false;
  enableNewDesignForLoginPage = false;
  Condition = Condition;
  continueAsGuest: boolean = false;

  enableB2cIntegration: boolean = false;

  constructor(
    protected store: Store<any>,
    protected _customerService: CustomerService,
    protected router: Router,
    protected route: ActivatedRoute, private formBuilder: FormBuilder,
    _itemService: ItemService,
    protected _publicBookingService: PublicBookingService,
    snackBar: MatSnackBar,
    protected dialog: MatDialog,
    protected translate: TranslateService,
    protected signalR: SignalRService,
    protected titleService: Title,
    protected optimoTranslate: OptimoTranslate
  ) {
    super(store, _itemService, snackBar);

    this.login = new Login();
    //var appsetting = environment.AppSetting as AppSetting;


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_title: this.appsetting.PageTitle + ' | Sign In',
          page_path: event.urlAfterRedirects,
          page_location: window.location.href
        })
      }
    });
    //this.cart$ = this.store.select('cart');
    this.isSystemAllowedImmediateConfirmation = false;
    this.systemDefaultPriceConsessionId = 1;
    this.customerDefaultPriceConsessionId = 0;
    this.bookingTypeArray = [];
    this.isAllImmediateConfirmed = false;
    this.ApiUrl = environment.ApiUrl;
    this.RegularBookingPortalUrl = environment.RegularBookingPortalUrl;
    this.WasteBookingPortalUrl = environment.WasteBookingPortalUrl;
    this.PublicBookingPortalUrl = environment.PublicWebPortalUrl;
    this.displayForgotUserNameLink = this.appsetting.ClientRegistrationConfig.DisplayForgotUserNameLink;
    this.ForgotPasswordLink = this.appsetting.ClientRegistrationConfig.ForgotPasswordLink;
    this.signUpButtonSetting = this.appsetting.MenuSettings ? this.appsetting.MenuSettings.find(x => x.Id == 'SignUp') ? this.appsetting.MenuSettings.find(x => x.Id == 'SignUp') : null : null;
    this.homeSetting = this.appsetting.MenuSettings ? this.appsetting.MenuSettings.find(x => x.Id == 'Home') ? this.appsetting.MenuSettings.find(x => x.Id == 'Home') : null : null;
    this.isPublicWebPortal = environment.PortalType == '4';

    this.store.select(LanguageSelector.getSelectedLanguage).safeSubscribe(this, storedLanguage => {
      this.selectedLanguage = !!storedLanguage ? storedLanguage : '';
    });
    this.isSocialMediaLogin = (environment.PublicBookingSetting as PublicBookingSettings).socialMediaLogin;
    this.enableNewDesignForLoginPage = (environment.PublicBookingSetting as PublicBookingSettings).enableNewDesignForLoginPage;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.appsetting.PageTitle) {
      this.titleService.setTitle(this.appsetting.PageTitle + ' | Sign In');
    }
    this.store.select(LanguageSelector.getLanguages).safeSubscribe(this, storedLanguages => {
      this.languages = !!storedLanguages ? storedLanguages : [];
    });

    this.route.queryParamMap.subscribe(params => {
      if (params.get("DirectLoginFromBooking") == "1") {
        this.directLoginFromBooking = true;
      }
      if (params.get("ContinueAsGuest") == "1") {
        this.continueAsGuest = true;
      }
      if (params.get("DirectLoginFromPublicWeb") == "1") {
        this.directLoginFromPublicWeb = true;
        if (params.get("bookingRef")) {
          this.canEnableGuestCheckout(params.get("bookingRef"))
          this.bookingRef = params.get("bookingRef");
        }
        if (params.get("ReturnUrl")) {
          this.PublicBookingReturnUrl = decodeURIComponent(params.get("ReturnUrl"));
        }
      }
      if (params.get("regularBooking") == "1") {
        this.regularBooking = true;
      }
      if (params.get('wasteBooking') == '1') {
        this.wasteBooking = true;
        this.wasteBookingFromCart = params.get('from') === 'bookingCart';
        //   this.wasteBookingFromBookingState = params.get('from') === 'bookingState';
      }

    });
    //  this.store.dispatch(new FacilitySearchLoaded());
    this.nextUrlPopulate();
    this.enableB2cIntegration = (this.appsetting?.B2CIntegration?.B2CLoginProvider ?? '') != '';

    this.frmLogin = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.errorMessage = "";

    //this.cart$.safeSubscribe(this,state => {
    //    this.customerDefaultPriceConsessionId = state.contact.client.priceConcessionId;
    //    this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
    //    console.log("customerDefaultPriceConsessionId LOGIN ", this.customerDefaultPriceConsessionId);

    //});
    this.frmLogin.controls.password.valueChanges.subscribe(x => {
      this.errorMessage = '';
    })
    this.frmLogin.controls.username.valueChanges.subscribe(x => {
      this.errorMessage = '';
    })
  }

  nextUrlPopulate() {
    // if portal setup it as regular Portal Only
    var _redirectFullUrl = '';
    if (environment.PortalType == "3" || this.regularBooking) {

      if ((environment.PortalLoginRedirectUrl != null && environment.PortalLoginRedirectUrl != '')) {
        _redirectFullUrl = environment.PortalLoginRedirectUrl;
      } else {
        _redirectFullUrl = environment.RegularBookingPortalUrl + '#/bookings'
      }

    } else if (environment.PortalType == '5' || this.wasteBooking) {
      this.store.dispatch(new ResetInternalUserDataAction());

      if (this.wasteBookingFromCart) {
        // this.store.dispatch(new Redirect('WasteManagementBookingCart'));
        _redirectFullUrl = environment.WasteBookingPortalUrl + RoutePath.WasteManagementBookingCart;
      } else if ((environment.PortalLoginRedirectUrl != null && environment.PortalLoginRedirectUrl != '')) {
        _redirectFullUrl = environment.PortalLoginRedirectUrl;
      } else {
        _redirectFullUrl = environment.WasteBookingPortalUrl;
      }
      // return;
    } else if (environment.PortalType == '4' || this.directLoginFromPublicWeb) {

      if (this.PublicBookingReturnUrl) {
        _redirectFullUrl = environment.PublicWebPortalUrl + ('#' + this.PublicBookingReturnUrl);
      } else if ((environment.PortalLoginRedirectUrl != null && environment.PortalLoginRedirectUrl != '')) {
        _redirectFullUrl = environment.PortalLoginRedirectUrl;
      } else {
        _redirectFullUrl = environment.PublicWebPortalUrl
      }
    } else {
      //move this to cart
      /* if ((this.customerDefaultPriceConsessionId && this.customerDefaultPriceConsessionId > 0) &&
        (!this.isAllImmediateConfirmed || this.customerDefaultPriceConsessionId !== this.systemDefaultPriceConsessionId)
      ) {
        priceChanged = true;
        this.calculateCartPrices();
      }
 */
    }
    //TODO check ciam url alredy not set and set
    if (_redirectFullUrl != '') {
      this.store.dispatch(new SetCIAMReturnUrl(_redirectFullUrl));
    }
  }
  guestCheckout() {
    const ref = this.dialog.open(GuestClientComponent, {
      data: {
        publicBookingReturnUrl: this.PublicBookingReturnUrl
      },
      height: "auto",
      width: '35%',
      panelClass: ['login-modal-width'],
    });
    ref.afterClosed().safeSubscribe(this, x => {
      if (x && x.clientAlreadyExist) {
        const ref = this.dialog.open(ExistingGuestMailComponent, {
          height: "auto",
          data: {
            publicBookingReturnUrl: this.PublicBookingReturnUrl
          },
          width: '40%',
          panelClass: ['custom-dialog-container']
        });
        ref.afterClosed().safeSubscribe(this, v => {
          if (v.login) {
            this.frmLogin.controls.username.setValue(x.email);
          } else if (v.forgetPassword) {
            this.router.navigate(['/client/forget-password'], {
              queryParams: {
                email: x.email,
                ReturnUrl: this.PublicBookingReturnUrl,
                directLoginFromPublicWeb: '1',
                bookingRef: this.bookingRef

              }
            })
          }
        })
      }
    })
  }
  canEnableGuestCheckout(bookingId) {
    this._publicBookingService.selectBooking(bookingId).safeSubscribe(
      this,
      (d) => {
        if (d.isError) {
        } else {
          const bookingPackages = d.data.bookingPackages;
          this.enableGuestCheckout = bookingPackages.length == bookingPackages.filter(x => x.package.guestCheckout).length;
        }
      }
    );
  }
  onSubmit() {
    if (!this.frmLogin.invalid) {
      this.login.Email = this.frmLogin.controls.username.value;
      this.login.Password = this.frmLogin.controls.password.value;

      this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
      this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;

      this.login.Status = 0;
      this._customerService.clientLogin(this.login).subscribe(data => {
        this._customerService.clientLoginOtherApp(this.login);

        if (!!data) {
          this.signalR.changeUserName(data?.id);
          if (!!data.preferredLanguageID && this.isPublicWebPortal) {
            if (!!this.languages && this.languages.length > 0) {
              let language = this.languages.find(x => x.id == data.preferredLanguageID);
              //Call below line whenever language is changed
              if (language) {
                this._publicBookingService.saveSelectedLanguage(language.languageCode)
                  .safeSubscribe(this, () => { this.translate.use(language.languageCode.toLowerCase()); });
              }
            }
          }

          // this.store.dispatch(new SetContact(data));
          this.customerDefaultPriceConsessionId = data.client.priceConcessionId;
          let priceChanged = false;
          let _idRedirectToAnother = false;
          let _redirectActionPayload: string, _navigateUrl: string, _queryParams: any, _redirectFullUrl: string, _action: any;

          // if portal setup it as regular Portal Only
          if (environment.PortalType == "3" || this.regularBooking) {
            _idRedirectToAnother = true;
            if ((environment.PortalLoginRedirectUrl != null && environment.PortalLoginRedirectUrl != '')) {
              _redirectFullUrl = environment.PortalLoginRedirectUrl;
            } else {
              if (((data.bookingTypeList != undefined
                && data.bookingTypeList.filter(x => x.id != "1")
                && data.bookingTypeList.filter(x => x.id != "1").length > 0))) {
                // this.store.dispatch(new Redirect("MyBooking"));
                _redirectActionPayload = "MyBooking";
                // _idRedirectToAnother = true;
              } else {
                this.frmLogin.controls.password.setValue('');
                this.errorMessage = 'User does not have permission to access to the regular-portal';
                // setTimeout(() => this.store.dispatch(new Redirect("MyProfile")), 1000);
                _redirectActionPayload = "MyProfile";
                // _idRedirectToAnother = true;
              }
            }
            // return;
          } else if (environment.PortalType == '5' || this.wasteBooking) {
            this.store.dispatch(new ResetInternalUserDataAction());
            _idRedirectToAnother = true;

            if (data && this.wasteBookingFromCart) {
              // this.store.dispatch(new Redirect('WasteManagementBookingCart'));
              _redirectFullUrl = environment.WasteBookingPortalUrl + RoutePath.WasteManagementBookingCart;
            } else if ((environment.PortalLoginRedirectUrl != null && environment.PortalLoginRedirectUrl != '')) {
              _redirectFullUrl = environment.PortalLoginRedirectUrl;
            } else {
              _redirectFullUrl = environment.WasteBookingPortalUrl;
            }
            // return;
          } else if (environment.PortalType == '4' || this.directLoginFromPublicWeb) {
            _idRedirectToAnother = true;

            if (this.PublicBookingReturnUrl) {
              _redirectFullUrl = environment.PublicWebPortalUrl + ('#' + this.PublicBookingReturnUrl);
            } else if ((environment.PortalLoginRedirectUrl != null && environment.PortalLoginRedirectUrl != '')) {
              _redirectFullUrl = environment.PortalLoginRedirectUrl;
            } else {
              _redirectFullUrl = environment.PublicWebPortalUrl
            }
          } else {
            if ((this.customerDefaultPriceConsessionId && this.customerDefaultPriceConsessionId > 0) &&
              (!this.isAllImmediateConfirmed || this.customerDefaultPriceConsessionId !== this.systemDefaultPriceConsessionId)
            ) {
              priceChanged = true;
              this.calculateCartPrices();
            }

            if (!_idRedirectToAnother) {
              // checking the URL History for Casual portal Only or Both (PortalType not setup means Both)
              if (this.directLoginFromBooking || this.isFromBooking == "1" && (environment.PortalType == "1" || (!environment.PortalType))) {
                if (priceChanged) {
                  //this.store.dispatch(new Redirect("CART-PRICECHANGE"));
                  _redirectActionPayload = "CART-PRICECHANGE";
                } else {
                  if (this.directLoginFromBooking)
                    //this.store.dispatch(new Redirect("CART"));
                    _redirectActionPayload = "CART";
                  else
                    //this.store.dispatch(new DoBooking());
                    _action = new SaveBooking();
                }
                _idRedirectToAnother = true;
                /// return;
              } else if ((environment.PortalLoginRedirectUrl != null && environment.PortalLoginRedirectUrl != '')) {
                _redirectFullUrl = environment.PortalLoginRedirectUrl;
                _idRedirectToAnother = true;
              } else if (Utility.urlHistorty.length > 0) {
                var link = Utility.urlHistorty[Utility.urlHistorty.length - 1];
                if (link != "/client/setup%3FfromLogin%3D1") {
                  var qry = { queryParams: Utility.sortParams(link) };
                  //this.router.navigate([`${link.split('?')[0]}`], qry)
                  _navigateUrl = `${link.split('?')[0]}`
                  _queryParams = qry
                  _idRedirectToAnother = true;
                  // return;
                }
              }
            }

            if (!_idRedirectToAnother) {
              // if there is No history and Not from Cart Page blow logic will execute
              if (environment.PortalType == "1") {
                //this.store.dispatch(new Redirect("FACILITY"));
                _redirectActionPayload = "FACILITY";
                _idRedirectToAnother = true;
                //return;
              } else if (!environment.PortalType) {
                // if user have RB access
                if (((data.bookingTypeList != undefined
                  && data.bookingTypeList.filter(x => x.id != "1")
                  && data.bookingTypeList.filter(x => x.id != "1").length > 0))) {
                  //this.store.dispatch(new Redirect("MyBooking"));
                  //return;
                  _redirectActionPayload = "MyBooking";
                  _idRedirectToAnother = true;
                }
                // portal type not defined  and User does not have access for Regular booking
                else {
                  //this.store.dispatch(new Redirect("FACILITY"));
                  //return;
                  _redirectActionPayload = "FACILITY";
                  _idRedirectToAnother = true;
                }
              }
            }
            // this will redirect to My booking page when portal type is not defined and History is not there and User have Both access (regular and casual  )
            // this.store.dispatch(new Redirect("MyBooking"));
          }

          if (_idRedirectToAnother) {
            this.store.dispatch(new SetContact(data, _redirectActionPayload, _navigateUrl, _queryParams, _redirectFullUrl, _action));
          } else {
            _redirectActionPayload = "MyBooking";
            this.store.dispatch(new SetContact(data, _redirectActionPayload, null, null, null, null));
          }
        }
      },
        err => {
          this.login.Status = 1;
          this._customerService.clientLoginOtherApp(this.login);
          this.frmLogin.controls.password.setValue('');
          // this.errorMessage = 'Invalid Credentials';
          this.appsetting.APIMessages.forEach((APIMessages) => {
            APIMessages.Value = this.optimoTranslate.transform('SIGNIN.' + APIMessages.Value);
          })
          let _errors = Utility.GetErrorMessageToBeDisplayed(err, 'Invalid Credentials', this.appsetting.APIMessages)
          this.errorMessage = _errors[0];
        },
        () => { }
      );

    } else {
      this.frmLogin.controls.password.setValue('');
      this.errorMessage = 'Invalid Credentials';
    }
  }

  onContinueAGuestClick(): void {
    this.store.dispatch(new Redirect("CONTINUE-AS-GUEST-FROM-CART"));
  }
  signupClick() {
    if (this.directLoginFromBooking) {
      this.store.dispatch(new Redirect("SIGNUP-FROM-BOOKING-DETAIL"));
    } else if (this.isFromBooking == "1") {
      this.store.dispatch(new Redirect("CART-SIGNUP"));
    } else if (this.wasteBooking) {
      if (this.wasteBookingFromCart) {
        this.store.dispatch(new Redirect('SignUpFromWasteManagementCart'));
      } else {
        this.store.dispatch(new Redirect('SignUpFromWasteManagement'));
      }
    } else if (this.directLoginFromPublicWeb) {
      if (this.PublicBookingReturnUrl) {
        const Url = environment.ApiUrl + "Booking/Casual#/client/setup?fromPublicWeb=1&ReturnUrl=" + this.PublicBookingReturnUrl;
        window.open(Url, "_self");
      } else {
        this.store.dispatch(new Redirect("SIGNUPFROMPUBLICWEB"));
      }
    }
    else {
      this.store.dispatch(new Redirect("SIGNUP"));
    }
    window.scrollTo(0, 0);
  }
  get WMComponentsTitleText() {
    return WMComponentsTitleText;
  }
  navigateToHome() {
    if (this.directLoginFromPublicWeb) {
      if (environment.PublicPortalHomeUrl.trim()) {
        window.location.href = environment.PublicPortalHomeUrl;
      }
      else {
        window.location.href = this.PublicBookingPortalUrl;
      }
    }
    else if (this.wasteBooking) {
      window.location.href = this.WasteBookingPortalUrl;
    }
    else {
      window.location.href = "";
    }
  }

  switchLanguage() {
    //Call below line whenever language is changed
    this._publicBookingService.saveSelectedLanguage(this.selectedLanguage).safeSubscribe(this, () => { this.translate.use(this.selectedLanguage.toLowerCase()); });
  }
  goToForgotPassword() {
    if (this.directLoginFromPublicWeb && !!this.PublicBookingReturnUrl)
      window.open(environment.ApiUrl + "#/client/forget-password?DirectLoginFromPublicWeb=1&ReturnUrl=" + this.PublicBookingReturnUrl, "_self");
    else
      window.open(environment.ApiUrl + "#/client/forget-password", "_self");
  }
}
