<main role="main">
  <form [formGroup]="guestClientSaveForm" autocomplete="off" (ngSubmit)="OnSaveGuestClient()">
    <div class="mmc_corporate client_wrapper individual_customer">
      <section>
        <div class="font__semibold title">
          <div class="name">
            <div class="name__inner desktop pw-txt-h3_small pw-text-semi-bold">{{'SIGNUP.Your Details'|
              optimotranslate}}</div>
            <div class="name__inner mobile pw-txt-h3_small pw-text-semi-bold">{{'SIGNUP.Your Details'|
              optimotranslate}}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <mat-form-field appearance="outline">
              <mat-label>{{'MYPROFILE.First Name'| optimotranslate}}</mat-label>
              <input
                matInput placeholder=""
                calss="form-control"
                formControlName = "FirstName"
                required
                autocomplete="off">
              <mat-hint></mat-hint>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <mat-form-field appearance="outline">
              <mat-label>{{'MYPROFILE.Last Name'| optimotranslate}}</mat-label>
              <input
                matInput placeholder=""
                calss="form-control"
                required
                formControlName = "LastName"
                autocomplete="off">
              <mat-hint></mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <mat-form-field appearance="outline">
              <mat-label>{{'MYPROFILE.Email Address' | optimotranslate}}</mat-label>
              <input
                matInput placeholder=""
                calss="form-control"
                required
                formControlName = "PersonalEmail"
                autocomplete="off">
              <mat-hint></mat-hint>
              <mat-error
                *ngIf="guestClientSaveForm.controls.PersonalEmail.errors !== null && guestClientSaveForm.controls.PersonalEmail.touched"
              >
                {{'MYPROFILE.Please enter valid'| optimotranslate}} {{'MYPROFILE.Email Address' | optimotranslate
                |lowercase}}.</mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <mat-form-field appearance="outline">
              <mat-label>{{'MYPROFILE.Phone Number' | optimotranslate}}</mat-label>
              <input
                matInput placeholder="{{placholderSampleNumber}}"
                calss="form-control"
                formControlName = "PersonalPhoneNumber"
                required
                autocomplete="off">
              <mat-hint></mat-hint>
              <mat-error
                *ngIf="guestClientSaveForm.controls.PersonalPhoneNumber.errors !== null && guestClientSaveForm.controls.PersonalPhoneNumber.touched"
              >
                {{'MYPROFILE.Please enter valid'| optimotranslate}} {{'MYPROFILE.Phone Number' | optimotranslate
                |lowercase}}.</mat-error>
            </mat-form-field>
          </div>
        </div>
      </section>
      <div class="mmc_flex__item--vcenter">
        <i class="material-icons"> chevron_left </i>
        <a
          class="pw-txt-h4"
          href="javascript:void(0);"
          (click)="Redirect()"
          (keydown.enter)="Redirect()"
        >Back
        </a>
      </div>
      <div class="mmc_flex__item--vcenter mmc_flex__item--hend">
        <div>

          <button
            type="submit"
            class="btn__card d-flex btn-continue main mmc_flex__item--vcenter"
            *ngIf="!(cart$|async)?.isAllImmediateConfirmed"
            [ngClass]="{'disable-div' : !guestClientSaveForm.valid }"
          >
            Submit enquiry
          </button>
          <button
            type="submit"
            class="btn__card btn-continue main mmc_flex__item--vcenter"
            *ngIf="(cart$|async)?.isAllImmediateConfirmed && (cart$|async)?.guestCartItemMinimumAmount > 0"
            [ngClass]="{'disable-div' : !guestClientSaveForm.valid }"
          >
            Confirm and pay
          </button>
          <button
            type="submit"
            class="btn__card btn-continue main mmc_flex__item--vcenter"
            *ngIf="(cart$|async)?.isAllImmediateConfirmed && (cart$|async)?.guestCartItemMinimumAmount == 0"
            [ngClass]="{'disable-div' : !guestClientSaveForm.valid }"
          >
            Confirm
          </button>
        </div>
      </div>

    </div>
  </form>
</main>
