
import { Injectable, Injector, OnDestroy } from '@angular/core';


import { environment } from 'src/environments/environment';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';

import { AuthService } from '../auth-service';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { ICart } from 'src/modules/store';
import { Contact } from 'src/modules/models/client/contact';
import { SetB2CContact, SetContact, SignOut } from 'src/modules/store/actions';
import { CustomerService } from 'src/modules/services/customer.service';
import { Client } from 'src/modules/models/client/client';
import { SetCIAMDataLoaded, SetCIAMReturnUrl } from 'src/modules/store/loading/actions';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { editProfileMsalInstance, editProfileRequest, silentRequest } from './utils/auth-config';
import { AccountInfo, AuthenticationResult, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { Utility } from 'src/modules/utility';
import { ContactFilters } from 'src/modules/models/filters/contact-filters';


@Injectable({
    providedIn: 'root',
})
export class AzureCiamAuthService implements AuthService, OnDestroy {

    appsetting = environment.AppSetting as AppSetting;
    loggedInDBContact: Contact;
    b2cContact: Contact;

    private store: Store<any>
    private customerService: CustomerService;
    private injector: Injector;

    private intervalId: any;
    private checkInterval = 1000 * 60 * 5; // Example interval time

    //CIAM
    //private optMsalProvider: MsalServiceProvider;
    private authService: MsalService
    private msalBroadcastService: MsalBroadcastService

    private readonly _destroying$ = new Subject<void>();
    private msalInstance;

    public loggedInAccounts: AccountInfo[] = [];
    public initialized: boolean = false;
    private loginIsProcessing = false;

    dependencyLoaded = false;
    constructor() {

    }



    async init(injector: Injector, st: Store<any>, customerService: CustomerService): Promise<void> {
        this.store = st;
        this.customerService = customerService;
        this.injector = injector;
        // this.optMsalProvider = injector.get(MsalServiceProvider);

        this.authService = injector.get(MsalService);
        this.msalBroadcastService = injector.get(MsalBroadcastService);
        this.msalInstance = this.authService.instance;
        if (!this.dependencyLoaded) {
            this.dependencyLoaded = true;
            this.initializeCIAM();


            this.store.select('cart').pipe().subscribe((st: ICart) => {
                this.b2cContact = st.b2cContact;
                this.loggedInDBContact = st.contact;
            })
        }

        console.log(`Init Azure CIAM`);

    }


    async silentLogin(fromLogin?: boolean): Promise<void> {
        console.log(`silentLogin Azure CIAM`);
        this.silentLoginRequestSend(fromLogin);
    }

    async loginRedirect(): Promise<void> {
        console.log(`Login with azure ciam`);
        if (!this.loginIsProcessing) {
            this.loginIsProcessing = true;

            this.loginActionClick()
        } else {
            setTimeout(() => {
                this.loginIsProcessing = false;
            }, 2000);
        }
    }
    //test
    async logout(callback?: () => void): Promise<void> {
        console.log('Logout from azure');
        // Implement logout logic
        this.handleLogout(callback)
    }

    async profileRedirect(): Promise<void> {
        console.log(`Azure Profile Redirect`);
        // var _redirectUrl = encodeURIComponent(environment.ApiUrl + '/Customer/Profile');      
        await editProfileMsalInstance.initialize();
        editProfileMsalInstance
            .handleRedirectPromise()
            .then((authResult) => {
                editProfileMsalInstance.loginRedirect(
                    editProfileRequest as RedirectRequest
                );
            })
            .catch((err) => {
                // TODO: Handle errors
                console.log(err);
            });
    }
    async changePasswordRedirect(): Promise<void> {
        console.log(`azure Change Password Redirect`);
        // var _redirectUrl = encodeURIComponent(environment.ApiUrl + '/Customer/Profile');      
        await editProfileMsalInstance.initialize();
        editProfileMsalInstance
            .handleRedirectPromise()
            .then((authResult) => {
                editProfileMsalInstance.loginRedirect(
                    editProfileRequest as RedirectRequest
                );
            })
            .catch((err) => {
                // TODO: Handle errors
                console.log(err);
            });
    }
    ngOnDestroy(): void {

        this._destroying$.next(undefined);
        this._destroying$.complete();

    }

    getSetupCustomerRedirectUrl(c: Contact): Promise<string> {
        return new Promise((resolve, reject) => {
            resolve(environment.ApiUrl + '/Booking/Casual#/client/setup');
        });
    }

    getSSOContactRef(c: Contact): string {
        return (c?.ssoContactRef ?? '')
    }
    /************************************************************************************************************************ */
    private async initializeCIAM() {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) => status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setAccounts();
            });

        this.msalInstance.addEventCallback((event: any) => {
            // set active account after redirect
            if (
                (event.eventType === EventType.LOGIN_SUCCESS ||
                    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                    event.eventType === EventType.SSO_SILENT_SUCCESS) &&
                event?.payload?.account
            ) {
                const account = event.payload.account;
                this.msalInstance.setActiveAccount(account);
            }

            /*  if (event.eventType === EventType.SSO_SILENT_FAILURE && event.error?.errorCode === 'monitor_window_timeout') 
             {       
               this.msalInstance.acquireTokenRedirect();
             } */
        });


        await this.authService.instance.initialize();
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        // Set active acccount on page load
        const accounts = this.msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            //this.msalInstance.setActiveAccount(accounts[0]);
        }

        this.msalInstance
            .handleRedirectPromise()
            .then((authResult: any) => {
                this.silentLoginRequestSend(false)
            })
            .catch((err: any) => {
                // TODO: Handle errors
                console.log(err);
                // retry one more time
                /*   setTimeout(() => {
                    this.silentLoginRequestSend()
                  }, 1000); */

            });
    }
    setAccounts() {
        this.loggedInAccounts = this.msalInstance.getAllAccounts();
    }


    async loginActionClick() {
        // this.store.dispatch(new SetOptimoContactChecked(false));
        // handle auth redired/do all initial setup for msal1
        await this.authService.instance.initialize();
        this.msalInstance
            .handleRedirectPromise()
            .then((authResult: any) => {
                // Check if user signed in
                const account = this.msalInstance.getActiveAccount();
                if (!account) {
                    this.store.dispatch(new SetCIAMDataLoaded(false));

                    this.azureSilentLogin(true,
                        () => {
                            this.initialized = true;
                            this.loginIsProcessing = false;
                            this.store.dispatch(new SetCIAMDataLoaded(true));
                            this.setAccounts();
                        },
                        async () => {
                            await this.authService.instance.initialize();
                            this.authService.loginRedirect();
                        }
                    );
                } else {
                    this.store.dispatch(new SetCIAMDataLoaded(true));
                    this.setAccounts();
                }
            })
            .catch(async (err: any) => {
                this.loginIsProcessing = false;
                // TODO: Handle errors
                console.log(err);
                await this.authService.instance.initialize();
                this.authService.loginRedirect();
            });
    }



    silentLoginRequestSend(fromLogin: boolean) {
        this.azureSilentLogin(fromLogin,
            () => {
                this.initialized = true;
                this.store.dispatch(new SetCIAMDataLoaded(true));
                this.setAccounts();
            },
            () => {
                console.log('silent login failed');
                this.initialized = true;
                this.store.dispatch(new SetCIAMDataLoaded(true));
                this.store.dispatch(new SetB2CContact(undefined));
                this.store.dispatch(new SetContact(undefined));
                this.setAccounts();
            }
        );
    }
    private handleLogin(response: any) {
        // Handle successful login here
        console.log('Login successful:', response);
        // Call your UI update function

    }

    private async handleLogout(callback?: () => void) {
        this.store.dispatch(
            new SetCIAMReturnUrl(
                window.location.href.replace(
                    '/Customer/Index#/',
                    '/Booking/Casual#/'
                )
            )
        );

        this.store.dispatch(new SetCIAMDataLoaded(false));
        await this.authService.instance.initialize();
        await this.msalInstance.handleRedirectPromise();
        this.customerService.signOut().subscribe(() => {

        });
        // this.store.dispatch(new ClearAll());
        environment.LoggedInUser = '';
        this.store.dispatch(new SignOut());

        const _azureSettings = this.appsetting.B2CIntegration?.AzureB2CIntegration;

        this.authService.logout({
            postLogoutRedirectUri:
                (_azureSettings)?.PostLogoutUrl ?? '',
        });
        // Redirect to Azure AD B2C logout URL
        var _tenantId = ((_azureSettings)?.Tenant.Id ?? "");
        var _logoutUrl = ((_azureSettings)?.LogoutUrl ?? "");
        // "https://" + _tenantId + ".b2clogin.com/" + _tenantId + ".onmicrosoft.com/oauth2/v2.0/logout"
        window.location.href = `${_logoutUrl}?post_logout_redirect_uri=${encodeURIComponent((_azureSettings)?.PostLogoutUrl ?? '')}`;

    }

    azureSilentLogin(fromLogin: boolean, onSuccess: Function, onError: Function) {
        console.log(silentRequest)
        this.authService.ssoSilent(silentRequest).subscribe({
            next: (result: AuthenticationResult) => {
                console.log('SsoSilent succeeded!');
                console.log(
                    this.appsetting?.B2CIntegration?.AzureB2CIntegration?.ContactFieldMappings
                );
                if (
                    result &&
                    result.account?.idTokenClaims &&
                    (this.appsetting?.B2CIntegration?.AzureB2CIntegration?.ContactFieldMappings ?? []).length > 0
                ) {
                    var _contact: { [key: string]: any } = JSON.parse(
                        JSON.stringify(this.loggedInDBContact ?? {})
                    );

                    this.appsetting?.B2CIntegration?.AzureB2CIntegration?.ContactFieldMappings?.forEach(
                        (item, index) => {
                            var _arr = item.MappedTo.split('.');
                            var _obj = _contact;
                            _arr.forEach((arr, i) => {
                                if (i < _arr.length - 1) {
                                    if (!_contact[arr]) {
                                        _contact[arr] = {};
                                    }
                                    _obj = _contact[arr];
                                } else {
                                    //last element
                                    let _dt = Utility.getValueByKey(
                                        result.account?.idTokenClaims,
                                        item.Claim
                                    );
                                    if (arr == 'phone') {
                                        if (Array.isArray(_dt)) {
                                            _obj[arr] = _dt
                                        } else {
                                            _obj[arr] = [];
                                            _obj[arr].push(_dt)
                                        }
                                    }
                                    else if (arr == 'email') {
                                        if (Array.isArray(_dt)) {
                                            _obj[arr] = _dt[0]
                                        } else {
                                            _obj[arr] = _dt;
                                        }
                                    }
                                    else {
                                        _obj[arr] = _dt
                                    }


                                }
                            });
                        }
                    );
                    let _cn = _contact as Contact;
                    this.store.dispatch(new SetB2CContact(_cn));

                    this.store.dispatch(new SetCIAMDataLoaded(true));

                    /*  if (fromOnLogin) {                   
                         this.store.dispatch(new SetCIAMDataLoaded(true));
                     }
                     else  */
                    if (
                        !this.loggedInDBContact?.lastFetchedTime ||
                        this.loggedInDBContact?.lastFetchedTime! <
                        new Date(Date.now() - 300 * 1000)
                    ) {
                        this.loadContactInfo(_cn?.ssoContactRef ?? '');
                    }

                    if (this.loggedInDBContact && this.b2cContact) {
                        this.compareContact(this.b2cContact, this.loggedInDBContact);
                    }

                    //if store contact  values are different than claim call client patch
                    //if store contact is empty redirect to the customer profile creation
                }
                if (onSuccess) {
                    onSuccess();
                }
            },
            error: (error: any) => {
                console.log(error);
                if (onError) {
                    onError(error);
                }
                this.msalInstance.setActiveAccount(null);
            },
        });
    }




    loadContactInfo(altRef: string, email: string = "") {
        if (altRef != "") {


            let filters: ContactFilters = {
                ssoContactRef: altRef,
                isFromLogin: true,
                disableSpinner: "1"
            };

            if ((email ?? '') != '') {
                filters.email = email;
            }

            //loading
            this.customerService.searchContacts(filters).subscribe(
                (res) => {
                    console.log('Optimo Contact: ', res);
                    let _contact: Contact | undefined = undefined;
                    if (res) {//(res?.length ?? 0) > 0
                        _contact = res;
                        _contact.lastFetchedTime = new Date();
                        this.store.dispatch(new SetContact(_contact));
                    } else {
                        this.store.dispatch(new SetContact(undefined));
                    }

                    //this.store.dispatch(new SetOptimoContactChecked(true));
                },
                () => {

                    //this.store.dispatch(new SetOptimoContactChecked(true));
                    this.store.dispatch(new SetContact(undefined));
                }
            );
        }
    }

    compareContact(
        ciamContact: Contact | undefined,
        dbContact: Contact | undefined
    ) {
        var _updatedContact: { [key: string]: any } = {};

        (this.appsetting?.B2CIntegration?.AzureB2CIntegration?.ContactFieldMappings ?? []).forEach(
            (item, index) => {
                if (item.MappedTo != 'email' && item.MappedTo != 'phone') {
                    var _arr = item.MappedTo.split('.');

                    var _objCIAM = JSON.parse(JSON.stringify(ciamContact ?? {}));
                    var _objDB = JSON.parse(JSON.stringify(dbContact ?? {}));

                    if (_arr.length == 1) {
                        if (
                            Utility.getValueByKey(_objCIAM, _arr[0]) !=
                            Utility.getValueByKey(_objDB, _arr[0])
                        ) {
                            _updatedContact[_arr[0]] = Utility.getValueByKey(
                                _objCIAM,
                                _arr[0]
                            );
                        }
                    } else {
                        var _obj = JSON.parse(JSON.stringify(_updatedContact));
                        var _firstLevelAttribute = '';
                        var _firstLevelObj: any;
                        _arr.forEach((arr, i) => {
                            if (i == 0) {
                                if (!_obj[arr]) {
                                    _obj[arr] = {};
                                }
                                _firstLevelAttribute = arr;
                                _firstLevelObj = _obj[arr];
                            }

                            if (i < _arr.length - 1) {
                                if (!_obj[arr]) {
                                    _obj[arr] = {};
                                }
                                _obj = _obj[arr];
                                _objCIAM = _objCIAM[arr];
                                _objDB = _objDB[arr];
                            } else {
                                //last element
                                if (
                                    Utility.getValueByKey(_objCIAM, arr) !=
                                    Utility.getValueByKey(_objDB, arr)
                                ) {
                                    _obj[arr] = Utility.getValueByKey(_objCIAM, arr);
                                    _updatedContact[_firstLevelAttribute] = _firstLevelObj;
                                }
                            }
                        });
                    }
                } else {
                    //email mapping
                    if (item.MappedTo == 'email') {
                        var _email = '';
                        if (Array.isArray(ciamContact?.email)) {
                            if ((ciamContact?.email ?? []).length > 0) {
                                (ciamContact?.email ?? []).forEach((item, index) => {
                                    _email = item ?? '';
                                });
                            }
                        } else {
                            _email = ciamContact?.email ?? '';
                        }

                        var _exi = (dbContact.communicationMethods ?? []).find(c => c.communicationTypeID == ((dbContact?.customerType == 1) ? "6" : "5"))
                        if (!_exi || _exi.value != _email) {
                            if (!ciamContact.communicationMethods) {
                                ciamContact.communicationMethods = []
                            }
                            ciamContact.communicationMethods.push({
                                communicationTypeID: ((dbContact?.customerType == 1) ? "6" : "5"),
                                value: _email,

                            })
                        }

                    } else if (item.MappedTo == 'phone') {
                        //phone mapping
                        var _phone = '';

                        if (Array.isArray(ciamContact?.phone)) {
                            if ((ciamContact?.phone ?? []).length > 0) {
                                (ciamContact?.phone ?? []).forEach((item, index) => {
                                    _phone = item ?? '';
                                });
                            }
                        } else {
                            _phone = ciamContact?.phone ?? '';
                        }
                        var _exi = (dbContact.communicationMethods ?? []).find(c => c.communicationTypeID == ((dbContact?.customerType == 1) ? "3" : "1"))
                        if (!_exi || _exi.value != _phone) {
                            if (!ciamContact.communicationMethods) {
                                ciamContact.communicationMethods = []
                            }
                            ciamContact.communicationMethods.push({
                                communicationTypeID: ((dbContact?.customerType == 1) ? "3" : "1"),
                                value: _phone,

                            })
                        }
                    }
                }
            }
        );

        console.log(_updatedContact);
        var _keys = Object.keys(_updatedContact);
        //attribute pending for patch
        if (_keys.length > 0) {
            //map ids
            _updatedContact['id'] = dbContact?.id;
            if (!_updatedContact['client']) {
                _updatedContact['client'] = {
                    Id: dbContact?.client?.id,
                };
            }

            if (_updatedContact['address']) {
                if (dbContact?.customerType == 1) {
                    //individual

                    _updatedContact['client']['address'] = JSON.parse(
                        JSON.stringify(_updatedContact['address'])
                    );
                    _updatedContact['client']['address']['id'] =
                        dbContact?.client?.address?.id;
                    delete _updatedContact['address'];
                } else {
                    _updatedContact['address']['id'] = dbContact?.address?.id;

                    if (
                        (dbContact?.address?.id ?? '') !=
                        (dbContact?.client?.address?.id ?? '') &&
                        this.isClientContactAddressSame(dbContact)
                    ) {


                        _updatedContact['client']['address'] = JSON.parse(
                            JSON.stringify(_updatedContact['address'])
                        );
                        _updatedContact['client']['address']['id'] =
                            dbContact?.client?.address?.id;
                    }
                }
            }

            //call customer patch
            _updatedContact['customerType'] = dbContact?.customerType;
            this.customerService.patchContactV2(_updatedContact).subscribe((res) => { });
        }


    }

    isClientContactAddressSame(dbContact: Contact) {
        return (
            (dbContact?.address?.address1 ?? '') ==
            (dbContact?.client?.address?.address1 ?? '') &&
            (dbContact?.address?.address2 ?? '') ==
            (dbContact?.client?.address?.address2 ?? '') &&
            (dbContact?.address?.address3 ?? '') ==
            (dbContact?.client?.address?.address3 ?? '') &&
            (dbContact?.address?.city ?? '') ==
            (dbContact?.client?.address?.city ?? '') &&
            (dbContact?.address?.country ?? '') ==
            (dbContact?.client?.address?.country ?? '') &&
            (dbContact?.address?.postCode ?? '') ==
            (dbContact?.client?.address?.postCode ?? '') &&
            (dbContact?.address?.stateCode ?? '') ==
            (dbContact?.client?.address?.stateCode ?? '') &&
            (dbContact?.address?.state ?? '') ==
            (dbContact?.client?.address?.state ?? '')
        );
    }





}
