import {Component, OnInit, OnDestroy, Input} from '@angular/core';

import { SystemService } from '../../services/system.service';
import { environment } from 'src/environments/environment';

import { Store, ICart } from '../../store/index';
import { Observable } from "rxjs";
import {SetGuestContact, SetIsGuestAgreedTerms, SetIsGuestisValidPackage, SetIsGuestMinimumAmount} from '../../store/actions';
import { Contact } from 'src/modules/models/client/contact';
import {FacilitySearchLoaded} from '../../store/loading';

@Component({
    selector: 'opt-mmc-enquiry',
    templateUrl: './mmc-enquiry.component.html'
})

export class MmcEnquiryComponent implements OnInit, OnDestroy {
    cart$: Observable<ICart>;
    cart_subscriber: any;
    submitEnquiryMessage: string;
    @Input() guestFirstName : string;
    @Input() guestLastName : string;

    constructor(private store: Store<any>, private _systemService: SystemService) {
        if (this.cart_subscriber) {
            this.cart_subscriber.unsubscribe();
        }
      this.submitEnquiryMessage = environment.SubmitEnquiryMessage ;
    }

    ngOnInit() {
        this.cart$ = this.store.select('cart');
        var contact = new Contact()
        this.store.dispatch(new SetIsGuestAgreedTerms(false));
        this.store.dispatch(new SetIsGuestisValidPackage(false));
        this.store.dispatch(new SetIsGuestMinimumAmount(0));
        this.store.dispatch(new FacilitySearchLoaded());
        this.store.dispatch(new SetGuestContact(contact))
    }

    ngOnDestroy() {
        if (this.cart_subscriber) {
            this.cart_subscriber.unsubscribe();
        }
    }

    getConfigurationSettings() {
        this._systemService.loadConfiguration().subscribe(data => {
            if (data != null) {
                this.submitEnquiryMessage = data.SubmitEnquiryConfig.Message;
            }
        })
    }
}
