import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";

import { OptMaterialModule } from '../shared/opt-material/opt-material.module';
import { CdkColumnDef } from '@angular/cdk/table';

import { OptFormModules } from 'src/modules/shared/form.modules';
import { BookingRoutingModule } from './booking-routing.module';

import { CustomMatPaginatorIntl } from './common/custom-mat-paginator-intl/CustomMatPaginatorIntl ';

import { OptCommonModules } from 'src/modules/shared/common.modules';
import { CustomerAccountModule } from 'src/modules/booking-portal/account/customer-account.module';

import { BookingCommonComponent } from './booking-common.component';

import { OptHttpProvider } from '../services/base/api-request-handler';
//Layout
import { SiteLayoutModule as LayoutModule, SiteLayoutModule } from 'src/modules/layout/site-layout/site-layout.module';

//components
import { MainComponent } from './main.component';
import { FacilitySearchComponent } from './facility/facility-search/facility-search.component';
import { DocModalComponent } from './booking-cart/doc-modal/doc-modal.component';
import { CalendarModalComponent } from './common/calendar-modal/calendar-modal.component';
import { EditModalComponent } from './booking-cart/edit-modal/edit-modal.component';
import { ModalContentComponent } from './common/modal-content/modal-content.component';
import { ModalWindowComponent } from './test-component/modal-window/modal-window.component';
import { FacilityDetailComponent } from './facility/facility-detail/facility-detail.component';
import { SearchComponent } from './facility/facility-search/search/search.component';
import { UpsellComponent } from './booking-cart/upsell/upsell.component';
import { BookingCartComponent } from './booking-cart/booking-cart.component';
import { MmcCartComponent } from './booking-cart/mmc-cart/mmc-cart.component';
import { MmcEnquiryComponent } from './mmc-enquiry/mmc-enquiry.component';
import { CartPanelComponent } from './booking-cart/cart-panel/cart-panel.component';
import { SelectConfigurationComponent } from './booking-cart/edit-modal/select-configuration/select-configuration.component';
import { BaseComponent } from 'src/modules/shared/base.component';
import { CalendarTabViewComponent } from './facility/facility-search/search/calendar-tab-view/calendar-tab-view.component';
import { MobileMonthCalenderComponent } from './facility/facility-search/search/calendar-tab-view/mobile-month-calendar/mobile-month-calendar.component';

//Html plugins
import { MmcCarouselComponent } from './facility/facility-detail/mmc-carousel/mmc-carousel.component';
import { SimpleComponent } from './facility/facility-detail/mmc-carousel/simple/simple.component';
import { NestedComponent } from './facility/facility-detail/mmc-carousel/nested/nested.component';
import { MmcSliderComponent } from './facility/facility-detail/mmc-slider/mmc-slider.component';

//Booking view
import { BookingSearchComponent } from './booking-view/booking-search/booking-search.component';
import { BookingDetailsComponent } from './booking-view/booking-details/booking-details.component';
import { BookingChangeRequestComponent } from './booking-view/booking-change-request/booking-change-request.component';
import { TimepickerConfig } from 'opt-timepicker';


export function getTimepickerConfig(): TimepickerConfig {
    return Object.assign(new TimepickerConfig(), {
        hourStep: 1,
        minuteStep: environment.MinimumBookingDuration,
        showMeridian: true,
        meridians: ['AM', 'PM'],
        readonlyInput: false,
        showMinutes: true,
        showSeconds: false
    });
}

import { TimepickerModule } from 'opt-timepicker';
import { NgxGalleryModule } from 'opt-ngx-gallery';
import { NguCarouselModule } from '@ngu/carousel';

// Services
import { AssetService } from '../services/asset.service';
import { EventService } from '../services/event.service';
import { AllocatedConfigurationDetailService } from 'src/modules/public-web/booking-application/services/AllocatedConfigurationDetail.service';
import { ItemService } from '../services/item.service';
import { PackageService } from '../services/package.service';
import { BookingService } from '../services/booking.service';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';
import { SystemService } from 'src/modules/services/system.service';
import { SystemConfig } from 'src/modules/system-configuration';
import { QASIntegrationService } from '../services/qas-integration.service';
import { ScriptService } from 'src/modules/services/script.service';

// Google Map
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { GoogleMapsConfig } from 'src/modules/google-maps-config'

// File Drop
import { FileDropModule } from 'ngx-file-drop';

//Store
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { statusReducer } from '../store/loading/reducer';
import { cartReducer } from '../store/cart-reducer';
import { AppState } from '../store/cart';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from '../store/effects/login-effects';
import { FacilityEffects } from '../store/effects/facility-effects';
import { environment } from 'src/environments/environment';

const reducers: ActionReducerMap<AppState> = { cart: cartReducer, loader: statusReducer, PBReducer: PBReducer, LanguageReducer: LanguageReducer };

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: ['cart', 'PBReducer', 'LanguageReducer', 'loader'], rehydrate: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

import { CusModules } from './client/cus.modules';
import { ModalsComponent } from './modals/modals.component';
import { AddExtrasComponent } from './modals/add-extras/add-extras.component';
import { CancelBookingComponent } from './modals/cancel-booking/cancel-booking.component';
import { QuestionnaireComponent } from './modals/questionnaire/questionnaire.component';
import { AlternativeOptionsComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/alternative-options/alternative-options.component';
import { FacilityCommonComponent } from './facility/facility-common.component';
import { ExtraAllocationComponent } from './modals/extra-allocation/extra-allocation.component';
import { ExtraDeletionComponent } from './modals/extra-deletion/extra-deletion.component';

//RB related components
import { RBSharedModule } from 'src/modules/regular-booking/rb-shared.module';

import { WpMessageComponent } from './modals/wp-message/wp-message.component';
import { PriceRangeComponent } from './modals/price-range/price-range.component';
import { WpChangeRequestComponent } from './modals/wp-change-request/wp-change-request.component';
import { WpRequestAccessComponent } from './modals/wp-request-access/wp-request-access.component';
import { WpAlreadyAccessComponent } from './modals/wp-already-access/wp-already-access.component';
import { WPBoookingRolloverComponent } from 'src/modules/regular-booking/wp-booking-rollover/wp-booking-rollover';
import { WpRolloverBookingModalComponent } from 'src/modules/regular-booking/popups/wp-rollover-booking-modal/wp-rollover-booking-modal.component';
import { ManageContactsComponent } from './modals/manage-contacts/manage-contacts.component';
import { WPInfoMessage } from 'src/modules/regular-booking/messages/wp-info-message/wp-info-message';
import { PbCommonModule } from '../public-web/pb-common.module';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { QuestionnaireComponent as PublicWebQuestionnaire } from "../booking-portal/modals/questionnaire/questionnaire.component";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { PBReducer } from '../store/public-web/public-web-reducers';
import { LanguageReducer } from '../store/public-web/select-language/language-reducer';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { FormControlValidatorDirective } from '../form-control-validator.directive';

import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr'; registerLocaleData(fr);
import zh from '@angular/common/locales/zh';

import { FileUploadQuestionireComponent } from './modals/questionnaire/dynamic-form-question/file-upload-questionire/file-upload-questionire.component';
import { GuestSetupFormComponent } from './booking-cart/guest-setup-form/guest-setup-form.component';

import { CIAMModule } from '../b2c/azure/utils/ciam.module'
import { MsalServiceProvider } from '../b2c/azure/utils/msal-service-provider';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
//translation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "../dist/assets/i18n/", ".json");
}
@NgModule({
    imports: [
        OptFormModules, CommonModule,
        BrowserModule,
        PbCommonModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        OptMaterialModule,
        FormsModule, ReactiveFormsModule,
        OptFormModules,
        OptCommonModules,
        BookingRoutingModule,
        LayoutModule,
        SiteLayoutModule,
        TimepickerModule.forRoot(),
        NgxGalleryModule,
        OptCommonModules,
        CusModules,
        HammerModule,
        NguCarouselModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([LoginEffects, FacilityEffects]),
        AgmCoreModule.forRoot(),
        HttpClientModule,
        FileDropModule,
        NgxCaptchaModule,
        RBSharedModule,
        CustomerAccountModule,
        CIAMModule
    ],
    declarations: [
        MainComponent, BookingCommonComponent,
        FacilitySearchComponent,
        SearchComponent,
        FacilityDetailComponent,
        MmcCarouselComponent,
        SimpleComponent,
        NestedComponent,
        MmcSliderComponent,
        ModalWindowComponent,
        DocModalComponent,
        ModalContentComponent,
        BookingCartComponent,
        MmcCartComponent,
        CalendarModalComponent,
        EditModalComponent,
        MmcEnquiryComponent,
        CartPanelComponent,
        UpsellComponent,
        BookingSearchComponent,
        BookingDetailsComponent,
        BookingChangeRequestComponent,
        ModalsComponent,
        AddExtrasComponent,
        CancelBookingComponent,

        QuestionnaireComponent,
        AlternativeOptionsComponent,
        SelectConfigurationComponent,
        FacilityCommonComponent,
        BaseComponent,
        WpMessageComponent,
        PriceRangeComponent,
        WpChangeRequestComponent,
        WpRequestAccessComponent,
        WpAlreadyAccessComponent,
        WPInfoMessage,
        ManageContactsComponent,
        ExtraAllocationComponent,
        ExtraDeletionComponent,
        // Public Web Question
        PublicWebQuestionnaire,
        CalendarTabViewComponent,
        MobileMonthCalenderComponent,
        FormControlValidatorDirective,
        FileUploadQuestionireComponent,
        GuestSetupFormComponent,
    ],
    entryComponents: [
        ModalWindowComponent,
        DocModalComponent,
        CalendarModalComponent,
        EditModalComponent,
        AddExtrasComponent,
        UpsellComponent,
        CancelBookingComponent,
        BookingChangeRequestComponent,
        QuestionnaireComponent,
        PublicWebQuestionnaire,
        AlternativeOptionsComponent,
        WpMessageComponent,
        PriceRangeComponent,
        WpChangeRequestComponent,
        WpRequestAccessComponent,
        WpAlreadyAccessComponent,
        ManageContactsComponent,
        WpRolloverBookingModalComponent,
        WPInfoMessage,
        ExtraAllocationComponent,
        ExtraDeletionComponent,
    ],
    providers: [OptHttpProvider,
        AssetService, EventService, ItemService, PackageService, SystemService, SystemConfig, AllocatedConfigurationDetailService,
        BookingService, QASIntegrationService,
        ScrollTopService, CdkColumnDef, ScriptService,

        { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig },
        { provide: TimepickerConfig, useFactory: getTimepickerConfig },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
    ],
    bootstrap: [MainComponent]
})

export class BookingModule { }

