import { Component, OnInit, ViewChild, Output, EventEmitter, ViewChildren, QueryList, ElementRef, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import { CustomerService } from '../../../../services/customer.service';
import { SystemService } from '../../../../services/system.service';
import { QASIntegrationService } from '../../../../services/qas-integration.service';
import { Deserializer } from 'json-api-format';
import { CorporateClient } from './../../../../models/client/corporate-client';
import { ReferenceData } from './../../../../models/client/referenceData';
import { Contact } from './../../../../models/client/contact';
import { CommunicationMethod } from './../../../../models/client/client';
import { Client, Address, User, Venue, Tax, PriceConcession } from './../../../../models/client/client';
import { QASAddress } from './../../../../models/client/qas-address';
import { ClientRegistration, CommunicationTypeValidation, RequestToAccessReqularBookingTypes } from 'src/modules/models/settings/casual-portal/client-registration-config';
import { Addresses, AddressDetail } from './../../../../models/client/Address';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RoutePath } from '.././../../../../modules/booking-portal/route-path';
import { Store, ICart, SetContact, Redirect, PatchBookingContact, SaveBooking } from './../../../../store/index';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'

import { Observable, Subject } from "rxjs";
import { map, startWith, debounceTime, distinctUntilChanged, mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment'
import { Utility } from 'src/modules/utility';
import { SystemConfig } from 'src/modules/system-configuration'

import { CustomerAdditionalDetail } from 'src/modules/models/client/customer-additional-detail';
import { Note } from 'src/modules/models/client/note';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { BaseComponent } from 'src/modules/shared/base.component';
import { PublicBookingSettings } from 'src/modules/models/settings/public-booking/public-booking-setting';
import * as LanguageSelector from '../../../../store/public-web/select-language/language-selector';
import { Country } from '../../../../models/public-web/customer/country';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SystemOption } from 'src/modules/models/public-web/SystemOption';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/modules/b2c/auth-service';
import { AuthServiceFactory } from 'src/modules/b2c/auth-service-factory';
import { id } from 'date-fns/locale';
import { name } from '@azure/msal-angular/packageMetadata';

declare let gtag: Function;

@Component({
    selector: 'opt-corporate',
    templateUrl: './corporate.component.html'
})

export class CorporateComponent extends BaseComponent implements OnInit, AfterViewInit {
    @Output() navigated: EventEmitter<number> = new EventEmitter<number>();
    @Output() LoggedIn: EventEmitter<number> = new EventEmitter<number>();
    @Output() loadSignUp: EventEmitter<number> = new EventEmitter<number>();
    @Input() isFromBooking: string;
    @Input() tabIndex: number;
    clientSaveForm: FormGroup;

    private fileReader = new FileReader();
    Image: string;
    ImageEx: string;

    Email: string;
    SelectedTitleId: string;
    SelectedTitleName: string;
    SelectedPhoneTypeId: string;
    SelectedPhoneTypeName: string;
    SelectedEmailTypeId: string;
    SelectedEmailTypeName: string;
    SelectedAdditionalPhoneTypeId: string;
    SelectedAdditionalPhoneTypeName: string;
    SelectedAdditionalEmailTypeId: string;
    SelectedAdditionalEmailTypeName: string;
    addressSearchText: string;
    IsEnableManualAddress: boolean = false;
    IsFromLogin: boolean;
    CurrentUrl: string;
    returnUrl: string = "";
    PasswordVal: string;
    CommunicationTypeValidation: CommunicationTypeValidation[] = [];
    CompanyPhoneNumber: string;
    Address_Country: string;
    DefaultCountryId: number;
    Address_Line1: string;
    Address_Line2: string;
    Address_Line3: string;
    Address_City: string;
    Address_CountyCode: string;
    Address_PostCode: string;
    selectedLanguage;

    QASLoader: boolean;

    Client: CorporateClient;
    Contact: Contact;
    Titles: Array<ReferenceData>;
    CommunicationTypes: Array<ReferenceData>;
    CommunicationTypesPhone: Array<ReferenceData>;
    CommunicationTypesEmail: Array<ReferenceData>;
    CustomerRegConfig = new ClientRegistration();
    AddressList: Addresses;
    filteredOptions: AddressDetail[];
    TempAdd: Array<string>;
    countries: Country[] = [];
    country: FormControl;
    filteredCountries: Observable<Country[]>;

    acceptedImageTypes = { 'image/png': true, 'image/jpeg': true, 'image/gif': true };
    @ViewChild('imageDrop') imageDrop;
    @ViewChild('imageError') imageError
    @ViewChild('imageInput') el;
    PrivacyPolicyLink: string;
    isChecked = false;
    siteKey: string;
    size: string;
    lang: string;
    theme: string;
    type: string;
    recaptcha;
    isCaptchaVerified = false;
    isLoad = false;
    defaultCurrencyId: number;
    defaultCountryName: string;
    countryName: string;

    loadingStyle: boolean;
    showImage = true;
    showError = false;
    addressLoaded = false;
    //  phoneNumberPattern = /^[- +()]*[0-9][- +()0-9]*$/;

    phoneNumberPattern: RegExp;
    phoneNumberPattern2: RegExp;
    emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    nameValidation: RegExp;


    pwPolicy: any;
    showValidationMessage: boolean = false;
    validationMessage: string = "";
    isValidEnforceComplexPassword: boolean;
    isValidCharactersMinimum: boolean;
    isValidOneLowercase: boolean;
    isValidOneUppercase: boolean;
    isValidOneDigit: boolean;
    isValidOneSpecialCharacter: boolean;
    isDisabledSubmit: boolean = true;
    isPasswordValid: boolean = true;
    isNoResultFound: boolean = false;
    placholderSampleNumber: string;

    IsEnableToCreateAdditionalDetailsSectionForCorporateClients: boolean = false;
    IsEnableToCreateAdditionalDocumentSectionForCorporateClients: boolean = false;
    priceConcessionQuestionText: string;
    PriceConcessionAnswerOptions: any[] = [];
    additionalDetail: CustomerAdditionalDetail;
    additionalDetailNote: string;
    public files: UploadFile[] = [];
    public fileSelector: ElementRef;
    @ViewChild('fileInputTag') fileInputTagVariable: ElementRef; status: boolean = false;
    @ViewChild(MatAutocompleteTrigger) matAutocomplete: MatAutocompleteTrigger;

    requestToAccessReqularBookingHeaderText: string = "";
    requestToAccessReqularBookingAcceptText: string = "";
    reqularBookingAccessRequestHeaderText: string = "";
    reqularBookingAccessRequestSubHeaderText: string = "";
    profileImageHeaderTextForCorporateClients: string = "";

    requestToRegularBookingAccess: boolean = false;
    requestToRegularBookingAccessNote: string;
    enableProfileImageUpload: boolean;
    enableWebPortalAccess: boolean;
    showAddressSearchBar: boolean = true;
    isDisableClientSaveBtn: boolean = false;
    @ViewChild('fileInputTagforProfileImage') fileInputTagforProfileImage: ElementRef;
    profileImage: any;
    appData: AppSetting;
    docSize: number;
    ClientFileUploadDescription: any;
    addressChanged: Subject<string> = new Subject<string>();
    inputSearchString: string = "";
    AddPhone: number = 0;
    AddEmail: number = 0;
    isValidate: boolean;
    cart$: Observable<ICart>;
    isAllImmediateConfirmed: boolean;
    isPriceConcessionQuestionRequired: boolean;
    isPublicPortal = false;
    bookingTermAndCondition = (environment.PublicBookingSetting as PublicBookingSettings).phrases;
    RequestToAccessReqularBookingTypes: RequestToAccessReqularBookingTypes;
    bookingTypes: any[] = [];

    b2cContact?: Contact;
    isB2CEnabled: boolean = false
    authService: AuthService;
    constructor(private _customerService: CustomerService, private _systemService: SystemService, private _sysConfig: SystemConfig, private _qasIntegrationService: QASIntegrationService, private fb: FormBuilder, private cdr: ChangeDetectorRef, private route: Router, private store: Store<any>, public snackBar: MatSnackBar, private sanitizer: DomSanitizer, private titleService: Title,) {
        super();
        this.route.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('event', 'page_view', {
                    page_title: this.appData.PageTitle + ' | Sign Up',
                    page_path: event.urlAfterRedirects,
                    page_location: window.location.href
                })
            }
        });
        this.SelectedTitleId = '';
        this.SelectedTitleName = '';
        this.Client = new CorporateClient();
        this.Titles = new Array<ReferenceData>();
        this.CommunicationTypes = new Array<ReferenceData>();
        this.CommunicationTypesPhone = new Array<ReferenceData>();
        this.CommunicationTypesEmail = new Array<ReferenceData>();
        this.CustomerRegConfig = new ClientRegistration();
        this.addressSearchText = '';
        this.AddressList = new Addresses();
        this.IsEnableManualAddress = true;
        this.Contact = new Contact();
        this.Contact.client = new Client();
        this.Image = '';
        this.ImageEx = '';
        this.DefaultCountryId = Number(environment.DefaultCountryId);
        var appsettings = environment.AppSetting as AppSetting;
        this.nameValidation = new RegExp(appsettings.ClientRegistrationConfig.NameValidation);
        this.defaultCurrencyId = environment.DefaultCurrencyId;
        this.defaultCountryName = environment.DefaultCountryName;
        this.isPublicPortal = environment.PortalType == '4' ? true : false;
        this.siteKey = environment.ReCaptchaSiteKey;
        this.size = 'Normal';
        this.lang = 'en';
        this.theme = 'Light';
        this.type = 'Image';
        this.recaptcha = 'recaptcha'
        this.isPasswordValid = true;
        this.cart$ = this.store.select('cart');
        if (environment.PriceConcessionQuestionText && environment.PriceConcessionQuestionText != "") {
            this.priceConcessionQuestionText = environment.PriceConcessionQuestionText;
            this.PriceConcessionAnswerOptions = environment.PriceConcessionAnswerOptions ? environment.PriceConcessionAnswerOptions : [];
        }
        this.IsEnableToCreateAdditionalDetailsSectionForCorporateClients = environment.IsEnableToCreateAdditionalDetailsSectionForCorporateClients;
        this.IsEnableToCreateAdditionalDocumentSectionForCorporateClients = environment.IsEnableToCreateAdditionalDocumentSectionForCorporateClients;

        this.requestToAccessReqularBookingHeaderText = environment.RequestToAccessReqularBookingHeaderText;
        this.requestToAccessReqularBookingAcceptText = environment.RequestToAccessReqularBookingAcceptText;
        this.reqularBookingAccessRequestHeaderText = environment.ReqularBookingAccessRequestHeaderText;
        this.reqularBookingAccessRequestSubHeaderText = environment.ReqularBookingAccessRequestSubHeaderText;
        this.profileImageHeaderTextForCorporateClients = environment.ProfileImageHeaderTextForCorporateClients;

        this.additionalDetail = new CustomerAdditionalDetail();
        this.additionalDetail.note = new Note();
        this.additionalDetail.documents = [];
        this.enableProfileImageUpload = environment.EnableProfileImageUpload;
        this.enableWebPortalAccess = environment.EnableWebPortalAccess;
        this.isPriceConcessionQuestionRequired = environment.IsPriceConcessionQuestionRequired;
        if (environment.ExternalIntegrationIdForAddress == 0 || environment.ExternalIntegrationIdForAddress == undefined) {
            this.showAddressSearchBar = false
        }
        this.appData = (environment.AppSetting as AppSetting);
        this.docSize = this.appData.ClientRegistrationConfig.MaximumDocUploadSize ? this.appData.ClientRegistrationConfig.MaximumDocUploadSize : 3;

        this.ClientFileUploadDescription = this.sanitizer.bypassSecurityTrustHtml(this.appData.ClientRegistrationConfig.ClientFileUploadDescription);
        this.addressChanged.pipe(
            debounceTime(1000)
        ).subscribe(model => this.loadAddress(model));

        this.cart$.safeSubscribe(this, state => {
            this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
        });
        this.isPublicPortal = environment.PortalType == '4' ? true : false;
        this.store.select(LanguageSelector.getSelectedLanguage).safeSubscribe(this, storedLanguage => {
            this.selectedLanguage = !!storedLanguage ? storedLanguage : '';
        });

        this._customerService.setCountryValues();

        this.RequestToAccessReqularBookingTypes = this.appData.ClientRegistrationConfig.RequestToAccessReqularBookingTypes;
    }



    ngOnInit() {
        this.authService = AuthServiceFactory.createAuthService((environment.AppSetting as AppSetting))
        if (this.appData.PageTitle) {
            this.titleService.setTitle(this.appData.PageTitle + ' | Sign Up');
        }
        if (!this.DefaultCountryId || this.DefaultCountryId == 0 || this.DefaultCountryId == null) {
            this._systemService.loadSystemOptions().safeSubscribe(this, (options: SystemOption[]) => {
                let defaultCountry = options.find((x) => x.keyName === "DefaultCountry");
                if (defaultCountry) {
                    this.DefaultCountryId = +(defaultCountry.keyValue);
                    this.setDefaultCountry();
                }
            })
        }

        let _urldata = decodeURIComponent(this.route.url).split('?');
        if (_urldata.length > 1) {
            let url = _urldata[1]?.split('&');
            this.CurrentUrl = url[0]?.split('=')[0];
            this.returnUrl = url?.find(x => x.includes('ReturnUrl'))?.replace('ReturnUrl=', '');
            if ((this.CurrentUrl == "fromLogin") && (this.route.url).indexOf('%3F') >= 0) {
                this.IsFromLogin = (((this.route.url).split('%3F')[1]).split('%3D')[1] == '1') ? true : false;
            } else if (this.route.url.indexOf('fromLogin=1') >= 0 || this.route.url.toLowerCase().indexOf('fromLogin=true') >= 0) {
                this.IsFromLogin = true;
                this.CurrentUrl = 'fromLogin'
            }

        }



        this.loadPasswordPolicy(); //load and bind pasword policy..

        this.QASLoader = false;

        this.clientSaveForm = this.fb.group({
            CompanyName: ['', this.getValidators(10)],
            FirstName: ['', [Validators.maxLength(50), Validators.pattern(this.nameValidation)]],
            LastName: ['', [Validators.maxLength(50), Validators.pattern(this.nameValidation)]],
            UserName: ['', [Validators.maxLength(200), Validators.required]],
            Password: ['', [Validators.required, this.passwordValidator.bind(this)]],
            ConfirmPassword: ['', [ConfPasswordValidator]],
            CompanyEmail: ['', this.getValidators(9)],
            RegistrationName: [''],
            PersonalPhoneNumber_1: [''],
            PersonalEmail_1: ['', Validators.pattern(this.emailPattern)],
            PersonalPhoneNumber_2: [''],
            PersonalEmail_2: ['', Validators.pattern(this.emailPattern)],
            Address_Line1: ['', this.getValidators(1)],
            Address_Line2: ['', this.getValidators(2)],
            Address_Line3: ['', this.getValidators(3)],
            Address_City: ['', this.getValidators(4)],
            Address_Country: ['', this.getValidators(5)],
            Address_CountyCode: ['', this.getValidators(6)],
            Address_PostCode: ['', this.getValidators(7)],
            CompanyPhoneNumber: ['', this.getValidators(8)],
            //ImageInput: [''],
            myControl: ['', this.appData.ClientRegistrationConfig.EnableManualAddress ? '' : [Validators.required]],
            recaptcha: ['', Validators.required],
            Additional_Detail_Note: ['', (environment.IsPriceConcessionQuestionRequired && this.priceConcessionQuestionText && this.IsEnableToCreateAdditionalDetailsSectionForCorporateClients) ? [Validators.required] : ''],
            Request_To_Begular_BookingAccess: [''],
            Request_To_Regular_Booking_Access_Note: [''],
            bookingTypesRequests: [[]],
        });
        this.country = new FormControl('', this.getValidators(5));

        if (environment.DisableUsernameTextBox) {
            this.clientSaveForm.controls.UserName.disable();
        }

        this.GetConfigurationSettings();
        this._sysConfig.loadAppConfiguration().then(() => {
            this.GetDropDownDetails();
        });

        // if (this.showAddressSearchBar == false) {
        //     this.clientSaveForm.controls.Address_Line1.setValidators([Validators.required]);
        // }

        //this.clientSaveForm.controls.myControl.valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), mergeMap(input =>
        //    this.GetQASAddress(false, '', input)
        //))
        //    .subscribe(data => {
        //        this.isNoResultFound = false;
        //        if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
        //            this.AddressList = data;
        //        }
        //        else {
        //            this.isNoResultFound = true;
        //            this.AddressList = new Addresses();
        //        }
        //        this.QASLoader = false;
        //    });

        this.cart$.safeSubscribe(this, state => {
            this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
        });
        this.cart$.pipe(take(1)).safeSubscribe(this, state => {
            this.b2cContact = state.b2cContact;
        });
        var appsettings = environment.AppSetting as AppSetting;
        this.PrivacyPolicyLink = appsettings.ClientRegistrationConfig.PrivacyPolicyLink;

        this.filteredCountries = this.country.valueChanges.pipe(
            startWith<string | Country>(''),
            map(value => (typeof value === "string" ? value : value.name)),
            map(name => (name ? this._filter(name) : this.countries))
        );
        this.isB2CEnabled = AuthServiceFactory.isB2CEnable(appsettings);
        if (this.isB2CEnabled && this.b2cContact != null) {

            this.clientSaveForm?.get('FirstName')?.setValue(this.b2cContact.firstName ?? '');
            this.clientSaveForm?.get('LastName')?.setValue(this.b2cContact.lastName ?? '');
            this.clientSaveForm.controls['FirstName'].disable();
            this.clientSaveForm.controls['LastName'].disable();
            this.clientSaveForm.controls['Password'].clearValidators();
            this.clientSaveForm.controls['ConfirmPassword'].clearValidators();

            this.clientSaveForm?.get('PersonalEmail_1')?.setValue(this.b2cContact.email ?? '');
            this.clientSaveForm.controls['PersonalEmail_1'].disable();
            this.clientSaveForm?.get('PersonalPhoneNumber_1')?.setValue((this.b2cContact?.phone ?? []).length > 0 ? (this.b2cContact?.phone[0]) : '');
            this.clientSaveForm.controls['PersonalPhoneNumber_1'].disable();

            if (AuthServiceFactory.disableCompanyData(appsettings)) {
                //client data
                this.clientSaveForm?.get('CompanyName')?.setValue(this.b2cContact?.client?.companyName ?? '');
                this.clientSaveForm.controls['CompanyName'].disable();
                this.clientSaveForm?.get('RegistrationName')?.setValue(this.b2cContact?.client?.companyRegistrationNo ?? '');
                this.clientSaveForm.controls['RegistrationName'].disable();
                this.clientSaveForm?.get('CompanyEmail')?.setValue(this.b2cContact?.client?.email ?? '');
                this.clientSaveForm.controls['CompanyEmail'].disable();
                this.clientSaveForm?.get('CompanyPhoneNumber')?.setValue((this.b2cContact?.client?.phone ?? []).length > 0 ? this.b2cContact?.client?.phone[0] : '');
                this.clientSaveForm.controls['CompanyPhoneNumber'].disable();
            }

            if (AuthServiceFactory.disableAddress(appsettings)) {
                this.clientSaveForm.controls['Address_Country'].setValue(this.b2cContact.address?.country ?? '');
                this.clientSaveForm.controls['Address_Line1'].setValue(this.b2cContact.address?.address1 ?? '');
                this.clientSaveForm.controls['Address_Line2'].setValue(this.b2cContact.address?.address2 ?? '');
                this.clientSaveForm.controls['Address_Line3'].setValue(this.b2cContact.address?.address3 ?? '');
                this.clientSaveForm.controls['Address_City'].setValue(this.b2cContact.address?.city ?? '');
                this.clientSaveForm.controls['Address_CountyCode'].setValue(this.b2cContact.address?.stateCode ?? '');
                this.clientSaveForm.controls['Address_PostCode'].setValue(this.b2cContact.address?.postCode ?? '');
                this.Address_Country = this.clientSaveForm?.get('Address_Country')?.value;
                this.Address_Line1 = this.clientSaveForm?.get('Address_Line1')?.value;
                this.Address_Line2 = this.clientSaveForm?.get('Address_Line2')?.value;
                this.Address_Line3 = this.clientSaveForm?.get('Address_Line3')?.value;
                this.Address_City = this.clientSaveForm?.get('Address_City')?.value;
                this.Address_CountyCode = this.clientSaveForm?.get('Address_CountyCode')?.value;
                this.Address_PostCode = this.clientSaveForm?.get('Address_PostCode')?.value;
                this.DisableAddressFromField()
            }
        }
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
        setTimeout(() => {
            this.isLoad = true;
        });
    }

    loadPasswordPolicy() {


        let passwordPolicyResult = this._customerService.GetAnonymousPasswordPolicies();
        passwordPolicyResult.subscribe(policy => {
            if (!policy.IsError) {
                this.pwPolicy = policy.Data;
                console.log("Password Policy -> ", policy);
            } else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: [policy.ErrorMessage]
                });
            }
        },
            err => {
                console.log("Error", err);
            }
        );



    }

    mobileNumberValidation(validationControlName: string, SelectedPhoneTypeId: string) {
        var pattern: RegExp;
        var sampleValueArray = [];
        this.clientSaveForm.get(validationControlName).clearValidators();

        this.CommunicationTypeValidation = environment.CommunicationTypeValidation;
        if (this.CommunicationTypeValidation.length > 0) {
            this.CommunicationTypeValidation.forEach(ctv => {
                if (ctv.Rule != undefined && ctv.Rule.length > 0 && ctv.CommunicationTypeId != undefined && ctv.CommunicationTypeId != "") {
                    var ids = ctv.CommunicationTypeId.split(",");
                    if (ids != undefined && ids.length > 0 && ids.indexOf(SelectedPhoneTypeId) > -1) {
                        ctv.Rule.forEach(rule => {
                            if (this.DefaultCountryId.toString() == rule.countryId) {
                                pattern = new RegExp(rule.Value);
                                //this.placholderSampleNumber = rule.SampleNumber;
                                if (rule.SampleNumber != "") {
                                    sampleValueArray.push(rule.SampleNumber);
                                }
                                //if (ctv.Rule.length > 1) {
                                //    if (rule.SampleNumber != "") {
                                //        //var resultSampleValue = ctv.Rule.map(e => {
                                //        //    return e.SampleNumber;
                                //        //}).join(', ');
                                //        //this.placholderSampleNumber = resultSampleValue;
                                //        sampleValueArray.push(rule.SampleNumber);
                                //    }
                                //} else {
                                //    if (rule.SampleNumber != "") {
                                //        this.placholderSampleNumber = rule.SampleNumber;
                                //    }
                                //}

                            }
                        });

                        if (pattern == undefined || pattern == null) {
                            var selectedRule = ctv.Rule.find(a => a.countryId == "");
                            pattern = new RegExp(selectedRule.Value);
                            //   this.placholderSampleNumber = selectedRule.SampleNumber;
                        }

                    }
                }
            });

            this.placholderSampleNumber = this.appData.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.Value;
            this.placholderSampleNumber = this.placholderSampleNumber == "" ? sampleValueArray.join(', ') : this.placholderSampleNumber;
        }

        this.clientSaveForm.get(validationControlName).setValidators(this.getMobileValidators(pattern, validationControlName))


        this.clientSaveForm.get(validationControlName).updateValueAndValidity();

    }



    GetFormattedAddress(address: string) {
        let selectedAdd = this.AddressList.AddressDetails.find(f => f.Address == address);
        let postCode = selectedAdd.Postcode;
        this.GetQASAddress(true, selectedAdd.QASID, '')
            .subscribe(data => {
                //  console.log(data.AddressDetails);
                if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                    //this.Address_Country = Utility.sentenceCase(data.AddressDetails[0].Country);
                    this.Address_Country = (data.AddressDetails[0].Country == '') ? this.defaultCountryName : Utility.sentenceCase(data.AddressDetails[0].Country);
                    this.Address_Line1 = Utility.sentenceCase(data.AddressDetails[0].AddressLine1);
                    this.Address_Line2 = (data.AddressDetails[0].AddressLine2 != null) ? Utility.sentenceCase(data.AddressDetails[0].AddressLine2) : ' ';
                    this.Address_Line3 = (data.AddressDetails[0].AddressLine3 != null) ? Utility.sentenceCase(data.AddressDetails[0].AddressLine3) : ' ';
                    this.Address_City = Utility.sentenceCase(data.AddressDetails[0].Locality);
                    this.Address_CountyCode = data.AddressDetails[0].StateCode;
                    this.Address_PostCode = data.AddressDetails[0].Postcode;
                    this.addressLoaded = true;
                    this.loadingStyle = true;
                }

            });
        this.AddressList = new Addresses();
        this.QASLoader = false;
    }//Format selected address



    checkfiles(files) {
        if (this.acceptedImageTypes[files[0].type] !== true) {
            this.imageError.nativeElement.innerHTML = "Not a valid image";
            this.imageDrop.nativeElement.style.display = 'none';
            this.imageError.nativeElement.style.display = 'block';
            this.Image = null;
            return;
        }
        else if (files.length > 1) {
            this.imageError.nativeElement.innerHTML = "Please choose 'One' Image";
            this.imageDrop.nativeElement.style.display = 'none';
            this.imageError.nativeElement.style.display = 'block';
            this.Image = null;
            return;
        }
        else {
            this.imageError.nativeElement.style.display = 'none';
            this.imageDrop.nativeElement.style.display = 'block';
            this.readfiles(files);
        }
    }//checkfiles

    readfiles(files) {
        this.fileReader.readAsDataURL(files[0]);
        this.ImageEx = files[0].type;
        setTimeout(() => this.Image = this.fileReader.result.toString(), 500);

    }//readfiles

    imageUploadSubmitted() {
        console.log('IMAGE VALUE SUBMIT = =  ', this.clientSaveForm.controls.imageInput.value);
    }

    allowDrop(e) {
        e.preventDefault();
    }

    drop(e) {
        //e.preventDefault();
        //this.clientSaveForm.controls.ImageInput.reset();
        this.imageDrop.innerHTML = "";

        this.checkfiles(e.dataTransfer.files);
    }

    imageChange(event) {
        this.imageDrop.innerHTML = "";
        this.checkfiles(event.target.files);
    }//imageChange

    GetConfigurationSettings() {
        let result = this._systemService.loadConfiguration();
        result.subscribe(data => {
            if (data != null) {
                this.CustomerRegConfig = data.ClientRegistrationConfig;
                //this.DefaultCountryId = Number(data.ClientRegistrationConfig.DefaultCountryId.Id);
                this.IsEnableManualAddress = data.ClientRegistrationConfig.EnableManualAddress;
                (this.IsEnableManualAddress) ? '' : this.DisableAddressFromField();
            }
        })
    }


    RegularBookingCheckboxCheck(event: MatCheckboxChange) {
        if (event.checked && this.appData.ClientRegistrationConfig.RegularHireAccessNoteMandatory) {
            this.clientSaveForm.controls.Request_To_Regular_Booking_Access_Note.setValidators([Validators.required]);
            this.clientSaveForm.controls.Request_To_Regular_Booking_Access_Note.updateValueAndValidity();

        } else {
            this.clientSaveForm.controls.Request_To_Regular_Booking_Access_Note.clearValidators();
            this.clientSaveForm.controls.Request_To_Regular_Booking_Access_Note.updateValueAndValidity();
        }
    }

    DisableAddressFromField() {
        this.clientSaveForm.controls['Address_Country'].disable();
        this.clientSaveForm.controls['Address_Line1'].disable();
        this.clientSaveForm.controls['Address_Line2'].disable();
        this.clientSaveForm.controls['Address_Line3'].disable();
        this.clientSaveForm.controls['Address_CountyCode'].disable();
        this.clientSaveForm.controls['Address_City'].disable();
        this.clientSaveForm.controls['Address_PostCode'].disable();

    }

    SaveCorporateClient() {

        if (this.requestToRegularBookingAccess && this.RequestToAccessReqularBookingTypes && this.RequestToAccessReqularBookingTypes.QuestionHeaderText && this.RequestToAccessReqularBookingTypes.Mandatory && this.clientSaveForm.controls.bookingTypesRequests.value == "") {
            return;
        }

        this.isDisableClientSaveBtn = true;
        this.Client.type = "corporateclient"
        this.Client.tid = "1";
        this.Client.companyName = this.clientSaveForm.controls.CompanyName.value;
        this.Client.clientCategoryId = this.CustomerRegConfig.CorporateClientConfig.ClientCategoryConfig.Id;
        this.Client.clientCategory = this.CustomerRegConfig.CorporateClientConfig.ClientCategoryConfig.Name;
        this.Client.clientTypeId = this.CustomerRegConfig.CorporateClientConfig.ClientTypeConfig.Id;
        this.Client.clientType = this.CustomerRegConfig.CorporateClientConfig.ClientTypeConfig.Name;
        this.Client.companyRegistrationNo = this.clientSaveForm.controls.RegistrationName.value;
        this.Client.legalName = this.clientSaveForm.controls.CompanyName.value;
        this.Client.active = true;
        this.Client.dateRegistered = new Date();
        this.Client.headOffice = true;
        this.Client.defaultCurrencyId = this.defaultCurrencyId;
        if (this.isB2CEnabled) {
            this.Client.SSOContactRef = this.b2cContact?.client?.SSOContactRef;
        }

        this.PriceConcessionAnswerOptions.forEach((PriceConcessionAnswerText) => {

            if (PriceConcessionAnswerText.PriceConcessionId) {

                if (PriceConcessionAnswerText.Key == this.additionalDetailNote) {
                    this.Client.priceConcession = new PriceConcession();
                    this.Client.priceConcession.id = PriceConcessionAnswerText.PriceConcessionId;
                }
            }

        });

        let accManager = new User();
        accManager.type = "user";
        accManager.id = (this.CustomerRegConfig.AccountManager.Id).toString();
        accManager.userName = this.CustomerRegConfig.AccountManager.Name;

        this.Client.accountManager = accManager;
        this.Client.creditLimit = 0;

        let defVenue = new Venue();
        defVenue.type = "venue";
        defVenue.id = (this.CustomerRegConfig.Venue.Id).toString();
        defVenue.name = this.CustomerRegConfig.Venue.Name;

        this.Client.defaultVenue = defVenue
        if (this.CustomerRegConfig.Tax.Id > 0) {
            this.Client.tax = new Tax();
            //this.Client.tax.tid = "1";
            this.Client.tax.id = (this.CustomerRegConfig.Tax.Id).toString();
            this.Client.tax.name = this.CustomerRegConfig.Tax.Name;
        }
        this.Client.profilePicture = (this.Image != null && this.Image != "") ? (this.Image.split(',')[1]) + '.' + this.ImageEx.split('/')[1] : null;

        let addt = new Address();
        addt.tid = "1";
        addt.country = this.clientSaveForm.controls.Address_Country.value;
        addt.countryID = !this.showAddressSearchBar ? this.country.value?.id : this.DefaultCountryId;
        addt.address1 = this.clientSaveForm.controls.Address_Line1.value;
        addt.address2 = this.clientSaveForm.controls.Address_Line2.value;
        addt.address3 = this.clientSaveForm.controls.Address_Line3.value;
        addt.city = this.clientSaveForm.controls.Address_City.value;
        addt.county = this.clientSaveForm.controls.Address_CountyCode.value;
        addt.postCode = this.clientSaveForm.controls.Address_PostCode.value;

        this.Client.address = addt;

        //add client regular booking types requested
        this.Client.regularbookingTypes = this.bookingTypes;

        this.Client.contacts = new Array<Contact>();
        let contact = new Contact();
        contact.tid = "1";
        contact.firstName = this.clientSaveForm.controls.FirstName.value;
        contact.lastName = this.clientSaveForm.controls.LastName.value;
        contact.titleId = +this.SelectedTitleId;
        contact.title = this.SelectedTitleName;
        contact.accountManager = accManager;
        contact.defaultVenue = defVenue;
        contact.decisionMaker = false;
        contact.active = true;

        contact.isPrimaryContact = true;
        if (!this.isB2CEnabled) {
            contact.password = this.clientSaveForm.controls.Password.value;

        }
        contact.ssoContactRef = this.b2cContact?.ssoContactRef + ((this.b2cContact?.client?.SSOContactRef ?? '') != '' ? ('_' + this.b2cContact?.client?.SSOContactRef) : '');

        let userEmail = this.clientSaveForm.controls.PersonalEmail_1.value;
        contact.userName = this.clientSaveForm.controls.UserName.value;
        contact.profilePicture = (this.Image != null && this.Image != "") ? (this.Image.split(',')[1]) + '.' + this.ImageEx.split('/')[1] : null;
        contact.address = addt;

        contact.communicationMethods = new Array<CommunicationMethod>();
        if (this.clientSaveForm.controls.PersonalPhoneNumber_1.value != null && this.clientSaveForm.controls.PersonalPhoneNumber_1.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "3";
            comMethod.communicationTypeID = this.SelectedPhoneTypeId;
            comMethod.communicationType = this.SelectedPhoneTypeName;
            comMethod.value = this.clientSaveForm.controls.PersonalPhoneNumber_1.value;
            contact.communicationMethods.push(comMethod);
        }
        if (this.clientSaveForm.controls.PersonalEmail_1.value != null && this.clientSaveForm.controls.PersonalEmail_1.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "4";
            comMethod.communicationTypeID = this.SelectedEmailTypeId;
            comMethod.communicationType = this.SelectedEmailTypeName;
            comMethod.value = this.clientSaveForm.controls.PersonalEmail_1.value;
            contact.communicationMethods.push(comMethod);
        }
        if (this.clientSaveForm.controls.PersonalPhoneNumber_2.value != null && this.clientSaveForm.controls.PersonalPhoneNumber_2.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "5";
            comMethod.communicationTypeID = this.SelectedAdditionalPhoneTypeId;
            comMethod.communicationType = this.SelectedAdditionalPhoneTypeName;
            comMethod.value = this.clientSaveForm.controls.PersonalPhoneNumber_2.value;
            contact.communicationMethods.push(comMethod);
        }
        if (this.clientSaveForm.controls.PersonalEmail_2.value != null && this.clientSaveForm.controls.PersonalEmail_2.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "6";
            comMethod.communicationTypeID = this.SelectedAdditionalEmailTypeId;
            comMethod.communicationType = this.SelectedAdditionalEmailTypeName;
            comMethod.value = this.clientSaveForm.controls.PersonalEmail_2.value;
            contact.communicationMethods.push(comMethod);
        }

        this.Client.contacts.push(contact);

        if (this.profileImage) {
            this.Client.profilePicture = this.profileImage;
        }

        if (this.clientSaveForm.controls.CompanyPhoneNumber.value || this.clientSaveForm.controls.CompanyEmail.value) {
            this.Client.communicationMethods = new Array<CommunicationMethod>();
        }
        if (this.clientSaveForm.controls.CompanyPhoneNumber.value != null && this.clientSaveForm.controls.CompanyPhoneNumber.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "1";
            comMethod.communicationTypeID = this.CommunicationTypesPhone.find(f => f.id == "1").id;
            comMethod.communicationType = this.CommunicationTypesPhone.find(f => f.id == "1").name;
            comMethod.value = this.clientSaveForm.controls.CompanyPhoneNumber.value;
            this.Client.communicationMethods.push(comMethod);
        }
        if (this.clientSaveForm.controls.CompanyEmail.value != null && this.clientSaveForm.controls.CompanyEmail.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "2";
            comMethod.communicationTypeID = this.CommunicationTypesEmail.find(f => f.id == "5").id;
            comMethod.communicationType = this.CommunicationTypesEmail.find(f => f.id == "5").name;
            comMethod.value = this.clientSaveForm.controls.CompanyEmail.value;
            this.Client.communicationMethods.push(comMethod);
        }



        if (this.clientSaveForm.invalid) {
            this.isDisableClientSaveBtn = false;
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ['Please check the indicated fields.'],
            });
        }
        //else if (this.clientSaveForm.controls.CompanyEmail.value == this.clientSaveForm.controls.PersonalEmail_1.value || this.clientSaveForm.controls.CompanyEmail.value == this.clientSaveForm.controls.PersonalEmail_2.value) {
        //    this.snackBar.openFromComponent(AlertMessageComponent, {
        //        duration: 3000,
        //        verticalPosition: 'top',
        //        data: ['Company email and Contact email cannot be the same.'],
        //    });
        //}
        else if (this.addressLoaded || this.IsEnableManualAddress) {
            let clientSaveResult = this._customerService.clientSave(this.Client);
            clientSaveResult.subscribe(data => {
                if (data != null) {
                    this.isDisableClientSaveBtn = false;
                    if (data.id != null) {
                        this.Contact.ssoContactRef = data.contacts[0].ssoContactRef;
                        this.Contact.id = data.contacts[0].id;
                        this.Contact.ref = data.contacts[0].ref;
                        this.Contact.type = data.type;
                        this.Contact.client.type = "corporateclient";
                        this.Contact.client.id = data.id;
                        this.Contact.client.clientRef = data.clientRef;
                        this.Contact.client.clientName = data.clientName;
                        this.Contact.client.clientRef = data.clientRef;
                        this.Contact.firstName = this.clientSaveForm.controls.FirstName.value;
                        this.Contact.lastName = this.clientSaveForm.controls.LastName.value
                        this.Contact.userName = contact.userName;
                        this.Contact.invoiceEmailAddress = userEmail;
                        this.Contact.client.clientRef = data.clientRef;
                        if (data.priceConcession && data.priceConcession.id) {

                            this.Contact.client.priceConcessionId = data.priceConcession.id;
                        }

                        this.Contact.bookingTypeList = (data.contacts[0] as any).bookingTypes ? (data.contacts[0] as any).bookingTypes : data.bookingTypes;
                        this.Contact.clientVenueIds = (data.clientVenues ?? []).map((i) => {
                            return { id: i.id, name: i.name }
                        });;
                        //this.Contact.isPrimaryContact = data.con
                        if (data.profilePicture != undefined)
                            this.Contact.profilePicture = data.profilePicture;
                        //this.store.dispatch(new SetContact(this.Contact));

                        //Moved this to Mvc
                        // this._customerService.SendCustomerRegisterConfirmation(userEmail, data.contacts[0].id).subscribe(result => {});

                        // additional details save

                        if (((this.additionalDetailNote || (this.additionalDetail.documents.length > 0)) && this.IsEnableToCreateAdditionalDetailsSectionForCorporateClients) || this.clientSaveForm.controls.Request_To_Begular_BookingAccess.value) {
                            this.additionalDetail.note.entityTypeID = "5";
                            this.additionalDetail.note.noteTypeId = this.appData?.ClientRegistrationConfig?.ReqularPortalRequestToAccessNoteTypeID ?? '';
                            this.additionalDetail.contactId = this.Contact.id;
                            this.additionalDetail.contactRef = this.Contact.ref;
                            this.additionalDetail.clientRef = this.Contact.client.clientRef;
                            this.additionalDetail.clientId = this.Contact.client.id;
                            this.additionalDetail.isUpdate = false;
                            this.additionalDetail.note.plainText = "";
                            this.additionalDetail.sendAdditionalDetailNotificationMail = ((this.additionalDetailNote != undefined && this.additionalDetailNote != "") || this.additionalDetail.documents.length > 0)
                            if (this.additionalDetailNote) {
                                this.additionalDetail.note.plainText = "Client Note - Additional Note Created At : " + Utility.convertToISO(new Date()) + " " + this.additionalDetailNote;
                            }
                            if (this.clientSaveForm.controls.Request_To_Regular_Booking_Access_Note.value) {
                                this.additionalDetail.note.plainText += "Client Note - Reqular Booking Note - 1 Created At: " + Utility.convertToISO(new Date()) + " " + this.clientSaveForm.controls.Request_To_Regular_Booking_Access_Note.value;
                                //this.additionalDetail.sendNotificationForRegularBookingAccess = true;

                            }

                            //add regboking type notes
                            if (this.bookingTypes.length > 0) {
                                this.additionalDetail.note.plainText += "Preffered booking types At: " + Utility.convertToISO(new Date()) + " " + (this.bookingTypes.length == 1 ?  "is" : "are") +" "+ this.bookingTypes.toString();
                                //this.additionalDetail.sendNotificationForRegularBookingAccess = true;
                            }

                            if (this.requestToRegularBookingAccess) {
                                this.additionalDetail.sendNotificationForRegularBookingAccess = true;
                            }


                            let clientAdditionalDetailSaveResult = this._customerService.AdditionalDetail(this.additionalDetail);
                            clientAdditionalDetailSaveResult.subscribe(data => {
                                if (data.isError) {
                                    let errorData = [];
                                    if (data.errors != null && data.errors.length > 0) {
                                        data.errors.forEach((error, i) => {
                                            errorData.push(error.detail);
                                        });
                                        this.snackBar.openFromComponent(AlertMessageComponent, {
                                            duration: 3000,
                                            verticalPosition: 'top',
                                            data: errorData,
                                        });
                                    }
                                }
                                this.urlRedirection();
                            },
                                err => {
                                    console.log(err);
                                    this.urlRedirection();
                                });
                        } else {
                            this.urlRedirection();
                        }
                    }
                }
            },
                error => {
                    this.isDisableClientSaveBtn = false;
                    let _err = JSON.parse(error.message);
                    console.log(_err);
                    let errorData = [];
                    if (_err.errors.length > 0) {
                        _err.errors.forEach(err => {
                            if (err.errorCode == 500) {
                                errorData.push(err.errorMessage);
                            }
                            else if (err.errorCode == 400) {
                                errorData.push(err.detail);
                                // if ((err.errors)) {
                                //     if (err.errors != null && err.errors.length > 0) {
                                //         err.errors.forEach((error, i) => {

                                //         });
                                //     }
                                // }
                            }
                        });
                    }

                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: errorData,
                    });
                }

            )
        }

    }

    urlRedirection() {
        //if (this.CurrentUrl == 'fromLogin' && this.IsFromLogin) {
        //    if (Utility.urlHistorty.length > 0) {
        //        var link = Utility.urlHistorty[Utility.urlHistorty.length - 1]
        //        this.route.navigate([`${link.split('?')[0]}`], { queryParams: Utility.sortParams(link) })
        //    } else {
        //        this.route.navigate([RoutePath.Facility])
        //    }
        //} else if (this.CurrentUrl == 'fromBookingDetail') {
        //    this.route.navigate([RoutePath.BookingCart])
        //} else  if (this.CurrentUrl === 'wasteBooking') {
        //  window.open(environment.WasteBookingPortalUrl,  '_self');
        //} else {
        //    this.store.dispatch(new DoBooking());
        //}
        let _redirectActionPayload: string, _navigateUrl: string, _queryParams: any, _redirectFullUrl: string, _action: any;
        var appsetting = environment.AppSetting as AppSetting;
        var _customerDefaultPriceConsessionId = this.Contact.client.priceConcessionId ? this.Contact.client.priceConcessionId : 0;
        var _systemDefaultPriceConsessionId = this.isAllImmediateConfirmed ? environment.ImmediateConfirmation.DefaultPriceConsessionId : appsetting.BookingConfig.DefaultPriceConsessionId ? appsetting.BookingConfig.DefaultPriceConsessionId : 0;
        if (((this.CurrentUrl == 'fromLogin') && this.IsFromLogin) || (this.CurrentUrl == 'tab')) {
            if (Utility.urlHistorty.length > 0) {
                var link = Utility.urlHistorty[Utility.urlHistorty.length - 1]
                //this.route.navigate([`${link.split('?')[0]}`], { queryParams: Utility.sortParams(link) })
                _navigateUrl = `${link.split('?')[0]}`;
                _queryParams = Utility.sortParams(link);

            } else {
                //this.route.navigate([RoutePath.Facility])
                _redirectActionPayload = "FACILITY";
            }
        } else if ((this.CurrentUrl == 'fromBookingDetail')) {
            if ((_customerDefaultPriceConsessionId && _customerDefaultPriceConsessionId > 0) &&
                (!this.isAllImmediateConfirmed || _customerDefaultPriceConsessionId != _systemDefaultPriceConsessionId)
            ) {
                _redirectActionPayload = "CART-PRICECHANGE";
            } else {
                _redirectActionPayload = "CART";
            }
            //this.route.navigate([RoutePath.BookingCart])
        } else if ((this.CurrentUrl == 'fromWasteBooking')) {
            //if (this.IsFromWasteBookingCart) {
            //    // window.open(environment.WasteBookingPortalUrl + '#/wm-booking-cart?loggedIn=1', '_self');
            //    _navigateUrl = environment.WasteBookingPortalUrl + '#/wm-booking-cart?loggedIn=1'
            //} else {
            //    //window.open(environment.WasteBookingPortalUrl,  '_self');
            //    _navigateUrl = environment.WasteBookingPortalUrl
            //}
        } else if ((this.CurrentUrl == "fromPublicWeb")) {
            if ((this.returnUrl != "")) {
                _redirectFullUrl = environment.PublicWebPortalUrl + '#/' + this.returnUrl;
            } else
                _redirectFullUrl = environment.PublicWebPortalUrl;
        } else {
            //this.store.dispatch(new DoBooking());
            if ((_customerDefaultPriceConsessionId && _customerDefaultPriceConsessionId > 0) &&
                (!this.isAllImmediateConfirmed || _customerDefaultPriceConsessionId != _systemDefaultPriceConsessionId)
            ) {
                _redirectActionPayload = "CART-PRICECHANGE";
            } else {
                _action = new SaveBooking();

            }
        }
        this.store.dispatch(new SetContact(this.Contact, _redirectActionPayload, _navigateUrl, _queryParams, _redirectFullUrl, _action));
    }
    passwordStrength(event) {

        console.log("password Keydown fire", event.target.value);
        this.isPasswordValid = this.validatePassword(event.target.value);



    }

    validatePassword(password): boolean {
        var isValid = true;

        // this.pass_Strength_progress = 0;
        // this.pass_Strength_count = 0;
        //   this.showValidationMessage = false;
        if (this.pwPolicy != null) {

            if (this.pwPolicy.EnforceComplexPassword) {

                var EnforceComplexPasswordValue = true;

                if (this.pwPolicy.EnforceDigit) {



                    var EnforceDigitValidation = new RegExp(".*[0-9].*");

                    if (EnforceDigitValidation.test(password)) {



                        this.isValidOneDigit = true;
                    }
                    else {
                        isValid = false;
                        EnforceComplexPasswordValue = false;
                        this.isValidOneDigit = false;

                    }
                }

                if (this.pwPolicy.EnforceMinimumPasswordLength) {
                    if (password != undefined && password != null && password.length >= this.pwPolicy.PasswordLength) {
                        this.isValidCharactersMinimum = true;

                    }
                    else {
                        isValid = false;
                        EnforceComplexPasswordValue = false;
                        this.isValidCharactersMinimum = false;

                    }
                }

                if (this.pwPolicy.EnforceLowercase) {
                    if ((/[a-z]/.test(password))) {
                        this.isValidOneLowercase = true;

                    }
                    else {
                        isValid = false;
                        EnforceComplexPasswordValue = false;
                        this.isValidOneLowercase = false;

                    }

                    if (this.pwPolicy.EnforceUppercase) {
                        if ((/[A-Z]/.test(password))) {
                            this.isValidOneUppercase = true;
                        }
                        else {
                            isValid = false;
                            EnforceComplexPasswordValue = false;
                            this.isValidOneUppercase = false;
                        }
                    }

                    if (this.pwPolicy.EnforceSpecialCharacters) {
                        if ((/[!@@#$%^&*(),.?"`~:;\[\]'/{}|\\<>+=._-]/.test(password))) {
                            this.isValidOneSpecialCharacter = true;
                        }
                        else {
                            isValid = false;
                            EnforceComplexPasswordValue = false;
                            this.isValidOneSpecialCharacter = false;
                        }
                    }

                    if (this.pwPolicy.EnforceSpecialConditions) {
                        let thisComponent = this;
                        let _FirstName = this.clientSaveForm.controls.FirstName.value.toLowerCase();
                        let _LastName = this.clientSaveForm.controls.LastName.value.toLowerCase();

                        if (_FirstName != null && _FirstName != "" && password.toLowerCase() == _FirstName) {

                            thisComponent.showValidationMessage = true;
                            thisComponent.validationMessage = _FirstName + " cannot be a password";
                            isValid = false;
                        }

                        if (_LastName != null && _LastName != "" && password.toLowerCase() == _LastName) {

                            thisComponent.showValidationMessage = true;
                            thisComponent.validationMessage = _LastName + " cannot be a password";
                            isValid = false;
                        }

                    }

                    if (EnforceComplexPasswordValue) {
                        this.isValidEnforceComplexPassword = true;
                    } else {
                        this.isValidEnforceComplexPassword = false;
                    }
                }
            }
            return isValid;
        }
    }

    GetDropDownDetails() {
        let titleResult = this._customerService.getClientTiltles();
        titleResult.subscribe(data => {
            if (data != null) {
                this.Titles = data.map((i) => {
                    return { id: i.id, name: i.name }
                });
            }
            this.SelectedTitleId = this.Titles[0].id;
            this.SelectedTitleName = this.Titles[0].name;
        },
            err => {

            }
        );
        let communicationTypeResult = this._customerService.CommunicationTypes();
        communicationTypeResult.subscribe(data => {
            if (data != null) {
                for (let comType of data) {
                    if (comType.format == "1") {
                        let comPhone = new ReferenceData();
                        comPhone.id = comType.id;
                        comPhone.name = comType.name;

                        this.CommunicationTypesPhone.push(comPhone); //Get phone com types
                    }
                    else if (comType.format == "2") {
                        let comEmail = new ReferenceData();
                        comEmail.id = comType.id;
                        comEmail.name = comType.name;

                        this.CommunicationTypesEmail.push(comEmail); //Get email com types
                    }
                    else {
                        let com = new ReferenceData();
                        com.id = comType.id;
                        com.name = comType.name;

                        this.CommunicationTypes.push(com); //Get other com types
                    }

                }
            }
            this.SelectedPhoneTypeId = this.CommunicationTypesPhone[0].id;
            this.SelectedPhoneTypeName = this.CommunicationTypesPhone[0].name;
            this.SelectedEmailTypeId = this.CommunicationTypesEmail[0].id;
            this.SelectedEmailTypeName = this.CommunicationTypesEmail[0].name;
            this.SelectedAdditionalPhoneTypeId = this.CommunicationTypesPhone[0].id;
            this.SelectedAdditionalPhoneTypeName = this.CommunicationTypesPhone[0].name;
            this.SelectedAdditionalEmailTypeId = this.CommunicationTypesEmail[0].id;
            this.SelectedAdditionalEmailTypeName = this.CommunicationTypesEmail[0].name;

            this.mobileNumberValidation("PersonalPhoneNumber_1", this.SelectedPhoneTypeId);
            this.mobileNumberValidation("PersonalPhoneNumber_2", this.SelectedAdditionalPhoneTypeId);
            this.mobileNumberValidation("CompanyPhoneNumber", "1");


        },
            err => {

            }
        );

        this._customerService.getCountryValues().safeSubscribe((this), values => {
            if (values.length > 0) {
                this.countries = values;
                this.setDefaultCountry();
            }
        })
    }

    SelectTitle(selectedTitleId: string) {
        this.SelectedTitleId = selectedTitleId;
        this.SelectedTitleName = this.Titles.find(i => i.id === selectedTitleId).name;
    }

    setDefaultCountry() {
        let defaultCountry = this.countries.find(c => +(c.id) == this.DefaultCountryId)
        if (defaultCountry) {
            this.Address_Country = defaultCountry.name;
            this.clientSaveForm.controls.Address_Country.setValue(defaultCountry.name);
            this.country.setValue(defaultCountry);
        }
    }

    SelectCountry(SelectedCountryId: string) {
        let selectedCountry = this.countries.find(c => c.id == SelectedCountryId);
        if (selectedCountry) {
            this.Address_Country = selectedCountry.name;
            this.clientSaveForm.controls.Address_Country.setValue(selectedCountry.name);
            this.country.setValue(selectedCountry);
        }
    }

    private _filter(value: string): Country[] {
        let filterValue = ''
        if (value.length > 0) {
            filterValue = value.toLowerCase();
            return this.countries.filter(country => country.name.toLowerCase().startsWith(filterValue));
        } else {
            return this.countries;
        }
    }

    handleMatAutoComplete() {
        if (this.matAutocomplete.autocomplete._isOpen) {
            setTimeout(() => {
                this.matAutocomplete.closePanel()
            }, 0)
        }
    }

    displayFn(country?: any): string | undefined {
        return country ? country.name : undefined;
    }


    uploadClick() {
        let ele: HTMLElement = this.el.nativeElement as HTMLElement;
        ele.click();
        //this.el.getNativeElement().click();
    }
    SelectComType(selectedComTypeId: string, basicComType: number, isAdditional: boolean) {
        if (!isAdditional) {
            if (basicComType == 1) {
                this.SelectedPhoneTypeId = selectedComTypeId;
                this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
                this.mobileNumberValidation("PersonalPhoneNumber_1", this.SelectedPhoneTypeId);
            }
            else if (basicComType == 2) {
                this.SelectedEmailTypeId = selectedComTypeId;
                this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }
        else {
            if (basicComType == 1) {
                this.SelectedAdditionalPhoneTypeId = selectedComTypeId;
                this.SelectedAdditionalPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
                this.mobileNumberValidation("PersonalPhoneNumber_2", this.SelectedAdditionalPhoneTypeId);
            }
            else if (basicComType == 2) {
                this.SelectedAdditionalEmailTypeId = selectedComTypeId;
                this.SelectedAdditionalEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }

    }

    findAddress(value) {
        this.addressChanged.next(value);
    }

    loadAddress(searchText: string) {
        this.AddressList = new Addresses();
        if (searchText) {
            if (searchText.trim() != "") {
                this.GetQASAddress(false, '', searchText).subscribe(data => {
                    this.isNoResultFound = false;
                    if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                        this.AddressList = data;
                    }
                    else {
                        this.isNoResultFound = true;
                        this.AddressList = new Addresses();
                    }
                    this.QASLoader = false;
                });
            } else {
                this.QASLoader = false;
                this.AddressList.AddressDetails = [];
            }
        }

    }

    GetQASAddress(isFormattedAddress: boolean, QASID: string, searchText: string) {
        (searchText.trim() == "") ? (this.QASLoader = false, this.AddressList = new Addresses()) : '';
        (!isFormattedAddress && searchText.trim() != "") ? (this.QASLoader = true) : (this.QASLoader = false);
        let qasAddressParam = new QASAddress();
        qasAddressParam.isFormattedAddress = isFormattedAddress;
        qasAddressParam.QASId = QASID;
        qasAddressParam.SearchText = searchText;

        return this._customerService.getQASAddress(qasAddressParam);
    }

    //RedirectToBookingDetail() {
    //    this.navigated.emit(2);
    //}
    Redirect(event, id, url) {

        event.preventDefault();
        if (url == 'fromBookingDetail') {
            return this.route.navigate([RoutePath.BookingCart])
        } else if (id == 1)
            return this.navigated.emit(1);
        else
            return this.route.navigate([RoutePath.Facility])

    }

    onChange(event) {
        this.isChecked = !this.isChecked;
    }
    handleSuccess(e) {
        this.isCaptchaVerified = true;
    }

    handleExpire() {
        this.isCaptchaVerified = false;
    }

    handleLoad() {
        console.log('load')
    }



    //#region File upload

    public droppedCl(event: UploadEvent) {
        this.files = event.files;
        let snack = this.snackBar;

        for (const droppedFile of event.files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (this.checkFileExtesion(file.name)) {

                        if (file.size < this.docSize * 1048576) {
                            var myReader: FileReader = new FileReader();
                            myReader.onloadend = (e) => {
                                let aHD = new AdHocDocument();
                                aHD.name = file.name;
                                aHD.fileSize = Utility.calculateFileSize(file.size);

                                let stringdata = myReader.result.toString().split(',');
                                aHD.documentFile = stringdata[stringdata.length - 1];
                                aHD.file = file;
                                aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                                this.additionalDetail.documents.push(aHD);
                                this.cdr.detectChanges();
                            };
                            myReader.readAsDataURL(file);
                        }
                        else {
                            snack.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                            });
                        }
                    }
                    else {
                        snack.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]
                        });
                    }
                });
            } else {
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }
    }

    onFileChangedCl(event) {

        if (this.checkFileExtesion(event.target.files[0].name)) {

            if (event.target.files[0].size < this.docSize * 1048576) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    let aHD = new AdHocDocument();
                    aHD.file = event.target.files[0];
                    aHD.name = event.target.files[0].name;
                    aHD.fileSize = Utility.calculateFileSize(event.target.files[0].size);
                    let stringdata = myReader.result.toString().split(',');
                    aHD.documentFile = stringdata[stringdata.length - 1];
                    aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                    this.additionalDetail.documents.push(aHD);
                    this.fileInputTagVariable.nativeElement.value = "";
                    this.cdr.detectChanges();
                };
                myReader.readAsDataURL(event.target.files[0]);
            }
            else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                });
            }
        }
        else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]
            });
        }
    }

    removeFile(index) {
        this.additionalDetail.documents.splice(index, 1);
    }

    checkFileExtesion(name: string) {
        let supportTypes = ["doc", "docx", "pdf", "txt", "xls", "xlsx", "jpeg", "jpg", "png"];
        let fileExtensionArray = name.split('.');
        let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
        return (supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
    }

    get64Stringdata(file: AdHocDocument) {
        if (file && file.file && file.file.size) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.file));
        }
        else return null;
    }
    //#endregion

    //#region profile image upload
    onFileChangedForProfileImage(event) {

        if (this.IsFileExtesionImage(event.target.files[0].name)) {

            if (event.target.files[0].size < this.docSize * 1048576) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.profileImage = myReader.result;
                    this.fileInputTagforProfileImage.nativeElement.value = "";
                };
                myReader.readAsDataURL(event.target.files[0]);
            }
            else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                });
            }
        }
        else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .jpeg, .jpg, .png"]
            });
        }
    }

    public droppedForProfileImage(event: UploadEvent) {
        if (this.profileImage)
            return;
        this.files = event.files;
        let snack = this.snackBar;

        for (const droppedFile of event.files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (this.IsFileExtesionImage(file.name)) {

                        if (file.size < this.docSize * 1048576) {
                            var myReader: FileReader = new FileReader();
                            myReader.onloadend = (e) => {
                                this.profileImage = myReader.result;
                            };
                            myReader.readAsDataURL(file);
                        }
                        else {
                            snack.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                            });
                        }
                    }
                    else {
                        snack.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .jpeg, .jpg, .png"]
                        });
                    }
                });
            } else {
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }
    }

    IsFileExtesionImage(name: string) {
        let supportTypes = ["jpeg", "jpg", "png"];
        let fileExtensionArray = name.split('.');
        let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
        return (supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
    }
    //#endregion profile image upload

    passwordValidator(control: FormControl) {
        let password = control.value;
        if (password && this) {
            const confirmConroller = control.root.get('ConfirmPassword');
            console.log(this.validatePassword(password));
            if (this.validatePassword(password)) {
                //return null;
            } else {
                return {
                    passwordStrength: {
                        errorMsg: "Password Invalid"
                    }
                }
            }
            if (confirmConroller) {
                const confirmPw = confirmConroller.value;
                if (password !== confirmPw && confirmConroller.touched) {
                    confirmConroller.setErrors({ 'incorrect': true });
                } else {
                    confirmConroller.setErrors({ 'incorrect': null });
                    confirmConroller.updateValueAndValidity();
                }
            }
        }
        return null;
    }

    EmailAdressChanged(email) {
        if (environment.AutoPopulateUsernameFromEmail) {
            this.clientSaveForm.controls.UserName.setValue(email);
        }
    }




    getValidators(type) {
        var _validators: any[] = [];
        if (this.appData.ClientRegistrationConfig.EnableManualAddress) {

            if (type == 1) {
                if (this.appData.ClientRegistrationConfig.Placeholder.Address?.AddressLine1?.IsMandatory == "1") {
                    _validators.push(Validators.required)
                }
                _validators.push(Validators.maxLength(450))
            }
            if (type == 2 && this.appData.ClientRegistrationConfig.Placeholder.Address?.AddressLine2?.IsMandatory == "1") {
                _validators.push(Validators.required)
            }
            if (type == 3 && this.appData.ClientRegistrationConfig.Placeholder.Address?.AddressLine3?.IsMandatory == "1") {
                _validators.push(Validators.required)
            }
            if (type == 4 && this.appData.ClientRegistrationConfig.Placeholder.Address?.City?.IsMandatory == "1") {
                _validators.push(Validators.required)
            }
            if (type == 5 && this.appData.ClientRegistrationConfig.Placeholder.Address?.Country?.IsMandatory == "1") {
                _validators.push(Validators.required)
            }
            if (type == 6 && this.appData.ClientRegistrationConfig.Placeholder.Address?.State?.IsMandatory == "1") {
                _validators.push(Validators.required)
            }
            if (type == 7 && this.appData.ClientRegistrationConfig.Placeholder.Address?.PostCode?.IsMandatory == "1") {
                _validators.push(Validators.required)
            }
            if (type == 8 && this.appData.ClientRegistrationConfig.Placeholder.Corporate?.CompanyPhoneNumber?.IsMandatory == "1") {
                _validators.push(Validators.required)
            }
            if (type == 9 && this.appData.ClientRegistrationConfig.Placeholder.Corporate?.CompanyEmail?.IsMandatory == "1") {
                _validators.push(Validators.required, Validators.pattern(this.emailPattern));
            }
            if (type == 9 && this.appData.ClientRegistrationConfig.Placeholder.Corporate?.CompanyEmail?.IsMandatory == "0") {
                _validators.push(Validators.pattern(this.emailPattern));
            }
            if (type == 10) {
                if (this.appData.ClientRegistrationConfig.Placeholder.Corporate?.CompanyName?.IsMandatory == "1") {
                    _validators.push(Validators.required);
                }
                _validators.push(Validators.maxLength(200))
            }

            if (type == 10) {
                if (this.appData.ClientRegistrationConfig.Placeholder.Corporate?.CompanyName?.IsMandatory == "1") {
                    _validators.push(Validators.required)
                }
                _validators.push(Validators.maxLength(200))
            }


        }

        return _validators;
    }

    getMobileValidators(pattern, validationControlName) {
        var mobileValidators: any[] = [];
        if (pattern != undefined && pattern != null) {
            mobileValidators.push(Validators.pattern(pattern))
        }
        if (validationControlName == "PersonalPhoneNumber_1" || (validationControlName == "CompanyPhoneNumber" && this.appData.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.IsMandatory == "1")) {
            mobileValidators.push(Validators.required)
        }

        return mobileValidators;
    }

    bookingTypesRequestCheck(event: any) {
        console.log(event);
        let _value = event.source.value
        let _isChecked = event.source._selected

        if (_isChecked && this.bookingTypes.findIndex(x => x == _value) < 0) {
            this.bookingTypes.push(_value);
        }

        else {
            this.bookingTypes.splice(this.bookingTypes.findIndex(x => x == _value), 1)
        }
    }



}
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

        return (invalidCtrl || invalidParent);
    }
}


export function ConfPasswordValidator(control: AbstractControl) {

    if (control !== null && control !== undefined) {
        const confirmPw = control.value;

        const pwConroller = control.root.get('Password');
        if (pwConroller) {
            const pw = pwConroller.value;

            if (pw !== confirmPw) {
                return {
                    isError: true
                }
            }
        }
    }
    return null;
}


//export function ConfPasswordValidator(control: AbstractControl) {


//    if (control !== null && control !== undefined) {
//        const Pw = control.value;

//        const confirmConroller = control.root.get('ConfirmPassword');
//        if (Pw) {
//            const confirmPw = confirmConroller.value;

//            if (Pw !== confirmPw && confirmConroller.touched) {
//                confirmConroller.setErrors({ 'incorrect': true });
//            } else {
//                confirmConroller.setErrors({ 'incorrect': null });
//                confirmConroller.updateValueAndValidity();
//            }
//        }
//    }
//    return null;
//}

export function EmailValidator(control: AbstractControl) {


    if (control !== null && control !== undefined) {
        const email = control.value;
        if (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
            if (re.test(String(email).toLowerCase())) {
                control.setErrors({ 'incorrect': null });
                control.updateValueAndValidity();
                return {
                    isError: false
                }
            } else {
                return {
                    isError: true
                }
            }
        }
        else {
            return {
                isError: true
            }
        }
    }
    else {
        return {
            isError: true
        }
    }
    return null;
}

