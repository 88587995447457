import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdHocDocument} from '../../../../../models/booking/adhoc-document';
import {AlertMessageComponent} from '../../../../../shared/alert-message/alert-message.component';
import {FileSystemDirectoryEntry, FileSystemFileEntry, UploadEvent} from 'ngx-file-drop';
import {AppSetting} from '../../../../../models/settings/casual-portal/app-setting';
import {BookingService} from '../../../../../services/booking.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import { AnswerUploadedFiles, Question} from 'src/modules/models/item/question';
import { Utility } from 'src/modules/utility';

@Component({
  selector: 'opt-file-upload-questionire',
  templateUrl: './file-upload-questionire.component.html',
  styleUrls: ['./file-upload-questionire.component.scss']
})
export class FileUploadQuestionireComponent implements OnInit {
  @ViewChild('fileInputTag') fileInputTagVariable: ElementRef;
  @ViewChild('target') scrollTarget: ElementRef;
  @Input() maxCount: number;
  @Input() maxSize: number;
  @Input() minCount: number;
  @Input() supportedFileType: string;
  @Input() answeredText: string;
  @Input() question: Question;
  @Input() questionType: Question;
  @Input() answerUploadedFiles : AnswerUploadedFiles[];
  @Input() isBookingQuestion : boolean;
  @Input() id : string;

  @Output() answeredTextChanged: EventEmitter<string> = new EventEmitter<string>();




  CartPageDocumentDescription: any;
  selectedFilesUpladArray: AdHocDocument[] = [];
  appSetting: AppSetting;
  uploadDocumentRefarray: string[] = [];
  uploadSelectedDocumentRefarray: string[] = [];
  constructor(
    private _bookingservice: BookingService,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if(this.isBookingQuestion == undefined){
      this.isBookingQuestion = false
    }

  }

  checkFileExtesion(name: string) {
    let supportTypes = this.supportedFileType;
    let fileExtensionArray = name.split('.');
    let fileExtension = fileExtensionArray[fileExtensionArray.length - 1].toLowerCase();
    return supportTypes.includes(fileExtension);

  }
  bytesToSize(bytes) {
    if (bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) {
        return 'n/a';
      }
      var i = +(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) {
        return bytes + ' ' + sizes[i];
      }
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    }
  }
  onFileChangednew(event) {
    var _answerUploadedFiles = this.isBookingQuestion ? (this.questionType.answerUploadedFiles ? this.questionType.answerUploadedFiles.filter(file => file.isDelete === undefined || file.isDelete !== true) : []) : (this.question.answerUploadedFiles ? this.question.answerUploadedFiles.filter(file => file.isDelete === undefined || file.isDelete !== true) : [])
    if (this.maxCount == undefined || (this.maxCount != undefined  &&  _answerUploadedFiles.length < this.maxCount)) {
      let ds = this.maxSize;
      if (this.checkFileExtesion(event.target.files[0].name)) {
        if (event.target.files[0].size < ds * 1048576) {
          var myReader: FileReader = new FileReader();
          myReader.onloadend = (e) => {
            let aHD = new AdHocDocument();
            aHD.file = event.target.files[0];
            aHD.name = event.target.files[0].name;
            aHD.fileSize = Utility.calculateFileSize(event.target.files[0].size);
            let stringdata = myReader.result.toString().split(',');
            aHD.documentFile = stringdata[stringdata.length - 1];
            aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], '');

            this._bookingservice.UploadBookingDocuments(aHD, false).subscribe((data: any) => {
                if (data && data.body) {
                  aHD.id = data.body;
                  this.selectedFilesUpladArray.push(aHD);
                  this.pushUploadedFile(aHD)
                  this.pushRefferance(data.body);
                }


              },
              err => {
                console.log('UploadBookingDocuments Error : ', err);
              });

            this.fileInputTagVariable.nativeElement.value = '';
          };
          myReader.readAsDataURL(event.target.files[0]);
        } else {
          this.snackBar.openFromComponent(AlertMessageComponent, {
            duration: 3000,
            verticalPosition: 'top',
            data: ['Your request could not be processed. You have attempted to upload a document larger than ' + ds + ' MB in size']
          });
        }
      } else {
        this.snackBar.openFromComponent(AlertMessageComponent, {
          duration: 3000,
          verticalPosition: 'top',
          data: ['Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:' + this.supportedFileType]
        });
      }
    }else {
      this.snackBar.openFromComponent(AlertMessageComponent, {
        duration: 3000,
        verticalPosition: 'top',
        data: ['You cannot exceed the maximum count of ' + this.maxCount + ' document(s).'],
      });
    }
  }

  public dropped(event: UploadEvent) {
    const files = event.files;
    const snack = this.snackBar;
    const ds = this.maxSize;


    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.checkFileExtesion(file.name)) {
            if (file.size == 0) {
              snack.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ['Your request could not be processed. You have attempted to upload an empty document']
              });
              return;
            } else if (file.size < ds * 1048576) {
              const myReader: FileReader = new FileReader();
              myReader.onloadend = (e) => {
                const aHD = new AdHocDocument();
                aHD.name = file.name;
                aHD.fileSize = Utility.calculateFileSize(file.size);
                let stringdata = myReader.result.toString().split(',');
                aHD.documentFile = stringdata[stringdata.length - 1];
                aHD.file = file;
                aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], '');

                this._bookingservice.UploadBookingDocuments(aHD, false).subscribe((data: any) => {
                  if (data && data.body) {
                    aHD.id = data.body;
                    this.selectedFilesUpladArray.push(aHD);
                    this.pushUploadedFile(aHD)
                    this.pushRefferance(data.body);

                  }
                }, err => {
                  console.log('UploadBookingDocuments Error : ', err);
                });
              };
              myReader.readAsDataURL(file);
            } else {
              snack.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ['Your request could not be processed. You have attempted to upload a document larger than ' + ds + ' MB in size']
              });
            }
          } else {
            snack.openFromComponent(AlertMessageComponent, {
              duration: 3000,
              verticalPosition: 'top',
              data: ['Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png']
            });
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;

      }
    }
  }

  pushRefferance(ref: string): void {
    if (!this.uploadSelectedDocumentRefarray.includes(ref)) {
      this.uploadSelectedDocumentRefarray.push(ref);
      if(this.isBookingQuestion){
        const newAnsweredText = this.uploadSelectedDocumentRefarray.join(',');
        this.questionType.answeredText = this.answeredText ? `${this.answeredText},${newAnsweredText}` : newAnsweredText;
        this.answeredTextChanged.emit(this.answeredText);
      }else {
        const newAnsweredText = this.uploadSelectedDocumentRefarray.join(',');
        this.question.answeredText = this.answeredText ? `${this.answeredText},${newAnsweredText}` : newAnsweredText;
       //this.question.answeredText = this.uploadSelectedDocumentRefarray.join(',');
        this.answeredTextChanged.emit(this.question.answeredText);
      }
    }
  }

  get64StringData(file: AdHocDocument) {
    if (file && file.file && file.file.size) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.file));
    } else {
      return null;
    }
  }

  removeFile(file: AdHocDocument) {
    this._bookingservice.UploadBookingDocuments(file, true).subscribe((data: any) => {
      const index = this.selectedFilesUpladArray.findIndex((uploadedFile) => uploadedFile.id === file.id);
      if (index !== -1) {
        this.selectedFilesUpladArray.splice(index, 1);
      }
      if (this.question.answerUploadedFiles) {
        const answerUploadedFileIndex = this.question.answerUploadedFiles.findIndex((uploadedFile) => uploadedFile.id === file.id);
        if (answerUploadedFileIndex !== -1) {
          this.question.answerUploadedFiles.splice(answerUploadedFileIndex, 1);
        }
      }
    });
  }
  removeUploadFile(id){
    if(!this.isBookingQuestion){
      if (this.question.answerUploadedFiles) {
        const answerUploadedFileIndex = this.question.answerUploadedFiles.findIndex((uploadedFile) => uploadedFile.id ===id);
        if (answerUploadedFileIndex !== -1) {
          this.question.answerUploadedFiles.splice(answerUploadedFileIndex, 1);
          this.updateAnsweredText();
        }
      }
    }else {
      if (this.questionType.answerUploadedFiles) {
        const answerUploadedFileIndex = this.questionType.answerUploadedFiles.findIndex((uploadedFile) => uploadedFile.id === id);
        if (answerUploadedFileIndex !== -1 ) {
          const isSavedDocumet = this.questionType.answerUploadedFiles[answerUploadedFileIndex].documentFileURL ? true : false
          if(isSavedDocumet == true){
            this.questionType.answerUploadedFiles[answerUploadedFileIndex].isDelete = true;
          }else {
            this.questionType.answerUploadedFiles.splice(answerUploadedFileIndex, 1);
          }
          this.questionType.answerUploadedFiles = [...this.questionType.answerUploadedFiles];
          this.updateAnsweredText();
        }
      }
    }

  }

  updateAnsweredText() {
    const oldAnsweredText = this.answeredText;
    if (this.answerUploadedFiles && this.answerUploadedFiles.length > 0) {
      const uploadedFileIds = this.answerUploadedFiles.map(file => file.id);
      const currentAnsweredIds = this.answeredText ? this.answeredText.split(',').map(id => id.trim()) : [];
      const newIdsToAdd = uploadedFileIds.filter(id => !currentAnsweredIds.includes(id));
      const idsToRemove = currentAnsweredIds.filter(id => !uploadedFileIds.includes(id));
      currentAnsweredIds.push(...newIdsToAdd);
      idsToRemove.forEach(id => {
        const index = currentAnsweredIds.indexOf(id);
        if (index !== -1) {
          currentAnsweredIds.splice(index, 1);
        }
      });
      if(this.isBookingQuestion){
        /*const filterdId = this.questionType.answerUploadedFiles.filter(file => !file.isDelete);
        const updatedAnsweredIds = filterdId.map(file => file.id);
        this.questionType.answeredText = updatedAnsweredIds.join(',');*/
        this.answeredText = currentAnsweredIds.join(',');
        this.answeredTextChanged.emit(this.questionType.answeredText);
      }else {
        this.question.answeredText =currentAnsweredIds.join(',');
        this.answeredTextChanged.emit(this.question.answeredText);
      }

    } else {
      if(this.isBookingQuestion){
        this.questionType.answeredText = undefined;
      }else {
        this.question.answeredText = undefined;
      }

    }
    if (oldAnsweredText !== this.answeredText) {
      this.cdr.detectChanges();
    }

  }
  pushUploadedFile(uploadedFile: AdHocDocument) {
    if(!this.isBookingQuestion){
      if (!this.question.answerUploadedFiles) {
        this.question.answerUploadedFiles = [];
      }
      const answerUploadedFile = new AnswerUploadedFiles();
      answerUploadedFile.id = uploadedFile.id;
      answerUploadedFile.name = uploadedFile.name;
      answerUploadedFile.file = uploadedFile.file;
      answerUploadedFile.fileSize = uploadedFile.fileSize;
      const fileNameParts = uploadedFile.name.split('.');
      answerUploadedFile.fileType = fileNameParts[fileNameParts.length - 1];
      this.question.answerUploadedFiles.push(answerUploadedFile);
    }else {
      if (!this.questionType.answerUploadedFiles) {
        this.questionType.answerUploadedFiles = [];
      }
      const answerUploadedFile = new AnswerUploadedFiles();
      answerUploadedFile.id = uploadedFile.id;
      answerUploadedFile.name = uploadedFile.name;
      answerUploadedFile.file = uploadedFile.file;
      answerUploadedFile.fileSize = uploadedFile.fileSize;
      const fileNameParts = uploadedFile.name.split('.');
      answerUploadedFile.fileType = fileNameParts[fileNameParts.length - 1];
      this.questionType.answerUploadedFiles.push(answerUploadedFile);
    }

  }
  downloadDocument(url,name ) {
    this._bookingservice.downloadFile(url, name);
  }

}
