
import { Store } from '@ngrx/store';
import { CognitoAuthService } from './cognito/congnito-auth-service'
import { AppSetting } from '../models/settings/casual-portal/app-setting';

import { AuthService } from './auth-service'
import { CDCAuthService } from './cdc/cdc-auth-service'
import { AzureCiamAuthService } from './azure/azure-ciam-auth-service';

export class AuthServiceFactory {


    static createAuthService(settings: AppSetting): AuthService | null {
        let _settings = (settings?.B2CIntegration?.B2CLoginProvider ?? '')
        switch (_settings) {
            case 'sap-cdc':
                return new CDCAuthService();
            case 'azure':
                return new AzureCiamAuthService();

            case 'cognito':
                return new CognitoAuthService();
            default:
                return null;
        }
    }

    static isB2CEnable(settings: AppSetting) {
        return (settings?.B2CIntegration?.B2CLoginProvider ?? '') != ''
    }
    static disableCompanyData(settings: AppSetting) {
        let _settings = (settings?.B2CIntegration?.B2CLoginProvider ?? '')
        return _settings == 'sap-cdc'
    }
    static disableAddress(settings: AppSetting) {
        let _settings = (settings?.B2CIntegration?.B2CLoginProvider ?? '')
        return _settings == 'sap-cdc'
    }
}
