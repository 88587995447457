import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MsalGuard, MsalBroadcastService, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalRedirectComponent, MsalModule,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth-config';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MsalModule//.forRoot(msalInstance,MSALGuardConfigFactory(),MSALInterceptorConfigFactory )
  ],
  exports: [MsalModule],
  providers: [

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
    // MsalServiceProvider
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [MsalRedirectComponent]
})
export class CIAMModule { }
