import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Layout
//import { ThemeUtility } from '../layout/layout';
import { SiteLayoutModule as LayoutModule } from 'src/modules/layout/site-layout/site-layout.module';
import { SiteLayoutComponent as LayoutComponent } from 'src/modules/layout/site-layout/site-layout.component';

//Component
import { FacilitySearchComponent } from './facility/facility-search/facility-search.component';
import { FacilityDetailComponent } from './facility/facility-detail/facility-detail.component';
import { ModalWindowComponent } from './test-component/modal-window/modal-window.component';
import { BookingCartComponent } from './booking-cart/booking-cart.component';
import { MmcLoaderComponent } from '../shared/mmc-loader/mmc-loader.component';
import { ToastMessageComponent } from '../shared/toast-message/toast-message.component';
import { ComingSoonComponent } from '../shared/coming-soon/coming-soon.component';
import { BookingSearchComponent } from './booking-view/booking-search/booking-search.component';
import { BookingDetailsComponent } from './booking-view/booking-details/booking-details.component';
import { CustomerProfileComponent } from './account/customer-profile/customer-profile.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { ModalsComponent } from './modals/modals.component';
import { environment } from 'src/environments/environment';
import { AuthGuard } from '../shared/Auth.guard';

//var LayoutComponent = ThemeUtility.getThemeComponent();
//var LayoutModule = ThemeUtility.getThemeModule();

const routes: Routes = [
    {
        path: 'facility',
        component: LayoutComponent,
        children: [{ path: '', component: FacilitySearchComponent, pathMatch: 'full' }], canActivate: [AuthGuard]
    },
    {
        path: '404',
        component: ComingSoonComponent
    },
    {
        path: 'facility-detail',
        component: LayoutComponent,
        children: [{ path: '', component: FacilityDetailComponent, pathMatch: 'full' }], canActivate: [AuthGuard]
    },
    {
        path: 'booking-cart',
        component: LayoutComponent,
        children: [{ path: '', component: BookingCartComponent }], canActivate: [AuthGuard]
    },
    {
        path: 'bookings',
        component: LayoutComponent,
        children: [{ path: '', component: BookingSearchComponent, pathMatch: 'full' }], canActivate: [AuthGuard]
    },
    {
        path: 'booking-details/:id',
        component: LayoutComponent,
        children: [{ path: '', component: BookingDetailsComponent, pathMatch: 'full' }], canActivate: [AuthGuard]
    },
    {
        path: 'my-profile',
        component: LayoutComponent,
        children: [{ path: '', component: CustomerProfileComponent, pathMatch: 'full' }], canActivate: [AuthGuard]
    },
    {
        path: 'change-password',
        component: LayoutComponent,
        children: [{ path: '', component: ChangePasswordComponent, pathMatch: 'full' }], canActivate: [AuthGuard]
    },
    {
        path: 'modals',
        children: [{ path: '', component: ModalsComponent, pathMatch: 'full' }], canActivate: [AuthGuard]
    },
    {
        path: 'login',
        outlet: 'client',
        loadChildren: () => import('src/modules/booking-portal/client/client.module').then(m => m.ClientModule)
    },
    {
        path: 'setup',
        outlet: 'client',
        loadChildren: () => import('src/modules/booking-portal/client/client.module').then(m => m.ClientModule)
    },
    {
        path: 'modal',
        children: [{ path: '', component: ModalWindowComponent, pathMatch: 'full' }]
    },
    {
        path: 'loader',
        children: [{ path: '', component: MmcLoaderComponent, pathMatch: 'full' }]
    },
    {
        path: 'alert',
        children: [{ path: '', component: ToastMessageComponent, pathMatch: 'full' }]
    },
    {
        path: 'client',
        loadChildren: () => import('src/modules/booking-portal/client/client.module').then(m => m.ClientModule)

    },
    {
        path: 'payment',
        loadChildren: () => import('src/modules/payment-portal/payment.module').then(m => m.PaymentModule), canActivate: [AuthGuard]
    },
    {
        path: 'b2c',
        loadChildren: () => import('src/modules/b2c/b2c.module').then(m => m.B2CModule)

    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'facility'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }), LayoutModule],
    exports: [RouterModule]
})

export class BookingRoutingModule { }
