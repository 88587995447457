import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'opt-callout-box',
  templateUrl: './callout-box.component.html',
  styleUrls: ['./callout-box.component.scss']
})
export class CalloutBoxComponent implements OnInit {

  constructor() { }

  @Input() mainHeader: string = '';
  @Input() status: string = 'opt-callout-success';
  // opt-callout-warning
  @Input() secondaryHeader: string = '';
  @Input() bodyText: string = '';

  ngOnInit(): void {
    console.log("Status"+ this.status);
  }

  

}
