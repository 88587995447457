import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';

import { AuthService } from 'src/modules/b2c/auth-service'
import { AuthServiceFactory } from 'src/modules/b2c/auth-service-factory'
import { CustomerService } from 'src/modules/services/customer.service';
import { ILoadingStatus } from 'src/modules/store/loading';
import { Contact } from 'src/modules/models/client/contact';
import { ICart } from 'src/modules/store';

@Component({
  selector: 'opt-b2c-login',
  templateUrl: './b2c-login.component.html',
})
export class B2CLoginComponent implements OnInit, AfterViewInit {
  appsetting = environment.AppSetting as AppSetting;
  authService: AuthService;

  dbContact: Contact;
  b2cContact: Contact;

  ciam_return_url = ''
  redirecting = false;
  constructor(
    private store: Store<any>,
    private router: Router,
    route: ActivatedRoute,
    private customerService: CustomerService,
    private injector: Injector,
  ) {
    this.authService = AuthServiceFactory.createAuthService(this.appsetting)
  }


  ngAfterViewInit() {
    if (this.authService && (this.appsetting.B2CIntegration?.B2CLoginProvider ?? '') != '') {
      this.authService.init(this.injector, this.store, this.customerService);
      this.authService.loginRedirect();
    }

  }

  ngOnInit(): void {


    this.store.select('cart').pipe().subscribe((st: ICart) => {
      this.b2cContact = st.b2cContact;
      this.dbContact = st.contact;

    })


    this.store.select('loader').pipe().subscribe(async (st: ILoadingStatus) => {
      this.ciam_return_url = st.ciam_return_url;
      if (st.ciamDataLoaded && this.b2cContact && !this.redirecting) {
        this.redirecting = true
        var _url = environment.ApiUrl + '/customer/Index#/signin';
        window.location.href = (_url)
      }
    });
  }



}