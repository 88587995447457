<div class="mmc-modal-wrapper questionnaire-modal">
    <div class="mmc-modal-header mmc_flex--container">
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center">
            <i class="material-icons">forum</i>
        </div>
        <div class="mmc_flex__item mmc_flex__item--vcenter">
            <span class="section-title">Questionnaire</span>
        </div>
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
            <button mat-button [mat-dialog-close]="true" tabindex="0"><i class="material-icons">close</i></button>
        </div>
    </div>

    <div class="mmc-modal-body">
        <mat-accordion class="mcc-expansion-panel-inner mcc-expansion-panel-inner-inner">
            <!-- Package Questions -->
            <mat-expansion-panel class="questionnaire-type package" [disabled]="" [expanded]="true"  *ngIf="package">
                <mat-expansion-panel-header class="questionnaire-expansion" collapsedHeight="65px" expandedHeight="65px" aria-hidden="true">
                    <div class="mmc_flex--container  questionnaire-expansion-header">
                        <div class="mmc_flex__item mmc_flex__item--vcenter">
                            <div class="mmc_flex--container space-between questionnaire-expansion-header--container">
                                <div class="mmc_flex__item mmc_flex__item--vcenter">
                                    <div class="pw-txt-s1">{{package.name}} <span>| {{package.totalAnsweredQuestions}} out of {{package.totalQuestions}} answered</span></div>
                                  <!--<div class="pw-txt-s1">{{package.name}} <span>| {{package.totalAnsweredQuestions}} out of {{visibleQuestionsCount}} answered</span></div>-->
                                </div>

                                <!-- <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end">
                                    <div class="expansion-header-icons mt-2">
                                        <span class="icon-p2-questions icon f-size-md"></span>
                                        <span class="count">{{package?.question.length}}</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end icon__expand wfixed">
                            <button class="down-arrow" mat-icon-button color="primary" tabindex="0" aria-label="expand arrow"
                                    [disableRipple]="true">
                                <mat-icon></mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <form>
                    <div class="questionnaire-accordion">
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-accordion multi="true">
                                  <mat-expansion-panel
                                    [expanded]="true"
                                    [disabled]="true"
                                    [ngSwitch]="ques.type"
                                    *ngFor="let ques of package.question;let i=index;"
                                    [hidden]="ques.isHidden"

                                  >
<!--                                    <mat-expansion-panel [expanded]="true" [disabled]="true" [ngSwitch]="ques.type" *ngFor="let ques of questionsTobeDisplayed; let i=index;">-->
                                      <mat-expansion-panel-header [ngClass]="{'mandatory-question-unanswered': isSubmitAttempted && ques.isMandatoryUnanswered}">
                                          <mat-panel-title class="font__semibold">
                                            <div [innerHTML]="getSanitizedHtml(ques.questionText)"></div>
                                          </mat-panel-title><span *ngIf="ques.mandatory">*</span>
                                      </mat-expansion-panel-header>

                                      <div
                                        *ngSwitchCase="'textQuestion'"

                                      >
                                          <div class="mmc_flex--container mmc_fd--col">
                                              <div class="answer-row mmc_flex__item">
                                                  <mat-form-field class="">
                                                      <input matInput placeholder="Enter your answer" name=Txt{{i}} required="{{ques.mandatory}}" [attr.id]="id" [(ngModel)]="package.question[i].answeredText" #Txt="ngModel" (ngModelChange)="questionAnswered(package.question, $event,ques.id,package.question[i].answeredText)" [disabled]="isReadOnly">
                                                      <mat-error *ngIf="(Txt.dirty || Txt.touched) && Txt.errors?.required">Enter your answer</mat-error>
                                                  </mat-form-field>
                                              </div>
                                          </div>
                                      </div>

                                      <div
                                        *ngSwitchCase="'booleanQuestion'"

                                      >
                                          <div class="mmc_flex--container mmc_fd--col">
                                              <div class="answer-row mmc_flex__item">
                                                  <mat-radio-group name="answer-radio-group{{i}}" [(ngModel)]="package.question[i].answeredText" aria-label="Select an option" (ngModelChange)="questionAnswered(package.question, $event,ques.id,package.question[i].answeredText)" [disabled]="isReadOnly">
                                                      <mat-radio-button class="answer-row mmc_flex__item" value="Yes">Yes</mat-radio-button>
                                                  </mat-radio-group>
                                              </div>
                                              <div class="answer-row mmc_flex__item">
                                                  <mat-radio-group name="answer-radio-group{{i}}" [(ngModel)]="package.question[i].answeredText" aria-label="Select an option" (ngModelChange)="questionAnswered(package.question, $event,ques.id,package.question[i].answeredText)" [disabled]="isReadOnly">
                                                      <mat-radio-button class="answer-row mmc_flex__item" value="No">No</mat-radio-button>
                                                  </mat-radio-group>
                                              </div>
                                          </div>
                                      </div>

                                      <div
                                        *ngSwitchCase="'multipleChoiceQuestion'"
                                        class="multiple-choice-container"
                                      >
                                          <div *ngFor="let questions of ques.answerChoices; let j=index">
                                              <div class="mmc_flex--container mmc_fd--col" *ngIf="!ques.quantityRequired">
                                                  <div class="answer-row mmc_flex__item">
                                                      <mat-checkbox name="check[{{j}}{{i}}{{j+i}}]" [(ngModel)]="package.question[i].answerChoices[j].isSelectedAnswer" (ngModelChange)="questionAnswered(package.question, $event,ques.id,questions.id)" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                                  </div>
                                              </div>
                                              <div class="mmc_flex--container mmc_fd--col" *ngIf="ques.quantityRequired">
                                                  <div class="answer-row mmc_flex__item multi-choice-w-text">
                                                    <div class="answer-row mmc_flex__item">
                                                      <mat-checkbox name="check[{{j}}{{i}}{{j+i}}]" [(ngModel)]="package.question[i].answerChoices[j].isSelectedAnswer" (ngModelChange)="questionAnswered(package.question, $event,ques.id,questions.id)" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                                    </div>
                                                    <mat-form-field class="" *ngIf="package.question[i].answerChoices[j].isSelectedAnswer">
                                                          <input
                                                            class=""
                                                            matInput type="number"
                                                            placeholder="Enter Qty"
                                                            name="quantityID[{{j}}{{i}}{{j+i}}]"
                                                            min="1"
                                                            onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off"
                                                            [attr.id]="id"
                                                            [(ngModel)]="package.question[i].answerChoices[j].Quantity"
                                                            #QtyTxt="ngModel"
                                                            (ngModelChange)="countAnswer()"
                                                            [disabled]="isReadOnly"
                                                            [required]="package.question[i].answerChoices[j].isSelectedAnswer"

                                                          >
                                                      <mat-error *ngIf="QtyTxt.errors">Enter valid quantity </mat-error> <!--( QtyTxt.dirty || QtyTxt.touched)-->
                                                      </mat-form-field>
                                                  </div>
                                              </div>
                                          </div>


                                      </div>

                                      <div
                                        *ngSwitchCase="'singleChoiceQuestion'"

                                      >
                                          <div *ngFor="let questions of ques.answerChoices;let j =index">
                                              <div class="answer-row mmc_flex__item">
                                                  <mat-radio-group name="answer-radio-group[{{j}}{{j+i}}]" [(ngModel)]="package.question[i].answeredText" aria-label="Select an option" (ngModelChange)="questionAnswered(package.question, $event,ques.id,questions.id)" [disabled]="isReadOnly">
                                                      <mat-radio-button class="answer-row mmc_flex__item" value="{{questions?.id}}">{{questions.name}} </mat-radio-button>
                                                  </mat-radio-group>
                                              </div>
                                          </div>


                                      </div>
                                    <div
                                      *ngSwitchCase="'fileUploadQuestion'"

                                    >
                                      <div>
                                        <opt-file-upload-questionire
                                          [maxCount]="ques.maxCount"
                                          [minCount]="ques.minCount"
                                          [maxSize]="ques.maxSize"
                                          [supportedFileType]="ques.supportedFileType"
                                          [question] ="ques"
                                          [answeredText]="ques.answeredText"
                                          [answerUploadedFiles]="ques.answerUploadedFiles"
                                          (answeredTextChanged)="questionAnswered(package.question, $event, ques.id, package.question[i].answeredText)"
                                        ></opt-file-upload-questionire>
                                      </div>


                                    </div>

                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>

                    <div class="button-row">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mmc_flex--container mmc_just-content-end" *ngIf="!isReadOnly">
                                    <div class="mmc_flex__item--inherit">
                                        <button mat-button class="mmc-btn-primary" (click)="submitData(package.question)" (keydown.enter)="submitData(package.question)" *ngIf="!isReadOnly" tabindex="0">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>


            </mat-expansion-panel>
            <!-- Facility Questions -->
            <mat-expansion-panel class="questionnaire-type facility" [disabled]="" [expanded]="true" *ngIf="facilitySummary">
              <mat-expansion-panel-header class="questionnaire-expansion" collapsedHeight="65px" expandedHeight="65px" aria-hidden="true">
                <div class="mmc_flex--container  questionnaire-expansion-header">
                  <div class="mmc_flex__item mmc_flex__item--vcenter">
                    <div class="mmc_flex--container space-between questionnaire-expansion-header--container">
                      <div class="mmc_flex__item mmc_flex__item--vcenter">
                        <div class="pw-txt-s1">
                          {{(data.isUpsellItem)? item.name: facilitySummary.name}}
                          <span>| {{answeredCurrentCount}} out of {{(data.isUpsellItem)? item?.totalQuestions : item?.totalQuestions + upsellTotalQuestion}} answered</span></div>
                      </div>

                      <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end">
                        <div class="expansion-header-icons mt-2">
                          <span class="icon-p2-questions icon f-size-md"></span>
                          <span class="count">{{((data.isUpsellItem)? item?.totalQuestions : item?.totalQuestions + upsellTotalQuestion ) - answeredCurrentCount }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end icon__expand wfixed">
                    <button class="down-arrow" mat-icon-button color="primary" tabindex="0" aria-label="expand arrow"
                            [disableRipple]="true">
                      <mat-icon></mat-icon>
                    </button>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <form>


                <div class="questionnaire-accordion" >
                  <div class="row">
                    <div class="col-sm-12">
                      <mat-accordion multi="true">
                        <mat-expansion-panel  [ngClass]="{'mandatory-question-unanswered': isSubmitAttempted && ques.isMandatoryUnanswered}"
                          [expanded]="true"
                          [disabled]="true"
                          [ngSwitch]="ques.type"
                          *ngFor="let ques of item?.question; let i=index;"
                          [hidden]="ques.isHidden"
                        >

                          <mat-expansion-panel-header [ngClass]="{'mandatory-question-unanswered': isSubmitAttempted && ques.isMandatoryUnanswered}">
                            <mat-panel-title class="font__semibold">
                              <div [innerHTML]="getSanitizedHtml(ques.questionText)"></div>
                            </mat-panel-title><span *ngIf="ques.mandatory">*</span>
                          </mat-expansion-panel-header>

                          <div *ngSwitchCase="'textQuestion'">
                            <div class="mmc_flex--container mmc_fd--col">
                              <div class="answer-row mmc_flex__item">
                                <mat-form-field class="">
                                  <input matInput placeholder="Enter your answer" name=Txt{{i}} required="{{ques.mandatory}}" [attr.id]="id" [(ngModel)]="item.question[i].answeredText" #Txt="ngModel" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                  <mat-error *ngIf="(Txt.dirty || Txt.touched) && Txt.errors?.required">Enter your answer</mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>

                          <div *ngSwitchCase="'booleanQuestion' ">
                            <div class="mmc_flex--container mmc_fd--col">
                              <div class="answer-row mmc_flex__item">
                                <mat-radio-group name="answer-radio-group{{i}}" [(ngModel)]="item.question[i].answeredText" aria-label="Select an option" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                  <mat-radio-button class="answer-row mmc_flex__item" value="Yes">Yes</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div class="answer-row mmc_flex__item">
                                <mat-radio-group name="answer-radio-group{{i}}" [(ngModel)]="item.question[i].answeredText" aria-label="Select an option" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                  <mat-radio-button class="answer-row mmc_flex__item" value="No">No</mat-radio-button>
                                </mat-radio-group>
                              </div>
                            </div>
                          </div>

                          <div *ngSwitchCase="'multipleChoiceQuestion'">
                            <div *ngIf="ques.isBookingQuestion">
                              <div *ngFor="let questions of ques.bookingQuestionChoices; let j=index">
                                <div class="mmc_flex--container mmc_fd--col" *ngIf="ques.question && !ques.question.quantityRequired">
                                  <div class="answer-row mmc_flex__item">
                                    <mat-checkbox name="check[{{j}}{{i}}{{j+i}}]" [(ngModel)]="item.question[i].bookingQuestionChoices[j].isSelectedAnswer" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                  </div>
                                </div>
                                <div class="mmc_flex--container mmc_fd--col multiple-choice-container" *ngIf="ques.question && ques.question.quantityRequired">
                                  <div class="answer-row mmc_flex__item multi-choice-w-text">
                                    <div class="answer-row mmc_flex__item">
                                      <mat-checkbox name="check[{{j}}{{i}}{{j+i}}]" [(ngModel)]="item.question[i].bookingQuestionChoices[j].isSelectedAnswer" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                    </div>
                                    <mat-form-field class="" appearance="legacy" *ngIf="item.question[i].bookingQuestionChoices[j].isSelectedAnswer">
                                      <input
                                        class=""
                                        matInput type="number"
                                        placeholder="Enter Qty"
                                        min="1"
                                        onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off"
                                        name="quantityID[{{j}}{{i}}{{j+i}}]"
                                        required="{{ques.question.quantityRequired}}"
                                        [attr.id]="id"
                                        [(ngModel)]="item.question[i].bookingQuestionChoices[j].Quantity"
                                        #QtyTxt="ngModel"
                                        (ngModelChange)="countAnswer()"
                                        [disabled]="isReadOnly"
                                        [required]="item.question[i].bookingQuestionChoices[j].isSelectedAnswer">
                                      <mat-error *ngIf="QtyTxt.errors && ( QtyTxt.dirty || QtyTxt.touched)">Enter valid quantity </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!ques.isBookingQuestion" class="multiple-choice-container">
                              <div *ngFor="let questions of ques.answerChoices; let j=index">
                                <div class="mmc_flex--container mmc_fd--col" *ngIf="!ques.quantityRequired">
                                  <div class="answer-row mmc_flex__item">
                                    <mat-checkbox name="check[{{j}}{{i}}{{j+i}}]" [(ngModel)]="item.question[i].answerChoices[j].isSelectedAnswer" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                  </div>
                                </div>
                                <div class="mmc_flex--container mmc_fd--col" *ngIf="ques.quantityRequired">
                                  <div class="answer-row mmc_flex__item multi-choice-w-text">
                                    <div class="answer-row mmc_flex__item">
                                      <mat-checkbox name="check[{{j}}{{i}}{{j+i}}]" [(ngModel)]="item.question[i].answerChoices[j].isSelectedAnswer" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                    </div>
                                    <mat-form-field class="" appearance="legacy" *ngIf="item.question[i].answerChoices[j].isSelectedAnswer">
                                      <input
                                        class=""
                                        matInput type="number"
                                        placeholder="Enter Qty"
                                        min="1"
                                        onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off"
                                        name="quantityID[{{j}}{{i}}{{j+i}}]"
                                        required="{{ques.quantityRequired}}"
                                        [attr.id]="id"
                                        [(ngModel)]="item.question[i].answerChoices[j].Quantity"
                                        #QtyTxt="ngModel" (ngModelChange)="countAnswer()" [disabled]="isReadOnly" [required]="item.question[i].answerChoices[j].isSelectedAnswer">
                                      <mat-error *ngIf="QtyTxt.errors && ( QtyTxt.dirty || QtyTxt.touched)">Enter valid quantity </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div *ngSwitchCase="'singleChoiceQuestion'">
                            <div *ngIf="ques.isBookingQuestion">
                              <div *ngFor="let questions of ques.bookingQuestionChoices;let j =index">
                                <div class="answer-row mmc_flex__item">
                                  <mat-radio-group name="answer-radio-group[{{j}}{{j+i}}]" [(ngModel)]="item.question[i].answeredText" aria-label="Select an option" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                    <mat-radio-button class="answer-row mmc_flex__item" value="{{item.question[i].bookingQuestionChoices[j].id}}">{{questions.name}} </mat-radio-button>
                                  </mat-radio-group>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!ques.isBookingQuestion">
                              <div *ngFor="let questions of ques.answerChoices;let j =index">
                                <div class="answer-row mmc_flex__item">
                                  <mat-radio-group name="answer-radio-group[{{j}}{{j+i}}]" [(ngModel)]="item.question[i].answeredText" aria-label="Select an option" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                    <mat-radio-button class="answer-row mmc_flex__item" value="{{item.question[i].answerChoices[j].id}}">{{questions.name}} </mat-radio-button>
                                  </mat-radio-group>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            *ngSwitchCase="'fileUploadQuestion'"

                          >
                            <div *ngIf="!ques.isBookingQuestion">
                              <opt-file-upload-questionire
                                [maxCount]="ques.maxCount"
                                [minCount]="ques.minCount"
                                [maxSize]="ques.maxSize"
                                [supportedFileType]="ques.supportedFileType"
                                [question] ="ques"
                                [answeredText]="ques.answeredText"
                                [answerUploadedFiles]="ques.answerUploadedFiles"
                                (answeredTextChanged)="countAnswer()"
                              ></opt-file-upload-questionire>
                            </div>
                            <div *ngIf="ques.isBookingQuestion">
                              <opt-file-upload-questionire
                                [maxCount]="ques.question.maxCount"
                                [minCount]="ques.question.minCount"
                                [maxSize]="ques.question.maxSize"
                                [supportedFileType]="ques.question.supportedFileType"
                                [question] ="ques.question"
                                [id]="ques.booingID"
                                [answeredText]="ques.answeredText"
                                [questionType] = "item.question[i]"
                                [answerUploadedFiles]="ques.answerUploadedFiles"
                                [isBookingQuestion] = "ques.isBookingQuestion"
                                (answeredTextChanged)="updateQuestion(ques)"
                              ></opt-file-upload-questionire>
                            </div>


                          </div>

                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>

                <div class="extras-label" *ngIf="isUpsellHasQuestion">
                  <div class="row">
                    <div class="col-sm-12 font__semibold txt-extra">
                      Extras
                    </div>
                  </div>
                </div>

                <div class="extras-accordion" *ngIf="isUpsellHasQuestion">
                  <div class="row">
                    <div class="col-sm-12" *ngFor="let upsellSummary of facilitySummary.upsellSummaries; let sumIndex=index;">
                      <mat-accordion multi="true">
                        <mat-expansion-panel
                          [expanded]="true"
                          [disabled]="true"
                          [ngSwitch]="upsellQues.type"
                          *ngFor="let upsellQues of upsellSummary.question; let i=index;"
                          [hidden]="upsellQues.isHidden"
                        >

                          <mat-expansion-panel-header [ngClass]="{'mandatory-question-unanswered': isSubmitAttempted && upsellQues.isMandatoryUnanswered}">
                            <mat-panel-title class="font__semibold">
                              <div [innerHTML]="getSanitizedHtml(upsellQues.questionText)"></div>
                            </mat-panel-title><span *ngIf="upsellQues.mandatory">*</span>
                          </mat-expansion-panel-header>
                          <div *ngSwitchCase="'textQuestion'">

                            <div class="mmc_flex--container mmc_fd--col">
                              <div class="answer-row mmc_flex__item">
                                <mat-form-field class="">
                                  <input matInput placeholder="Enter your answer" name=TxtUpsell{{i}} required="{{upsellQues.mandatory}}" [attr.id]="id" [(ngModel)]="upsellSummary.question[i].answeredText" #TxtUpsell="ngModel" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                  <mat-error *ngIf="(TxtUpsell.dirty || TxtUpsell.touched) && TxtUpsell.errors?.required">Enter your answer</mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>

                          <div *ngSwitchCase="'booleanQuestion'">
                            <div class="mmc_flex--container mmc_fd--col">
                              <div class="answer-row mmc_flex__item">
                                <mat-radio-group name="answer-radio-groupUpsell{{i}}" [(ngModel)]="upsellSummary.question[i].answeredText" aria-label="Select an option" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                  <mat-radio-button class="answer-row mmc_flex__item" value="Yes">Yes</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div class="answer-row mmc_flex__item">
                                <mat-radio-group name="answer-radio-groupUpsell{{i}}" [(ngModel)]="upsellSummary.question[i].answeredText" aria-label="Select an option" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                  <mat-radio-button class="answer-row mmc_flex__item" value="No">No</mat-radio-button>
                                </mat-radio-group>
                              </div>
                            </div>
                          </div>
                          <div *ngSwitchCase="'multipleChoiceQuestion'" class="multiple-choice-container">
                            <div *ngIf="upsellQues.isBookingQuestion">

                              <div *ngFor="let questions of upsellQues.bookingQuestionChoices; let j=index">

                                <div class="mmc_flex--container mmc_fd--col" *ngIf="upsellQues.question && !upsellQues.question.quantityRequired">
                                  <div class="answer-row mmc_flex__item">
                                    <mat-checkbox name="checkUpsell[{{j+i+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].bookingQuestionChoices[j].isSelectedAnswer" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                  </div>
                                </div>

                                <div class="mmc_flex--container mmc_fd--col" *ngIf="upsellQues.question && upsellQues.question.quantityRequired">
                                  <div class="answer-row mmc_flex__item multi-choice-w-text">
                                    <div class="answer-row mmc_flex__item">
                                      <mat-checkbox name="checkUpsell[{{j+i+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].bookingQuestionChoices[j].isSelectedAnswer" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                    </div>
                                    <mat-form-field class="" appearance="legacy" *ngIf="upsellSummary.question[i].bookingQuestionChoices[j].isSelectedAnswer">
                                      <input
                                        class=""
                                        matInput type="number"
                                        placeholder="Enter Qty"
                                        min="1"
                                        onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off"
                                        name="quantityIDUpsell[{{j+i+sumIndex}}]"
                                        required="{{upsellQues.question.quantityRequired}}" [attr.id]="id" [(ngModel)]="upsellSummary.question[i].bookingQuestionChoices[j].Quantity" #QtyTxt="ngModel" (ngModelChange)="countAnswer()" [disabled]="isReadOnly" [required]="upsellSummary.question[i].bookingQuestionChoices[j].isSelectedAnswer">
                                      <mat-error *ngIf="QtyTxt.errors && ( QtyTxt.dirty || QtyTxt.touched)">Enter valid quantity </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!upsellQues.isBookingQuestion" class="multiple-choice-container">

                              <div *ngFor="let questions of upsellQues.answerChoices; let j=index">

                                <div class="mmc_flex--container mmc_fd--col" *ngIf="!upsellQues.quantityRequired">
                                  <div class="answer-row mmc_flex__item">
                                    <mat-checkbox name="checkUpsell[{{j+i+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].answerChoices[j].isSelectedAnswer" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                  </div>
                                </div>

                                <div class="mmc_flex--container mmc_fd--col" *ngIf="upsellQues.quantityRequired">
                                  <div class="answer-row mmc_flex__item multi-choice-w-text">
                                    <div class="answer-row mmc_flex__item">
                                      <mat-checkbox name="checkUpsell[{{j+i+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].answerChoices[j].isSelectedAnswer" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                    </div>
                                    <mat-form-field class="" appearance="legacy" *ngIf="upsellSummary.question[i].answerChoices[j].isSelectedAnswer">
                                      <input
                                        class=""
                                        matInput type="number"
                                        placeholder="Enter Qty"
                                        min="1"
                                        onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off"
                                        name="quantityIDUpsell[{{j+i+sumIndex}}]" required="{{upsellQues.quantityRequired}}" [attr.id]="id" [(ngModel)]="upsellSummary.question[i].answerChoices[j].Quantity" #QtyTxt="ngModel" (ngModelChange)="countAnswer()" [disabled]="isReadOnly" [required]="upsellSummary.question[i].answerChoices[j].isSelectedAnswer">
                                      <mat-error *ngIf="QtyTxt.errors && ( QtyTxt.dirty || QtyTxt.touched)">Enter valid quantity </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div *ngSwitchCase="'singleChoiceQuestion'">
                            <div *ngIf="upsellQues.isBookingQuestion">

                              <div *ngFor="let questions of upsellQues.bookingQuestionChoices;let j =index">
                                <div class="answer-row mmc_flex__item">
                                  <mat-radio-group name="answer-radio-groupUpsell[{{i+j+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].answeredText" aria-label="Select an option" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                    <mat-radio-button class="answer-row mmc_flex__item" value="{{upsellSummary.question[i].bookingQuestionChoices[j].id}}">{{questions.name}} </mat-radio-button>
                                  </mat-radio-group>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!upsellQues.isBookingQuestion">

                              <div *ngFor="let questions of upsellQues.answerChoices;let j =index">
                                <div class="answer-row mmc_flex__item">
                                  <mat-radio-group name="answer-radio-groupUpsell[{{i+j+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].answeredText" aria-label="Select an option" (ngModelChange)="countAnswer()" [disabled]="isReadOnly">
                                    <mat-radio-button class="answer-row mmc_flex__item" value="{{upsellSummary.question[i].answerChoices[j].id}}">{{questions.name}} </mat-radio-button>
                                  </mat-radio-group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            *ngSwitchCase="'fileUploadQuestion'"

                          >
                            <div *ngIf="!upsellQues.isBookingQuestion">
                              <opt-file-upload-questionire
                                [maxCount]="upsellQues.maxCount"
                                [minCount]="upsellQues.minCount"
                                [maxSize]="upsellQues.maxSize"
                                [supportedFileType]="upsellQues.supportedFileType"
                                [question] ="upsellQues"
                                [answeredText]="upsellQues.answeredText"
                                [answerUploadedFiles]="upsellQues.answerUploadedFiles"
                                (answeredTextChanged)="countAnswer()"
                              ></opt-file-upload-questionire>
                            </div>
                            <div *ngIf="upsellQues.isBookingQuestion">
                              <opt-file-upload-questionire
                                [maxCount]="upsellQues.question.maxCount"
                                [minCount]="upsellQues.question.minCount"
                                [maxSize]="upsellQues.question.maxSize"
                                [supportedFileType]="upsellQues.question.supportedFileType"
                                [question] ="upsellQues.question"
                                [id]="upsellQues.booingID"
                                [answeredText]="upsellQues.answeredText"
                                [questionType] = "upsellSummary.question[i]"
                                [answerUploadedFiles]="upsellQues.answerUploadedFiles"
                                [isBookingQuestion] = "upsellQues.isBookingQuestion"
                                (answeredTextChanged)="updateQuestion(ques)"
                              ></opt-file-upload-questionire>
                            </div>


                          </div>

                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>

                <div class="button-row">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="mmc_flex--container mmc_just-content-end" *ngIf="!isReadOnly">
                        <div class="mmc_flex__item--inherit">
                          <button mat-button class="mmc-btn-primary" (click)="submitData(item.question)" (keydown.enter)="submitData(item.question)" tabindex="0" *ngIf="!isReadOnly">Continue</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-expansion-panel>


            <!-- Booking Questions -->
            <div *ngIf="booking && isFullBookingView">
                <mat-expansion-panel
                  class="questionnaire-type package"
                  [disabled]=""
                  [expanded]="true"
                  *ngIf="booking?.packageQuestion?.length>0"
                >
                    <mat-expansion-panel-header class="questionnaire-expansion" collapsedHeight="65px" expandedHeight="65px" aria-hidden="true">
                        <div class="mmc_flex--container  questionnaire-expansion-header">
                            <div class="mmc_flex__item mmc_flex__item--vcenter">
                                <div class="mmc_flex--container space-between questionnaire-expansion-header--container">
                                    <div class="mmc_flex__item mmc_flex__item--vcenter">
                                        <div class="pw-txt-s1">{{booking.package?.name}} <span>| {{booking.totalAnsweredPackageQuestions}} out of {{booking.totalPackageQuestions}} answered</span></div>
                                    </div>

                                    <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end">
                                        <div class="expansion-header-icons mt-2">
                                            <span class="icon-p2-questions icon f-size-md"></span>
                                            <span class="count">{{booking.packageQuestion.length}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end icon__expand wfixed">
                                <button class="down-arrow" mat-icon-button color="primary" tabindex="0" aria-label="expand arrow"
                                        [disableRipple]="true">
                                    <mat-icon></mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-expansion-panel-header>


                    <form>
                        <div class="questionnaire-accordion">
                            <div class="row">
                                <div class="col-sm-12">
                                    <mat-accordion multi="true">
                                        <mat-expansion-panel
                                          [expanded]="true"
                                          [disabled]="true"
                                          [ngSwitch]="ques.type"
                                          *ngFor="let ques of booking.packageQuestion; let i=index;"
                                          [hidden]="ques.isHidden"
                                        >
                                            <mat-expansion-panel-header [ngClass]="{'mandatory-question-unanswered': isSubmitAttempted && ques.isMandatoryUnanswered}">
                                                <mat-panel-title class="font__semibold">
                                                  <div [innerHTML]="getSanitizedHtml(ques.questionText)"></div>
                                                </mat-panel-title><span *ngIf="ques.mandatory">*</span>
                                            </mat-expansion-panel-header>

                                            <div *ngSwitchCase="'textQuestion'">
                                                <div class="mmc_flex--container mmc_fd--col">
                                                    <div class="answer-row mmc_flex__item">
                                                        <mat-form-field class="">
                                                            <input matInput placeholder="Enter your answer" name=Txt-pack-{{i}} required="{{ques.mandatory}}" [attr.id]="id" [(ngModel)]="booking.packageQuestion[i].answeredText" #Txt="ngModel" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                            <mat-error *ngIf="(Txt.dirty || Txt.touched) && Txt.errors?.required">Enter your answer</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngSwitchCase="'booleanQuestion'">
                                                <div class="mmc_flex--container mmc_fd--col">
                                                    <div class="answer-row mmc_flex__item">
                                                        <mat-radio-group name="answer-radio-group-pack-{{i}}" [(ngModel)]="booking.packageQuestion[i].answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                            <mat-radio-button class="answer-row mmc_flex__item" value="Yes">Yes</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="answer-row mmc_flex__item">
                                                        <mat-radio-group name="answer-radio-group-pack-{{i}}" [(ngModel)]="booking.packageQuestion[i].answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                            <mat-radio-button class="answer-row mmc_flex__item" value="No">No</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngSwitchCase="'multipleChoiceQuestion'" class="multiple-choice-container">
                                                <div *ngFor="let questions of ques.answerChoices; let j=index">
                                                    <div class="mmc_flex--container mmc_fd--col" *ngIf="ques.question && !ques.question.quantityRequired">
                                                        <div class="answer-row mmc_flex__item">
                                                            <mat-checkbox name="check-pack[{{j}}{{i}}{{j+i}}]" [(ngModel)]="booking.packageQuestion[i].answerChoices[j].isSelectedAnswer" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="mmc_flex--container mmc_fd--col" *ngIf="ques.question && ques.question.quantityRequired">
                                                        <div class="answer-row mmc_flex__item multi-choice-w-text">
                                                          <div class="answer-row mmc_flex__item">
                                                            <mat-checkbox name="check-pack[{{j}}{{i}}{{j+i}}]" [(ngModel)]="booking.packageQuestion[i].answerChoices[j].isSelectedAnswer" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                                          </div>
                                                            <mat-form-field class="" appearance="legacy" *ngIf="booking.packageQuestion[i].answerChoices[j].isSelectedAnswer">
                                                            <input
                                                              class=""
                                                              matInput type="number"
                                                              placeholder="Enter Qty"
                                                              name="quantitypackID[{{j}}{{i}}{{j+i}}]"
                                                              required="{{ques.question.quantityRequired}}"
                                                              [attr.id]="id"
                                                              [(ngModel)]="booking.packageQuestion[i].answerChoices[j].Quantity" #QtyTxt="ngModel"
                                                              (ngModelChange)="updateQuestion(ques)"
                                                              [disabled]="isReadOnly"
                                                              [required]="booking.packageQuestion[i].answerChoices[j].isSelectedAnswer"
                                                              min="1"
                                                              onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off"
                                                            >
                                                              <mat-error *ngIf="QtyTxt.errors && ( QtyTxt.dirty || QtyTxt.touched)">Enter valid quantity </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngSwitchCase="'singleChoiceQuestion'">
                                                <div *ngFor="let questions of ques.bookingQuestionChoices;let j =index">
                                                    <div class="answer-row mmc_flex__item">
                                                        <mat-radio-group name="answer-radio-group-pack[{{j}}{{j+i}}]" [(ngModel)]="booking.packageQuestion[i].answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                            <mat-radio-button class="answer-row mmc_flex__item" value="{{booking.packageQuestion[i].bookingQuestionChoices[j].id}}">{{questions.name}} </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                </div>
                                            </div>
                                          <div
                                            *ngSwitchCase="'fileUploadQuestion'"

                                          >
                                            <div>
                                              <opt-file-upload-questionire
                                                [maxCount]="ques.question.maxCount"
                                                [minCount]="ques.question.minCount"
                                                [maxSize]="ques.question.maxSize"
                                                [supportedFileType]="ques.question.supportedFileType"
                                                [question] ="ques.question"
                                                [id]="ques.booingID"
                                                [answeredText]="ques.answeredText"
                                                [questionType] = "booking.packageQuestion[i]"
                                                [answerUploadedFiles]="ques.answerUploadedFiles"
                                                [isBookingQuestion] = "ques.isBookingQuestion"
                                                (answeredTextChanged)="updateQuestion(ques)"
                                              ></opt-file-upload-questionire>
                                            </div>


                                          </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>

                            </div>
                        </div>
                    </form>
                </mat-expansion-panel>

                <div *ngIf="booking.facilitySummaries && booking.facilitySummaries.length>0">
            <div *ngFor="let facilitySummary of booking.facilitySummaries; let ij=index;">

                <mat-expansion-panel
                  class="questionnaire-type facility"
                  [disabled]=""
                  [expanded]="true"
                  *ngIf="facilitySummary.totalQuestions>0 && facilitySummary.bookingItemSummaries && facilitySummary.bookingItemSummaries.length>0"
                >
                    <mat-expansion-panel-header class="questionnaire-expansion" collapsedHeight="65px" expandedHeight="65px" aria-hidden="true">
                        <div class="mmc_flex--container  questionnaire-expansion-header">
                            <div class="mmc_flex__item mmc_flex__item--vcenter">
                                <div class="mmc_flex--container space-between questionnaire-expansion-header--container">
                                    <div class="mmc_flex__item mmc_flex__item--vcenter">
                                        <div class="pw-txt-s1">{{facilitySummary.name}} <span>| {{facilitySummary.totalAnswerdQuestions}} out of {{facilitySummary.totalQuestions}} answered</span></div>
                                    </div>

                                    <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end">
                                        <div class="expansion-header-icons mt-2">
                                            <span class="icon-p2-questions icon f-size-md"></span>
                                            <span class="count">{{facilitySummary.totalQuestions}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--inherit mmc_just-content-end icon__expand wfixed">
                                <button class="down-arrow" mat-icon-button color="primary" tabindex="0" aria-label="expand arrow"
                                        [disableRipple]="true">
                                    <mat-icon></mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-expansion-panel-header>

                    <form>
                        <div *ngFor="let bookingitemSummary of facilitySummary.bookingItemSummaries; let jl=index;">
                            <div class="questionnaire-accordion" *ngIf="bookingitemSummary.question && bookingitemSummary.question.length>0 ">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <mat-accordion multi="true">
                                            <mat-expansion-panel
                                              [expanded]="true"
                                              [disabled]="true"
                                              [ngSwitch]="ques.type"
                                              *ngFor="let ques of bookingitemSummary?.question; let k=index;"
                                              [hidden]="ques.isHidden"
                                            >
                                                <mat-expansion-panel-header [ngClass]="{'mandatory-question-unanswered': isSubmitAttempted && ques.isMandatoryUnanswered}">
                                                    <mat-panel-title class="font__semibold">
                                                      <div [innerHTML]="getSanitizedHtml(ques.questionText)"></div>
                                                    </mat-panel-title><span *ngIf="ques.mandatory">*</span>
                                                </mat-expansion-panel-header>

                                                <div *ngSwitchCase="'textQuestion'">
                                                    <div class="mmc_flex--container mmc_fd--col">
                                                        <div class="answer-row mmc_flex__item">
                                                            <mat-form-field class="">
                                                                <input matInput placeholder="Enter your answer" name=Txt-item-{{ij}}{{jl}}{{k}} required="{{ques.mandatory}}" [attr.id]="id" [(ngModel)]="ques.answeredText" #Txt="ngModel" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                                <mat-error *ngIf="(Txt.dirty || Txt.touched) && Txt.errors?.required">Enter your answer</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngSwitchCase="'booleanQuestion'">
                                                    <div class="mmc_flex--container mmc_fd--col">
                                                        <div class="answer-row mmc_flex__item">
                                                          <mat-radio-group name="answer-radio-group-item-y-{{ij}}{{jl}}{{k}}" [(ngModel)]="ques.answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                            <mat-radio-button class="answer-row mmc_flex__item" value="Yes">Yes</mat-radio-button>
                                                          </mat-radio-group>
                                                        </div>
                                                        <div class="answer-row mmc_flex__item">
                                                            <mat-radio-group name="answer-radio-group-item-n-{{ij}}{{jl}}{{k}}" [(ngModel)]="ques.answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                                <mat-radio-button class="answer-row mmc_flex__item" value="No">No</mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngSwitchCase="'multipleChoiceQuestion'" class="multiple-choice-container">
                                                    <div *ngFor="let questions of ques.bookingQuestionChoices; let jk=index">
                                                        <div class="mmc_flex--container mmc_fd--col" *ngIf="ques.question && !ques.question.quantityRequired">
                                                            <div class="answer-row mmc_flex__item">
                                                                <mat-checkbox name="checkitem[{{ques.id}}{{ij}}{{jl}}{{jk}}{{jk*k}}]" [(ngModel)]="ques.bookingQuestionChoices[jk].isSelectedAnswer" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                                            </div>
                                                        </div>
                                                        <div class="mmc_flex--container mmc_fd--col" *ngIf="ques.question && ques.question.quantityRequired">
                                                            <div class="answer-row mmc_flex__item multi-choice-w-text">
                                                              <div class="answer-row mmc_flex__item">
                                                                <mat-checkbox name="checkitem[{{ques.id}}{{ij}}{{jl}}{{jk}}{{jk*k}}]" [(ngModel)]="ques.bookingQuestionChoices[jk].isSelectedAnswer" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                                              </div>
                                                                <mat-form-field class="" appearance="legacy" *ngIf="ques.bookingQuestionChoices[jk].isSelectedAnswer">
                                                                    <input
                                                                      class=""
                                                                      matInput type="number"
                                                                      placeholder="Enter Qty"
                                                                      min="1"
                                                                      onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off"
                                                                      name="quantityitemID[{{ij}}{{jl}}{{k}}{{jk*k}}]" required="{{ques.question.quantityRequired}}" [attr.id]="id" [(ngModel)]="ques.bookingQuestionChoices[jk].Quantity" #QtyTxt="ngModel" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly" [required]="ques.bookingQuestionChoices[jk].isSelectedAnswer">
                                                                  <mat-error *ngIf="QtyTxt.errors && ( QtyTxt.dirty || QtyTxt.touched)">Enter valid quantity </mat-error>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngSwitchCase="'singleChoiceQuestion'">
                                                    <div *ngFor="let questions of ques.bookingQuestionChoices;let jk =index">
                                                        <div class="answer-row mmc_flex__item">
                                                            <mat-radio-group name="answer-radio-groupitem[{{ij}}{{jl}}{{k}}{{jk+k}}]" [(ngModel)]="ques.answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                                <mat-radio-button class="answer-row mmc_flex__item" value="{{ques.bookingQuestionChoices[jk].id}}">{{questions.name}} </mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                    </div>
                                                </div>

                                              <div
                                                *ngSwitchCase="'fileUploadQuestion'"
                                              >
                                                <div>
                                                  <opt-file-upload-questionire
                                                    [maxCount]="ques.question.maxCount"
                                                    [minCount]="ques.question.minCount"
                                                    [maxSize]="ques.question.maxSize"
                                                    [supportedFileType]="ques.question.supportedFileType"
                                                    [question] ="ques.question"
                                                    [id]="ques.booingID"
                                                    [answeredText]="ques.answeredText"
                                                    [questionType] = "bookingitemSummary?.question[k]"
                                                    [answerUploadedFiles]="ques.answerUploadedFiles"
                                                    [isBookingQuestion] = "ques.isBookingQuestion"
                                                    (answeredTextChanged)="updateQuestion(ques)"
                                                  ></opt-file-upload-questionire>
                                                </div>


                                              </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                </div>
                            </div>

                            <div class="extras-label" *ngIf="bookingitemSummary.isUpsellHasQuestion">
                                <div class="row">
                                    <div class="col-sm-12 font__semibold txt-extra">
                                        Extras
                                    </div>
                                </div>
                            </div>

                            <div class="extras-accordion" *ngIf="bookingitemSummary.isUpsellHasQuestion">
                                <div class="row">
                                    <div
                                      class="col-sm-12"
                                      *ngFor="let upsellSummary of bookingitemSummary.upsellSummaries; let sumIndex=index;"

                                    >
                                        <mat-accordion multi="true">
                                            <mat-expansion-panel
                                              [expanded]="true"
                                              [disabled]="true"
                                              [ngSwitch]="upsellQues.type"
                                              *ngFor="let upsellQues of upsellSummary.question; let i=index;"
                                              [hidden]="upsellQues.isHidden"
                                            >
                                                <mat-expansion-panel-header [ngClass]="{'mandatory-question-unanswered': isSubmitAttempted && upsellQues.isMandatoryUnanswered}">
                                                    <mat-panel-title class="font__semibold">
                                                      <div [innerHTML]="getSanitizedHtml(upsellQues.questionText)"></div>
                                                    </mat-panel-title><span *ngIf="upsellQues.mandatory">*</span>
                                                </mat-expansion-panel-header>


                                                <div *ngSwitchCase="'textQuestion'">

                                                    <div class="mmc_flex--container mmc_fd--col">
                                                        <div class="answer-row mmc_flex__item">
                                                            <mat-form-field class="">
                                                                <input matInput placeholder="Enter your answer" name=TxtUpsell{{i}} required="{{upsellQues.mandatory}}" [attr.id]="id" [(ngModel)]="upsellQues.answeredText" #TxtUpsell="ngModel" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                                <mat-error *ngIf="(TxtUpsell.dirty || TxtUpsell.touched) && TxtUpsell.errors?.required">Enter your answer</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngSwitchCase="'booleanQuestion'">
                                                  <div class="mmc_flex--container mmc_fd--col">
                                                    <div class="answer-row mmc_flex__item">
                                                      <mat-radio-group name="answer-radio-groupUpsell{{i}}" [(ngModel)]="upsellSummary.question[i].answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                        <mat-radio-button class="answer-row mmc_flex__item" value="Yes">Yes</mat-radio-button>
                                                      </mat-radio-group>
                                                    </div>
                                                    <div class="answer-row mmc_flex__item">
                                                      <mat-radio-group name="answer-radio-groupUpsell{{i}}" [(ngModel)]="upsellSummary.question[i].answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">
                                                        <mat-radio-button class="answer-row mmc_flex__item" value="No">No</mat-radio-button>
                                                      </mat-radio-group>
                                                    </div>
                                                  </div>



                                                </div>

                                                <div *ngSwitchCase="'multipleChoiceQuestion'" class="multiple-choice-container">

                                                  <div *ngFor="let questions of upsellQues.bookingQuestionChoices; let j=index">

                                                    <div class="mmc_flex--container mmc_fd--col" *ngIf="!upsellQues.quantityRequired">
                                                      <div class="answer-row mmc_flex__item">
                                                        <mat-checkbox name="checkUpsell[{{j+i+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].bookingQuestionChoices[j].isSelectedAnswer" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                                      </div>
                                                    </div>

                                                    <div class="mmc_flex--container mmc_fd--col" *ngIf="upsellQues.quantityRequired">
                                                      <div class="answer-row mmc_flex__item multi-choice-w-text">
                                                        <div class="answer-row mmc_flex__item">
                                                          <mat-checkbox name="checkUpsell[{{j+i+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].bookingQuestionChoices[j].isSelectedAnswer" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly">{{questions.name}}</mat-checkbox>
                                                        </div>
                                                        <mat-form-field class="" appearance="legacy" *ngIf="upsellSummary.question[i].bookingQuestionChoices[j].isSelectedAnswer">
                                                          <input
                                                            class=""
                                                            matInput type="number"
                                                            placeholder="Enter Qty"
                                                            min="1"
                                                            onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off"
                                                            name="quantityIDUpsell[{{j+i+sumIndex}}]" required="{{upsellQues.quantityRequired}}" [attr.id]="id" [(ngModel)]="upsellSummary.question[i].bookingQuestionChoices[j].Quantity" #QtyTxt="ngModel" (ngModelChange)="updateQuestion(ques)" [disabled]="isReadOnly" [required]="upsellSummary.question[i].bookingQuestionChoices[j].isSelectedAnswer">
                                                          <mat-error *ngIf="QtyTxt.errors && ( QtyTxt.dirty || QtyTxt.touched)">Enter valid quantity </mat-error>
                                                        </mat-form-field>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div *ngSwitchCase="'singleChoiceQuestion'">
                                                  <div *ngFor="let questions of upsellQues.bookingQuestionChoices;let j =index">
                                                    <div class="answer-row mmc_flex__item">
                                                      <mat-radio-group name="answer-radio-groupUpsell[{{i+j+sumIndex}}]" [(ngModel)]="upsellSummary.question[i].answeredText" aria-label="Select an option" (ngModelChange)="updateQuestion(ques)">
                                                        <mat-radio-button class="answer-row mmc_flex__item" value="{{upsellSummary.question[i].bookingQuestionChoices[j].id}}">{{questions.name}} </mat-radio-button>
                                                      </mat-radio-group>
                                                    </div>
                                                  </div>
                                                </div>
                                              <div
                                                *ngSwitchCase="'fileUploadQuestion'"
                                              >
                                                <div>
                                                  <opt-file-upload-questionire
                                                    [maxCount]="upsellQues.question.maxCount"
                                                    [minCount]="upsellQues.question.minCount"
                                                    [maxSize]="upsellQues.question.maxSize"
                                                    [supportedFileType]="upsellQues.question.supportedFileType"
                                                    [question] ="upsellQues.question"
                                                    [id]="upsellQues.booingID"
                                                    [answeredText]="upsellSummary.question[i].answeredText"
                                                    [questionType] = "upsellSummary.question[i]"
                                                    [answerUploadedFiles]="upsellQues.answerUploadedFiles"
                                                    [isBookingQuestion] = "upsellQues.isBookingQuestion"
                                                    (answeredTextChanged)="updateQuestion(ques)"
                                                  ></opt-file-upload-questionire>
                                                </div>


                                              </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>


                </mat-expansion-panel>

                <div>

                </div>

            </div>
        </div>

                <div class="button-row">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mmc_flex--container mmc_just-content-end" *ngIf="!isReadOnly">
                                <div class="mmc_flex__item--inherit">
                                    <button mat-button class="mmc-btn-primary" (click)="submitBookingData()" (keydown.enter)="submitBookingData()" tabindex="0" *ngIf="!isReadOnly">Continue</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


</mat-accordion>


                            </div>

                        </div>
                        <!--</div>-->
