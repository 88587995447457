<div class="mmc-modal-wrapper alternative-options-modal add-extras-modal">
    <div class="mmc-modal-header mmc_flex--container">
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center">
            <i class="material-icons">star_border</i>
        </div>
        <div class="mmc_flex__item mmc_flex__item--vcenter">
            <span class="section-title">Alternative Options</span>
        </div>
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
            <button mat-button [mat-dialog-close]="true"><i class="material-icons">close</i></button>
        </div>
    </div>
    <div class="mmc-modal-body" *ngIf ="1<=items.length">

        <div class="row">
            <div class="col-sm-12">
                <div class="font__small items-available-label opt-txt__caption font__semibold available-count">{{items.length}} items available</div>
            </div>
        </div>
        <div class="row" *ngFor="let itm of items ; let i = index">
            <div class="col-sm-12">
                <div class="inner-container">
                    <div class="mmc_card search--result">
                        <div class="mmc_flex--container mobile-card">

                            <div class="mmc_flex__item content">
                                <div class="mmc_flex--container">
                                    <div class="mmc_flex__item">
                                        <div class='item_name'>
                                            <div class="pw-txt-h3_small pw-text-color__primary pw-text-semi-bold">{{itm.name}}</div>
                                        </div>
                                        <div class='item_description opt-txt__caption'>
                                            <div class="mmc_flex--container ">
                                                <div class="mmc_flex__item--vcenter">


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mmc_flex__item--inherit mmc_just-content-center mmc_fd--col quentity">
                                        <label class="pw-txt-b3 qty font__semibold">Available Configurations</label>
                                        <div class="mmc_flex--container" >
                                            <div class="mmc_flex__item--hcenter mr-3 config-item mmc_fd--col" *ngFor="let config of configurations(itm)">
                                                <div class="cog-d-none config-img"><img *ngIf="config.configurationThumbnail" src="{{config.configurationThumbnail}}" alt="{{config.name}}" /></div>
                                                <div class="pw-txt-s2">{{config.name}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mmc_flex__item butn__cage">
                                <div class="price">
                                    <!--<span class="per-item__amount font__semibold">$95/Item</span>-->
                                    <span class="total__amount pw-text-bold pw-txt-h3_small" *ngIf="!calculatedPriceRange(itm) && getcalculatedPrice(itm)">{{getcalculatedPrice(itm)| CurrencyFormat}}</span>
                                    <span class="total__amount pw-text-bold pw-txt-h3_small" *ngIf="calculatedPriceRange(itm)"> {{itm.calculatedMinPrice?.priceIncludingTax| CurrencyFormat}} - {{itm.calculatedMaxPrice?.priceIncludingTax| CurrencyFormat}} </span>

                                </div>
                                <div *ngIf="getcalculatedPrice(itm)">
                                    <button mat-button class="mmc-btn-primary mmc_flex__item--vcenter" tabindex="0" (click)="addToBookingAlternative(itm)" (keydown.enter)="addToBookingAlternative(itm)" role="button">Add to booking</button>
                                </div>
                                <div *ngIf="!getcalculatedPrice(itm)">
                                    <div class="pw-txt-b2">prices are not defined</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>












