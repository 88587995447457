import { BrowserCacheLocation, Configuration, InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "../../../../environments/environment";
import { AppSetting } from '../../../../modules/models/settings/casual-portal/app-setting';
import { AzureB2CIntegration } from "../../../models/settings/casual-portal/b2c-settings";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";

if (!(environment.AppSetting as AppSetting)?.B2CIntegration?.AzureB2CIntegration || !((environment.AppSetting as AppSetting)?.B2CIntegration?.AzureB2CIntegration?.ClientId)) {
   let _window: any = window;
   if (_window["AzureB2CSettings"]) {
      if (!environment.AppSetting) {
         environment.AppSetting = {}
      }
      if (!environment.AppSetting["B2CIntegration"]) {
         environment.AppSetting["B2CIntegration"] = {}
      }
      environment.AppSetting["B2CIntegration"]["AzureB2CIntegration"] = JSON.parse(_window["AzureB2CSettings"])
   }
}


const _appSettings = environment.AppSetting as AppSetting;


var b2cSetting: AzureB2CIntegration = _appSettings.B2CIntegration?.AzureB2CIntegration ?? {};
export const b2cPolicies = {
   "names": {
      "signUpSignIn": b2cSetting.SignUpSignIn?.ProfileName ?? "",
      "editProfile": b2cSetting.ProfileEdit?.ProfileName ?? "",
   },
   "authorities": {
      "signUpSignIn": {
         "authority": b2cSetting.SignUpSignIn?.AuthorityUrl ?? ""
      },
      "editProfile": {
         "authority": b2cSetting.ProfileEdit?.AuthorityUrl ?? ""
      }
   },
   "authorityDomain": b2cSetting.Domain ?? ""

};


export const msalConfig: Configuration = {
   auth: {
      clientId: b2cSetting.ClientId ?? "",
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: b2cSetting.SignUpSignIn?.RedirectUrl ?? "",
      postLogoutRedirectUri: b2cSetting.PostLogoutUrl ?? "",
   },

   cache: {

      storeAuthStateInCookie: true,
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      //storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
   },
   // More configuration here
}

export const msalConfigProfileEdit: Configuration = {
   auth: {
      clientId: b2cSetting.ClientId ?? "",
      authority: b2cPolicies.authorities.editProfile.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: b2cSetting.ProfileEdit?.RedirectUrl ?? "",
      postLogoutRedirectUri: b2cSetting.PostLogoutUrl ?? "",
   },

   cache: {

      storeAuthStateInCookie: true,
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      //storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
   },
   // More configuration here
}

export const editProfileMsalInstance = new PublicClientApplication(msalConfigProfileEdit);



/* export const protectedResources = {
 todoListApi: {
   endpoint: "http://localhost:5000/api/todolist",
   scopes: ["https://your-tenant-namee.onmicrosoft.com/api/tasks.read"],
 },
} */

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
   scopes: []
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "loginHint" property (such as a username). For more, visit:
 * https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-between-different-apps
 * If you do not receive the username claim in ID tokens, see also:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#why-is-getaccountbyusername-returning-null-even-though-im-signed-in
 */
export const silentRequest = {
   scopes: ['openid', 'profile'],//, 'offline_access',
   timeout: 60000 // Set a specific timeout for this call
   // loginHint: "example@domain.net"
};

export const editProfileRequest = {
   scopes: ['openid', 'profile'],//, 'offline_access'
   authority: b2cPolicies.authorities.editProfile.authority,
   timeout: 60000 // Set a specific timeout for this call
}


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {

   return new PublicClientApplication(msalConfig);
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
   return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
   };
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
   return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
         ['/', [msalConfig.auth.clientId + `/openid`]],
      ]),
   }
};

//export const msalInstance =MSALInstanceFactory();
